/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useRef, useState } from "react";
// import close from "../../Assets/Images/closeee.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientmedicationlist,
  patientaddmedication,
  patientMedicationEdit,
  getPatientfamilymembers as getpatientfamilymemberslist,
} from "../../../../../Redux/Action/UserprofileActions";
import moment from "moment";
import { DatePicker, message } from "antd";
import { getLocalTime } from "../../../../Helper/LocalTimeFormat";
import { ToastContainer, toast } from 'react-toastify';
import { Form, Input } from "antd";

function Addmedication(props) {

  const [memberList, setMemberList] = useState([]);
  const [FamilymemberName, setFamilymemberName] = useState("");
  const location = useLocation();
  const [addmedication, setaddmedication] = useState([]);
  const history = useHistory();
  const [fromDate, setfromDate] = useState("");
  const [fromDateShow, setfromDateShow] = useState("");
  const [toDateShow, setToShow] = useState("");
  const [toDate, settoDate] = useState("");
  const dispatch = useDispatch();
  const formRef = useRef();
  const [DateFrom, setDateFrom] = useState("");
  const [DateTo, setDateTo] = useState("");
  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  useEffect(() => {
    // dispatch(
    //   getpatientfamilymemberslist(props.patient ? props.patient : patientCode)
    // )
    //   .then((result) => {
    //     console.log(result);
    //     setMemberList(result);
    //   })
    //   .catch((error) => {
    //     // setLoader(false)
    //     // redirectTo();
    //   });
    if (props?.editmedication) {

      console.log('mydscnkdsnc', JSON.stringify(props?.editmedication))
      setaddmedication(props?.editmedication);
      setfromDateShow(
        moment(props?.editmedication?.startCreatedTime).format("MM/DD/yyyy")
      );
      setToShow(
        moment(props?.editmedication?.endCreatedTime).format("MM/DD/yyyy")
      );
    }
  }, []);
  const goBack = () => {
    props.closePopup();
  };
  const handleChangeFamilymem = (e) => {
    setFamilymemberName(e);
  };
  const handleChange = (e) => {
    setaddmedication({ ...addmedication, [e.target.name]: e.target.value });
  };

  const addmedicationclick = (e) => {
    e.preventDefault();
    const formValues = formRef.current.getFieldsValue();
    console.log('Form values:', formValues);
    const { drugName, dosage } = formValues;
    if (!drugName) {
      message.error("Please enter drug name.")

    } else if (!DateFrom) {
      message.error("Please select From Date.")
    } else if (!DateTo) {
      message.error("Please select To Date.")
    }
    else {
      let patient = localStorage.getItem("patientprofile");
      const givenDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
      const data = [
        {
          patientCode: props.patCode,
          drugName: drugName,
          dosage: dosage,
          startCreatedTime: DateFrom,
          endCreatedTime: DateTo,
          givenDate: givenDate,
          status: 1,
          id: "",
          createdBy: props.patCode,
          modifiedBy: props.patCode,
          enteredBy: "P",
          fromDate: DateFrom,
          toDate: DateTo,
          // "strength": addmedication.strength,
          recordFor: "",
          //-- -> P means patient and U means User
        },
      ];
      dispatch(patientaddmedication(data)).then((result) => {
        dispatch(getPatientmedicationlist(props.patCode));
        props.loadData();
        formRef.current.resetFields();
        message.success("Added Successfully");
        setaddmedication({})
        props.closePopup();
      });
    }
  };


  const handleDateChange = (date, dateString) => {
    const Currdate = new Date();
    const hours = String(Currdate.getHours()).padStart(2, '0');
    const minutes = String(Currdate.getMinutes()).padStart(2, '0');
    const seconds = String(Currdate.getSeconds()).padStart(2, '0');
    const constructedDate = dateString + ` ${hours}:${minutes}:${seconds}`;
    console.log(constructedDate)
    const formattedDate = moment(constructedDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(formattedDate);
    setDateFrom(formattedDate);
  };
  const handleDateToChange = (date, dateString) => {
    const Currdate = new Date();
    const hours = String(Currdate.getHours()).padStart(2, '0');
    const minutes = String(Currdate.getMinutes()).padStart(2, '0');
    const seconds = String(Currdate.getSeconds()).padStart(2, '0');
    const constructedDate = dateString + ` ${hours}:${minutes}:${seconds}`;
    console.log(constructedDate)
    const formattedDate = moment(constructedDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(formattedDate);
    setDateTo(formattedDate);
  };
  const changeDate = (e) => {
    setfromDate(moment(e).format("YYYY-MM-DD hh:mm:ss"));
    setfromDateShow(moment(e).format("DD/MM/yyyy"));
  };
  const changeToDate = (e) => {
    settoDate(moment(e).format("YYYY-MM-DD hh:mm:ss"));
    setToShow(moment(e).format("DD/MM/yyyy"));
  };
  return (
    <>
      <Form ref={formRef} className="CommonForm" layout="vertical" autoComplete="off">
        <Form.Item label="Drug Name" name="drugName">
          <Input placeholder="Enter Drug Name" />
        </Form.Item>
        <Form.Item label="Dosage" name="dosage">
          <Input placeholder="Enter Dosage" />
        </Form.Item>
        <Form.Item label="From Date" name="fromDate">
          <div className="flex ">
            <DatePicker className="w-full h-[50px]" onChange={handleDateChange} />
          </div>
        </Form.Item>
        <Form.Item label="To Date" name="toDate">
          <div className="flex ">
            <DatePicker className="w-full h-[50px]" onChange={handleDateToChange} />
          </div>
        </Form.Item>
        <Form.Item>
          <button
            onClick={addmedicationclick}
            className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center "
            type="primary"
            htmlType="submit"
          >
            Save details
          </button>
        </Form.Item>
      </Form>
    </>
  );
}
export default Addmedication;
