import React from "react";
import Layouts from "../../Layouts";
import CareerImage from "../../../Assets/Images/career.jpg"
import SubHeader from "../../Shared/SubHeader";

function Career() {
  return (
    <Layouts>
      <SubHeader />
      <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Join CureBay. Join the Healthcare Revolution.
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
              We are India's fastest growing  health-tech company
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Career.webp"
                  alt="images"
                  className="w-full h-full image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="md:grid md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px] mb-10 block md:hidden">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Career/career3.jpg"
                alt="images"
                className="rounded-xl w-full h-full"
              />
            </div>
            <p className="text-base md:leading-loose text-textGray">
              Joining CureBay means being part of India’s fastest-growing health
              tech startup revolutionizing healthcare accessibility. Our hybrid
              and flexible work culture empowers you to thrive in a dynamic
              environment where innovation meets impact.
            </p>
            <div className="md:h-[350px] hidden md:block">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Career/career3.jpg"
                alt="images"
                className="rounded-xl w-full h-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px]">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Career/career1.jpg"
                alt="images"
                className="rounded-xl w-full h-full "
              />
            </div>
            <p className="text-base md:leading-loose text-White">
              As a member of the CureBay team, you’ll be at the forefront of
              transforming healthcare delivery across India. Our innovative
              approach combines cutting-edge technology with a human touch,
              ensuring that every individual, regardless of their location, has
              access to quality healthcare services.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="md:grid grid-cols-2 gap-10 items-center">
          <div className="md:h-[350px] mb-10  md:hidden block">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Career/career2.jpg"
                alt="images"
                className="rounded-xl w-full h-full "
              />
            </div>
            <p className="text-base md:leading-loose text-textGray">
              At CureBay, we value diversity, collaboration, and creativity. Our
              inclusive work environment encourages you to bring your unique
              perspectives and ideas to the table, fostering innovation and
              driving positive change in the healthcare industry.
            </p>
            <div className="md:h-[350px]  hidden md:block">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Career/career2.jpg"
                alt="images"
                className="rounded-xl w-full h-full "
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:h-[350px]">
              <img
                src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Career/career4.jpg"
                alt="images"
                className="rounded-xl w-full h-full "
              />
            </div>
            <p className="text-base md:leading-loose text-White">
              With our flexible work culture, you have the freedom to balance
              your professional and personal commitments, allowing you to work
              in a way that best suits your lifestyle. Whether you prefer remote
              work or thrive in a collaborative office setting, CureBay offers
              opportunities for growth and development that align with your
              career aspirations.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="md:grid grid-cols-2 gap-10 items-center">
          <div className="md:h-[350px] mb-10 md:hidden block">
              <img
                src={CareerImage}
                alt="images"
                className="rounded-xl w-full h-full "
              />
            </div>
            <p className="text-base md:leading-loose text-textGray">
              Join us in our mission to make healthcare accessible to all and be
              part of a team that is revolutionizing the future of healthcare in
              India and beyond.
            </p>
            <div className="md:h-[350px]  hidden md:block">
              <img
                src={CareerImage}
                alt="images"
                className="rounded-xl w-full h-full "
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <div className="bg-primaryColor py-10 px-10 text-White md:leading-loose rounded-xl">
            Please reach out to us at{" "}
            <span className="cursor-pointer text-secondaryColor" onClick={() => window.open("mailto:resumes@curebay.com")}>
              resumes@curebay.com
            </span>{" "}
            and share how you envision contributing to the transformation of
            healthcare in rural India within 100 words. And, send your resume to
            <span className="cursor-pointer text-secondaryColor" onClick={() => window.open("mailto:resumes@curebay.com")}>resumes@curebay.com</span> along with your response. We eagerly await
            hearing about your potential impact on revolutionizing healthcare
            accessibility in remote Indian communities.
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Career;
