import React, { useState, useRef, useEffect } from "react";
// import arrowicon from "../../Assets/Images/arrowicon.png";
// import attach from "../../Assets/Images/attach.png";
import { InputText } from "primereact/inputtext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { secureStorage } from "../../Redux/Reducer/authReducer";
import ChatLoading from "../Shared/chatLoading";
import {
  Box,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { Card } from "primereact/card";
import { useSelector } from "react-redux";
import { Modal, Drawer } from "antd";
import moment from "moment";
import { MenuOutlined } from "@ant-design/icons";

const CareSathi = () => {
  const [getAnswerBot, setgetAnswerBot] = useState([]);
  const [message, setMessage] = useState("");
  const [question, setQuestion] = useState("");
  let s_token = secureStorage.getItem("token");
  const [formDataState, setFormDataState] = useState(null);
  const [qaHistory, setQaHistory] = useState([]);
  const fileInputRef = useRef(null);
  const [extractedData, setExtractedData] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const activePatient = JSON.parse(localStorage.getItem("activePatient"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  var circleCode = localStorage.getItem("userId");
  const [base64pdf, setBase64pdf] = useState("");
  const [snackbarmsg, setSnackbarmsg] = useState("");
  const userData = useSelector((state) => state.authReducer.patientData);
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [documanttype, setDocumanttype] = useState("");
  const [chatHistory, setChatHistory] = useState({})
  const [chatHistoryDrawer, setChatHistoryDrawer] = useState(false)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [timeStamp, setTimestamp] = useState("");
  console.log(userData, "s_token");
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };


  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [qaHistory]);
  useEffect(() => {
    setshowaddmemberpopup(true);
  }, []);

  useEffect(() => {
    genrateRandomTimestamp();
  }, []);

  const genrateRandomTimestamp = () =>{
    const currentDateTime = new Date();
    const unixTimestamp = Math.floor(currentDateTime.getTime() / 1000);
    console.log(unixTimestamp, "New Timestamp Generated");
 
    setTimestamp(unixTimestamp);
  }

  useEffect(() =>{
    if(userData.code){
    getChatHistory();
    }
  }, [userData.code])

  const getChatHistory = () =>{
    let payload = {
        identifier: userData.code,
        page: 1,   
        page_size: 10 
    }
    
     
fetch(`${process.env.REACT_APP_CARE_SATHI_CHATHISTORY}qa`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${s_token}`,
  },
  body: JSON.stringify(payload),
}).then(async (res) => {
  let result = await res.json();
  if(result.data.length){
    // const groupedHistory = result?.data.reduce((acc, session) => {
    //   const date = new Date(
    //     session.qa_pairs[session.qa_pairs.length - 1].created_date
    //   ).toLocaleDateString();
    //   if (!acc[date]) {
    //     acc[date] = [];
    //   }
    //   acc[date].push({
    //     session_id: session.session_id,
    //     question: session.qa_pairs[session.qa_pairs.length - 1].question,
    //     qa_pairs: session.qa_pairs,
    //   });
    //   return acc;
    // }, {})
    let filterDataWithSessionId = {}
    result.data.forEach(res =>{
      const date = new Date(
        res.qa_pairs[res.qa_pairs.length - 1].created_date
      ).toLocaleDateString();
      if(/^\d+$/.test(res.session_id)){
        if(!filterDataWithSessionId[date]){
          filterDataWithSessionId[date] = [res] 
        }else{
          filterDataWithSessionId[date].push(res)
        }
      }
       
      })
    setChatHistory(filterDataWithSessionId)
    // let ee = Object.keys(filterDataWithSessionId)
    // console.log(filterDataWithSessionId[ee[0]],filterDataWithSessionId, "sdvihsdouvghsdudsvsdv")
  }
  
})
  }

  const typeEffect = (text, callback) => {
    let index = 0;
    const delay = 10; // Adjust the typing speed here (milliseconds per character)

    const typeCharacter = () => {
      if (index < text.length) {
        callback(text.slice(0, index + 1));
        index++;
        setTimeout(typeCharacter, delay);
      } else {
        callback(text); // Ensure the full text is set at the end
      }
    };

    typeCharacter();
  };

  const getAnswer = async (reData) => {
    try {
      setQaHistory((prevHistory) => [
        ...prevHistory,
        { question: reData, answer: "", isLoading: true },
      ]);
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer
`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${s_token}`,
          },
          body: JSON.stringify({ user_prompt: reData, classify: 1 }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const contentType = response.headers.get("Content-Type");

        let responseText;
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          responseText = data.response;
        } else {
          responseText = await response.text();
        }
        const formattedAnswer = formatAnswer(responseText);

        typeEffect(formattedAnswer, (typedText) => {
          updateAnswer(typedText);
        });
        finalizeAnswer(formattedAnswer);

        setgetAnswerBot((prevAnswers) => [
          ...prevAnswers,
          { response: responseText },
        ]);

        // const data = await response.json();
        // console.log(data, "respohjnse");
        console.log("Response received: ", responseText);
        // setQaHistory((prevHistory) =>
        //   prevHistory.map((item) =>
        //     item.question === reData
        //       ? { ...item, answer: data.response, isLoading: false }
        //       : item
        //   )
        // );
        // setgetAnswerBot((prevAnswers) => [...prevAnswers, data]);
        const removeEmojis = formattedAnswer.replace(/[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE00}-\u{FE0F}]|[\u{1F910}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}]/gu, '')
        saveMessageToApi({
          question: reData,
          answer: removeEmojis,
          createdBy: userData ? userData.code : "",
          chatSession: timeStamp,
          sourceChannel: "B2C",
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setQaHistory((prevHistory) => prevHistory.slice(0, -1));
      setSnackbarmsg("Something went wrong. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleArrowClick = async () => {
    console.log(loading, "loading");
    if (loading) {
      setSnackbarmsg("Please wait till the previous response is loading.");
      setOpenSnackbar(true);
      return;
    }
    console.log(message, base64pdf, "gkjnkl");
    if (!message) {
      // Both message and file are empty, show a Snackbar.
      setOpenSnackbar(true);
      setSnackbarmsg("Please write a message");
      return;
    }
    if (base64pdf) {
      try {
        setQaHistory((prevHistory) => [
          ...prevHistory,
          {
            question: `File: ${uploadedFileName} Question: ${message}`,
            answer: "",
            isLoading: true,
          },
        ]);
        setLoading(true);

        const requestBody = {
          // input_query: extractedData,
          // user_question: message,
          user_prompt: message,
          classify: 1,
          document: base64pdf,
          document_type: documanttype,
        };
        console.log(requestBody, "requestBody");
        const response = await fetch(
          `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${s_token}`,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const contentType = response.headers.get("Content-Type");

          let responseText;
          if (contentType && contentType.includes("application/json")) {
            const data = await response.json();
            responseText = data.response;
          } else {
            responseText = await response.text();
          }
          const formattedAnswer = formatAnswer(responseText);

          typeEffect(formattedAnswer, (typedText) => {
            updateAnswer(typedText);
          });
          finalizeAnswer(formattedAnswer);

          setgetAnswerBot((prevAnswers) => [
            ...prevAnswers,
            { response: responseText },
          ]);
          const removeEmojis = formattedAnswer.replace(/[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE00}-\u{FE0F}]|[\u{1F910}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}]/gu, '')
          saveMessageToApi({
            question: message,
            answer: removeEmojis,
            createdBy: userData ? userData.code : "",
            sourceChannel: "B2C",
          });
          // const data = await response.json();
          // console.log(data, "hgbjkhkj");
          // setQaHistory((prevHistory) =>
          //   prevHistory.map((item) =>
          //     item.question === `File: ${uploadedFileName} Question: ${message}`
          //       ? { ...item, answer: data.response, isLoading: false }
          //       : item
          //   )
          // );
          // console.log(response, "datadatafgv");
          // saveMessageToApi({
          //   question: message,
          //   answer: data.response,
          //   createdBy: userData?userData.code:"",
          //   sourceChannel: "B2C",
          // });
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
        setUploadedFileName("");
        setFormDataState(null);
        setMessage(""); // Clear the message state after uploading the file.
        fileInputRef.current.value = ""; // Clear the file input
      }
    } else {
      setQuestion(message);
      await getAnswer(message);
      setMessage("");
    }
  };

  const saveMessageToApi = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ChatBotQa/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${s_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw Error(`HTTP error! status: ${response.status}`);
      } else {
        const responseData = await response.json();
        console.log("Message saved successfully:", responseData);
        getChatHistory();
      }
    } catch (error) {
      console.error("Failed to save message:", error);
    }
  };
  const updateAnswer = (typedText) => {
    setQaHistory((prevHistory) =>
      prevHistory.map((item, index) =>
        index === prevHistory.length - 1 ? { ...item, answer: typedText } : item
      )
    );
  };
  const finalizeAnswer = (formattedAnswer) => {
    setQaHistory((prevHistory) =>
      prevHistory.map((item, index) =>
        index === prevHistory.length - 1
          ? { ...item, answer: formattedAnswer, isLoading: false }
          : item
      )
    );
  };

  // const handleFileChange = async (event) => {
  //   if (event.target.files.length > 0) {
  //     const selectedFile = event.target.files[0];

  //     if (selectedFile.type == "application/pdf") {
  //       // console.error("File is not a PDF.");
  //       // setOpenSnackbar(true);
  //       // setSnackbarmsg("Please upload pdf file.");
  //       // return;

  //     console.log(selectedFile, "selectedFile");
  //     const base64 = await convertToBase64(selectedFile);
  //     const base64Content = base64.split(",")[1];
  //     setBase64pdf(base64Content);
  //     console.log(base64Content, "base64 PDF");
  //     setUploadedFileName(selectedFile.name);
  //     const formData = new FormData();
  //     formData.append("pdf_file", selectedFile, selectedFile.name);
  //     }
  //     else{

  //     }
  //   }
  // };

  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      // Function to convert file to base64
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      const fileType = selectedFile.type;
      const documentType = fileType.split("/")[1];
      const validFileTypes = ["application/pdf", "image/jpeg", "image/png"];

      if (!validFileTypes.includes(fileType)) {
        console.error("Unsupported file type.");
        // setOpenSnackbar(true);
        // setSnackbarmsg("Please upload a PDF or image file.");
        return;
      }

      console.log(documentType, "selectedFile");
      setDocumanttype(documentType);
      const base64 = await convertToBase64(selectedFile);
      const base64Content = base64.split(",")[1];
      setBase64pdf(base64Content);
      console.log(base64Content, "base64 Content");
      setUploadedFileName(selectedFile.name);
      const formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);
    }
  };

  // const convertToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const formatAnswer = (response) => {
    return response
      .split("\n")
      .map((line) => {
        let result = "";
        let inBold = false;

        // Handle the cases for list items and bold formatting
        if (line.startsWith("* **")) {
          const parts = line.split("**");
          return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
        }

        // if (line.startsWith("    * **")) {
        //   const parts = line.split("**");
        //   return `<li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>${parts[1]}</strong>${parts[2]}</li>`;
        // }
        if (line.startsWith("    * **")) {
          line = "  • " + line.slice(2);
        }

        // Process bold text within the line
        for (let i = 0; i < line.length; i++) {
          if (line[i] === "*" && line[i + 1] === "*") {
            if (inBold) {
              result += "</strong>";
              inBold = false;
            } else {
              result += "<strong>";
              inBold = true;
            }
            i++; // Skip the next '*'
          } else {
            result += line[i];
          }
        }

        // Close any remaining bold tags
        if (inBold) {
          result += "</strong>";
        }

        // Wrap the result in list item tags if it starts with '• '
        if (line.startsWith("• ")) {
          return `<li>${result}</li>`;
        } else {
          return `<p>${result}</p>`;
        }
      })
      .join("");
  };

  

  // const formatAnswer = (response) => {
  //   return response
  //     .split("\n")
  //     .map((line) => {
  //       if (line.startsWith("* **")) {
  //         const parts = line.split("**");
  //         return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
  //       }
  //       if (line.startsWith("* ")) {
  //         return `<li>${line.slice(2)}</li>`;
  //       }
  //       if (line.startsWith("**") && line.endsWith("**")) {
  //         return `<strong>${line.slice(2, -2)}</strong>`;
  //       }
  //       if (line.startsWith("**")) {
  //         return `<p><strong>${line.slice(2)}</strong></p>`;
  //       }
  //       if (line.endsWith("**")) {
  //         return `<p>${line.slice(0, -2)}<strong></strong></p>`;
  //       }
  //       return `<p>${line}</p>`;
  //     })
  //     .join("");
  // };
  console.log(qaHistory, "qaHistory");

  const handleChatHistoryQuestionClick = (sessionId , qaPairs) =>{
    console.log(qaPairs , "salasovuasovasgvas")
    let result = qaPairs.map(res => {
      return{
          question: res.question,
          answer: res.answer,
          isLoading: false,
          created_date: res.created_date
      }
    }).sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
    setTimestamp(sessionId)
    setQaHistory(result);
    setChatHistoryDrawer(false);
  }

  const handleNewChatWindow = () =>{
    genrateRandomTimestamp();
    setQaHistory([]);
    setChatHistoryDrawer(false);
  }
  const chatHistoryUI = () =>{
    return(<>
      <div className="flex w-full justify-between relative" >
      <h4 style={{color: "#0D0D0D", fontSize: 16, fontWeight: 700, textAlign: "center"}} className="hidden sm:block py-2" >Chat History</h4>
      <button onClick={handleNewChatWindow} aria-label="New chat" className="h-10 rounded-lg absolute right-0 px-2 text-token-text-secondary focus-visible:outline-0 disabled:text-token-text-quaternary focus-visible:bg-token-sidebar-surface-secondary enabled:hover:bg-token-sidebar-surface-secondary"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" class="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg></button>
      </div>
       
        {
          Object?.keys(chatHistory)?.map((res, index) => { 
          return( <div>
              <h4 style={{color: "#0D0D0D", fontSize: 12, fontWeight: 800}} className="py-2" >{res}</h4>
             {  chatHistory[res].map((res1 , index1) =>{
                return( <div><button style={res1.session_id == timeStamp ? {color: "#0D0D0D",textAlign: "start", fontSize: 14,backgroundColor: "rgb(230 230 230)"} : {color: "#0D0D0D",textAlign: "start", fontSize: 14}} className="mb-2 w-full rounded-md px-2 py-2 careSathHistoryText" key={index1} onClick={() => handleChatHistoryQuestionClick(Number(res1.session_id), res1.qa_pairs)} >{res1.qa_pairs[res1.qa_pairs.length - 1].question}</button></div>
                )
              })}

</div>)
          })
        }
      </>)
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarmsg}
        </Alert>
      </Snackbar>
      <div className="flex" >
        <div className="hidden sm:block" style={{width: "25%"}} >
          <div style={{backgroundColor: "#f4f4f4", paddingLeft: "1rem",paddingRight: "1rem"}} className=" overflow-y-scroll h-screen" >
        {chatHistoryUI()}
        </div>
        </div>
        <div style={{height: screenHeight - 2}} className="flex items-center flex-col overflow-hidden relative px-2 sm:px-20 chat-container">
        <div className="flex items-center w-full justify-center" >
          <h1
          className="sm:text-3xl text-lg text-[#005D8E] text-center font-bold pt-[10px]"
          style={{ alignContent: "center" }}
        >
          Welcome to <span className="text-brand-primary">ChatBot Support</span>
        </h1>
        <MenuOutlined className="block sm:hidden pt-[10px] absolute right-2" onClick={() => setChatHistoryDrawer(true)} />
        </div>

        <div
          className="flex flex-col justify-between mt-12 overflow-y-scroll"
          style={{scrollbarWidth: "none"}}
          ref={chatContainerRef}
        >
          {/* <div
            
            className="chat-container "
            // overflow-y-scroll
          > */}
            {qaHistory.length ? (
              qaHistory.map((item, index) => (
                <div key={index} className="mb-8">
                  <div
                    className="p-5 float-right mb-2 w-9/12 sm:w-1/2 mr-2 sm:mr-0"
                    style={{ backgroundColor: "#f4f4f4", borderRadius: "10px" }}
                  >
                    <div className="w-full flex justify-between">
                      <div className="flex">
                        {/* <p className="text-brand-primary font-semibold text-sm borderstyle2">
                      QN
                    </p> */}
                        <p className="sm:pt-1 pl-3 text-sm sm:text-md">
                          {item.question}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex bgclr px-2 sm:px-8 py-3 w-full gap-3">
                    <div className="w-9">
                      {/* <p className="text-brand-primary font-semibold text-sm borderstyle">
                    CS
                  </p> */}
                    </div>
                    <div className=" ">
                      {item.isLoading ? (
                        <ChatLoading />
                      ) : (
                        <div className="font-normal text-lg mb-6">
                          {/* {item.answer.split("\n\n").map((paragraph, i) => (
                            <p className="text-sm sm:text-md" key={i}>
                              {" "}
                              {paragraph}
                            </p>
                          ))} */}
                          <div
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="flex flex-col  justify-center sm:flex-row gap-6 p-4 pt-[50px]"
                style={{ height: "65vh" }}
              >
                <button
                  onClick={() => setMessage("What are diabetes symptoms?")}
                  className="h-max ai-suggesstion-card"
                >
                  <p
                    className="line-clamp-3 text-balance text-gray-600 dark:text-gray-500 break-word"
                    style={{ color: "#676767" }}
                  >
                    What are diabetes symptoms?
                  </p>
                </button>
                <button
                  onClick={() => setMessage("How to lower cholesterol?")}
                  className="h-max ai-suggesstion-card"
                >
                  <p
                    className="line-clamp-3 text-balance text-gray-600 dark:text-gray-500 break-word"
                    style={{ color: "#676767" }}
                  >
                    How to lower cholesterol?
                  </p>
                </button>
                <button
                  onClick={() =>
                    setMessage("Need pediatrician recommendation?")
                  }
                  className="h-max ai-suggesstion-card"
                >
                  <p
                    className="line-clamp-3 text-balance text-gray-600 dark:text-gray-500 break-word"
                    style={{ color: "#676767" }}
                  >
                    Need pediatrician recommendation?
                  </p>
                </button>
              </div>
            )}
          {/* </div> */}
         
        </div>
        <div className="w-full boxposition absolute bottom-0 px-2 sm:px-20" style={{paddingBottom: 5}}>
            <div className="pt-5">
              {uploadedFileName && (
                 <span className="text-sm font-normal mb-2 ml-7 py-2 px-2 shadow rounded" style={{backgroundColor: "#ffff"}}>
                 <strong>Uploaded File:</strong> {uploadedFileName}
               </span>
              )}
              <div className="w-full flex justify-between relative items-center">
                <label className="flex text-lg font-normal p-3 cursor-pointer absolute">
                  <AttachFileIcon />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </label>
                <InputText
                  value={message}
                  onChange={handleInputChange}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleArrowClick();
                    }
                  }}
                  placeholder="Please type your Medical Problems only ..."
                  style={{
                    maxHeight: "200px",
                    height: "52px",
                    width: "100%",
                    border: "none",
                    backgroundColor: "#f4f4f4",
                    outline: "none",
                  }}
                  className=" pr-10 pl-12"
                />
                <button
                  className="absolute right-2 sm:right-4"
                  onClick={handleArrowClick}
                >
                  <SendIcon style={{ color: "#339AF0" }} />
                </button>
              </div>
              {/* <p class="flex text-lg font-normal pr-3">Disclaimer</p> */}
            </div>
          </div>
      </div>
      </div>
      <Drawer styles={{body: {padding: 0}}} title="Chat History" onClose={() => setChatHistoryDrawer(false)} placement= "right" open={chatHistoryDrawer}>
      <div style={{minWidth: "25%", backgroundColor: "#f4f4f4", paddingLeft: "1rem",paddingRight: "1rem"}} className="min-w-3/12 overflow-y-scroll h-screen" >
        {chatHistoryUI()}
        </div>
      </Drawer>

      <Modal
        title="Disclaimer"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
        }}
        width="65%"
        style={{ top: 20 }}
        footer={[]}
      >
        <p className="sm:text-base text-sm">
          {" "}
          CareSathi AI is designed to provide an automatic response with a
          general overview of the user's health status based on the available
          research data. CareSathi AI is not tailored to detect individual
          symptoms and is not a substitute for professional medical diagnosis.
          Users bear sole responsibility for their reliance on the response and
          we expressly disclaim any and all liability arising from the use and
          reliance on CareSathi AI responses. For personalized advice and
          recommendations, pls visit our CureBay clinic.
        </p>
      </Modal>
    </>
  );
};

export default CareSathi;
