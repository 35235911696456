import React, { useEffect, useRef, useState } from "react";
import Layouts from "../../Layouts";
import Lab111 from "../../../Assets/Images/Icons/lab.svg";
import Lab222 from "../../../Assets/Images/Icons/testreport.svg";
import Lab333 from "../../../Assets/Images/Icons/ontime.svg";
import Lab444 from "../../../Assets/Images/Icons/doorstep.svg";
import OneImage from "../../../Assets/Images/Icons/one.svg";
import TwoImage from "../../../Assets/Images/Icons/Two.svg";
import ThreeImage from "../../../Assets/Images/Icons/Three.svg";
import CartDocImg from "../../../Assets/Images/medicalSummary.png";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import { Form, Input, InputNumber, Select, message } from "antd";
import Slider from "react-slick";
import Search from "antd/es/input/Search";
import { Card } from "antd";
import TestTube from "../../../Assets/Images/testTube.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import diagonstics from "../../../Assets/Images/diagnostics.png";
import { useDispatch, useSelector } from "react-redux";
import { getlabPartnerslist } from "../../../Redux/Action/DiagnosticsActions";
import { connect } from "react-redux";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import {
  setAddressString,
  setLatLong,
  actioncustomPinCode,
  //actioncustomRadius
} from "../../../Redux/Action/userActions";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import LocateIcon from "../../../Assets/Images/locate.svg";
import moment from "moment";
import http from "../../../Redux/Services/http-common";
import { encodeBase64File } from "../../Helper/filebase64";
import { ToastContainer, toast } from "react-toastify";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import Loader from "../../Shared/loader";
import { EnglishText } from '../../PageRouting/EnglishText';
import { Helmet } from 'react-helmet';

import "react-toastify/dist/ReactToastify.css";

function Lab() {
  const lablistData = useSelector((state) => state.listpartnerslab);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const dispatch = useDispatch();
  const { cartList } = useSelector((state) => state.cartReducer);
  const [newPinCode, setPinCode] = useState("");
  const locationDropdownRef = useRef(null);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const [labSearched, setlabSearched] = useState([]);
  const [choosetoCart, setchoosetoCart] = useState([]);
  const [labPartners, setLabPartners] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [currPagePartners, setCurrPagePartners] = useState([]);
  const [labNewName, setLabNewName] = useState("");
  const formRef = useRef();
  const secondFormRef = useRef();
  const inputRef = useRef();
  const [image, setImage] = useState(null);
  const [secImage, setSecImage] = useState(null);
  const [lastSearchText, setLastSearchText] = useState("");
  const [showTopSearch, setShowTopSearch] = useState(false);
  const [labsearchvalue, setLabSearchValue] = useState("");
  const [profile, setProfile] = useState(userData);
  const [open, setOpen] = useState(false);
  const [cartaddedlab, setcartaddedlab] = useState();
  const coords = useSelector((state) => state.authReducer.coords);
  const [radiusselected, setRadiusselected] = useState("25");

  const [error, setError] = useState({
    isMMILoading: false,
    locationError: "",
  });

  
  const handleRadiusChange =(e) =>{
    setRadiusselected(e.target.value)
    sessionStorage.setItem("customRadius", e.target.value);
    // dispatch(
    //   actioncustomRadius({
    //     radius: e.target.value,
    //     location: "searchbar",
    //   })
    // );
  }
  const customPinCode = useSelector((state) => state.authReducer.customPinCode);
  useEffect(() => {
    SlicedProducts();

    console.log(cartList);
  }, [currPage]);

  const handleNumberPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const getTheAge = (date) => {
    return moment().diff(date, "years", false);
  };

  const handleFormOne = async () => {
    const formValues = formRef.current.getFieldsValue();

    let { name, gender, age, email, phone } = formValues;

    if (profile.dob === undefined || profile.dob === "") {
      if (age === undefined || age === "") {
        message.error("Please fill in all fields");
        return;
      }
    } else {
      if (age === undefined || age === "") {
        age = getTheAge(profile.dob);
      }
    }

    if (profile.gender === undefined || profile.gender === "") {
      if (gender === undefined || gender === "") {
        message.error("Please fill in all fields");
        return;
      }
    } else {
      if (gender === undefined || gender === "") {
        gender = profile.gender === "M" ? "MALE" : "FEMALE";
      }
    }

    if (
      profile.firstName === undefined ||
      profile.firstName === "" ||
      profile.firstName === null
    ) {
      if (name === undefined || name === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    let payload;

    if (Object.keys(userData).length === 0) {
      if (email === undefined || email === "") {
        message.error("Please fill in all fields");
        return;
      }
      if (phone === undefined || phone === "") {
        message.error("Please fill in all fields");
        return;
      }
      payload = {
        contactPerson: name,
        email,
        phoneNumber: phone,
        age,
        gender,
        type: "UploadPrescription",
      };
    } else {
      payload = {
        contactPerson: name ? name : profile.firstName,
        email: profile.email,
        phoneNumber: profile.mobile,
        age,
        gender,
        type: "UploadPrescription",
      };
    }

    // Convert the uploaded file to Base64
    if (image) {
      const res = await encodeBase64File(image);
      if (image.type.includes("pdf")) {
        payload.photoType = "pdf";
        payload.photo = res;
      } else if (image.type.includes("png")) {
        payload.photoType = "png";
        payload.photo = res;
      } else if (image.type.includes("jpg")) {
        payload.photoType = "jpg";
        payload.photo = res;
      } else if (image.type.includes("jpeg")) {
        payload.photoType = "jpeg";
        payload.photo = res;
      }
    } else {
      message.error("Please upload a Prescription");
      return;
    }

    try {
      setLoading(true)
      const res = await http.post("partnerEnquiryForm/helpUs", payload);
      console.log(res);
      formRef.current.resetFields();
      setLoading(false)

      message.success("Thank You! Our support team will contact you shortly.");
      setImage(null);
    } catch (error) {
      console.log(error);
      message.error("Error Occurred");
    }
  };

  const handleFormTwo = async () => {
    const formValues = secondFormRef.current.getFieldsValue();
    console.log("Form values:", formValues);
    let { name, gender, town, email, phone } = formValues;

    if (profile.city === undefined || profile.city === "") {
      if (town === undefined || town === "") {
        message.error("Please fill in all fields");
        return;
      }
    }
    // else{
    //   if(town === undefined || town === ""){
    //     town = profile.city;
    //   }
    // }

    if (profile.gender === undefined || profile.gender === "") {
      if (gender === undefined || gender === "") {
        message.error("Please fill in all fields2");
        return;
      }
    } else {
      if (gender === undefined || gender === "") {
        gender = profile.gender === "M" ? "MALE" : "FEMALE";
      }
    }

    if (
      profile.firstName === undefined ||
      profile.firstName === "" ||
      profile.firstName === null
    ) {
      if (name === undefined || name === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    let payload;

    if (Object.keys(userData).length === 0) {
      if (email === undefined || email === "") {
        message.error("Please fill in all fields");
        return;
      }
      if (phone === undefined || phone === "") {
        message.error("Please fill in all fields");
        return;
      }
      payload = {
        contactPerson: name ? name : profile.firstName,
        email,
        phoneNumber: phone,
        address: town ? town : profile.city,
        gender,
        type: "UploadTestReport",
      };
    } else {
      payload = {
        contactPerson: name ? name : profile.firstName,
        email: profile.email,
        phoneNumber: profile.mobile,
        address: town ? town : profile.city,
        gender,
        type: "UploadTestReport",
      };
    }

    if (secImage) {
      const res = await encodeBase64File(secImage);
      if (secImage.type.includes("pdf")) {
        payload.photoType = "pdf";
        payload.photo = res;
      } else if (secImage.type.includes("png")) {
        payload.photoType = "png";
        payload.photo = res;
      } else if (secImage.type.includes("jpg")) {
        payload.photoType = "jpg";
        payload.photo = res;
      } else if (secImage.type.includes("jpeg")) {
        payload.photoType = "jpeg";
        payload.photo = res;
      }
    } else {
      message.error("Please upload a report");
      return;
    }

    try {
      setLoading(true)

      const res = await http.post("partnerEnquiryForm/helpUs", payload);
      console.log(res);
      secondFormRef.current.resetFields();
      setLoading(false)

      message.success("Thank You! Our support team will contact you shortly.");
      setSecImage(null);
    } catch (error) {
      console.log(error);
      message.error("Error Occurred");
    }
  };

  const SlicedProducts = () => {
    const labArray = labPartners;
    const itemsPerPage = 4;
    const startIdx = currPage;
    const lastIdx = currPage + itemsPerPage;
    const Labs = labArray.slice(startIdx, lastIdx);
    setCurrPagePartners(Labs);
  };

  const handlePage = (type) => {
    if (type === "increment") {
      setCurrPage((prevState) => prevState + 1);
    } else {
      setCurrPage((prevState) => (prevState === 0 ? prevState : prevState - 1));
    }
  };

  const handleClick = (labName) => {
    console.log(labName.seoNameId);
    history.push(APP_ROUTES.DIAGNOSTICDETAILS, {
      partnerData: labName,
      labId: labName.seoNameId,
    });
  };

  const handleNavigation = (e, data,labTestCode) => {
    console.log(labTestCode,"hyuhyubhuhu")
    e.preventDefault();
   
    history.push({ pathname: APP_ROUTES.LABVIEW, state: { labdata:data,labTestCode:labTestCode }, login: false });
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  // const addTocart = () => {
  //   history.push({
  //     pathname: APP_ROUTES.HOME,
  //     // state: { background: location, login: true },
  //   });
  // }

  const addTocart = (e, data, index,labtestcode, quantity = 1) => {
    const cartIDs = getProductsIDs();
   
   
    if (!userData.code) {
      history.push({
        pathname: APP_ROUTES.HOME,
        // state: { background: location, login: true },
      });
    }
   
   
      // toast("we are currently in the process of upgrading our website, apologies for the inconvenience");
  
  if(labtestcode){

    if (cartList?.patientLabTestsOrder) {
      let availableItem = cartList?.patientLabTestsOrder.find(
        (item) => item.hospitalId === data.labId
      );

      if (availableItem === undefined) {
        // setDialog(true);
        setLabNewName(
          cartList?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]
            ?.hospitalName
        );
        message.warning(
          "Select this test from the same lab or replace the tests in your cart"
        );
        return;
      }
    }

    // setIsAdding(index);
    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (data?.medicinePrescriptionRequired == "N") {
      prescriptionRequired = "N";
      ePrescriptionRequired = "N";
    } else if (data?.medicinePrescriptionRequired == "Y") {
      prescriptionRequired = "Y";
      ePrescriptionRequired = "Y";
    }
    let calculatedAmount = data?.amount;
    const totalAmount = calculatedAmount * quantity;

    let dataObj = {};
    if (cartList && cartList.patientLabTestsOrder) {
      console.log(cartList, "dsvsdhvousdhvshvsov");
      cartList.totalAmount = cartList?.totalAmount
        ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
        : parseFloat(totalAmount);
      cartList.patientLabTestsOrder.map((res) => {
        res.patientLabTestsOrderDetailsList.push({
          patientLabTestOrderId: data.id,
          labTestCode: data.labTestCode,
          labTestName: data.labTestName,
          hospitalId: data.labId,
          locationId: data.locationId,
          amount: data.amount,
          discountAmount: data?.discountAmount ? data?.discountAmount : 0,
          totalAmount: totalAmount,
          status: 1,
          labTestType: data.testType,

          quantity: quantity,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          prescriptionRequired: prescriptionRequired,
          ePrescriptionRequired: ePrescriptionRequired,
          medicineTypeOfSell: null,
          membershipCode: null,
          membershipName: null,
          membershipCard: null,
          membershipDiscountPercentage: 0.0,
        });
      });
      dataObj = cartList;
    } else {
      console.log(cartList, "dsvsoudvdvhsdoivs");
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,

        labOrdersYN: true,
        drugsOrdersYN: false,
        totalAmount: cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount),
        patientLabTestsOrder: [
          {
            hospitalId: data.labId,
            locationId: data.locationId,

            orderId: "",
            patientId: userData.code,
            orderDetailsRequired: "Y",
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            amount: totalAmount,
            address1: data.address1,
            address2: data.address2,
            address3: null,
            city: data.city,
            state: data.state,
            country: null,
            pincode: data.pinCode,
            deliveryAddress1: data.address1,
            deliveryAddress2: data.address2,
            deliveryAddress3: null,
            deliveryCity: data.city,
            deliveryState: data.state,
            deliveryCountry: null,
            deliveryZipcode: data.pinCode,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            patientLabTestsOrderDetailsList: [
              {
                patientLabTestOrderId: data.id,
                labTestCode: data.labTestCode,
                labTestName: data.labTestName,
                hospitalId: data.labId,
                locationId: data.locationId,
                amount: data.amount,
                discountAmount: data?.discountAmount ? data?.discountAmount : 0,
                totalAmount: totalAmount,
                status: 1,
                labTestType: data.testType,
                tat: data?.tat,
                labStartTime: data?.labStartTime,
                labEndTime: data?.labEndTime,
                description: data?.description,

                quantity: quantity,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                orderId: null,
                patientId: userData.code,
                prescriptionRequired: prescriptionRequired,
                ePrescriptionRequired: ePrescriptionRequired,
                medicineTypeOfSell: null,
                membershipCode: null,
                membershipName: null,
                membershipCard: null,
                membershipDiscountPercentage: 0.0,
              },
            ],
          },
        ],
        // };
      };
    }
    setLoading(true)

    dispatch(AddtoCart(dataObj)).then((res) => {
      // window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      // window.dataLayer.push({
      //   event: "add_to_cart",
      //   ecommerce: {
      //     value: data?.amount,
      //     items: [
      //       {
      //         labId: data?.labId,
      //         locationId: data?.locationId,
      //         locationName: data?.locationName,
      //         pinCode: data?.pinCode,
      //         state: data?.state,
      //         testDisplayName: data?.testDisplayName,
      //         testType: data?.testType,
      //         labTestName: data?.labTestName,
      //         amount: data?.amount,
      //       },
      //     ],
      //   },
      // });
      setLoading(false)
      dispatch(getCartDetails(userData.code))
      // setIsAdding(-1);
    });
  }
  };
  console.log(cartaddedlab,"hello")

  const isItemInCart = (labTestCode) => {
    return cartaddedlab.includes(labTestCode);
  }
  const fetchPartners = async () => {
    setLoading(true)
    try {
        const response = await http.get(`${process.env.REACT_APP_ELASTIC_BASEURL}labs?latitude=${coords.lat}&longitude=${coords.long}&radius=${radiusselected}&source=b2c`);

      console.log(response.data , "dvdhsvodsovds");
      setLabPartners(response.data);
      setLoading(false)

    } catch (e) {
      console.log("Error");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter keypress here
      setIsOpen(false);
      window.scrollTo({
        top: 530,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchPartners();
    dispatch(getCartDetails(userData.code)).then((response) => {
      setLoading(false)
    })
  }, []);

  const drugIDsInCart = getProductsIDs();

  function getProductsIDs() {
    let ids = [];
    if (cartList) {
      if (cartList.patientLabTestsOrder) {
        for (const product of cartList.patientLabTestsOrder) {
          // if (product && product.patientLabTestsOrderDetailsList) {
          for (const drug of product.patientLabTestsOrderDetailsList) {
            ids.push(drug.labTestCode);
          }
          console.log("Collected product IDs:", ids);
          // }
        }
      }
    }
    return ids; 
  }

  const seletedSearch = (event) => {
    // setlabSearched([])
    console.log(event);
    let x = [];
    x.push(event);
    setlabSearched(x);
    window.scrollTo({
      top: 530,
      behavior: "smooth",
    });
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const searchText = e.target.value;
    setLabSearchValue(searchText);
    debounceSearch(e, coords,customPinCode);
  };

  const debounceSearch = React.useRef(
    debounce(async (e, coords,customPinCode) => {
      // Searching letters lengh less than 3
      // Will not make any api calls
      const searchText = e.target.value;

      if (
        (searchText && searchText.length < 3) ||
        searchText === lastSearchText
      ) {
        setIsOpen(false);
        setLastSearchText(searchText);
        setSearchTerm("");
        return;
      } else if (searchText && searchText.length) {
        setSearchTerm(searchText);
        setIsOpen(true);
        let res2 = await http.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?latitude=${coords.lat}&longitude=${coords.long}&freeTextSearch=${searchText}&source=b2c&radius=${radiusselected}`
        );
        console.log(res2.data);
        setlabSearched(res2.data);
        setLastSearchText(searchText);
      } else {
        // No valid search criteria, clear the old data.
        setIsOpen(false);
        setlabSearched([]);
        setLastSearchText("");
      }
    }, 300)
  ).current;
  const lab = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const homeBanner = document.querySelector(".primary-search-bar");
  //     // const topSearch = document.querySelector(".topsearch");
  //     console.log(homeBanner, "sdvsdgviusdgviusdgvsd" , homeBanner.getBoundingClientRect().top)
  //     if(homeBanner == null) return
  //     if (homeBanner.getBoundingClientRect().top <= 95 && homeBanner.getBoundingClientRect().top !== "undefined") {
  //       if(labSearched.length == 0){
  //         setShowTopSearch(true);
  //       }
  //     } else if(homeBanner.getBoundingClientRect().top !== "undefined" && homeBanner.getBoundingClientRect().top > 95) {
  //       if(labSearched.length == 0){
  //         setShowTopSearch(false);
  //         setLabSearchValue("")
  //       }

  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //      window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSecImageChange = (e) => {
    setSecImage(e.target.files[0]);
  };

  const handleUploadDelete1 = () => {
    setImage(null);
  };

  const handleUploadDelete2 = () => {
    setSecImage(null);
  };

  // useEffect(() => {
  //   if (showTopSearch && inputRef?.current) {
  //     inputRef.current.focus();
  //   }
  // }, [showTopSearch]);

  // const handleInputSearchClick = () =>{
  //   setShowTopSearch(true)
  // }
  const handleLocationClick = () => {
    setOpen((prev) => !prev);
  };

  function showPosition(position) {
    const { coords } = position;
    var coordsObj = {
      lat: coords.latitude,
      long: coords.longitude,
    };
    dispatch(setLatLong(coordsObj));
    var apiUrl = "MMI/accesstoken";
    http.get(apiUrl).then((response) => {
      let data = response.data;
      // const res = await http.get(
      //   `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
      // );
      axios
        .get(
          `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
        )
        .then((res) => {
          
          if (!res.data.results[0].pincode) {
            sessionStorage.setItem("customPinCode", "751009");
            dispatch(
              actioncustomPinCode({
                pincode: "751009",
                location: "current",
              })
            )
            setError({
              isMMILoading: false,
              locationError: "Not able to detect your location.",
            });
            setOpen(false);
          } else {
            sessionStorage.setItem(
              "customPinCode",
              res.data.results[0].pincode
            );
            dispatch(
              actioncustomPinCode({
                pincode: res.data.results[0].pincode,
                location: "current",
              })
            )
            setError({
              isMMILoading: false,
            });
            setOpen(false);
          }
        })
        .catch((err) => {
          setError({
            locationError: "Error occured in MMI",
            isMMILoading: false,
          });
        });
      // let addressString = res.data.results[0]?.city;
      // dispatch(setAddressString(addressString));
    });
    secureStorage.setItem("coords", coordsObj);
    // setMapOpen(!mapOpen);
  }

  function getLocation() {
    setLoading(true)
    setError({
      isMMILoading: true,
      locationError: "",
    });
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(showPosition);
        } else {
        }
      } else if (result.state === "prompt") {
        // showButtonToEnableMap();
      } else if (result.state === "denied") {
        setError({
          locationError: "Please allow browser to detect location",
          isMMILoading: false,
        });
      }
      // Don't do anything if the permission was denied.
      setLoading(false)

    });
  }

  const handleSavedAddressClick = () => {
    setError({
      locationError: "",
    });

    if (patientinfoData?.pinCode) {
      let address1 = patientinfoData?.address1;
      let address2 = patientinfoData?.address2;
      let city = patientinfoData?.city;
      let pinCode = patientinfoData?.pinCode;
      const pincodePackage = require("pincode-lat-long");
      let coords = pincodePackage.getlatlong(pinCode);
      // console.log(coords ,"dvjsdbvsbovdf")
      if (coords == undefined) {
        setError({
          locationError: "Please use valid pincode",
        });
        return;
      }
      let coordsObj = {
        lat: coords.lat,
        long: coords.long,
      };
      dispatch(setLatLong(coordsObj));
      dispatch(setAddressString(city));
      sessionStorage.setItem("customPinCode", pinCode);
      dispatch(
        actioncustomPinCode({ pincode: pinCode, location: "profileaddress" })
      );
      setOpen(false);

      // setLocation();
    }
  };

  const handlePinCodeChange = (e) => {
    const re = /^[0-9]*$/; //rules
    if (re.test(e.target.value)) {
      e.target.value.length <= 6 && setPinCode(e.target.value);
    }

    if (e.target.value.length == 0) {
      setError("");
      return;
    }

    if (e.target.value.length == 6) {
      const pincode = require("pincode-lat-long");
      let coords = pincode.getlatlong(e.target.value);
      if (coords == undefined) {
        setError({
          locationError: "Please add valid pincode",
        });
        return;
      }
      setOpen(false);
      setError({
        locationError: "",
      });
      let coordsObj = {
        lat: coords.lat,
        long: coords.long,
      };
      dispatch(setLatLong(coordsObj));
      dispatch(
        actioncustomPinCode({ pincode: e.target.value, location: "custompincode" })
      );
      sessionStorage.setItem("customPinCode", e.target.value);
    }
  };

  const LocationSearch = (
    <div className="">
      <button className="no" onClick={handleLocationClick}>
        <div className=" flex text-left relative items-center top-0.5 ">
          <LocationMarkerIcon
            style={{
              height: "15px",
              color: "#5dbb63",
              marginRight: "10px",
            }}
          />
          <p className="text-sm  font-semibold text-black-700">
            {sessionStorage.getItem("customPinCode")}
          </p>
        </div>
      </button>
      {open ? (
        <div
          ref={locationDropdownRef}
          style={{
            minHeight: "fit-content",
            zIndex: 10,
            boxShadow: "0 6px 12px -4px rgb(11 18 25 / 20%)",
            borderRadius: "5px",
          }}
          className="block md:w-[400px]  absolute bg-White top-14 bottom-0 left-0 p-5"
        >
          <div className="p-2">
            <div className="text-left">
              <h1 className="text-primaryColor text-base font-medium">
                Where do you want the delivery?
              </h1>
              <span className="text-textGray text-sm font-light">
                Get access to your Addresses and Orders
              </span>
              <div
                className={`${
                  patientinfoData?.address1 === null ||
                  patientinfoData?.address2 === null ||
                  patientinfoData?.address1 === undefined ||
                  patientinfoData?.address2 === undefined ||
                  patientinfoData?.city === undefined ||
                  patientinfoData?.pinCode === undefined
                    ? "hidden"
                    : "block"
                }`}
              >
                <div
                  className="cursor-pointer  w-full p-4 mt-2 bg-lightBlue border border-[#bee0ff] rounded  mb-1"
                  onClick={handleSavedAddressClick}
                >
                  <p className="text-primaryColor  text-sm font-normal ">
                    {patientinfoData?.address1 +
                      ", " +
                      patientinfoData?.address2 +
                      ", " +
                      patientinfoData?.city +
                      ", " +
                      patientinfoData?.pinCode}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-1 py-1">
            <div className="text-left ">
              <h1 className="text-primaryColor text-base font-medium">
                Enter Pincode or Radius
              </h1>
              <span className="text-textGray text-sm font-light block">
                Select pincode or Radius to see product availability.
              </span>
              <div className="flex  gap-2">
                        <div>
                          <input
                            className="border border-gray h-[50px] text-sm w-full py-1 pl-2 my-3 rounded"
                            onChange={handlePinCodeChange}
                            placeholder="Enter Pincode"
                            value={newPinCode}
                          />
                        </div>
                        <div  >
                          <select
                            name="radius"
                            id="radius"
                            value={radiusselected}
                            onChange={handleRadiusChange}
                           className="border border-gray h-[50px]  text-sm  py-1 pl-2 my-3 rounded sm:w-[180px] w-[120px] text-textGray"
                          >
                             <option className=" text-sm font-light" >Select Services within</option>
                            <option value="5" >5 km</option>
                            <option value="10" >10km</option>
                            <option value="15" >15km</option>
                            <option value="25" >25km</option>
                          </select>
                        </div>
                      </div>
              <div
                className="flex gap-1 items-center cursor-pointer content-center bg-lightGreen"
                onClick={getLocation}
              >
                <img
                  src={LocateIcon}
                  alt=""
                  draggable={false}
                  className="h-8 w-8"
                />
                <p className="text-secondaryColor  flex items-center text-base font-medium">
                  Detect my location{" "}
                  {error.isMMILoading && (
                    <div className="loader ml-2 float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                  )}
                </p>
              </div>
              {error.locationError && (
                <div className="text-xs font-semibold text-red-500">
                  {" "}
                  {error.locationError}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );

  const onSearch = () => {
    setIsOpen(false);
    window.scrollTo({
      top: 530,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ToastContainer />
      {loading &&<Loader/>}

      <div onClick={() => handleKeyPress("Enter")}>

        <Layouts>
          <div className="w-full h-[75px] bg-[#bfe4ff] py-3 fixed z-10">
            <div className="container mx-auto">
              <div className="md:w-[45%] mx-auto">
                <Search
                  className="uniqueSearch apptWhite bg-White"
                  placeholder="Search Lab Tests"
                  ref={inputRef}
                  value={labsearchvalue}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  enterButton
                  onSearch={onSearch}
                  addonBefore={LocationSearch}
                />
                <p>{showTopSearch}</p>
                {labSearched.length > 0 && isOpen ? (
                  <div className="relative w-full min-h-[10rem] mt-2">
                    <div className=" flex  absolute  w-full bg-White max-h-[20rem] overflow-y-scroll rounded-2xl scrollWidth">
                      <div className="w-full flex flex-col gap-1">
                        {labSearched.map((lab, idx) => (
                          <div className="w-full p-1 rounded-lg ">
                            <p
                              onClick={() => seletedSearch(lab)}
                              className="py-1 px-16 hover:bg-lightGray hover:text-primaryColor rounded-lg  w-full"
                            >
                              {lab.labTestName}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
            <div className="container mx-auto pt-[70px]">
              <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[63vh] rounded-e-xl"></div>
              <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
                <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                  <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                    Unlock{" "}
                    <span className="text-secondaryColor">
                      {" "}
                      cutting edge diagnostics
                    </span>{" "}
                    for timely medical insights
                  </h1>
                  <p className="text-white md:text-White font-light text-sm md:md:text-lg text-basemb-5 mb-5">
                    Explore CureBay's Comprehensive Range of Pathology &
                    Radiology Tests. Our extensive selection offers accurate
                    diagnostics and insights, supporting your health journey
                    with precision and care.
                  </p>
                  {/* {!showTopSearch && (
                    <div className={!showTopSearch ? 'primary-search-bar' : 'invisible primary-search-bar' }>
                    <Search
                  className= " apptSearch apptWhite bg-White"
                  placeholder="Search Lab Tests"
                  onFocus={handleInputSearchClick}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress} 
                />
                      {labSearched.length > 0 && isOpen ? (
                        <div className="relative w-full h-[10rem] mt-2">
                          <div className=" flex  absolute inset-0 w-full bg-White overflow-y-scroll rounded-2xl scrollWidth">
                            <div className="w-full flex flex-col gap-1">
                              {labSearched.map((lab, idx) => (
                                <div className="w-full p-1 rounded-lg ">
                                  <p
                                    onClick={() => seletedSearch(lab)}
                                    className="py-1 px-16 hover:bg-lightGray hover:text-primaryColor rounded-lg  w-full"
                                  >
                                    {lab.labTestName}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                )}  */}
                </div>
                <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                  <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Lab%20test1.webp"
                      alt="images"
                      className="w-full h-full object-cover image-boxshadow rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-White">
            {searchTerm && labSearched.length !== 0 ? (
              <div className="container mx-auto py-[50px] md:py-[90px]">
                <div>
                  <div>
                    <div className="flex flex-col">
                      {labSearched.length !== 0 && (
                        <h2 className="text-center text-xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
                          Available Lab test(s)
                        </h2>
                      )}
                      {labSearched
                        ? labSearched.map((item, index) => (
                            <div className="mb-5 w-full" key={index}>
                              <Card>
                                <div className="hidden md:flex items-center gap-5">
                                  <div className="bg-lightBlue w-[5rem] h-[5rem] rounded-full p-5">
                                    <img
                                      src={TestTube}
                                      alt="test"
                                      className="h-full w-full"
                                    />
                                  </div>
                                  <div className="flex justify-between w-full gap-5">
                                    <div className="ml-5 w-[75%]">
                                      <h6 className="text-lg font-semibold text-primaryColor mb-1">
                                        {item.labTestName}
                                      </h6>
                                      <p className="text-textGray text-base font-light">
                                        <span className="font-medium">
                                          Test Display Name:
                                        </span>{" "}
                                        {item.testDisplayName}
                                      </p>
                                    </div>
                                    <div className="w-[25%] flex justify-between items-center gap-3">
                                      <p className="font-bold text-xl text-secondaryColor ">
                                        ₹ {item.amount}
                                      </p>
                                      <button
                                        className={`${
                                          drugIDsInCart.indexOf(
                                            item.labTestCode
                                          ) === -1
                                            ? "bg-primaryColor text-White"
                                            : "text-secondaryColor bg-White border border-secondaryColor"
                                        }  w-[150px] h-[45px]  text-sm font-medium rounded cursor-pointer `}
                                        onClick={(e) =>
                                          addTocart(e, item, index,drugIDsInCart.indexOf(
                                            item.labTestCode
                                          ) === -1)
                                        }
                                      >
                                        {drugIDsInCart.indexOf(
                                          item.labTestCode
                                        ) === -1
                                          ? "Add to Cart"
                                          : "ADDED"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  onClick={(e) => handleNavigation(e, item,drugIDsInCart.indexOf(
                                    item.labTestCode
                                  ) === -1)}
                                  className="text-secondaryColor underline text-sm cursor-pointer text-right"
                                >
                                  View More Details
                                </div>
                                <div className="block md:hidden">
                                  <div className="md:flex items-center mb-3 gap-2">
                                    <div className="bg-lightBlue w-[3rem] md:w-[2rem] h-[3rem] md:h-[2rem] rounded-full p-2 mb-3 md:mb-0">
                                      <img
                                        src={TestTube}
                                        alt="test"
                                        className="h-full w-full"
                                      />
                                    </div>
                                    <div className="md:ml-2">
                                      <h6 className="text-sm font-semibold text-primaryColor mb-1">
                                        {item.labTestName}
                                      </h6>
                                    </div>
                                  </div>
                                  <p className="text-textGray text-xs font-light">
                                    <span className="font-medium">
                                      Test Display Name:
                                    </span>{" "}
                                    {item.testDisplayName}
                                  </p>
                                </div>
                                <hr className="border-lightBlue border-[1px] my-3" />
                                <div className="md:flex justify-between gap-5">
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-13">
                                    <div className="font-medium flex gap-1">
                                      Test Type <div>:</div>
                                    </div>{" "}
                                    {item.testType}
                                  </p>
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                                    <div className="font-medium flex">
                                      Lab Name :
                                    </div>{" "}
                                    {item.labName}
                                  </p>
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                                    <div className="font-medium flex gap-1">
                                      City <div>:</div>
                                    </div>{" "}
                                    {item.city}
                                  </p>
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3 w-[40%]">
                                    <div className="font-medium flex gap-1">
                                      Address <div>:</div>
                                    </div>{" "}
                                    {item.address1}
                                  </p>
                                </div>
                                <div className="flex justify-between items-center mt-5 md:hidden">
                                  <p className="font-bold text-lg text-secondaryColor ">
                                    ₹ {item.amount}
                                  </p>

                                  <button
                                    className={`${
                                      drugIDsInCart.indexOf(
                                        item.labTestCode
                                      ) === -1
                                        ? "bg-primaryColor text-White"
                                        : "text-secondaryColor bg-White border border-secondaryColor"
                                    }  w-[120px] h-[30px]  text-sm font-medium rounded cursor-pointer `}
                                    onClick={(e) => addTocart(e, item, index)}
                                  >
                                    {drugIDsInCart.indexOf(item.labTestCode) ===
                                    -1
                                      ? "Add to Cart"
                                      : "ADDED"}
                                  </button>
                                </div>
                              </Card>
                            </div>
                          ))
                        : choosetoCart.map((item, index) => (
                            <div className="mb-5 w-full">
                              <Card>
                                <div className="hidden md:flex items-center gap-5">
                                  <div className="bg-lightBlue w-[5rem] h-[5rem] rounded-full p-5">
                                    <img
                                      src={TestTube}
                                      alt="test"
                                      className="h-full w-full"
                                    />
                                  </div>
                                  <div className="flex justify-between w-full">
                                    <div className="ml-5">
                                      <h6 className="text-lg font-semibold text-primaryColor mb-1">
                                        {item.labTestName}
                                      </h6>
                                      <p className="text-textGray text-base font-light">
                                        <span className="font-medium">
                                          Test Display Name:
                                        </span>{" "}
                                        {item.testDisplayName}
                                      </p>
                                    </div>
                                    <p className="font-bold text-xl text-secondaryColor ">
                                      ₹ {item.amount}
                                    </p>
                                    <button
                                      className=" text-White w-[150px] h-[45px]  text-sm font-medium rounded cursor-pointer bg-primaryColor"
                                      onClick={(e) => addTocart(e, item, index)}
                                    >
                                      Add to Cart
                                    </button>
                                  </div>
                                </div>
                                <div className="block md:hidden">
                                  <div className="flex items-center mb-3 gap-2">
                                    <div className="bg-lightBlue w-[2rem] h-[2rem] rounded-full p-2">
                                      <img
                                        src={TestTube}
                                        alt="test"
                                        className="h-full w-full"
                                      />
                                    </div>
                                    <div className="ml-2">
                                      <h6 className="text-sm font-semibold text-primaryColor mb-1">
                                        {item.labTestName}
                                      </h6>
                                    </div>
                                  </div>
                                  <p className="text-textGray text-xs font-light">
                                    <span className="font-medium">
                                      Test Display Name:
                                    </span>{" "}
                                    {item.testDisplayName}
                                  </p>
                                </div>
                                <hr className="border-lightBlue border-[1px] my-3" />
                                <div className="md:flex justify-between gap-5">
                                  <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-13">
                                    <span className="font-medium">
                                      Test Type :
                                    </span>{" "}
                                    {item.testType}
                                  </p>
                                  <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                                    <span className="font-medium">
                                      Lab Name :
                                    </span>{" "}
                                    {item.labName}
                                  </p>
                                  <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                                    <span className="font-medium">City :</span>{" "}
                                    {item.city}
                                  </p>
                                  <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3 w-[40%]">
                                    <span className="font-medium">
                                      Address :
                                    </span>{" "}
                                    {item.address1}
                                  </p>
                                </div>
                                <div className="flex justify-between items-center mt-5 md:hidden">
                                  <p className="font-bold text-lg text-secondaryColor ">
                                    ₹ {item.amount}
                                  </p>
                                  <button
                                    className=" text-White w-[120px] h-[30px]  text-sm font-medium rounded cursor-pointer bg-primaryColor"
                                    onClick={(e) => addTocart(e, item, index)}
                                  >
                                    Add to Cart
                                  </button>
                                </div>
                              </Card>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            ):labSearched.length === 0 && lastSearchText !== "" ? (
              <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-12 items-center text-center ">
                <div>
                  <img src={NodataImage} alt="emptyData" className="w-[60px]" />
                </div>
                <p className="text-center font-light item-center text-base text-textGray">
                  No results found
                </p>
              </div>
            ):""}
          </section>
          <section className="bg-lightGray">
            <div className="container mx-auto py[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
                Why <span className="text-secondaryColor">CureBay</span>?
              </h2>
              <div className="md:w-[60%] mx-auto grid md:grid-cols-2 gap-5">
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow  hoverWhite">
                  <div className="mb-5">
                    <img src={Lab111} alt="images" />
                  </div>
                  <p className="text-textGray md:leading-loose text-base">
                    NABL Accredited Fully Automated Labs
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Lab222} alt="images" />
                  </div>
                  <p className="text-textGray md:leading-loose text-base">
                    Accurate & Efficient Test Reports
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Lab333} alt="images" />
                  </div>
                  <p className="text-textGray md:leading-loose text-base">
                    On-time report delivery
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Lab444} alt="images" />
                  </div>
                  <p className="text-textGray md:leading-loose text-base">
                    Sample collection at your doorstep
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-White">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
                Lab <span className="text-secondaryColor">Partners</span>
              </h2>
              {labPartners && (
                <>
                 <Helmet>
                 <title>{'Lab Tests | Curebay'}</title>
                 <meta
                   name="description"
                   content={EnglishText.LAB_META_DESCRIPTION}
                 />
                 <meta name="title" content={"Lab Tests | Curebay"} />
                 <link rel="canonical" href={window.location.href} />{" "}
               </Helmet>
                <Slider {...lab}>
                  {labPartners.map((lab, idx) => (
                    <div class="bg-lightBlue  gap-5 labwidth p-5 rounded-lg mb-5 md:mb-0 ">
                      <div className="flex flex-col justify-between md:min-h-[100px]">
                        <div class="flex gap-4 ">
                          <div class="w-[80px] h-[80px]">
                            <img
                              src={process.env.REACT_APP_IMG_BASEURL + lab.logo}
                              alt={lab?.locationName}
                              className="rounded-full bg-White text-xs  w-[65px] h-[65px] object-contain"
                            />
                          </div>
                          <div class="w-full">
                            <p class="text-primaryColor text-sm font-semibold uppercase ">
                              {lab.locationName}
                            </p>
                            <p class="text-textGray mb-5 text-xs font-normal mt-1  ">
                              {lab.city}
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="w-full text-center bg-primaryColor px-2 py-2.5 text-White font-medium text-sm rounded-md"
                        onClick={() => handleClick(lab)}
                      >
                        View Details
                      </button>
                    </div>
                  ))}
                </Slider>
                </>
              )}
            </div>
          </section>
          <section className="bg-primaryColor">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-White leading-normal mb-[50px]">
                How it <span className="text-secondaryColor">works</span>?
              </h2>
              <div className="grid grid-cols-1 w-[80%] mx-auto md:w-full md:grid-cols-3 gap-10">
                <div className="md:block flex justify-center flex-col items-center">
                  <img src={OneImage} alt="images" />
                  <p className="text-White text-center md:text-left mt-3">
                    Select from our extensive range of diagnostic tests for your
                    convenience at home.
                  </p>
                </div>
                <div className="md:block flex justify-center flex-col items-center">
                  <img src={TwoImage} alt="images" />
                  <p className="text-White text-center md:text-left mt-3">
                    Benefit from our partnerships with top-notch diagnostic
                    partners nearby, ensuring accurate and rapid results.
                  </p>
                </div>
                <div className="md:block flex justify-center flex-col items-center">
                  <img src={ThreeImage} alt="images" />
                  <p className="text-White text-center md:text-left mt-3">
                    Pick a date and time that suits you best. A phlebotomist
                    will then arrive at your location to securely collect the
                    sample, following strict sanitary protocols.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-White md:bg-lightGray w-full overflow-x-hidden">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
                Upload <span className="text-secondaryColor">prescription</span>{" "}
                <br /> & book test
              </h2>
              <div className="bg-White rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
                <Form
                  ref={formRef}
                  layout="vertical"
                  autoComplete="off"
                  className="CommonForm grid grid-cols-1 md:grid-cols-2 md:gap-5"
                >
                  <Form.Item
                    name="name"
                    label={
                      <span>
                        Patient Name<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      placeholder="Enter your name"
                      defaultValue={profile.firstName}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label={
                      <span>
                        Phone Number<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <InputNumber
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                      defaultValue={profile.mobile}
                      placeholder="Enter number"
                      onKeyPress={handleNumberPress}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span>
                        Email Address<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      placeholder="Enter email"
                      defaultValue={profile.email}
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="age"
                    label={
                      <span>
                        Age<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <InputNumber
                      placeholder="Enter age"
                      onKeyPress={handleNumberPress}
                      maxLength={3}
                      defaultValue={Object.keys(userData).length !== 0?getTheAge(profile.dob):""}
                    />
                  </Form.Item>
                  <Form.Item
                    name="gender"
                    label={
                      <span>
                        Gender<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Select
                      placeholder="Select your Gender"
                      defaultValue={
                        profile.gender
                          ? profile.gender === "M"
                            ? "Male"
                            : "Female"
                          : ""
                      }
                      optionFilterProp="children"
                      options={[
                        {
                          value: "Male",
                          label: "Male",
                        },
                        {
                          value: "Female",
                          label: "Female",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="upload"
                    label={
                      <span>
                        Upload Prescription
                        <span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <div className="h-[50px] border border-gray w-full rounded-md">
                      <input
                        className=" px-3 py-2 w-96 h-full flex items-center cursor-pointer relative z-[5]"
                        placeholder="Upload Prescription"
                        type="file"
                        onChange={handleImageChange}
                      />
                      {!image && (
                        <div className="absolute inset-0 flex justify-center items-center cursor-pointer">
                          <div className="text-textGray text-sm ">
                            {" "}
                            <CloudUploadOutlined className="pr-3" />
                            Upload Prescription
                          </div>
                        </div>
                      )}
                      {image && (
                        <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                          <p>{image.name}</p>
                          <DeleteOutlined
                            className="text-danger"
                            role="button"
                            onClick={handleUploadDelete1}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Item>
                </Form>
                <button
                  className="primary_btn !w-full mt-5 !h-[50px] !text-lg"
                  onClick={handleFormOne}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
          <section className="bg-lightGray md:bg-White w-full overflow-x-hidden">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
                View <span className="text-secondaryColor">test report</span>{" "}
                <br /> & request a{" "}
                <span className="text-secondaryColor">callback</span>
              </h2>
              <div className="bg-[#F5F6FA] rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
                <Form
                  ref={secondFormRef}
                  layout="vertical"
                  autoComplete="off"
                  className="CommonForm grid grid-cols-1 md:grid-cols-2 md:gap-5"
                >
                  <Form.Item
                    name="name"
                    label={
                      <span>
                        Name<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      placeholder="Enter your name"
                      defaultValue={profile.firstName}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label={
                      <span>
                        Phone Number<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <InputNumber
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                      placeholder="Enter number"
                      onKeyPress={handleNumberPress}
                      maxLength={10}
                      defaultValue={profile.mobile}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span>
                        Email Address<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      placeholder="Enter email"
                      defaultValue={profile.email}
                      disabled={
                        Object.keys(userData).length === 0 ? false : true
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="town"
                    label={
                      <span>
                        Town/Village<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Input
                      defaultValue={profile.city}
                      placeholder="Enter village name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="gender"
                    label={
                      <span>
                        Gender<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Select
                      defaultValue={
                        profile.gender
                          ? profile.gender === "M"
                            ? "Male"
                            : "Female"
                          : ""
                      }
                      placeholder="Select your Gender"
                      optionFilterProp="children"
                      options={[
                        {
                          value: "Male",
                          label: "Male",
                        },
                        {
                          value: "Female",
                          label: "Female",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="upload"
                    label={
                      <span>
                        Upload Test Report<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <div className="h-[50px] border border-gray w-full rounded-md">
                      <input
                        className="  px-3 py-2 w-96 h-full flex items-center cursor-pointer relative z-[5]"
                        placeholder="Upload Prescription"
                        type="file"
                        onChange={handleSecImageChange}
                      />
                      {!secImage && (
                        <div className="absolute bg-White inset-0 flex justify-center items-center cursor-pointer">
                          <div className="text-textGray text-sm ">
                            <CloudUploadOutlined className="pr-3" />
                            Upload Test Report
                          </div>
                        </div>
                      )}
                      {secImage && (
                        <div className="absolute flex justify-between top-[23%] left-[3%] w-[90%]">
                          <p>{secImage.name}</p>
                          <DeleteOutlined
                            className="text-danger"
                            role="button"
                            onClick={handleUploadDelete2}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Item>
                </Form>
                <button
                  className="primary_btn !w-full mt-5 !h-[50px] !text-lg"
                  onClick={handleFormTwo}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>
          <sectiion className="bg-White">
            <div className="w-[90%] md:w-[60%] mx-auto pb-[50px] md:pb-[90px] ">
              <div className="bg-secondaryColor mt-5 rounded-md md:flex justify-between relative">
                <div className="px-7 py-5">
                  <p className="font-medium text-xl mb-2 text-White capitalize">
                    Do you need to book lab test?
                  </p>
                  <p className="text-White font-light text-sm ">
                    Call us, we will book appointment for you.
                  </p>
                  <div className="md:flex w-full mt-6 gap-5">
                    <button
                      className="w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-5 rounded-md text-White text-sm font-medium"
                      onClick={() => window.open("tel:+918335000999")}
                    >
                      +91-8335000999
                    </button>
                  </div>
                </div>
                <div className="md:absolute right-0 bottom-0">
                  <img
                    src={CartDocImg}
                    alt="img"
                    className="w-full h-[200px]"
                  />
                </div>
              </div>
            </div>
          </sectiion>
        </Layouts>
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  // getToken: () => dispatch(getToken()),
  getlabPartnerslist: () => dispatch(getlabPartnerslist()),
});

// export default Lab;
export default connect(null, mapDispatchToProps)(Lab);
