import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { RightOutlined } from "@ant-design/icons";
import pielssurgeryimg1 from "../../../Assets/Images/pielssurgeryimg1.png";
import surgeryvectoricon1 from "../../../Assets/Images/surgeryvectoricon1.png";
import surgeryvectoricon2 from "../../../Assets/Images/surgeryvectoricon2.png";
import surgeryvectoricon3 from "../../../Assets/Images/surgeryvectoricon3.png";
import surgeryvectoricon4 from "../../../Assets/Images/surgeryvectoricon4.png";
import surgeryvectoricon5 from "../../../Assets/Images/surgeryvectoricon5.png";
import surgeryvectoricon6 from "../../../Assets/Images/surgeryvectoricon6.png";
import surgeryvectoricon7 from "../../../Assets/Images/surgeryvectoricon7.png";
import surgeryvectoricon8 from "../../../Assets/Images/surgeryvectoricon8.png";
import surgeryvectoricon9 from "../../../Assets/Images/surgeryvectoricon9.png";
import axios from "axios";
import choose1 from "../../../Assets/Images/Icons/Holistic.svg";
import choose2 from "../../../Assets/Images/Icons/Smooth.svg";
import choose3 from "../../../Assets/Images/Icons/Surgical.svg";
import choose4 from "../../../Assets/Images/Icons/Digital Records.svg";
import choose5 from "../../../Assets/Images/Icons/Online Enquiries.svg";

import { useParams, useHistory, useLocation } from "react-router-dom";
import bethanyhospital from "../../../Assets/Images/bethanyhospital.svg";
import Video from "../../../Assets/Images/videocall.svg";
import female_icon from "../../../Assets/Images/Female-Vector-icon.svg";
import male_icon from "../../../Assets/Images/Male-Vector-icon.svg";
import Location from "../../../Assets/Images/Location.svg";
import Surg_2 from "../../../Assets/Images/Surgery/popup.svg";
import Surg_3 from "../../../Assets/Images/Surgery/popup_1.svg";
import Surg_4 from "../../../Assets/Images/Surgery/popup_2.svg";
import Surg_5 from "../../../Assets/Images/Surgery/popup_3.svg";
// import states from "../helper/state.json";
import http from "../../../Redux/Services/http-common";
import sucess_msg from "../../../Assets/Images/sucess_msg.svg";
import Layouts from "../../Layouts";
import userprofileservice from "../../../Redux/Services/userprofileservice";
import FAQ from "../collapse_surgery";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Dropdown, Form, Input, InputNumber, Modal, Select, message } from "antd";
import SurgeryIcon1 from "../../../Assets/LabImages/USFDA Approved.svg";
import SurgeryIcon2 from "../../../Assets/LabImages/24 Hour Stay.svg";
import SurgeryIcon3 from "../../../Assets/LabImages/Cab Services.svg";
import SurgeryIcon4 from "../../../Assets/LabImages/Advanced Technology.svg";
import SurgeryIcon5 from "../../../Assets/LabImages/Less Cut Less Pain.svg";
import SurgeryIcon6 from "../../../Assets/LabImages/Patient Care.svg";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var positionScroll = 6;
var positionScrollDoctor = 6;
var isFirstTimeLeft = true;

function SurgeryDetails() {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const uniqueIds = new Set();
  const location = useLocation();
  const [openModal, setModal] = useState(false);
  const [surgeryData, setSurgeryData] = useState({});
  const [surgeryObj, setSurgeryObj] = useState([]);
  const [surgeryObjdata, setSurgeryObjdata] = useState([]);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [profile, setProfile] = useState(userData);
  // const [surgeryDataAttribute, setSurgeryDataAttribute] = useState({});
  const [hospitallistData, setHospitalData] = useState({});
  const [doctorData, setDoctorData] = useState({});
  // const [city, setCity] = useState(states["Odisha"]);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;
  const [appointmentMessage, setAppointmentMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const params = useParams();
  const uniqueID = params.search.split("--")[0];
  const specialityID = params.search.split("--")[1];

  

  // const [submitData, setSubmitData] = useState({
  //   patientId: patientinfoData.code,
  //   patientName: patientinfo?.name,
  //   patientEmail: "",
  //   mobileNo: patientinfo?.mobileNo,
  //   city: patientinfo.city,
  //   surgeryId: patientinfo.disease,
  // });
  const [formerror, setFormerror] = useState({
    patientId: "",
    patientName: "",
    mobileNo: "",
    state: "",
    city: "",
    surgeryId: "",
    email: "",
  });
  const uniqueFAQs = surgeryData?.faq?.filter((faq) => {
    if (uniqueIds.has(faq.id)) {
      return false; // Skip duplicate FAQ
    }
    uniqueIds.add(faq.id);
    return true; // Keep unique FAQ
  });



  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  const appointment = () => {
    setModal(true);
  };
  const handleCancel = () => {
    setModal(false);
  };

  const getSurgeries = async () => {
    try {
      let data = await http.get(
        `${process.env.REACT_APP_BASEURL}surgery/allSurgTreatments`
      );
      setSurgeryObjdata(data.data);
      let diseases = data.data.map((obj) => {
        return obj.surgeryName;
      });
      setSurgeryObj(data.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getSurgeryHospitals = async () => {
    try {
      const data = await userprofileservice.getSurgeryHospitals(uniqueID);
      setHospitalData(data?.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  const getSurgeryDoctors = async () => {
    try {
      const data = await userprofileservice.getSurgeryDoctors(uniqueID);
      setDoctorData(data?.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  const getSurgeryDataById = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_CMS_BASEURL}surgery-details/${params.search}`
      );
      setSurgeryData(data?.data?.data?.attributes);
      // setSurgeryDataAttribute(data?.data?.data?.attributes);
    } catch (err) {
      console.log("error", err);
    }
  };
  // console.log("surgeryAttribute", surgeryDataAttribute);

  useEffect(() => {
    getSurgeryDataById();
    getSurgeryHospitals();
    getSurgeryDoctors();
    getSurgeries();
  }, [location.pathname]);

  const viewprofile = (e, doct) => {
    e.preventDefault();
    history.push(`/doctors/${doct.seoNameId}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const handleCitySelection = (e) => {
  //   try {
  //     console.log(e.target.value, "CityChecki12");
  //     setSelectedCity(e.target.value);
  //     console.log(selectedCity, "CityChecki");
  //     const currentURL = window.location.pathname;
  //     let surgery_id = parseInt(surgeryData.surgery_id);
  //     let surgeryName = surgeryData.surgeryName;
  //     const surgeryDetailsRoute = `${currentURL}/${encodeURIComponent(
  //       selectedCity
  //     )}`;
  //     history.push({
  //       pathname: `${currentURL}/${e.target.value}`,
  //       state: { selectedCity: e.target.value },
  //     });
  //     console.log(e.target.value, "cityURL");
  //   } catch (error) {
  //     console.error("Error setting selected city:", error);
  //   }
  // };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const bookappointment = (e, doct) => {
    e.preventDefault();
     history.push(`/doctors/${doct.seoNameId}`);
   

  
  
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleImgError = (e) => {
    e.target.src = bethanyhospital;
  };
  const handleScroll = (e, dir) => {
    e.preventDefault();

    if (dir == "rightArrow") {
      if (positionScroll == 0) {
        positionScroll = 6;
      }
      positionScroll = positionScroll + 2;
    } else {
      if ((isFirstTimeLeft = true)) {
        positionScroll = positionScroll - 6;
        isFirstTimeLeft = false;
      } else {
        positionScroll = positionScroll - 2;
      }
    }

    if (positionScroll > hospitallistData.length) {
      positionScroll = hospitallistData.length - 2;
    }

    if (positionScroll < 0) {
      positionScroll = 0;
    }

    scrollRefs?.current[positionScroll]?.current?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  };
  const handleScrollDoctor = (e, dir) => {
    e.preventDefault();

    if (dir == "rightArrow") {
      if (positionScrollDoctor == 0) {
        positionScrollDoctor = 6;
      }
      positionScrollDoctor = positionScrollDoctor + 2;
    } else {
      if ((isFirstTimeLeft = true)) {
        positionScrollDoctor = positionScrollDoctor - 6;
        isFirstTimeLeft = false;
      } else {
        positionScrollDoctor = positionScrollDoctor - 2;
      }
    }

    if (positionScrollDoctor > doctorData.length) {
      positionScrollDoctor = doctorData.length - 2;
    }

    if (positionScrollDoctor < 0) {
      positionScrollDoctor = 0;
    }

    scrollRefsDoc?.current[positionScrollDoctor]?.current?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  };
  const scrollRefsDoc = useRef([]);
  scrollRefsDoc.current = [...Array(doctorData.length).keys()].map(
    (_, i) => scrollRefsDoc?.current[i] ?? createRef()
  );
  const scrollRefs = useRef([]);
  scrollRefs.current = [...Array(hospitallistData.length).keys()].map(
    (_, i) => scrollRefs?.current[i] ?? createRef()
  );

  const handleChange = (value) => {
    // setSubmitData({
    //   ...submitData,
    //   [e.target.name]: e.target.value,
    // });
    setSelectedCity(value);
  };

  // const handleSubmit1 = (e) => {
  //   e.preventDefault();
  //   const errors = {};
  //   const phonepattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  //   //  console.log(submitData, "submitdata")
  //   if (!submitData.patientName) {
  //     errors.patientName = "Please enter  your name";
  //   } else if (!submitData.city) {
  //     errors.mobileNo = "Please enter your mobile number";
  //   } else if (!phonepattern.test(submitData.mobileNo)) {
  //     errors.city = "Please select City";
  //   } else if (!submitData.surgeryId) {
  //     errors.disease = "Please select disease";
  //   } else {
  //     // const data=surgeryObj.find(e=>e.name==submitData.disease)
  //     setLoading(true);
  //     let reqData = submitData;
  //     reqData.surgeryId = parseInt(surgeryData.surgery_id);

  //     http
  //       .post(
  //         `${process.env.REACT_APP_BASEURL}surgery/bookAppointment`,
  //         reqData
  //       )
  //       .then((res) => {
  //         console.log(res, "xyz");
  //         setAppointmentMessage(true);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.log(error, "yellow");
  //         setAppointmentMessage(false);
  //         setLoading(false);
  //       });
  //   }
  // };

  const handleSubmit = () => {
    let values = form.getFieldValue();
    if (specialityID === undefined || specialityID === "" || specialityID === null) {
      if (values.surgeryId === "" || values.surgeryId === undefined) {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.firstName === undefined || profile.firstName === "" || profile.firstName === null) {
      if (values.patientName === undefined || values.patientName === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (values.city === undefined || values.city === "") {
        message.error("Please fill in all fields");
        return;
    }
    

    let payload;

    if (Object.keys(userData).length === 0) {
      if (values.patientEmail === undefined || values.patientEmail === "") {
        message.error("Please fill in all fields");
        return;
      }
      if (values.mobileNo === undefined || values.mobileNo === "") {
        message.error("Please fill in all fields");
        return;
      }
      payload = {
        patientName: values.patientName ? values.patientName : profile.firstName,
        patientEmail: values.patientEmail,
        city: values.city,
        patientId: userData.code,
        mobileNo: values.mobileNo,
        sourceChannel: "B2c",
        state: "OD",
        status: 1,
        surgeryId: values.surgeryId ? values.surgeryId : Number(specialityID),
      };
    }
    else {

      payload = {
        patientName: values.patientName ? values.patientName : profile.firstName,
        patientEmail: profile.email,
        city: values.city,
        patientId: userData.code,
        mobileNo: profile.mobile,
        sourceChannel: "B2c",
        state: "OD",
        status: 1,
        surgeryId: values.surgeryId ? values.surgeryId : Number(specialityID),
      };

    }

    setLoading(true);
    http
      .post(`${process.env.REACT_APP_BASEURL}surgery/bookAppointment`, payload)
      .then((res) => {
        message.success("Appointment Booked");
        form.resetFields();
        setLoading(false);
        setModal(false);
      })
      .catch((error) => {
        setLoading(false);
      });
   
  };

  const viewDetails = (e, hosp) => {
    e.preventDefault();
    history.push({ pathname: `/hospital/${hosp.seoNameId}`, state: hosp });
  };

  const Surgery = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Industry = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layouts>
      <ToastContainer/>
       <Helmet>
                <title>{'Surgery | Curebay'}</title>
                <meta
                    name="description"
                //   content={EnglishText.LAB_META_DESCRIPTION}
                />
                <meta name="title" content={"Surgery | Curebay"} />
                <link rel="canonical" href={window.location.href} />{" "}
            </Helmet>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[20px] md:py-[30px]">
          <div className="w-full bg-primaryColor rounded-md px-8 py-4 mb-5">
            <div className=" md:text-xl text-White">
              <Link to="/services/surgery">Surgery</Link>
              <RightOutlined className="text-base" />
              <span className="cursor-pointer text-secondaryColor">
                {surgeryData?.surgeryName}
              </span>
            </div>
          </div>
          <div className="bg-White p-7 rounded-md md:flex gap-5">
            <div className="md:w-[70%]">
              <div className="mb-5">
                <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-3">
                  {surgeryData?.surgeryName}
                </h4>
                <p className="text-textGray text-sm">
                  {surgeryData?.description}
                </p>
              </div>
              {surgeryData?.symptoms?.length ? (
                <div className="mb-5">
                  <h4 className="text-textGray font-semibold text-base underline mb-3">
                    Symptoms :
                  </h4>
                  <ul className="list-decimal list-inside text-textGray text-sm pl-5">
                    {Array.from(
                      new Set(surgeryData.symptoms.map((obj) => obj.label))
                    ).map((label) => {
                      const symptom = surgeryData.symptoms.find(
                        (obj) => obj.label === label
                      );
                      if (symptom) {
                        return (
                          <li className="pb-1" key={symptom.label}>
                            {symptom.label}
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </div>
              ) : null}
              {surgeryData?.causes?.length ? (
                <div className="mb-5">
                  <h4 className="text-textGray font-semibold text-base underline mb-3">
                    Causes :
                  </h4>
                  <ol
                    style={{ listStyleType: "numbers" }}
                    className="list-decimal list-inside text-textGray text-sm pl-5"
                  >
                    {Array.from(
                      new Set(surgeryData.causes.map((obj) => obj.label))
                    ).map((label) => {
                      // Find the first occurrence of the cause with the unique label
                      const cause = surgeryData.causes.find(
                        (obj) => obj.label === label
                      );
                      if (cause) {
                        return (
                          <li className="pb-1" key={cause.label}>
                            {cause.label}
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ol>
                </div>
              ) : null}
              <button
                onClick={appointment}
                className="py-2 px-4 bg-primaryColor text-sm text-White rounded-md hover:bg-secondaryColor"
              >
                Book Appointment
              </button>
              {/* <Dropdown
              value={selectedCity}
              options={cities}
              onChange={handleCitySelection}
              optionLabel="name"
              optionValue="value"
              placeholder="Select City"
              className="mt-8 ml-4 h-14 sm:h-10 btn-52 text-white rounded-md flex jus-content-center align-center"
            /> */}
            </div>
            <div className="md:w-[30%] mt-5 md:mt-0">
              <img
                className="border border-gray rounded-md w-full sticky top-36"
                src={
                  surgeryData?.surgery_icon_url
                    ? surgeryData?.surgery_icon_url
                    : pielssurgeryimg1
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-6 mt-5 gap-5">
            <div className="bg-White p-3 rounded-md flex flex-col justify-center items-center">
              <img src={SurgeryIcon1} alt="images" className="w-16" />
              <div className="text-primaryColor text-center text-sm font-medium mt-3">USFDA-Approved</div>
            </div>
            <div className="bg-White p-3 rounded-md flex flex-col justify-center items-center">
              <img src={SurgeryIcon2} alt="images" className="w-16" />
              <div className="text-primaryColor text-center text-sm font-medium mt-3">24hr Stay</div>
            </div>
            <div className="bg-White p-3 rounded-md flex flex-col justify-center items-center">
              <img src={SurgeryIcon3} alt="images" className="w-16" />
              <div className="text-primaryColor text-center text-sm font-medium mt-3">Cab Services</div>
            </div>
            <div className="bg-White p-3 rounded-md flex flex-col justify-center items-center">
              <img src={SurgeryIcon4} alt="images" className="w-16" />
              <div className="text-primaryColor text-center text-sm font-medium mt-3">Advance Technology</div>
            </div>
            <div className="bg-White p-3 rounded-md flex flex-col justify-center items-center">
              <img src={SurgeryIcon5} alt="images" className="w-16" />
              <div className="text-primaryColor text-center text-sm font-medium mt-3">Less Cut Less Pain</div>
            </div>
            <div className="bg-White p-3 rounded-md flex flex-col justify-center items-center">
              <img src={SurgeryIcon6} alt="images" className="w-16" />
              <div className="text-primaryColor text-center text-sm font-medium mt-3">Quick Discharge</div>
            </div>
          </div>
        </div>
      </section>
      {surgeryData?.surgical_types?.length ||
        surgeryData?.non_surgical_types?.length ? (
        <section className="bg-White ">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-10 text-center">
              Treatment Options
            </h4>
            <div className="md:flex gap-10">
              {surgeryData?.surgical_types?.length ? (
                <div className="md:w-[60%]">
                  <div>
                    <h4 className="text-primaryColor font-semibold text-xl mb-3">
                      {surgeryData?.surgical_description
                        ? "Surgical:"
                        : "Surgical Options:"}
                    </h4>
                    <p className="text-textGray text-sm">
                      {surgeryData?.surgical_description}
                    </p>
                  </div>

                  {Array.from(
                    new Set(surgeryData.surgical_types.map((obj) => obj.label))
                  ).map((label) => {
                    // Find the first occurrence of the surgical type with the unique label
                    const surgicalType = surgeryData.surgical_types.find(
                      (obj) => obj.label === label
                    );
                    if (surgicalType) {
                      return (
                        <>
                          <h4 className="text-base text-secondaryColor font-medium mt-3">
                            {surgicalType.label}
                          </h4>
                          <p className="text-textGray text-sm ">
                            {surgicalType.value}
                          </p>
                        </>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}
              <div className="md:w-[40%] mt-5 md:mt-0">
                {surgeryData?.surgical_image_url &&
                  surgeryData?.surgical_types &&
                  surgeryData?.surgical_description && (
                    <img
                      src={surgeryData?.surgical_image_url}
                      className="w-full sticky top-32 border border-gray rounded-md"
                    />
                  )}
              </div>
            </div>
            <div className="md:flex gap-10 mt-10">
              {selectedCity === "puri" ? (
                <h3 className="text-primaryColor font-semibold text-xl mb-3 block md:hidden">
                  Non Surgical options:
                </h3>
              ) : (
                <h3 className="text-primaryColor font-semibold text-xl mb-3 block md:hidden">
                  Non Surgical options
                </h3>
              )}
              <div className="md:w-[40%]">
                {surgeryData?.surgical_image_url &&
                  surgeryData?.surgical_types &&
                  surgeryData?.surgical_description && (
                    <img
                      src={surgeryData?.non_surgical_image_url}
                      className="w-full sticky top-32 border border-gray rounded-md h-auto"
                    />
                  )}
              </div>
              {surgeryData?.non_surgical_types?.length ? (
                <div className="md:w-[60%]">
                  {selectedCity === "puri" ? (
                    <h3 className="text-primaryColor font-semibold text-xl mb-3 md:block hidden">
                      Non Surgical options:
                    </h3>
                  ) : (
                    <h3 className="text-primaryColor font-semibold text-xl mb-3 md:block hidden">
                      Non Surgical options
                    </h3>
                  )}
                  {Array.from(
                    new Set(
                      surgeryData.non_surgical_types.map((obj) => obj.label)
                    )
                  ).map((label) => {
                    // Find the first occurrence of the non-surgical type with the unique label
                    const nonSurgicalType = surgeryData.non_surgical_types.find(
                      (obj) => obj.label === label
                    );
                    if (nonSurgicalType) {
                      return (
                        <div key={nonSurgicalType.id}>
                          <h4 className="text-base text-secondaryColor font-medium mt-3">
                            {nonSurgicalType.label}
                          </h4>
                          <p className="text-textGray text-sm ">
                            {nonSurgicalType.value}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </section>
      ) : null}
      {doctorData && doctorData.length > 0 ? (
        doctorData.length > 3 ? (
          <section className="bg-lightGray">
            <div className="container mx-auto py-[50px] md:py-[70px]">
              <div>
                <h2 className="text-center capitalize text-2xl md:text-3xl font-semibold text-primaryColor leading-normal mb-[50px]">
                  First-rated Doctors For <br />
                  <span className="text-secondaryColor">
                    {surgeryData?.surgeryName}
                  </span>{" "}
                  treatment
                </h2>
                <Slider {...Surgery}>
                  {doctorData.map((doct, i) => (
                    <div key={i}>
                      <div className="bg-White rounded-xl p-2  mb-2 min-h-[150px] topCardwidth h-full   ">
                        <div className="flex gap-5">
                          <div className="w-[75px] h-[75px]">
                            <img
                              src={
                                doct.userPhoto
                                  ? `${process.env.REACT_APP_IMG_BASEURL}${doct.userPhoto}`
                                  : doct.gender === "M"
                                    ? male_icon
                                    : female_icon
                              }
                              alt={doct.userName}
                              onError={(e) => handleImgError(e, doct.gender)}
                              className="rounded-full bg-lightBlue text-xs  w-[65px] h-[65px] object-contain"
                            />
                          </div>
                          <div className="w-[80%]">
                            <h5 className="text-primaryColor w-full truncate overflow-hidden whitespace-nowrap font-semibold ">
                              {doct.userName}
                            </h5>
                            <div className="text-textGray text-sm ">
                              <span>{doct.specialities}</span>
                            </div>
                            <p className="text-textGray text-xs font-light mt-2 mb-1.5 flex">
                              <span className="font-medium mr-2">
                                Experience :
                              </span>
                              {doct.yearOfExperience} years
                            </p>
                            <p className="text-textGray text-xs font-light mb-1.5 flex">
                              <span className="font-medium mr-2">
                                Location :
                              </span>
                              {doct.city}
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-3 mt-3 mb-3">
                          <button
                            onClick={(e) => viewprofile(e, doct)}
                            className="hover:text-secondaryColor text-primaryColor text-xs border border-primaryColor hover:border-secondaryColor w-full py-2 rounded-lg "
                          >
                            View Profile
                          </button>
                          <button
                            className="hover:bg-secondaryColor bg-primaryColor rounded-lg text-White text-xs w-full py-2 "
                            onClick={(e) => bookappointment(e, doct)}
                          >
                            Book Consultation
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
        ) : (
          <section className="bg-lightGray">
            <div className="container mx-auto py-[50px] md:py-[70px]">
              <div>
                <h2 className="text-center capitalize text-2xl md:text-3xl font-semibold text-primaryColor leading-normal mb-[50px]">
                  First-rated Doctors For <br />
                  <span className="text-secondaryColor">
                    {surgeryData?.surgeryName}
                  </span>{" "}
                  treatment
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {doctorData.map((doct, i) => (
                    <div
                      key={i}
                      className="bg-White rounded-xl p-2 mb-2 topCardwidth h-full mr-2"
                    >
                      <div className="flex gap-5">
                        <div className="w-[75px] h-[75px]">
                          <img
                            src={
                              doct.userPhoto
                                ? `${process.env.REACT_APP_IMG_BASEURL}${doct.userPhoto}`
                                : doct.gender === "M"
                                  ? male_icon
                                  : female_icon
                            }
                            alt={doct.userName}
                            onError={(e) => handleImgError(e, doct.gender)}
                            className="rounded-full bg-lightBlue text-xs  w-[65px] h-[65px] object-contain"
                          />
                        </div>
                        <div className="w-[80%]">
                          <h5 className="text-primaryColor w-full truncate overflow-hidden whitespace-nowrap font-semibold ">
                            {doct.userName}
                          </h5>
                          <div className="text-textGray text-sm ">
                            <span>{doct.specialities}</span>
                          </div>
                          <p className="text-textGray text-xs font-light mt-2 mb-1.5 flex">
                            <span className="font-medium mr-2">
                              Experience :
                            </span>
                            {doct.yearOfExperience} years
                          </p>
                          <p className="text-textGray text-xs font-light mb-1.5 flex">
                            <span className="font-medium mr-2">Location :</span>
                            {doct.city}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-3 mt-3 mb-3">
                        <button
                          onClick={(e) => viewprofile(e, doct)}
                          className="hover:text-secondaryColor text-primaryColor text-xs border border-primaryColor hover:border-secondaryColor w-full py-2 rounded-lg "
                        >
                          View Profile
                        </button>
                        <button
                          className="hover:bg-secondaryColor bg-primaryColor rounded-lg text-White text-xs w-full py-2 "
                          onClick={(e) => bookappointment(e, doct)}
                        >
                          Book Consultation
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )
      ) : <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[70px]">
          <div>
            <h2 className="text-center capitalize text-2xl md:text-3xl font-semibold text-primaryColor leading-normal mb-[50px]">
              First-rated Doctors For <br />
              <span className="text-secondaryColor">
                {surgeryData?.surgeryName}
              </span>{" "}
              treatment
            </h2>
            <div className="flex justify-center items-center" >
              <p className="font-semibold text-base center mr-4">Please leave details and we will get back to you.</p>
              <button
                onClick={appointment}
                className="py-2 px-4 bg-primaryColor text-sm text-White rounded-md hover:bg-secondaryColor cursor-pointer min-w-max"
              >
                Fill details
              </button>
            </div>
          </div>
        </div>
      </section>}

      {surgeryData?.when_to_see_doctor &&
        surgeryData?.when_to_see_doctor.length > 0 && (
          <section className="bg-White ">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-10 text-center">
                When to see a doctor?
              </h4>
              <div className="md:flex gap-5 items-center">
                <div className="md:w-[70%]">
                  <ul className="list-decimal list-inside text-textGray text-sm">
                    {surgeryData?.when_to_see_doctor?.length
                      ? Array.from(
                        new Set(
                          surgeryData.when_to_see_doctor.map(
                            (obj) => obj.label
                          )
                        )
                      ).map((label) => {
                        const uniqueItem =
                          surgeryData.when_to_see_doctor.find(
                            (obj) => obj.label === label
                          );
                        return (
                          <li className="pb-1" key={uniqueItem.label}>
                            {uniqueItem.label}
                          </li>
                        );
                      })
                      : null}
                  </ul>
                </div>
                <div className="md:w-[30%] mt-5 md:mt-0">
                  {surgeryData?.when_to_see_doctor &&
                    surgeryData.when_to_see_doctor.length > 0 && (
                      <img
                        className="border border-gray rounded-md"
                        src={pielssurgeryimg1}
                      />
                    )}
                </div>
              </div>
            </div>
          </section>
        )}

      <section className="bg-primaryColor">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          {surgeryData?.post_surgical_care &&
            surgeryData.post_surgical_care?.length > 0 && (
              <h4 className="text-White font-semibold text-2xl md:text-3xl mb-10 text-center">
                Post-surgical Care Includes
              </h4>
            )}
          <div className="hidden sm:grid mt-2 grid-cols-2 relative mx-16 mb-5">
            {surgeryData?.post_surgical_care?.length
              ? Array.from(
                new Set(surgeryData.post_surgical_care.map((res) => res.id))
              ).map((id) => {
                const uniqueItem = surgeryData.post_surgical_care.find(
                  (res) => res.id === id
                );
                return (
                  uniqueItem.label &&
                  uniqueItem.value && (
                    <div key={uniqueItem.id} className="pointed-border">
                      <label className="font-semibold text-base">
                        {uniqueItem.label}
                      </label>
                      :{" "}
                      <label className="font-light text-sm">
                        {uniqueItem.value}
                      </label>
                    </div>
                  )
                );
              })
              : null}
          </div>

          <div className="block sm:hidden mt-2 mx-2">
            {surgeryData?.post_surgical_care?.length
              ? Array.from(
                new Set(surgeryData.post_surgical_care.map((res) => res.id))
              ).map((id) => {
                const uniqueItem = surgeryData.post_surgical_care.find(
                  (res) => res.id === id
                );
                return (
                  <div key={uniqueItem.id} className="text-justify mb-5">
                    <label className="text-secondaryColor font-semibold text-md">
                      {uniqueItem.label}
                    </label>
                    :{" "}
                    <label className="text-White font-light text-xs">
                      {uniqueItem.value}
                    </label>
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </section>
      {Array.isArray(hospitallistData) && hospitallistData.length > 3 ? (
        <section className="bg-lightGray">
          {hospitallistData?.length ? (
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-10 text-center">
                Industry Leading <br />
                <span className="text-secondaryColor">Hospital Partners</span>
              </h4>
              <Slider {...Industry}>
                {hospitallistData?.map((hosp, i) => (
                  <div key={i}>
                    <div className="bg-White rounded-xl p-2 mb-2 topCardwidth h-full mr-2">
                      <div className="mb-2 common-shadow rounded-lg">
                        <img
                          src={
                            hosp.hospitalPhoto
                              ? `${process.env.REACT_APP_IMG_BASEURL}${hosp.hospitalPhoto}`
                              : ""
                          }
                          alt={hosp?.hospitalName}
                          onError={handleImgError}
                          className="w-full h-[150px] object-contain"
                        />
                      </div>
                      <h5 className="text-primaryColor text-center uppercase w-full truncate overflow-hidden whitespace-nowrap font-semibold mb-1">
                        {hosp.hospitalName}
                      </h5>
                      <div className="flex justify-center items-center my-2 text-textGray text-sm">
                        <EnvironmentOutlined
                          className="text-secondaryColor"
                          style={{ marginRight: "5px" }}
                        />
                        <span>{hosp.city ? hosp.city : ""} </span>
                      </div>
                      <button
                        onClick={(e) => viewDetails(e, hosp)}
                        className="w-full bg-primaryColor text-White px-2 py-2 rounded-md mt-1 hover:bg-secondaryColor"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : null}
        </section>
      ) : (
        <section className="bg-lightGray">
          {hospitallistData?.length ? (
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-10 text-center">
                Industry Leading <br />
                <span className="text-secondaryColor">Hospital Partners</span>
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {Array.isArray(hospitallistData) &&
                  hospitallistData.map((hosp, i) => (
                    <div
                      key={i}
                      className="bg-White rounded-xl p-2 mb-2 topCardwidth h-full mr-2"
                    >
                      <div className="mb-2 common-shadow rounded-lg">
                        <img
                          src={
                            hosp.hospitalPhoto
                              ? `${process.env.REACT_APP_IMG_BASEURL}${hosp.hospitalPhoto}`
                              : ""
                          }
                          alt={hosp?.hospitalName}
                          onError={handleImgError}
                          className="w-full h-[150px] object-contain"
                        />
                      </div>
                      <h5 className="text-primaryColor text-center uppercase w-full truncate overflow-hidden whitespace-nowrap font-semibold mb-1">
                        {hosp.hospitalName}
                      </h5>
                      <div className="flex justify-center items-center my-2 text-textGray text-sm">
                        <EnvironmentOutlined
                          className="text-secondaryColor"
                          style={{ marginRight: "5px" }}
                        />
                        <span>{hosp.city ? hosp.city : ""} </span>
                      </div>
                      <button
                        onClick={(e) => viewDetails(e, hosp)}
                        className="w-full bg-primaryColor text-White px-2 py-2 rounded-md mt-1 hover:bg-secondaryColor"
                      >
                        View Details
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </section>
      )}

      {surgeryData?.Indications?.length ? (
        <section className="bg-lightGray py-[50px]">
          <div className="bg-White container mx-auto rounded-md p-7">
            <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-5 text-center">
              Indications
            </h4>
            {surgeryData?.Indications?.length
              ? Array.from(
                new Set(surgeryData.Indications.map((obj) => obj.id))
              ).map((id) => {
                const uniqueObj = surgeryData.Indications.find(
                  (obj) => obj.id === id
                );
                return (
                  <div className="mb-2 text-justify">
                    <div className="text-secondaryColor text-base font-medium w-full">
                      {uniqueObj.label}
                    </div>{" "}
                    <div className="text-textGray text-sm ">
                      {uniqueObj.value}
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </section>
      ) : null}

      {surgeryData?.Contraindications?.length ? (
        <section className="bg-lightGray pb-[50px]">
          <div className="bg-White container mx-auto rounded-md p-7">
            <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-5 text-center">
              Contraindications
            </h4>
            {surgeryData?.Contraindications?.length
              ? Array.from(
                new Set(surgeryData.Contraindications.map((obj) => obj.id))
              ).map((id) => {
                const uniqueObj = surgeryData.Contraindications.find(
                  (obj) => obj.id === id
                );
                return (
                  <div className="mb-2 text-justify">
                    <div className="text-secondaryColor text-base font-medium w-full">
                      {uniqueObj.label}
                    </div>{" "}
                    <div className="text-textGray text-sm ">
                      {uniqueObj.value}
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </section>
      ) : null}
      {surgeryData?.surgical_procedure && (
        <section className="bg-lightGray pb-[50px]">
          <div className="bg-White container mx-auto rounded-md p-7">
            <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-5 text-center">
              Surgical Procedures
            </h4>
            {surgeryData?.surgical_procedure?.Introduction && (
              <div className="mb-2 text-justify">
                <div className="text-secondaryColor text-base font-medium w-full">
                  Introduction:
                </div>
                <div className="text-textGray text-sm ">
                  {surgeryData?.surgical_procedure?.Introduction}
                </div>
              </div>
            )}
            {surgeryData?.surgical_procedure?.conclusion && (
              <div className="mb-2 text-justify">
                <div className="text-secondaryColor text-base font-medium w-full">
                  Conclusion:
                </div>
                <div className="text-textGray text-sm ">
                  {surgeryData?.surgical_procedure?.conclusion}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      {surgeryData?.preventive_measures && (
        <section className="bg-lightGray pb-[50px]">
          <div className="bg-White container mx-auto rounded-md p-7">
            <h4 className="text-primaryColor font-semibold text-2xl md:text-3xl mb-5 text-center">
              Preventative Measures
            </h4>
            <div className="md:w-[70%] mx-auto mb-5">
              <p className="text-textGray text-sm text-center">
                {" "}
                {surgeryData.preventive_measures_description}
              </p>
            </div>
            {surgeryData?.preventive_measures?.length
              ? Array.from(
                new Set(
                  surgeryData.preventive_measures.map((obj) => obj.label)
                )
              ).map((label) => {
                const measure = surgeryData.preventive_measures.find(
                  (obj) => obj.label === label
                );
                if (measure) {
                  return (
                    <div className="mb-2 text-justify" key={measure.label}>
                      <div className="text-secondaryColor text-base font-medium w-full">
                        {measure.label}
                      </div>{" "}
                      <div className="text-textGray text-sm ">
                        {measure.value}
                      </div>
                    </div>
                  );
                }
                return null;
              })
              : null}
          </div>
        </section>
      )}
      <div>
        <div className="mx-2 sm:mx-8">
          {surgeryData?.surgical_procedure?.length
            ? surgeryData?.surgical_procedure?.length &&
            surgeryData.surgical_procedure?.map((obj, index) => {
              return (
                <div className="mb-4 text-justify" key={index}>
                  <div className="fc-42BA85 font-semibold margin-top-5 w-full sm:w-10/12">
                    {obj?.Introduction}
                  </div>{" "}
                  <div className="mt-2 w-full sm:w-10/12">
                    {obj?.conclusion}
                  </div>
                </div>
              );
            })
            : null}
        </div>

        {selectedCity === "puri" && surgeryData.surgeryName === "Piles" && (
          <h2 className="font-bold fs-25 mt-12 flex justify-center align-center">
            What to do Next?
          </h2>
        )}
        {selectedCity === "puri" && surgeryData.surgeryName === "Piles" && (
          <div className="mt-5 mx-8 w-10/12 mb-8">
            CureBay has partnered with the best hospitals in{" "}
            <strong>{selectedCity}</strong>. If you are suffering from piles,
            book your appointment today and let our experienced proctologists
            provide you with the care you deserve.
          </div>
        )}
      </div>
      <section className="bg-primaryColor mb-10">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h4 className="text-White font-semibold text-2xl md:text-3xl mb-10 text-center">
            Frequently Asked Questions
          </h4>
          {uniqueFAQs && uniqueFAQs.length > 0 && (
            <div className="mt-12">
              <FAQ data={uniqueFAQs} />
            </div>
          )}
        </div>
      </section>

      <Modal
        title="Book Appointment"
        visible={openModal}
        width={"50%"}
        okText="Submit"
        centered={true}
        onOk={() => {
          setModal(false);
        }}
        cancelText="Cancel"
        className="commonModal"
        onCancel={handleCancel}
        footer={null}
      >
        <>
          <div className="">
            <div className="flex">
              <div className="w-1/2 flex-col mr-2 hidden">
                <img
                  style={{ height: "30%" }}
                  className="m-auto"
                  src={Surg_2}
                  alt="#"
                />
                <div className="p-2 gap-4 space-y-3">
                  <h1 className="font-bold text-2xl">Our next step</h1>
                  <p>Check how we make your process easy :</p>

                  <div className=" flex p-3 shadow-lg mt-4">
                    <div className="w-1/5">
                      <img src={Surg_3} alt="#" />
                    </div>
                    <div className="w-full">
                      <h1
                        classname=""
                        style={{ color: "#004171", fontWeight: "700" }}
                      >
                        Care Coordinator
                      </h1>
                      <p className="text-sm">
                        Share your details and our care coordinator will get in
                        touch with you.
                      </p>
                    </div>
                  </div>

                  <div className=" flex p-3 shadow-lg">
                    <div className="w-1/5 mt-2">
                      <img src={Surg_4} alt="#" />
                    </div>
                    <div className="w-full">
                      <h1 style={{ color: "#004171", fontWeight: "700" }}>
                        Detailed Evaluation
                      </h1>
                      <p className="text-sm">
                        Top notch doctors at your service who will make a
                        detailed evaluation of your health.
                      </p>
                    </div>
                  </div>

                  <div className=" flex  p-3 shadow-lg">
                    <div className="w-1/5 mt-2">
                      <img src={Surg_5} alt="#" />
                    </div>
                    <div className="w-full ml-2">
                      <h1
                        classname=""
                        style={{ color: "#004171", fontWeight: "700" }}
                      >
                        Schedule
                      </h1>
                      <p className="text-sm">
                        Appointment will be scheduled to the earliest at our
                        partner hospitals..
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <p className="text-base text-textGray mb-5">
                  Fill up the following details and we'll appoint you the best
                  suitable surgeon
                </p>
                <Form
                  // onChange={handleChange}
                  form={form}
                  onFinish={handleSubmit}
                  className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
                >
                  <div className="">
                    <label className="text-primaryColor text-sm mb-2 inline-block">
                      Patient Name <span className="text-danger">*</span>
                    </label>
                    <Form.Item className="mb-0" name="patientName">
                      <Input
                        type="text"
                        placeholder="Name"
                        name="patientName"
                        required={true}
                        className=" h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                        defaultValue={profile.firstName}
                      />
                    </Form.Item>
                    {formerror.patientName && (
                      <span>{formerror.patientName}</span>
                    )}
                  </div>
                  <div className="">
                    <label className="text-primaryColor text-sm mb-2 inline-block">
                      Email ID <span className="text-danger">*</span>
                    </label>
                    <Form.Item className="mb-0" name="patientEmail">
                      <Input
                        type="text"
                        placeholder="Mail ID"
                        // name="mailId"
                        required={true}
                        className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                        defaultValue={profile.email}
                        disabled={Object.keys(userData).length === 0 ? false : true}
                      />
                    </Form.Item>
                    {formerror.patientName && (
                      <span>{formerror.patientName}</span>
                    )}
                  </div>
                  <div>
                    <label className="text-primaryColor text-sm mb-2 inline-block">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <Form.Item className="mb-0" name="mobileNo">
                      <InputNumber
                        disabled={Object.keys(userData).length === 0 ? false : true}
                        onKeyPress={handleKeyPress}
                        name="mobileNo"
                        maxLength={10}
                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        required={true}
                        placeholder="Phone No."
                        className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                        defaultValue={profile.mobile}
                      // onInput={(e) =>
                      //   (e.target.value = e.target.value.slice(0, 10))
                      // }
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <label className="text-primaryColor text-sm mb-2 inline-block">
                      City{" "} <span className="text-danger">*</span>
                    </label>
                    <Form.Item className="mb-0" name="city">
                      <Select
                        autocomplete="off"
                        // id="city"
                        // name="city"
                        onChange={handleChange}
                        options={[
                          {
                            value: "Bhubaneswar",
                            label: "Bhubaneswar",
                          },
                          {
                            value: "Puri",
                            label: "Puri",
                          },
                          {
                            value: "Cuttack",
                            label: "Cuttack",
                          },
                        ]}
                        required={true}
                        optionLabel=""
                        placeholder="Select a City"
                        className="h-[50px] rounded-md w-full text-sm"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <label className="text-primaryColor text-sm mb-2 inline-block">
                      Select Disease <span className="text-danger">*</span>
                    </label>
                    <Form.Item className="mb-0" name="surgeryId">
                      <Select
                        autocomplete="off"
                        id="surgeryId"
                        name="surgeryId"
                        // value={submitData.surgeryId}
                        defaultValue={uniqueID}
                        required={true}
                        options={surgeryObj.map((hc) => ({
                          value: hc.id,
                          label: hc.surgeryName,
                          key: hc.surgeryName,
                        }))}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionLabel=""
                        placeholder="Select Disease"
                        className="h-[50px] rounded-md w-full text-sm"
                      />
                    </Form.Item>
                  </div>
                </Form>
                <div className=" flex justify-center gap-5 mt-8">
                  <button
                    onClick={handleCancel}
                    className=" border border-primaryColor text-center text-primaryColor md:p-2 rounded-lg font-medium mt-4 text-sm md:text-base h-10 md:h-14 w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                    className="bg-primaryColor text-center text-White md:p-2 rounded-lg font-medium mt-4 text-sm md:text-base h-10 md:h-14 w-full"
                  >
                    Book Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </Layouts>
  );
}

export default SurgeryDetails;
