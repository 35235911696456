import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorslist } from "../../../Redux/Action/doctorAction";
import { useParams, useLocation } from "react-router-dom";
import Bottles from "../../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../../Assets/pharamacyImages/Tablets.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import doctorImage from "../../../Assets/Images/doctorImage.svg";
import VideoCall from "../../../Assets/Images/Icons/videocall.svg";
import LocationImages from "../../../Assets/Images/Icons/locationblue.svg";
import NodataFound from "../../../Assets/Images/Icons/nodata.svg";
import { debounce } from "lodash";
import { AddtoCart,getCartDetails } from "../../../Redux/Action/cartPlaceOrderAction";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import moment from "moment";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { Card, Spin, message } from "antd";
import TestTube from "../../../Assets/Images/testTube.svg";
import { gethospitallist } from "../../../Redux/Action/hospitalpageActions";
import bethanyhospital from "../../../Assets/Images/bethanyhospital.svg";
import { EnvironmentOutlined, LoadingOutlined } from "@ant-design/icons";
import SubHeader from "../../Shared/SubHeader";
import NodataImage from "../../../Assets/Images/No data-found.svg";
import Loader from "../../Shared/loader";
import { Helmet } from 'react-helmet';
import { EnglishText } from '../../PageRouting/EnglishText';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AllSearch() {
  const dispatch = useDispatch();
  const history = useHistory();
  const hospitallist = useSelector((state) => state.hospitallist);
  const { hospitallistData } = hospitallist;
  const [isAdding, setIsAdding] = useState(-1);
  const [medicineSearched, setmedicineSearched] = useState([]);
  const userData = useSelector((state) => state.authReducer.patientData);
  const { cartList } = useSelector((state) => state.cartReducer);
  const doctorlist = useSelector((state) => state.doctorlist);
  const { coords } = useSelector((state) => state.authReducer);
  const customPinCode = useSelector((state) => state.authReducer.customPinCode);
  const { doctorData } = doctorlist;
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const Search = params.search;
  const [choosetoCart, setchoosetoCart] = useState([]);
  const [labSearched, setlabSearched] = useState([]);
  const [labNewName, setLabNewName] = useState("");

  {
    /* Doctor Function */
  }
  console.log(customPinCode,coords,"pincodeand cooards")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getDoctorslist( coords,Search ? Search : "", 1, 20,customPinCode?customPinCode:""));
        setLoading(false);
        if (response && response.status === 500) {
          console.error("Request failed with status code 500");
        }
      } catch (error) {
        console.error("Error fetching doctors list:", error);
      }
    };    

    fetchData();
  }, [ Search, dispatch,customPinCode]);

  const handleCardClick = (e, doctor) => {
    e.preventDefault();

    history.push(`/doctors/${doctor.seoNameId}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleImgError = (e) => {
    e.target.src = doctorImage;
  };
  {
    /* Medicine Function */
  }

  const resolvePharamcyImageq = (Image) => {
    let PharamncyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharamncyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharamncyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharamncyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharamncyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharamncyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharamncyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharamncyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharamncyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharamncyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharamncyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharamncyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharamncyImage = Tablet;
    } else {
      PharamncyImage = defaultMed;
    }
    // console.log(PharamncyImage);
    return PharamncyImage;
  };

  const debouncedSearch = debounce(async (Search) => {
    if (Search.length >= 3) {
      setLoading(true);
      // const res = await axios.get(
      //   `https://patient.curebay.com/es/api/v1/medicine?freeTextSearch=${Search}&pageNo=1&pageSize=20`
      // );
      const res = await axios.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${Search}&pageNo=1&pageSize=20`
      );
      setLoading(false);
      setmedicineSearched(res.data);
      // setIsOpen(true);
    }
  }, 500);

  useEffect(() => {
    debouncedSearch(Search);
  }, [Search]);

  const addTocart = (item) => {


    history.push(`/medicine/${item.seoNameId}`, { medicine: item });
    history.push(`/medicine/${item.seoNameId}`, { medicine: item });
  };

  const handleImage = (images, type) => {
    if (typeof images === "string") {
      let result = images.replace(/[[\]]/g, "");
      let medicineImage = result.split(",");
      console.log(medicineImage);
      if (medicineImage.length !== 0) {
        if (medicineImage.length === 1 && medicineImage[0] === "") {
          return resolvePharamcyImageq(type);
        } else {
          return medicineImage[0];
        }
      } else {
        return resolvePharamcyImageq(type);
      }
    } else {
      return resolvePharamcyImageq(type);
    }
  };

  const addTocart2 = (e, data, index, quantity = 1) => {
    if (Object.keys(userData).length === 0) {
      history.push(APP_ROUTES.HOME);
    } else {
      // e.preventDefault();
      setIsAdding(index);

      let prescriptionRequired = "N";
      let ePrescriptionRequired = "N";

      if (data?.prescriptionRequired == "No") {
        prescriptionRequired = "N";
        // ePrescriptionRequired = "N";
      } else if (data?.prescriptionRequired == "Yes") {
        prescriptionRequired = "Y";
        // ePrescriptionRequired = "Y";
      }
      let calculatedAmount = data?.drugsInfoDiscountedRate
        ? data?.drugsInfoDiscountedRate
        : data.medicineRate;
      const totalAmount = calculatedAmount * quantity;
      // data.discountPrice !== 0
      //   ? (data.unitPrice - data.discountPrice) * quantity
      //   :
      let dataObj = {};

      let isAdded = false;

      if (cartList && cartList.patientMedicineOrder) {
        cartList.totalAmount = cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount);
        cartList.patientMedicineOrder.forEach((element) => {
          element.patientMedicineOrderDetailsList.push({
            drugCode: data.id,
            drugName: data.medicineName,
            drugCategory: data.drugCategory,
            unitPrice: data.medicineRate,
            discountAmount: 0.0,
            totalAmount: totalAmount,
            quantity: quantity,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            prescriptionRequired: prescriptionRequired,
            ePrescriptionRequired: ePrescriptionRequired,
            medicineTypeOfSell: data?.medicineTypeOfSell,
            membershipCode: null,
            membershipName: null,
            membershipCard: null,
            membershipDiscountPercentage: 0.0,
          });
        });
        dataObj = cartList;
      } else {
        dataObj = {
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          status: 1,
          labOrdersYN: false,
          drugsOrdersYN: true,
          totalAmount: cartList?.totalAmount
            ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
            : parseFloat(totalAmount),
          patientMedicineOrder: [
            {
              orderId: "",
              patientId: userData.code,
              prescriptionId: null,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              txnId: "",
              totalAmount: totalAmount,
              address1: null,
              address2: null,
              address3: null,
              city: null,
              state: null,
              country: null,
              pincode: null,
              deliveryAddress1: null,
              deliveryAddress2: null,
              deliveryAddress3: null,
              deliveryCity: null,
              deliveryState: null,
              deliveryCountry: null,
              deliveryZipcode: null,
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              status: 1,
              procedureStatus: -1,
              payMode: "E",
              collectionStatus: null,
              paymentLinkForPatient: "N",
              discountCouponCode: null,
              patientName: userData.firstName,
              patientGender: userData?.gender,
              patientMobile: userData?.mobile,
              patientMedicineOrderDetailsList: [
                {
                  drugCode: data.id,
                  drugName: data.medicineName,
                  drugCategory: data.drugCategory,
                  unitPrice: data.medicineRate,
                  discountAmount: 0.0,
                  totalAmount: totalAmount,
                  quantity: quantity,
                  cartId: cartList && cartList.id ? cartList.cartId : "",
                  createdBy: userData.code,
                  createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  modifiedBy: userData.code,
                  modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  orderId: null,
                  patientId: userData.code,
                  prescriptionRequired: prescriptionRequired,
                  ePrescriptionRequired: ePrescriptionRequired,
                  medicineTypeOfSell: data?.medicineTypeOfSell,
                  membershipCode: null,
                  membershipName: null,
                  membershipCard: null,
                  membershipDiscountPercentage: 0.0,
                },
              ],
            },
          ],
        };
      }
      dispatch(AddtoCart(dataObj)).then((res) => {
        setIsAdding(-1);
      });
      dispatch(getCartDetails(userData.code));
    }
  };

  // const addTocart2 = (e, data, index, quantity = 1) => {
  //   if (Object.keys(userData).length === 0) {
  //     history.push(APP_ROUTES.HOME);
  //   } else{
  //     toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
  //   }
  // }

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder) {
        for (const product of cartList?.patientMedicineOrder) {
          if (product && product?.patientMedicineOrderDetailsList) {
            for (const drug of product?.patientMedicineOrderDetailsList) {
              ids.push(drug?.drugCode);
            }
          }
        }
      }
    }
    return ids;
  };
  const drugIDsInCart = getProductsIDs();

  {
    /* Lab Function */
  }

  const handleNavigation = (e, data,labTestCode) => {
    e.preventDefault();
    history.push({ pathname: APP_ROUTES.LABVIEW, state: { labdata:data,labTestCode:labTestCode }, login: false });
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const addLabTocart = (e, data, index,labtestcode, quantity = 1) => {
    if (!userData.code) {
      history.push({
        pathname: APP_ROUTES.HOME,
        // state: { background: location, login: true },
      });
    }
    if(labtestcode){

    if (cartList?.patientLabTestsOrder) {
      let availableItem = cartList?.patientLabTestsOrder.find(
        (item) => item.hospitalId === data.labId
      );

      if (availableItem === undefined) {
        // setDialog(true);
        setLabNewName(
          cartList?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]
            ?.hospitalName
        );
        message.warning(
          "Select this test from the same lab or replace the tests in your cart"
        );
        return;
      }
    }
    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (data?.medicinePrescriptionRequired == "N") {
      prescriptionRequired = "N";
      ePrescriptionRequired = "N";
    } else if (data?.medicinePrescriptionRequired == "Y") {
      prescriptionRequired = "Y";
      ePrescriptionRequired = "Y";
    }
    let calculatedAmount = data?.amount;
    const totalAmount = calculatedAmount * quantity;

    let dataObj = {};
    if (cartList && cartList.patientLabTestsOrder) {
      cartList.totalAmount = cartList?.totalAmount
        ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
        : parseFloat(totalAmount);
      cartList.patientLabTestsOrder.map((res) => {
        res.patientLabTestsOrderDetailsList.push({
          patientLabTestOrderId: data.id,
          labTestCode: data.labTestCode,
          labTestName: data.labTestName,
          hospitalId: data.labId,
          locationId: data.locationId,
          amount: data.amount,
          discountAmount: data?.discountAmount ? data?.discountAmount : 0,
          totalAmount: totalAmount,
          status: 1,
          labTestType: data.testType,

          quantity: quantity,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          prescriptionRequired: prescriptionRequired,
          ePrescriptionRequired: ePrescriptionRequired,
          medicineTypeOfSell: null,
          membershipCode: null,
          membershipName: null,
          membershipCard: null,
          membershipDiscountPercentage: 0.0,
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,

        labOrdersYN: true,
        drugsOrdersYN: false,
        totalAmount: cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount),
        patientLabTestsOrder: [
          {
            hospitalId: data.labId,
            locationId: data.locationId,

            orderId: "",
            patientId: userData.code,
            orderDetailsRequired: "Y",
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            amount: totalAmount,
            address1: data.address1,
            address2: data.address2,
            address3: null,
            city: data.city,
            state: data.state,
            country: null,
            pincode: data.pinCode,
            deliveryAddress1: data.address1,
            deliveryAddress2: data.address2,
            deliveryAddress3: null,
            deliveryCity: data.city,
            deliveryState: data.state,
            deliveryCountry: null,
            deliveryZipcode: data.pinCode,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            patientLabTestsOrderDetailsList: [
              {
                patientLabTestOrderId: data.id,
                labTestCode: data.labTestCode,
                labTestName: data.labTestName,
                hospitalId: data.labId,
                locationId: data.locationId,
                amount: data.amount,
                discountAmount: data?.discountAmount ? data?.discountAmount : 0,
                totalAmount: totalAmount,
                status: 1,
                labTestType: data.testType,
                tat: data?.tat,
                labStartTime: data?.labStartTime,
                labEndTime: data?.labEndTime,
                description: data?.description,

                quantity: quantity,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                orderId: null,
                patientId: userData.code,
                prescriptionRequired: prescriptionRequired,
                ePrescriptionRequired: ePrescriptionRequired,
                medicineTypeOfSell: null,
                membershipCode: null,
                membershipName: null,
                membershipCard: null,
                membershipDiscountPercentage: 0.0,
              },
            ],
          },
        ],
        // };
      };
    }

    dispatch(AddtoCart(dataObj)).then((res) => {
      dispatch(getCartDetails(userData.code));
    });
  }
  
  };

  // const addLabTocart = (e, data, index, quantity = 1) => {
  //   if (!userData.code) {
  //     history.push({
  //       pathname: APP_ROUTES.HOME,
  //       // state: { background: location, login: true },
  //     });
  //   }
  //   else{
  //     toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
  //   }
  // }

  useEffect(() => {
    dispatch(getCartDetails(userData.code));
  }, []);

  const drugIDsInLabCart = getProductsLabIDs();

  function getProductsLabIDs() {
    let ids = [];
    if (cartList) {
      if (cartList.patientLabTestsOrder) {
        for (const product of cartList.patientLabTestsOrder) {
          // if (product && product.patientLabTestsOrderDetailsList) {
          for (const drug of product.patientLabTestsOrderDetailsList) {
            ids.push(drug.labTestCode);
          }
          // }
        }
      }
    }
    return ids;
  }

  const debouncedLabSearch = debounce(async (Search) => {
    const searchText = Search;
    if (searchText) {
      setLoading(true);
      // let res2 = await axios.get(
      //   `https://patient.curebay.com/es/api/v1/labtest?latitude=20.2961&longitude=85.8245&freeTextSearch=${searchText}&source=b2c`
      // );
      let res2 = await axios.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?latitude=${coords.lat}&longitude=${coords.long}&freeTextSearch=${searchText}&source=b2c`
      );
      setLoading(false);
      setlabSearched(res2.data);
    }
  }, 500);

  useEffect(() => {
    debouncedLabSearch(Search);
  }, [Search]);

  {
    /* Hosptial Function */
  }

  const handleViewClick = (e, hosp) => {
    e.preventDefault();
    history.push(
      `/hospital/${hosp.seoNameId}`
      // state: hosp
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleHospitalError = (e) => {
    e.target.src = bethanyhospital;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(gethospitallist(coords, Search, 1, 80));
    setLoading(false);
  }, [sessionStorage.getItem("customPinCode"), Search]);

  return (
    <Layouts>
        <ToastContainer />
      <SubHeader />

      <div>
        <>
          {doctorData.length > 0 && (
            <>
            <Helmet> 
            <title>{'Doctors | Curebay'}</title>
   <meta name='description' content={EnglishText.DOCTOR_META_DESCRIPTION} />
   <meta name='title' content={'Doctors | Curebay'} />
   
   <link rel="canonical" href={window.location.href} /> 
     </Helmet>
            <section className="bg-lightGray">
              <div className="container mx-auto py-[20px] md:py-[50px]">
                <h1 className="text-primaryColor text-center text-xl md:text-3xl font-bold mb-10 !leading-snug">
                  <span className="text-secondaryColor">Available</span> Doctors
                </h1>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6">
                  {doctorData?.map((doctor, index) => (
                    <div className="bg-White p-3 md:p-5 rounded-lg flex flex-col justify-between">
                      <div className="flex gap-4">
                        <div className="w-[75px] h-[75px]">
                          <img
                            src={
                              doctor.userPhoto
                                ? `${process.env.REACT_APP_IMG_BASEURL}${doctor.userPhoto}`
                                : doctorImage
                            }
                            alt={doctor.userName}
                            className="rounded-full bg-lightBlue text-xs  w-[65px] h-[65px] object-contain"
                            onError={handleImgError}
                          />
                        </div>
                        <div className="w-full">
                          <div className="flex justify-between w-full items-baseline mb-2">
                            <div className="w-[75%]">
                              <h6 className="text-primaryColor uppercase text-base font-semibold">
                                {doctor.userName}
                              </h6>
                              <p className="text-textGray text-xs font-medium mb-1.5 flex">
                                {doctor.specialities}
                              </p>
                              <p className="text-textGray text-xs font-medium mb-1.5 flex">
                                {doctor.yearOfExperience} years of exp.
                              </p>
                            </div>
                            <div className="w-[20%] flex justify-end">
                              <img src={VideoCall} alt="images" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between items-center">
                        <p
                          style={{ width: "max-content" }}
                          className="text-textGray text-xs font-medium mb-1.5 flex gap-2 items-center"
                        >
                          <img
                            src={LocationImages}
                            alt="images"
                            className="w-3"
                          />
                          {doctor.city}
                        </p>
                        <p className="text-secondaryColor text-sm cursor-pointer font-medium">
                          ₹ {doctor.videoConsultCharges}
                        </p>
                      </div>
                      <div className="flex gap-3 mt-5">
                        <button
                          onClick={(e) => handleCardClick(e, doctor)}
                          className="hover:text-secondaryColor text-primaryColor text-xs md:text-sm border border-primaryColor hover:border-secondaryColor w-full py-2 rounded-lg "
                        >
                          View Profile
                        </button>
                        <button
                          className="hover:bg-secondaryColor bg-primaryColor rounded-lg text-White text-xs md:text-sm w-full py-2 "
                          onClick={(e) => handleCardClick(e, doctor)}
                        >
                          Book Consultation
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="text-secondaryColor text-lg text-center underline mt-10 font-medium cursor-pointer">
              View More
            </div> */}
              </div>
            </section>
            </>
          )}
        </>

        <>
          {medicineSearched.length > 0 && (
            <section className="bg-White">
              <div className="container mx-auto py-[20px] md:py-[50px]">
                <h1 className="text-primaryColor text-center text-xl md:text-3xl font-bold mb-10 !leading-snug">
                  <span className="text-secondaryColor">Medicine</span> List
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                  {medicineSearched.length !== 0 ? (
                    medicineSearched.map((item, idx) => (
                      <div key={idx} className="bg-lightGray rounded-lg p-5 ">
                        <div className="bg-White h-[150px] flex justify-center items-center rounded-lg cursor-pointer">
                          <img
                            // src={resolvePharamcyImageq(item.medicineTypeOfSell)}
                            src={handleImage(
                              item.medicineImages,
                              item.medicineTypeOfSell
                            )}
                            alt="images"
                            className="w-[90px] h-[90px] rounded-lg object-fill"
                          />

                          {/* <img
                          src={item?.medicineImages &&JSON.parse(item.medicineImages)?.length > 0 ? JSON.parse(item.medicineImages[0]) : resolvePharamcyImageq(item.medicineTypeOfSell)}
                          alt="images"
                          className="w-[90px] h-[90px] rounded-lg object-fill"
                        /> */}
                        </div>
                        <div className="pt-3">
                          <div
                            onClick={() => addTocart(item)}
                            className=" cursor-pointer"
                          >
                            <h5 className="text-primaryColor text-lg mb-2 font-medium text-ellipsis overflow-hidden text-nowrap">
                              {item.medicineName}
                            </h5>
                            <div className="text-secondaryColor text-lg font-semibold flex items-baseline">
                              <div className="flex w-fit">
                                {" "}
                                ₹{item.medicineRate}
                              </div>
                              <div className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                                {item.medicineTypeOfSell}
                              </div>
                            </div>
                          </div>
                          <button
                            className={`${
                              !drugIDsInCart.includes(item.id)
                                ? "bg-primaryColor text-White"
                                : "bg-White text-secondaryColor border border-secondaryColor hover:bg-secondaryColor hover:text-White"
                            }  rounded-lg py-2 text-center w-full mt-5`}
                            onClick={(e) => addTocart2(e, item, idx)}
                            disabled={
                              isAdding !== -1 ||
                              drugIDsInCart.indexOf(item.id) !== -1
                            }
                          >
                            {!drugIDsInCart.includes(item.id)
                              ? "Add to Cart"
                              : "Added"}
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center gap-3">
                      <img
                        src={NodataFound}
                        alt="no data found"
                        className="w-[50px] h-[40px]"
                      />
                      <p className="text-textGray text-center">
                        No medicine found
                      </p>
                    </div>
                  )}
                </div>
                {/* <div className="text-secondaryColor text-lg text-center underline mt-10 font-medium cursor-pointer">
              View More
            </div> */}
              </div>
            </section>
          )}
        </>

        <>
          {labSearched.length > 0 && (
            <section className="md:bg-lightGray">
              <div className="container mx-auto py-[20px] md:py-[50px]">
                <h1 className="text-primaryColor text-center text-xl md:text-3xl font-bold mb-10 !leading-snug">
                  <span className="text-secondaryColor">Available</span> Lab
                  Test(s)
                </h1>
                {labSearched
                  ? labSearched.map((item, index) => (
                      <div className="mb-5 w-full" key={index}>
                        <Card>
                          <div className="hidden md:flex items-center gap-5">
                            <div className="bg-lightBlue w-[5rem] h-[5rem] rounded-full p-5">
                              <img
                                src={TestTube}
                                alt="test"
                                className="h-full w-full"
                              />
                            </div>
                            <div className="flex justify-between w-full gap-5">
                              <div className="ml-5 w-[75%]">
                                <h6 className="text-lg font-semibold text-primaryColor mb-1">
                                  {item.labTestName}
                                </h6>
                                <p className="text-textGray text-base font-light">
                                  <span className="font-medium">
                                    Test Display Name:
                                  </span>{" "}
                                  {item.testDisplayName}
                                </p>
                              </div>
                              <div className="w-[25%] flex justify-between items-center gap-3">
                                <p className="font-bold text-xl text-secondaryColor ">
                                  ₹ {item.amount}
                                </p>
                                <button
                                  className={`${
                                    drugIDsInLabCart.indexOf(
                                      item.labTestCode
                                    ) === -1
                                      ? "bg-primaryColor text-White"
                                      : "text-secondaryColor bg-White border border-secondaryColor"
                                  }  w-[150px] h-[45px]  text-sm font-medium rounded cursor-pointer `}
                                  onClick={(e) => addLabTocart(e, item, index,drugIDsInLabCart.indexOf(
                                    item.labTestCode
                                  ) === -1)}
                                >
                                  {drugIDsInLabCart.indexOf(
                                    item.labTestCode
                                  ) === -1
                                    ? "Add to Cart"
                                    : "ADDED"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={(e) => handleNavigation(e,item,drugIDsInLabCart.indexOf(
                              item.labTestCode
                            ) === -1)}
                            className="text-secondaryColor underline text-sm cursor-pointer text-right"
                          >
                            View More Details
                          </div>
                          <div className="block md:hidden">
                            <div className="md:flex items-center mb-3 gap-2">
                              <div className="bg-lightBlue w-[3rem] md:w-[2rem] h-[3rem] md:h-[2rem] rounded-full p-2 mb-3 md:mb-0">
                                <img
                                  src={TestTube}
                                  alt="test"
                                  className="h-full w-full"
                                />
                              </div>
                              <div className="md:ml-2">
                                <h6 className="text-sm font-semibold text-primaryColor mb-1">
                                  {item.labTestName}
                                </h6>
                              </div>
                            </div>
                            <p className="text-textGray text-xs font-light">
                              <span className="font-medium">
                                Test Display Name:
                              </span>{" "}
                              {item.testDisplayName}
                            </p>
                          </div>
                          <hr className="border-lightBlue border-[1px] my-3" />
                          <div className="md:flex justify-between gap-5">
                            <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-13">
                              <div className="font-medium flex gap-1">
                                Test Type <div>:</div>
                              </div>{" "}
                              {item.testType}
                            </p>
                            <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                              <div className="font-medium flex">Lab Name :</div>{" "}
                              {item.labName}
                            </p>
                            <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                              <div className="font-medium flex gap-1">
                                City <div>:</div>
                              </div>{" "}
                              {item.city}
                            </p>
                            <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3 w-[40%]">
                              <div className="font-medium flex gap-1">
                                Address <div>:</div>
                              </div>{" "}
                              {item.address1}
                            </p>
                          </div>
                          <div className="flex justify-between items-center mt-5 md:hidden">
                            <p className="font-bold text-lg text-secondaryColor ">
                              ₹ {item.amount}
                            </p>

                            <button
                              className={`${
                                drugIDsInLabCart.indexOf(item.labTestCode) ===
                                -1
                                  ? "bg-primaryColor text-White"
                                  : "text-secondaryColor bg-White border border-secondaryColor"
                              }  w-[120px] h-[30px]  text-sm font-medium rounded cursor-pointer `}
                              onClick={(e) => addLabTocart(e, item, index)}
                            >
                              {drugIDsInLabCart.indexOf(item.labTestCode) === -1
                                ? "Add to Cart"
                                : "ADDED"}
                            </button>
                          </div>
                        </Card>
                      </div>
                    ))
                  : choosetoCart.map((item, index) => (
                      <div className="mb-5 w-full">
                        <Card>
                          <div className="hidden md:flex items-center gap-5">
                            <div className="bg-lightBlue w-[5rem] h-[5rem] rounded-full p-5">
                              <img
                                src={TestTube}
                                alt="test"
                                className="h-full w-full"
                              />
                            </div>
                            <div className="flex justify-between w-full">
                              <div className="ml-5">
                                <h6 className="text-lg font-semibold text-primaryColor mb-1">
                                  {item.labTestName}
                                </h6>
                                <p className="text-textGray text-base font-light">
                                  <span className="font-medium">
                                    Test Display Name:
                                  </span>{" "}
                                  {item.testDisplayName}
                                </p>
                              </div>
                              <p className="font-bold text-xl text-secondaryColor ">
                                ₹ {item.amount}
                              </p>
                              <button
                                className=" text-White w-[150px] h-[45px]  text-sm font-medium rounded cursor-pointer bg-primaryColor"
                                onClick={(e) => addLabTocart(e, item, index)}
                              >
                                Add to Cart
                              </button>
                            </div>
                          </div>
                          <div className="block md:hidden">
                            <div className="flex items-center mb-3 gap-2">
                              <div className="bg-lightBlue w-[2rem] h-[2rem] rounded-full p-2">
                                <img
                                  src={TestTube}
                                  alt="test"
                                  className="h-full w-full"
                                />
                              </div>
                              <div className="ml-2">
                                <h6 className="text-sm font-semibold text-primaryColor mb-1">
                                  {item.labTestName}
                                </h6>
                              </div>
                            </div>
                            <p className="text-textGray text-xs font-light">
                              <span className="font-medium">
                                Test Display Name:
                              </span>{" "}
                              {item.testDisplayName}
                            </p>
                          </div>
                          <hr className="border-lightBlue border-[1px] my-3" />
                          <div className="md:flex justify-between gap-5">
                            <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-13">
                              <span className="font-medium">Test Type :</span>{" "}
                              {item.testType}
                            </p>
                            <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                              <span className="font-medium">Lab Name :</span>{" "}
                              {item.labName}
                            </p>
                            <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                              <span className="font-medium">City :</span>{" "}
                              {item.city}
                            </p>
                            <p className="text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3 w-[40%]">
                              <span className="font-medium">Address :</span>{" "}
                              {item.address1}
                            </p>
                          </div>
                          <div className="flex justify-between items-center mt-5 md:hidden">
                            <p className="font-bold text-lg text-secondaryColor ">
                              ₹ {item.amount}
                            </p>
                            <button
                              className=" text-White w-[120px] h-[30px]  text-sm font-medium rounded cursor-pointer bg-primaryColor"
                              onClick={(e) => addLabTocart(e, item, index)}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </Card>
                      </div>
                    ))}
              </div>
            </section>
          )}
        </>

        <>
          {hospitallistData.length > 0 && (
            <section className="md:bg-lightGray">
              <div className="container mx-auto py-[20px] md:py-[50px]">
                <h1 className="text-primaryColor text-center text-xl md:text-3xl font-bold mb-10 !leading-snug">
                  <span className="text-secondaryColor">Available</span>{" "}
                  Hospitals
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                  {hospitallistData.map((hosp, i) => (
                    <div
                      key={i}
                      className="bg-White rounded-xl p-2 common-shadow mb-2 h-full"
                    >
                      <div className="mb-2">
                        {/* Replace `choose1` with an image of a hospital */}
                        <img
                          src={
                            hosp.hospitalPhoto
                              ? `${process.env.REACT_APP_IMG_BASEURL}${hosp.hospitalPhoto}`
                              : bethanyhospital
                          }
                          alt={hosp?.hospitalName}
                          className="mt-2 flex mx-auto"
                          style={{ width: "200px", height: "150px" }}
                          onError={handleHospitalError}
                        />
                      </div>
                      <h5 className="text-primaryColor text-center w-full truncate overflow-hidden whitespace-nowrap font-semibold mb-1">
                        {hosp.hospitalName}
                      </h5>
                      <div className="flex justify-center items-center my-4">
                        <EnvironmentOutlined
                          className="text-secondaryColor"
                          style={{ marginRight: "5px" }}
                        />
                        <span>{hosp.city ? hosp.city : "NA"}</span>
                      </div>
                      <button
                        onClick={(e) => handleViewClick(e, hosp)}
                        className="w-full bg-primaryColor text-White px-2 py-2 rounded-md mt-1 hover:bg-secondaryColor"
                      >
                        View Details
                      </button>
                    </div>
                  ))}
                </div>
                {/* <div className="text-secondaryColor text-lg text-center underline mt-10 font-medium cursor-pointer">
              View More
            </div> */}
              </div>
            </section>
          )}
        </>

        {loading && (
         <Loader />
         )}

    {!loading && ( 
      <>
        {(doctorData.length === 0 &&
          medicineSearched.length === 0 &&
          labSearched.length === 0 &&
          hospitallistData.length === 0) && (
            <div className="w-full h-[400px] flex gap-5 flex-col justify-center my-auto items-center text-center ">
              <div>
                <img src={NodataImage} alt="emptyData" className="w-[60px]" />
              </div>
              <p className="text-center font-light item-center text-base text-textGray">
                No Search Result Found
              </p>
            </div>
          )}
      </>
    )}
        
      </div>
    </Layouts>
  );
}

export default AllSearch;
