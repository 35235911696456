import React, {useState} from 'react'
import http from "../../../../Redux/Services/http-common";
import { useDispatch } from "react-redux";
import {
    setLatLong
  } from "../../../../Redux/Action/userActions";
import { getAuthTokenForMMI, getLatAndLongFromEloc,getLatLongFromAddress } from './../cartApi/mmiapis';
const useCurrentLocation = () => {
  const dispatch = useDispatch();
  const [currentLocationError, setError] = useState("");
  const [currentLocationloading, setLoading] = useState(false);
  const [coords, setCoords] = useState({});
  let auth;
  async function getLocation() {
    setError("");
    setLoading(true);
     let result = await navigator.permissions.query({ name: "geolocation" })
     console.log(result.state, "sdvioshdiovhsdiohvdsiohvisdhviodsd")
      if (result.state === "granted") {
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(showPosition);
        } else {
        }
      } else if (result.state === "prompt") {
      } else if (result.state === "denied") {
        setError("Please allow browser to detect location");
        setLoading(false);
      }
  }

  const showPosition = (position) => {
    const { coords } = position;
    var coordsObj = {
      lat: coords.latitude,
      long: coords.longitude,
    };
    setCoords(coordsObj);
    setLoading(false);
    dispatch(setLatLong(coordsObj));
  }

  function convertLatLongToAddress(){
    var apiUrl = "MMI/accesstoken";
    http.get(apiUrl).then((response) => {
      let data = response.data;
      http
        .get(
          `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
        )
        .then((res) => {
          if (!res.data.results[0].pincode) {
            setError("Not able to detect your location.");
            setLoading(false);
          } else {
            setError("");
            setLoading(false);
          }
        })
        .catch((err) => {
          setError("Error occured in MMI");
          setLoading(false);
        });
    });
  }

  async function getAuthToken(){
    auth = await getAuthTokenForMMI()
  }

  async function getcoordsFromAddress(address){
            await getAuthToken()
            let eloc = await getLatLongFromAddress(address, auth.data.access_token)
            console.log(eloc, "sdvionsdvinsdivdsivishvids")
            eLocToLatLong(eloc.data.copResults.eLoc)
  }

  async function eLocToLatLong(eloc){ 
    await getAuthToken();
    let coords = await getLatAndLongFromEloc(eloc, auth.data.access_token)
    setCoords({
      lat: coords.data.latitude,
      long: coords.data.longitude,
    });
  }

  return [currentLocationError, currentLocationloading, coords,eLocToLatLong, getLocation, getcoordsFromAddress];
};

export default useCurrentLocation;
