import { useState, useRef, createRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import "./medicine.scss";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";

import { useEffect } from "react";
import http from "../../../Redux/Services/http-common";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Bottles from "../../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../../Assets/pharamacyImages/Satchels.png";
import Tablets from "../../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../../Assets/Images/Cart/Medicines.jpg";
import moment from "moment";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import SectionContainer from "./SectionContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var positionScroll = 6;
var medicineScroll = 6;
var isFirstTimeLeft = true;

function PrevBrowsedMedicine() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [previouslyBrowsedMedicine, setPreviouslyBrowsedMedicine] = useState([])
  const [recentlyOrderMedicine, setRecentlyOrderMedicine] = useState([])
  const [isAdding, setIsAdding] = useState(-1);

  const addThreeDots = (text) => {
    if (text.length > 20) {
      return `${text.substring(0, 20)}...`;
    }

    return text;
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
      paritialVisibilityGutter: 80,
    },

    tablet: {
      breakpoint: { max: 1024, min: 464 },

      items: 2,

      slidesToSlide: 2, // optional, default to 1.
    },

    mobile: {
      breakpoint: { max: 464, min: 0 },

      items: 1,

      slidesToSlide: 1, // optional, default to 1.
    },
  };
  async function getMedicineData(medicineCode, type) {
    if (medicineCode?.length) {
      var raw = JSON.stringify(medicineCode);

      dispatch(getAllProductByIds(raw))
        .then((res) => {
          if (type == "recentMedicine") {
            setPreviouslyBrowsedMedicine(res);
            console.log(res, "Recent");
          } else if (type == "orderMedicines") {
            setRecentlyOrderMedicine(res);
          }
        })
        .catch((err) => {});
    } else {
    }
  }

  const redirectTo = (data) => {
    if (userData?.id) {
      history.push({ pathname: APP_ROUTES.UPLOADED_PRESCRIPTION, state: data });
      
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };

  const resolvePharamcyImageq = (Image) => {
    let PharamncyImage;
    if (!Image) {
      return "";
    }
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharamncyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharamncyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharamncyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharamncyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharamncyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharamncyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharamncyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharamncyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharamncyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharamncyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharamncyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharamncyImage = Tablets;
    } else {
      PharamncyImage = defaultMed;
    }
    return PharamncyImage;
  };

  // useEffect(() => {
  //   // http.get(`${process.env.REACT_APP_BASEURL}PatientRecent/Search?patientId=${userData?.code}&category=1`)
  //     .then(res => {
  //       let drugCode = res.data.data.map(item => item.itemCode)
  //       getMedicineData(drugCode, "recentMedicine")
  //       // setPreviouslyBrowsedMedicine(res.data.data)
  //     })
  // }, [userData])

  useEffect(() => {
    http.get(`${process.env.REACT_APP_BASEURL}Drug/commonDrug`).then((res) => {
      getMedicineData(res.data.data, "orderMedicines");
    });
  }, [userData]);

  // const handleUploadPrescriptionClick = () =>{
  //     setIsSidebarOpen(!isSidebarOpen)
  // }
  // const handleEPrescriptionClick = () =>{
  //     if (userData?.id) {
  //         // (e) => redirectTo(e, APP_ROUTES.UPLOADED_PRESCRIPTION)
  //         history.push({ pathname: APP_ROUTES.EPRESCRIPTION });
  //     }
  //     else {
  //         history.push({ pathname: APP_ROUTES.LOGIN, state: { background: location, login: true } });
  //     }
  // }

  // const handleSavedPrescriptionClick = () =>{
  //     if (userData?.id) {
  //         // (e) => redirectTo(e, APP_ROUTES.UPLOADED_PRESCRIPTION)
  //         history.push({ pathname: APP_ROUTES.SAVEDPRESCRIPTION });
  //     }
  //     else {
  //         history.push({ pathname: APP_ROUTES.LOGIN, state: { background: location, login: true } });
  //     }
  // }

  const addtoCart = (e, data, index, quantity = 1) => {
    if (!userData.code) {
      redirectTo(e, {
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
    e.preventDefault();
    setIsAdding(index);

    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (data?.prescriptionRequired == "No") {
      prescriptionRequired = "N";
      // ePrescriptionRequired = "N";
    } else if (data?.prescriptionRequired == "Yes") {
      prescriptionRequired = "Y";
      // ePrescriptionRequired = "Y";
    }
    let calculatedAmount = data?.drugsInfoDiscountedRate
      ? data?.drugsInfoDiscountedRate
      : data.medicineRate;
    const totalAmount = calculatedAmount * quantity;
    // data.discountPrice !== 0
    //   ? (data.unitPrice - data.discountPrice) * quantity
    //   :
    let dataObj = {};

    let isAdded = false;

    if (cartList && cartList.patientMedicineOrder) {
      cartList.patientMedicineOrder.forEach((element) => {
        element.patientMedicineOrderDetailsList.push({
          drugCode: data.id,
          drugName: data.medicineName,
          drugCategory: data.drugCategory,
          unitPrice: data.medicineRate,
          discountAmount: 0.00,
          totalAmount: totalAmount,
          quantity: quantity,
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          prescriptionRequired: prescriptionRequired,
          ePrescriptionRequired: ePrescriptionRequired,
          medicineTypeOfSell: data?.medicineTypeOfSell,
          membershipCode: null,
          membershipName: null,
          membershipCard: null,
          membershipDiscountPercentage: 0.00,
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,
        labOrdersYN: false,
        drugsOrdersYN: true,
        totalAmount: totalAmount,
        patientMedicineOrder: [
          {
            orderId: "",
            patientId: userData.code,
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            totalAmount: totalAmount,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            state: null,
            country: null,
            pincode: null,
            deliveryAddress1: null,
            deliveryAddress2: null,
            deliveryAddress3: null,
            deliveryCity: null,
            deliveryState: null,
            deliveryCountry: null,
            deliveryZipcode: null,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            patientMedicineOrderDetailsList: [
              {
                drugCode: data.id,
                drugName: data.medicineName,
                drugCategory: data.drugCategory,
                unitPrice: data.medicineRate,
                discountAmount: 0.00,
                totalAmount: totalAmount,
                quantity: quantity,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                orderId: null,
                patientId: userData.code,
                prescriptionRequired: prescriptionRequired,
                ePrescriptionRequired: ePrescriptionRequired,
                medicineTypeOfSell: data?.medicineTypeOfSell,
                membershipCode: null,
                membershipName: null,
                membershipCard: null,
                membershipDiscountPercentage: 0.00,
              },
            ],
          },
        ],
      };
    }
    dispatch(AddtoCart(dataObj)).then((res) => {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          value: data.medicineRate,
          items: [data],
        },
      });
      setIsAdding(-1);
    });
  };

  // const addtoCart = (e, data, index, quantity = 1) => {
  //   if (!userData.code) {
  //     redirectTo(e, {
  //       pathname: APP_ROUTES.LOGIN,
  //       state: { background: location, login: true },
  //     });
  //   }
  //   else{
  //     toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
  //   }
  // }

  function getProductsIDs() {
    let ids = [];
    if (cartList) {
      if (cartList.patientMedicineOrder) {
        for (const product of cartList.patientMedicineOrder) {
          if (product && product.patientMedicineOrderDetailsList) {
            for (const drug of product.patientMedicineOrderDetailsList) {
              ids.push(drug.drugCode);
            }
          }
        }
      }
    }
    return ids;
  }

  const drugIDsInCart = getProductsIDs();

  const redirectToMedicine = (event, product) => {
    event.preventDefault();
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [product],
      },
    });
    history.push({
      pathname: `/medicine/${product.seoNameId}`,
    });
  };

  const medicineScrollable = (e, dir) => {
    e.preventDefault();

    if (dir == "rightArrow") {
      if (medicineScroll == 0) {
        medicineScroll = 6;
      }
      medicineScroll = medicineScroll + 1;
    } else {
      if ((isFirstTimeLeft = true)) {
        medicineScroll = medicineScroll - 5;
        isFirstTimeLeft = false;
      } else {
        medicineScroll = medicineScroll - 1;
      }
    }

    if (medicineScroll > previouslyBrowsedMedicine.length) {
      medicineScroll = previouslyBrowsedMedicine.length - 1;
    }

    if (medicineScroll < 0) {
      medicineScroll = 0;
    }

    scrollRefspreviouslyBrowsed?.current[
      medicineScroll
    ]?.current?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  const scrollRefspreviouslyBrowsed = useRef([]);
  scrollRefspreviouslyBrowsed.current = [
    ...Array(previouslyBrowsedMedicine.length).keys(),
  ].map((_, i) => scrollRefspreviouslyBrowsed?.current[i] ?? createRef());

  return (
    <>
    <ToastContainer/>
    <div className="">
    
      {previouslyBrowsedMedicine.lenth ? (
        <div className="">
          <h2 className="font-bold text-lg lg:text-xl text-center sm:text-center md:text-center lg:text-center ">
            Previously Browsed Medicines{" "}
          </h2>
        </div>
      ) : null}
      {previouslyBrowsedMedicine.length ? (
        <div className="">
          <SectionContainer
            data={previouslyBrowsedMedicine}
            onlyArrow={true}
            link={""}
            seeAll={"hide"}
            handelSroll={medicineScrollable}
          />
          <div className="w-full lg:max-w-full lg:flex ">
            <div className="flex overflow-x-scroll  hide-scroll-bar">
              <div
                id="hostipalSlider"
                className="hostipalSlider flex flex-nowrap space-x-4 sm:space-x-12 mb-4"
              >
                {previouslyBrowsedMedicine.length &&
                  previouslyBrowsedMedicine.map((product, index) => {
                    return (
                      <div
                        className="hos_slide_list translate-x-0  leading-normal flex-none bg-cover overflow-hidden shadow-lg  ml-4"
                        key={index}
                        ref={scrollRefspreviouslyBrowsed.current[index]}
                      >
                        <div
                          className="cursor-pointer "
                          onClick={(e) => redirectToMedicine(e, product)}
                          key={index}
                          style={{ maxHeight: "350px" }}
                        >
                          <div className="Prescriptiondiv7 px-4 pt-2 pb-3 flex flex-col relative w-52 sm:h-72 h-72">
                            <div className="flex justify-center mb-2 h-32">
                              {product?.medicineImages?.length ? (
                                <img
                                  src={product?.medicineImages[0]}
                                  className="cursor-pointer sm:m-auto left-2 h-32 w-32"
                                />
                              ) : (
                                <img
                                  src={resolvePharamcyImageq(
                                    product.medicineTypeOfSell
                                  )}
                                  className="cursor-pointer sm:m-auto left-2 h-20 w-16 mt-5 mb-7 sm:mt-4 sm:mb-0"
                                />
                              )}
                              {/* <img  src= {Bottles}  className="cursor-pointer sm:m-auto left-2 h-36 w-36"/> */}
                            </div>
                            <div className="">
                              <p className="medicinename font-semibold mb-2 text_color">
                                {" "}
                                {addThreeDots(product.medicineName)}
                              </p>
                              <p className="text-xs font-normal  Prescriptiondiv8 mb-2">
                                {" "}
                                {addThreeDots(product.medicineTypeOfSell)}
                              </p>
                              <p className="text-base sm:text-base font-medium pb-2 text_color">
                                ₹ {product.medicineRate}
                              </p>
                            </div>
                            <div>
                              <button
                                onClick={(e) => {
                                  addtoCart(e, product, index);
                                }}
                                disabled={
                                  isAdding !== -1 ||
                                  drugIDsInCart.indexOf(product.id) !== -1
                                }
                                //   style ={{
                                //     bottom: "20px",left:"10%"
                                // }}
                                className={`${
                                  drugIDsInCart.indexOf(product.id) !== -1
                                    ? "absolute text-red-500 font-medium text-xs cursor-not-allowed  border-2 border-gray pb-1 rounded-lg px-16 h-9 pt-1 added_btn"
                                    : "hover:scale-150 cursor-pointer  text-base font-semibold mb-3 rounded-sm px-2 h-8 w-full mt-4 sm:mt-auto cartbtn"
                                }`}
                              >
                                {drugIDsInCart.indexOf(
                                  product.id.toString()
                                ) === -1
                                  ? "Add to Cart"
                                  : "Added"}{" "}
                                {index === isAdding && (
                                  <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-300 h-1 w-5"></div>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
    </>
  );
}

export default PrevBrowsedMedicine;
