import React from "react";
import avatar from "../../../Assets/Images/Cart/avatar.png";
import moment from "moment";
import { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";

function LabCart(props) {
  const [selectedPreferredDate, setSelectedPreferredDate] = useState();
  const [selectedPreferredLabDate, setSelectedPreferredLabDate] = useState();

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [sampleStartTime, setSampleStartTime] = useState("");
  const [sampleEndTime, setSampleEndTime] = useState("");

  useEffect(() => {
    const startTime = props.labDetails.labStartTime
      ? new Date(
          0,
          0,
          0,
          props.labDetails.labStartTime.split(":")[0],
          props.labDetails.labStartTime.split(":")[1]
        )
      : "";
    const endTime = props.labDetails.labEndTime
      ? new Date(
          0,
          0,
          0,
          props.labDetails.labEndTime.split(":")[0],
          props.labDetails.labEndTime.split(":")[1]
        )
      : "";
    const sampleStartTime = props.labDetails.SCStartTime
      ? new Date(
          0,
          0,
          0,
          props.labDetails.SCStartTime.split(":")[0],
          props.labDetails.SCStartTime.split(":")[1]
        )
      : "";
    const sampleEndTime = props.labDetails.SCEndTime
      ? new Date(
          0,
          0,
          0,
          props.labDetails.SCEndTime.split(":")[0],
          props.labDetails.SCEndTime.split(":")[1]
        )
      : "";
    setStartTime(startTime);
    setEndTime(endTime);
    setSampleStartTime(sampleStartTime);
    setSampleEndTime(sampleEndTime);
  }, []);
  console.log(JSON.stringify(props.data), "uifivvuguojblj");

  console.log(props.labDetails.hospitalName, "iygiuhio");
  return (
    <>
      <div className="bg-white ">
        {!props.data.length == 0 && (
          <>
            <div className="flex hidden">
              <div>
                <img
                  src={
                    props.labDetails.hospitalPhoto
                      ? `${process.env.REACT_APP_IMG_BASEURL}${props.labDetails.hospitalPhoto}`
                      : avatar
                  }
                  onError={(e) => (e.target.src = avatar)}
                  alt="avatar"
                  className="w-12 h-12 mt-5"
                />
              </div>
              <div className="p-5">
                <p className="lg:text-sm text-md  text-gray-primary font-medium">
                  {props.labDetails.hospitalName}
                </p>
                <p className="lg:block hidden text-xs  text-gray-primary">
                  {props?.patientLabTestsOrder[0]?.locationCity}
                </p>
              </div>
            </div>
          </>
        )}
        {props.data.map((res, i) => (
          <div>
            {res.status == 1 && (
              <div class=" bg-lightBlue items-center md:flex gap-5 mb-3 rounded-md p-4 lg:w-full">
                <div class="">
                  <div className="h-20 w-20 flex-none bg-White text-center overflow-hidden">
                    <img
                      src={
                        props.labDetails.hospitalPhoto
                          ? `${process.env.REACT_APP_IMG_BASEURL}${props.labDetails.hospitalPhoto}`
                          : avatar
                      }
                      onError={(e) => (e.target.src = avatar)}
                      alt="avatar"
                      className="w-full h-full"
                    />
                  </div>
                </div>
                <div className="w-full bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                  <div className="md:flex justify-between">
                    <div className="md:w-[88%]">
                      <div>
                        <p className="text-base text-primaryColor flex items-center font-medium">
                          {res.labTestName ? res.labTestName : res.labTestName}
                        </p>

                        <p className="text-sm text-textGray flex items-center font-medium mt-2">
                          {res.hospitalName}
                        </p>
                      </div>
                    </div>

                    <div className="md:w-[12%] flex flex-col justify-between">
                      {!props?.readOnly ? (
                        <div className="flex mt-1 justify-end ">
                          {/* <img src={heart} alt="heart" className=" w-4 mr-2 cursor-pointer" /> */}
                          {/* <DeleteIcon
                        style={{ color: "#D22B2B" }}
                        className=" cursor-pointer"
                        onClick={(e) => {
                          props.deleteTest(e, res, { testId: res.id });
                        }}
                      /> */}
                          <DeleteOutlined
                            onClick={(e) => {
                              props.deleteTest(e, res, { testId: res.id });
                            }}
                            className="text-danger text-lg cursor-pointer hidden md:block"
                          />
                        </div>
                      ) : null}
                      <div className="flex md:block justify-between items-baseline mt-2">
                        <p className="text-primaryColor font-semibold text-base text-right">
                          ₹{" "}
                          {parseFloat(res.discountAmount)
                            ? parseFloat(res.discountAmount).toFixed(2)
                            : res.amount}
                        </p>
                        {parseFloat(res.discountAmount) &&
                        +res.discountAmount !== +res.amount ? (
                          <p className="text-xs line-through text-textGray  pl-2">
                            {" "}
                            ₹ {res?.amount}
                          </p>
                        ) : null}
                        <DeleteOutlined
                            onClick={(e) => {
                              props.deleteTest(e, res, { testId: res.id });
                            }}
                            className="text-danger text-lg cursor-pointer block md:hidden"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
export default LabCart;
