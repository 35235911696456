// import { XIcon } from "@heroicons/react/outline";
// import moment from "moment";
// import React from "react";
// import { Bar } from "react-chartjs-2";

// function VitalChart(props) {
//   const colors = [
//     "rgb(54, 162, 235)",
//     "rgb(75, 192, 192)",
//     "rgb(255, 99, 132)",
//   ];
//   let dataset = [];
//   if (props.type) {
//     dataset.push({
//       label: props.type.toString(),
//       data: props.data?.map((d) => d[props.type] ?? 0),
//       backgroundColor: colors[0],
//     });
//   } else if (props.types) {
//     dataset = props.types?.map((type, i) => {
//       return {
//         label: type.toString(),
//         data: props.data?.map((d) => d[type] ?? 0),
//         backgroundColor: colors[i % colors.length],
//       };
//     });
//   }
//   const barData = {
//     labels: props.data?.map((d) =>
//       moment(d.givenDate, "yyyy-MM-DD HH:mm:ss").format("MMM Do YY")
//     ),
//     datasets: dataset,
//   };
//   return (
//     <>
//       <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//         <div className="relative w-11/12 md:w-1/3 my-6 mx-auto max-w-3xl">
//           <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-White outline-none focus:outline-none">
//             <div class="rounded-lg shadow-lg bg-White w-full h-112 p-5 antialiased justify-between border border-gray-200">
//               <div className="flex w-auto justify-between mb-4">
//                 <p className="text-medium font-medium text-2xl    ">
//                   {props.title}
//                 </p>
//                 <div className="w-8 h-8 cursor-pointer">
//                   <XIcon onClick={props.closePopup} />
//                 </div>
//               </div>
//               <div>
//                 <Bar
//                   data={barData}
//                   options={{
//                     scales: {
//                       yAxes: [
//                         {
//                           ticks: {
//                             beginAtZero: true,
//                           },
//                         },
//                       ],
//                     },
//                     legend: {
//                       position: "right",
//                     }
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="opacity-25 fixed inset-0 z-40 bg-black" />
//     </>
//   );
// }
// export default VitalChart;


import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function VitalChart(props) {
  const colors = [
    "rgb(54, 162, 235)",
    "rgb(75, 192, 192)",
    "rgb(255, 99, 132)",
  ];

  let dataset = [];
  if (props.type) {
    dataset.push({
      name: props.type.toString(),
      data: props.data?.map((d) => ({ date: moment(d.givenDate, "yyyy-MM-DD HH:mm:ss").format("MMM Do YY"), [props.type]: d[props.type] ?? 0 })),
      color: colors[0],
    });
  } else if (props.types) {
    dataset = props.types?.map((type, i) => {
      return {
        name: type.toString(),
        data: props.data?.map((d) => ({ date: moment(d.givenDate, "yyyy-MM-DD HH:mm:ss").format("MMM Do YY"), [type]: d[type] ?? 0 })),
        color: colors[i % colors.length],
      };
    });
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-11/12 md:w-1/3 my-6 mx-auto max-w-3xl">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-White outline-none focus:outline-none">
            <div className="rounded-lg shadow-lg bg-White w-full h-112 p-5 antialiased justify-between border border-gray-200">
              <div className="flex w-auto justify-between mb-4">
                <p className="text-medium font-medium text-2xl">
                  {props.title}
                </p>
                <div className="w-8 h-8 cursor-pointer">
                  <CloseOutlined onClick={props.closePopup} />
                </div>
              </div>
              <div>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={dataset[0]?.data} // Assuming there is only one dataset for now
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barSize={30}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey={props.type || props.types[0]} fill={dataset[0]?.color} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

export default VitalChart;
