import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import WestIcon from "@mui/icons-material/West";
import Mapp from "./../hooks/mapp";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocateIcon from "../../../../Assets/Images/Cart/locate.svg";
import useAddressInput from "../hooks/useAddressInput";
import useCurrentLocation from "../hooks/useCurrentLocation";
import { Modal } from "antd";
// import http from "../../Redux/services/http-common";
// import Loader from "../Loader";
const AddAddressModel = (props) => {
  const {
    isOpen,
    handleHideClick,
    handleSaveAddressClick,
    loading,
    editAddress,
  } = props;
  const [switchToMap, setSwitchToMap] = useState(true);
  const [error, setError] = useState("");
  // const [landmark, setLandMark] = useState("")
  const [
    addressString,
    onAddressInputChange,
    onAddressTypeSelect,
    savePreFetchedValues,
    editSelectedAddress,
  ] = useAddressInput();
  const [
    currentLocationError,
    currentLocationloading,
    coords,
    eLocToLatLong,
    getLocation,
    getcoordsFromAddress,
  ] = useCurrentLocation();
  // const [addressString, setAddressString] = useState({
  //   city:"",
  //   formatted_address:"",
  //   lat:"",
  //   lng: "",
  //   pincode:"",
  //   state:"",
  //   street:"",
  //   subLocality:""
  // })
  const [latlong, setLatLong] = useState({
    // lat: "28.1473",
    // long:"77.3260"
  });
  const [switchToDetailFilling, setSwitchToDetailFilling] = useState(false);
  const [switchToSearchLocation, setSwitchToSearchLocation] = useState(false);
  // const [customAddress, setCustomAddress] = useState("")
  // const [houseNo, setHouseNo] = useState("")

  useEffect(() => {
    getLocation();
  }, [isOpen]);

  useEffect(() => {
    if (switchToSearchLocation) {
      loadSearchFunctionality();
    }
  }, [switchToSearchLocation]);

  const dialogHeader = () => {
    return (
      <>
        {switchToMap && (
          <p className="text-center text-cyan-900">Select Address</p>
        )}
        {switchToDetailFilling && (
          <div className="flex">
            <div>
              <WestIcon onClick={onBackClick} className="cursor-pointer" />
            </div>
            <p className="text-cyan-900 w-full text-center">Address Details</p>
          </div>
        )}
        {switchToSearchLocation && (
          <div className="flex">
            <div>
              <WestIcon onClick={onBackClick} className="cursor-pointer" />
            </div>
            <p className="text-cyan-900 w-full text-center">Search Location</p>
          </div>
        )}
      </>
    );
  };

  const getAddressFromMap = (data) => {
    savePreFetchedValues(data);

    // setCustomAddress(`${data?.street} ${data?.subLocality}`)
  };

  const confirmLocationClick = () => {
    setSwitchToDetailFilling(true);
    setSwitchToMap(false);
  };

  const onBackClick = () => {
    setSwitchToDetailFilling(false);
    setSwitchToSearchLocation(false);
    setSwitchToMap(true);
  };

  const onSearchLocationButtonClick = () => {
    setSwitchToSearchLocation(true);
    setSwitchToDetailFilling(false);
    setSwitchToMap(false);
  };
  //   const onCustomAddressChange = (e) =>{
  //     setCustomAddress(e.target.value)
  // }

  const loadSearchFunctionality = (address) => {
    console.log(address, "avaisbvuabvua");
    /*Search plugin initialization*/
    var optional_config = {
      location: [28.61, 77.23],
      // pod:'City',
      // bridge:true,
      // distance:true,
      width: "100%",
      height: 350,
    };
    if (address?.length) {
      new window.MapmyIndia.search(address, optional_config, callback);
    } else {
      new window.MapmyIndia.search(
        document.getElementById("auto"),
        optional_config,
        callback
      );
    }

    /*CALL for fix text - LIKE THIS
     *
     *
     * */

    var marker;
    async function callback(data) {
      console.log(data, "avaisbvuabvua");
      if (data) {
        var dt = data[0];
        if (!dt) return false;
        var eloc = dt.eLoc;
        await eLocToLatLong(eloc);
        onBackClick();
        /*Use elocMarker Plugin to add marker*/
        // if(marker) marker.remove();
        // marker=new window.MapmyIndia.elocMarker({map:map,eloc:eloc,popupHtml:place,popupOptions:{openPopup:true}}).fitbounds();
      }
    }
  };

  const handleSave = async () => {
    console.log(addressString, "saiochasoihaosivhsoaihvoaisas");
    if (!addressString.houseNo) {
      setError("Please fill house number");
    } else if (!addressString.address) {
      setError("Please fill address input");
    } else if (!addressString.pincode) {
      setError("Please fill pincode");
    } else if (!addressString.city) {
      setError("Please fill city");
    } else if (!addressString.state) {
      setError("Please fill state");
    } else if (!addressString.type) {
      setError("Please fill address type");
    } else {
      setError("");
      // if(editClick){
      //   let formatted_address = addressString.houseNo + addressString.address + addressString.city + addressString.state + addressString.pincode
      //   // await getcoordsFromAddress(formatted_address);
      //   loadSearchFunctionality(formatted_address)
      //   onBackClick()
      // }
      handleSaveAddressClick(addressString);
    }
  };

  const handleCurrentLocationClick = async () => {
    await getLocation();
    onBackClick();
  };

  const detailFillingHtml = () => {
    return (
      <div className="flex flex-col justify-center ">
        <TextField
          required
          sx={{ fontSize: "10px" }}
          id="outlined-basic"
          value={addressString.houseNo}
          name="houseNo"
          onChange={(e) => onAddressInputChange(e)}
          fullWidth
          size="small"
          label="House no | Appartment name"
          color="success"
          variant="outlined"
          margin="normal"
        />
        <TextField
          required
          id="outlined-basic"
          fullWidth
          size="small"
          value={addressString.address}
          name="address"
          onChange={(e) => onAddressInputChange(e)}
          label="Address"
          color="success"
          variant="outlined"
          margin="normal"
        />
        <TextField
          id="outlined-basic"
          fullWidth
          size="small"
          value={addressString.landmark}
          name="landmark"
          onChange={(e) => onAddressInputChange(e)}
          label="Landmark (optional)"
          color="success"
          variant="outlined"
          margin="normal"
        />
        <div className="flex flex-row">
          <TextField
            required
            value={addressString.pincode}
            style={{ marginRight: "16px" }}
            id="outlined-basic"
            fullWidth
            size="small"
            label="Pincode"
            color="success"
            variant="outlined"
            margin="normal"
          />
          <TextField
            required
            value={addressString.city}
            id="outlined-basic"
            fullWidth
            size="small"
            label="City"
            color="success"
            variant="outlined"
            margin="normal"
          />
        </div>
        <TextField
          required
          value={addressString.state}
          id="outlined-basic"
          fullWidth
          size="small"
          label="State"
          color="success"
          variant="outlined"
          margin="normal"
        />
        <div className="mt-2">
          <span className="font-semibold text-sm">
            Make this my default address
          </span>{" "}
          <Checkbox
            onChange={(e) => onAddressInputChange(e)}
            checked={addressString.isDefault ? true : false}
          />
        </div>
        <div>
          <p className="font-semibold text-sm mt-4">Address Type</p>
          <div className="flex justify-between my-2 mb-4">
            <button
              className="border rounded text-md font-semibold px-2 py-1"
              style={
                addressString?.type == "Home"
                  ? { backgroundColor: "#6366F1", color: "white" }
                  : {}
              }
              onClick={(e) => onAddressTypeSelect("Home")}
            >
              Home
            </button>
            <button
              className="border rounded text-md font-semibold px-2 py-1"
              style={
                addressString?.type == "Office"
                  ? { backgroundColor: "#6366F1", color: "white" }
                  : {}
              }
              onClick={(e) => onAddressTypeSelect("Office")}
            >
              Office
            </button>
            <button
              className="border rounded text-md font-semibold px-2 py-1"
              style={
                addressString?.type == "Others"
                  ? { backgroundColor: "#6366F1", color: "white" }
                  : {}
              }
              onClick={(e) => onAddressTypeSelect("Others")}
            >
              Other
            </button>
          </div>
        </div>
        {/* <div className='relative' > */}
        <button
          disabled={loading}
          style={{ backgroundColor: "#66B889" }}
          className="flex justify-center items-center text-sm font-semibold text-white py-2.5 rounded mb-3 relative "
          onClick={() => handleSave()}
        >
          Save Address
          {/* {loading && <Loader size="small" />} */}
        </button>
        {/* </div> */}
        {error ? (
          <div className="text-center text-sm font-medium text-red-800">
            {error}
          </div>
        ) : null}
      </div>
    );
  };

  const searchLocationHtml = () => {
    return (
      <div>
        <div className="relative">
          <SearchIcon className="absolute left-2 top-2" />
          <input
            id="auto"
            name="auto"
            className="search-outer form-control as-input border-2 rounded w-full py-1.5 pl-8 border-green-700 outline-none"
            placeholder="search places"
          />
        </div>
        <div className="text-center font-medium text-sm my-1">-- OR --</div>
        <div className="flex justify-center items-center cursor-pointer">
          <img src={LocateIcon} alt="" draggable={false} className="h-8 w-8" />
          <button
            onClick={() => handleCurrentLocationClick()}
            className="font-medium text-md text-center"
            style={{ color: "#42BA85" }}
          >
            Use current location
          </button>
        </div>
      </div>
    );
  };

  console.log(coords, "USBOUBSVOUBVOUBOUV");

  return (
    <div>
      <Modal
        title={dialogHeader}
        centered={true}
        footer={false}
        className="commonModal"
        open={isOpen}
        width="30%"
        // onOk={handleHideClick}
        onCancel={() => {
          setSwitchToMap(true);
          setSwitchToDetailFilling(false);
          setSwitchToSearchLocation(false);
          handleHideClick();
        }}
      >
        {/* <div className='hidden' ><Mapp /></div> */}
        {switchToMap && (
          <div>
            <Mapp
              getAddressFromMap={getAddressFromMap}
              currentLocationcoords={coords}
            />
            <>
              {" "}
              <div className="flex flex-col justify-center text-center">
                <p className="text-base font-bold text-cyan-900 mb-3 mt-6">
                  Identify your delivery location
                </p>
                <p className="text-xs font-normal text-cyan-900 mb-4">
                  {addressString?.formatted_address}
                </p>
                <button
                  className="text-sm bg-secondaryColor font-semibold text-White py-2.5 rounded mb-3"
                  onClick={confirmLocationClick}
                >
                  CONFIRM LOCATION
                </button>
                <span className="text-xs font-meduim text-cyan-900 mb-2">
                  Can’t find your location on the map?
                </span>
                <button
                  style={{ border: "1px solid #66B889", color: "#66B889" }}
                  className="text-sm font-semibold py-2.5 rounded"
                  onClick={onSearchLocationButtonClick}
                >
                  Search Location
                </button>
              </div>
            </>
          </div>
        )}
        {switchToDetailFilling && detailFillingHtml()}
        {switchToSearchLocation && searchLocationHtml()}
      </Modal>
      {/* <Dialog
      visible={isOpen}
      modal={false}
      header={dialogHeader}
      style={{ width: "400px", height: "85vh" }}
      onHide={() => {
        setSwitchToMap(true);
        setSwitchToDetailFilling(false);
        setSwitchToSearchLocation(false);
        handleHideClick();
      }}
    >
      {switchToMap && (
        <div>
          <Mapp
            getAddressFromMap={getAddressFromMap}
            currentLocationcoords={coords}
          />
          <>
            {" "}
            <div className="flex flex-col justify-center text-center">
              <p className="text-base font-bold text-cyan-900 mb-3 mt-6">
                Identify your delivery location
              </p>
              <p className="text-xs font-normal text-cyan-900 mb-4">
                {addressString?.formatted_address}
              </p>
              <button
                style={{ backgroundColor: "#66B889" }}
                className="text-sm font-semibold text-white py-2.5 rounded mb-3"
                onClick={confirmLocationClick}
              >
                CONFIRM LOCATION
              </button>
              <span className="text-xs font-meduim text-cyan-900 mb-2">
                Can’t find your location on the map?
              </span>
              <button
                style={{ border: "1px solid #66B889", color: "#66B889" }}
                className="text-sm font-semibold py-2.5 rounded"
                onClick={onSearchLocationButtonClick}
              >
                Search Location
              </button>
            </div>
          </>
        </div>
      )}
      {switchToDetailFilling && detailFillingHtml()}
      {switchToSearchLocation && searchLocationHtml()}
    </Dialog> */}
    </div>
  );
};

export default AddAddressModel;
