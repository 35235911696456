import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const env = process.env.REACT_APP_ENV;

// Script content based on environment
const mapScriptSrc = "https://apis.mapmyindia.com/advancedmaps/v1/2ddf0e5a548dd9df0f7d033438a1218a/map_load?v=1.5"

// Conditionally add the map script based on environment
if (env === "production") {
  const scriptTag = document.createElement('script');
  scriptTag.src = mapScriptSrc;
  document.head.appendChild(scriptTag);
}

// Conditionally hide the map element in development and staging environments
const envScriptContent = `
  if (window.REACT_APP_ENV === "development" || window.REACT_APP_ENV === "staging" || window.REACT_APP_ENV === "preprod") {
    document.addEventListener("DOMContentLoaded", function() {
      var mapElement = document.getElementById("map");
      if (mapElement) {
        mapElement.style.display = "none";
      }
    });
  }
`;

// Inject the script to hide the map element
const scriptTag = document.createElement('script');
scriptTag.innerHTML = envScriptContent.replace('window.REACT_APP_ENV', `"${env}"`);
document.head.appendChild(scriptTag);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
