import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Input, InputNumber, Modal, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Checkbox } from "antd";
import { encodeBase64File } from "../../Helper/filebase64";
import moment from "moment";
import steth from "../../../Assets/Images/avatar.png";
import camera from "../../../Assets/Images/camera.svg";
import { ToastContainer, toast } from "react-toastify";
import { editPatientDetails } from "../../../Redux/Action/UserprofileActions";
import getconsentversion from "../../Helper/consentMangment";
import http from "../../../Redux/Services/http-common";
import { addRegistration } from "../../../Redux/Action/userActions";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import state from "../../Helper/state.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TermsAndConditions from "../../../Assets/privacyPolicy.pdf";
import PrivacyPolicy from "../../../Assets/Terms&conditions.pdf";
import dayjs from 'dayjs';
import Loader from "../../Shared/loader";
import { Document, Page } from 'react-pdf';

function AddFamilymember(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const userData = useSelector((state) => state.authReducer.patientData);
  const [isConsentChecked, setisConsentChecked] = useState(false);
  const [isConsentChecked2, setisConsentChecked2] = useState(false);
  const [isConsentWarningVisible, setisConsentWarningVisible] = useState(false);
  const [isHouseNumber, setHouseNumber] = useState(false);
  const [isFirstName, setFirstName] = useState(false);
  const [isAadharId, setAadharId] = useState(false);
  const [isRelation, setRelation] = useState(false);
  const [isGender, setGender] = useState(false);
  const [isDOB, setDOB] = useState(false);
  const [isBloodGroup, setBloodGroup] = useState(false);
  const [isAddress, setAddress] = useState(false);
  const [isPinCode, setPinCode] = useState(false);
  const [isCity, setCity] = useState(false);
  const [isState, setState] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [date, setDate] = useState();
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numPagestcPDf, setNumPagestcPDf] = useState();
  const [numPagesprivacy, setNumPagesPrivacy] = useState();
  const [addmember, setaddmember] = useState();
  const [loginVersion, setloginVersion] = useState([""]);
  const [consentInfo, setConsentInfo] = useState({
    privacyPdf: "",
    privacyVersion: "",
    tcPdf: "",
    tcVersion: "",
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    const updateVh = () => {
      setVh(window.innerHeight * 0.01);
    };

    // Initial update
    updateVh();

    // Update on resize
    window.addEventListener('resize', updateVh);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updateVh);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let modalWidth;
  if (width <= 767) {
    modalWidth = '100%'; // Mobile devices
  } else if (width >= 768 && width <= 1023) {
    modalWidth = '500px'; // Tablets and small laptops
  } else {
    modalWidth = '600px'; // Desktops and large laptops
  }

   useEffect(() => {
    getVersion();
  }, []);


  const getVersion = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setloginVersion(data?.data);
      console.log(data?.data,"consent")
      let info = {
        privacyPdf: data?.data[0].consentFile,
        privacyVersion: data?.data[0].versionNumber,
        tcPdf: data?.data[1].consentFile,
        tcVersion: data?.data[1].versionNumber
  
      }
      setConsentInfo(info)
      setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      setLoading(false);
    }
  };


  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  }, []);
  const dateObject = props?.editmembers?.dob ? dayjs(props.editmembers.dob, "DD/MM/YYYY") : null;
  useEffect(() => {
    const dateObject = props?.editmembers?.dob ? dayjs(props.editmembers.dob, "DD/MM/YYYY") : null;
    setaddmember({
      salutation: props?.editmembers ? props?.editmembers?.salutation : "",
      firstName: props?.editmembers ? props?.editmembers?.firstName : "",
      lastName: props?.editmembers ? props?.editmembers?.lastName : "",
      mobile: props?.editmembers ? props?.editmembers?.mobile : "",
      relation: props?.editmembers ? props?.editmembers?.relation : "",
      gender: props?.editmembers ? props?.editmembers?.gender : "",
      dob: props?.editmembers ? dateObject : new Date(),
      bloodGroup: props?.editmembers ? props?.editmembers?.bloodGroup : "",
      createdBy: userData.code,
      modifiedBy: userData.code,
      code: userData.code,
      status: 1,
      parentCode: userData.code,
      photoName: props?.editmembers !== "" ? props?.editmembers?.photoName : "",
      address1: props?.editmembers !== "" ? props?.editmembers?.address1 : "",
      address2: props?.editmembers !== "" ? props?.editmembers?.address2 : "",
      pinCode: props?.editmembers !== "" ? props?.editmembers?.pinCode : "",
      city: props?.editmembers !== "" ? props?.editmembers?.city : "",
      state: props?.editmembers !== "" ? props?.editmembers?.state : "",
      photoName: props?.photoName !== "" ? props?.editmembers?.photoName : "",
      photo: null,
      uniqueId:
        props?.editmembers !== ""
          ? props?.editmembers?.uniqueId
            ? props?.editmembers?.uniqueId
            : ""
          : "",
    })

    setisConsentChecked(props.isEdit);
    setisConsentChecked2(props.isEdit);

    console.log(props.editmembers);
  }, [props.editmembers])

  useEffect(() => {
    // Set the stateData and cityData from the imported JSON data
    setStateData(state.stateData);
    setCityData(state.cityData);
  }, []);

  const afterSave = (message) => {
    props?.saveMessage(message);
  };


  useEffect(() => {
    setLoading(true)
    dispatch(getAllStates())
      .then((result) => {
        setstateList(result);
        setLoading(false)

      })
      .catch((error) => { 
        setLoading(false)

      });
  }, []);

  useEffect(() => {
    form.resetFields();

  }, [props.refresh]);
  const changeHandler = async (file) => {
    let b64File = await encodeBase64File(file);
    setaddmember({ ...addmember, ["photo"]: b64File });
  };

  const changeDate = (date, dateString) => {
    setaddmember({ ...addmember, dob: dateString });
    setDate(dateString);
  };

  const { Option } = Select;
  const handleChange = (e) => {
    if (e.target.name == "mobile" && e.target.value.length == 11) {
      return;
    }
    setaddmember({ ...addmember, [e.target.name]: e.target.value });
  };


  const saveaddmember = (e) => {
    // Fetch form values
    let values = form.getFieldsValue();
    // Set createdBy, modifiedBy, and status
    const { salutation, firstName, lastName, gender, mobileNumber, uniqueId, dob, bloodGroup, relation,
      pinCode, state, city, address1, address2 } = values;
    console.log(values);
    values.createdBy = userData.code;
    values.modifiedBy = userData.code;
    values.status = 1;
    values.parentCode = userData.code;

    if (props.editmembers) {
      let payload = { ...props.editmembers };
      if (salutation) {
        setaddmember({ ...addmember, salutation });
        payload = { ...payload, salutation };
      }
      if (firstName) {
        setaddmember({ ...addmember, firstName });
        payload = { ...payload, firstName };
      }
      if (lastName) {
        setaddmember({ ...addmember, lastName });
        payload = { ...payload, lastName };
      }
      if (gender) {
        setaddmember({ ...addmember, gender });
        payload = { ...payload, gender };
      }
      if (mobileNumber) {
        setaddmember({ ...addmember, mobileNumber });
        payload = { ...payload, mobile: mobileNumber };
      }
      if (uniqueId) {
        if (uniqueId.length !== 4) {
          toast("Please enter the last 4 digits of your Aadhaar");
          return;
        }
        else {
          setaddmember({ ...addmember, uniqueId });
          payload = { ...payload, uniqueId };
        }

      }

      if (dob) {
        payload = { ...payload, dob: date };
      }
      else {
        const dobString = props?.editmembers?.dob?.format('YYYY-MM-DD');
        payload = { ...payload, dob: dobString }
      }

      if (bloodGroup) {
        setaddmember({ ...addmember, bloodGroup });
        payload = { ...payload, bloodGroup };
      }
      if (relation) {
        setaddmember({ ...addmember, relation });
        payload = { ...payload, relation };
      }
      if (pinCode) {
        if (pinCode.length !== 6) {
          toast("Please enter a valid pincode");
        }
        else {
          setaddmember({ ...addmember, pinCode });
          payload = { ...payload, pinCode };
        }
      }
      if (state) {
        setaddmember({ ...addmember, state });
        payload = { ...payload, state };
      }
      else if (city) {
        setaddmember({ ...addmember, city });
        payload = { ...payload, city };
      }
      if (address1) {
        setaddmember({ ...addmember, address1 });
        payload = { ...payload, address1 };
      }
      if (address2) {
        setaddmember({ ...addmember, address2 });
        payload = { ...payload, address2 };
      }
      if (addmember.photo) {
        payload. photo = addmember.photo
      }
      if (!isConsentChecked && props?.editmembers?.id) {
        // setisConsentWarningVisible(true);
        toast("Please accept consent");
        return;
      } else if (!isConsentChecked) {
        toast('Please accept consent.');
        message.error("Please select consent checkbox");
        return;
      }

      if (!isConsentChecked2 && props?.editmembers?.id) {
        // setisConsentWarningVisible(true);
        toast("Please accept consent");
        return;
      } else if (!isConsentChecked2) {
        toast('Please accept consent.');
        message.error("Please select consent checkbox");
        return;
      }

      console.log(payload)
      setLoading(true)

      dispatch(editPatientDetails(props.editmembers?.id, payload)).
        then(
          async (res) => {
            if (res === 1) {
              setLoading(true)

              let [consentVersion, istcAccepted, isprivacyAccepted] = await getconsentversion(props?.editmembers.code)
              const payload = {
                createdBy: "CUREBAYADMIN",
                privacyPolicyConsent: isConsentChecked ? 1 : 0,
                sourceChannel: "B2C",
                status: 1,
                termsAndConditionConsent: isConsentChecked ? 1 : 0,
                type: "P",
                patientId: props?.editmembers.code,
                privacyVersionNumber: consentVersion.privacyVersion,
                tcVersionNumber: consentVersion.tcVersion,
              };
              const postResponse = await http.post(
                `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
                payload
              );
              props.handleEditmember();
              setaddmember({});
              // console.log("POST Request Response for test", postResponse);
              // if (postResponse.status === 200) {

              // }
              // toast("Profile Updated Successfully");
              afterSave("Profile Added Successfully");
              form.resetFields();
              setLoading(false)

            }
          }
        );
    }
    else {
      console.log("hiiii");
      // if (
      // ) {
      //   message.error("Please fill out all mandatory fields");
      //   return;
      // }

      if (!values.salutation || !values.firstName || !values.lastName || !values.relation || !values.gender || !values.dob || !values.bloodGroup || !values.address1 || !values.address2 || !values.pinCode || !values.state || !values.city || !values.uniqueId) {
        toast('Please fill all required fields.');
        return;
      }

      if (values.pinCode && values.pinCode.length !== 6) {
        toast("Please enter a valid pincode");
        return;
      }
      values.dob = date;

      values.mobile = values.mobileNumber;
      if (values.uniqueId.length !== 4) {
        toast("Please enter the last 4 digits of your Aadhaar");
        return;
      }


      if (!isConsentChecked && props?.editmembers?.id) {
        setisConsentWarningVisible(true);
        return;
      } else if (!isConsentChecked) {
        toast('Please accept consent.');
        message.error("Please select consent checkbox");
        return;
      }

      if (!isConsentChecked2 && props?.editmembers?.id) {
        setisConsentWarningVisible(true);
        return;
      } else if (!isConsentChecked2) {
        toast('Please accept consent.');
        message.error("Please select consent checkbox");
        return;
      }


      // Set dob format
      values.dob = moment(values.dob).format("MM/DD/YYYY");

      // Dispatch action to save family member
      setLoading(true)

      dispatch(addRegistration(values))
        .then(async (res) => {
          // Add consent for new member
          let [consentVersion, istcAccepted, isprivacyAccepted] = await getconsentversion(res);
          const payload = {
            createdBy: "CUREBAYADMIN",
            privacyPolicyConsent: isConsentChecked2 ? 1 : 0,
            sourceChannel: "B2C",
            status: 1,
            termsAndConditionConsent: isConsentChecked ? 1 : 0,
            type: "P",
            patientId: res,
            privacyVersionNumber: consentVersion.privacyVersion,
            tcVersionNumber: consentVersion.tcVersion,
          };
          const postResponse = await http.post(
            `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
            payload
          );
          if (postResponse.status === 200) {
            afterSave("Profile Added Successfully");
            form.resetFields();
            setisConsentChecked(false);
            setisConsentChecked2(false);
            setaddmember({
              salutation: "",
              firstName: "",
              lastName: "",
              mobile: "",
              relation: "",
              gender: "",
              dob: new Date(),
              bloodGroup: "",
              createdBy: userData.code,
              modifiedBy: userData.code,
              status: 1,
              parentCode: userData.code,
              photoName: "",
              address1: "",
              address2: "",
              pinCode: "",
              city: "",
              state: "",
              photoName: "",
              photo: null,
              uniqueId: ""
            });
          }
          setLoading(false)

        })
        .catch((err) => {
          if (err.response?.data?.details?.length) {
            toast(err.response?.data?.details[0]);
            setLoading(false)

          }
        });

    }
    console.log("hiii after else");
  };


    const maxDate = moment();
  
    const disabledDate = current => {
      return current && current > maxDate;
    };


  function onDocumentLoadSuccesstcPdf({ numPages }) {
    setNumPagestcPDf(numPages);
  }

  function onDocumentLoadSuccessprivacy({numPages}) {
    setNumPagesPrivacy(numPages);
  }


  return (
    <>
      <ToastContainer />
      {loading &&<Loader/>}

      <div>
        <div className="h-auto md:px-3">
          <Form
            initialValues={
              {
                salutation: props.editmembers?.salutation,
                firstName: props.editmembers?.firstName,
                lastName: props.editmembers?.lastName,
                mobileNumber: props.editmembers?.mobile,
                uniqueId: props.editmembers?.uniqueId,
                dob: dateObject,
                bloodGroup: props.editmembers?.bloodGroup,
                relation: props.editmembers?.relation,
                address1: props.editmembers?.address1,
                address2: props.editmembers?.address2,
                pinCode: props.editmembers?.pinCode,
                state: props.editmembers?.state,
                city: props.editmembers?.city,
                gender: props.editmembers?.gender

              }
            }
            layout="vertical"
            autoComplete="off"
            form={form}
            className="CommonForm grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-5"
          >
            <div className="flex justify-center w-auto h-full rounded-lg bg-lightGray text-primaryColor">
              <div className=""></div>
              {/* Image section */}
              <div className="">
                <img
                  src={
                    addmember?.photo
                      ? `${"data:image;base64,"}` + addmember?.photo
                      : addmember?.photoName
                        ? addmember ?.photoName
                        : steth
                  }
                  alt="stethescope"
                  className="lg:w-24 w-20 lg:h-24 h-20 mt-6 rounded-full"
                />
                <div className="-mt-4 justify-center  flex  left-14">
                  <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        changeHandler(e.target.files[0]);
                      }}
                      className="hidden"
                    />
                    <img
                      src={camera}
                      alt="camera"
                      className="w-8 bg-primaryColor rounded-full"
                    />
                  </label>
                </div>
              </div>
            </div>


            <Form.Item
              className="mb-0"
              name="salutation"
              label={
                <span className="text-primaryColor">
                  Salutation <span className="text-[red] ">*</span>
                </span>
              }
            >
              <Select
                placeholder="Select Salutation"
                className="text-primaryColor"
                // optionFilterProp="children"
                options={[
                  {
                    value: "Mr.",
                    label: "Mr.",
                  },
                  {
                    value: "Ms.",
                    label: "Ms.",
                  },
                  {
                    value: "Mrs.",
                    label: "Mrs.",
                  },
                  {
                    value: "Dr.",
                    label: "Dr.",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              className="mb-0"
              name="firstName"
              label={
                <span className="text-primaryColor">
                  First Name <span className="text-[red] ">*</span>
                </span>
              }
            >
              <Input placeholder="Please Enter Your First Name" />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="lastName"
              label={<span className="text-primaryColor">
                Last Name <span className="text-[red] ">*</span>
              </span>}
            >
              <Input placeholder="Please Enter Your Last Name" />
            </Form.Item>





            <Form.Item
              className="mb-0"
              name="gender"
              label={
                <span className="text-primaryColor">
                  Gender <span className="text-[red] ">*</span>
                </span>
              }
            >
              <Select
                placeholder="Select"
                optionFilterProp="children"
                options={[
                  {
                    value: "M",
                    label: "Male",
                  },
                  {
                    value: "F",
                    label: "Female",
                  },
                  {
                    value: "O",
                    label: "Others",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              className="mb-0"
              name="mobileNumber"
              label={<span className="text-primaryColor">
                Mobile Number <span className="text-[red] "></span>
              </span>}
            >
              <Input placeholder="Please Enter Your Mobile Number" maxLength={10}
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} />
            </Form.Item>


            <Form.Item
              className="mb-0"
              name="uniqueId"
              label={
                <span className="text-primaryColor">
                  Aadhar Id (last 4 digit)
                  <span className="text-[red] ">*</span>
                </span>
              }
            >
              <Input placeholder="Please enter Aadhar number" maxLength={4}
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} />
            </Form.Item>

            <Form.Item
              className="mb-0"
              name="dob"
              label={
                <span className="text-primaryColor">
                  DOB
                  <span className="text-[red] ">*</span>
                </span>
              }
            >
              <DatePicker
               disabledDate={disabledDate}
                placeholder="Please Select Your Date of Birth"
                format="MM/DD/YYYY"
                className="w-full"
                value={props?.editmembers?.dob ? props.editmembers.dob : ""}
                onChange={changeDate}
              />
            </Form.Item>
            <Form.Item
              name="bloodGroup"
              label={
                <span className="text-primaryColor">
                  Blood group
                  <span className="text-[red] ">*</span>
                </span>
              }
              className="text-primaryColor mb-0"
            >
              <Select
                placeholder="Select"
                optionFilterProp="children"
                options={[
                  {
                    value: "O-",
                    label: "O-",
                  },
                  {
                    value: "O+",
                    label: "O+",
                  },
                  {
                    value: "A-",
                    label: "A-",
                  },
                  {
                    value: "A+",
                    label: "A+",
                  },
                  {
                    value: "B-",
                    label: "B-",
                  },
                  {
                    value: "B+",
                    label: "B+",
                  },
                  {
                    value: "AB-",
                    label: "AB-",
                  },
                  {
                    value: "AB+",
                    label: "AB+",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="relation"
              label={
                <span className="text-primaryColor">
                  Relation
                  <span className="text-[red] ">*</span>
                </span>
              }
              className="text-primaryColor mb-0"
            >
              <Select placeholder="Please Select Your Relation">
                <Option value="Father">Father</Option>
                <Option value="Mother">Mother</Option>
                <Option value="Daughter">Daughter</Option>
                <Option value="Son">Son</Option>
                <Option value="Brother">Brother</Option>
                <Option value="Sister">Sister</Option>
                <Option value="Spouse">Spouse</Option>
                <Option value="Grandparent">Grandparent</Option>
                <Option value="Relative">Relative</Option>
                <Option value="Staff">Staff</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="address1"
              label={
                <span className="text-primaryColor">
                  House No/Street Name
                  <span className="text-[red] ">*</span>
                </span>
              }
              className="text-primaryColor mb-0"
            >
              <TextArea placeholder=" " />
            </Form.Item>
            <Form.Item
              name="address2"
              label={
                <span className="text-primaryColor">
                  Address
                  <span className="text-[red] ">*</span>
                </span>
              }
              className="text-primaryColor mb-0"
            >
              <TextArea placeholder=" " />
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="pinCode"
              label={
                <span className="text-primaryColor">
                  Pincode <span className="text-[red] ">*</span>
                </span>
              }
            >
              <Input
                placeholder="Please Enter Your Pincode" maxLength={6}
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item
              name="state"
              label={
                <span className="text-primaryColor">
                  State <span className="text-[red] ">*</span>
                </span>
              }
              className="text-primaryColor mb-0"
            >
              <select
                id="state"
                name="state"
                value={addmember?.state}
                placeholder="Select State"
                //   disabled={!showinput}
                className="w-full  h-12 border border-gray rounded-md"
                // className="border-b-2 border-gray-300 text-xs mt-2 w-full bg-transparent text-gray-900 focus:outline-none"
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {/* {
                          <option disabled={true} value={""}>
                            Select State
                          </option>
                        } */}
                {stateData.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </select>
            </Form.Item>
            <Form.Item
              name="city"
              label={
                <span className="text-primaryColor">
                  City <span className="text-[red] ">*</span>
                </span>
              }
              className="text-primaryColor mb-0"
            >
              <Select showSearch placeholder="Select City" style={{ height: 50 }}>

                {addmember?.state &&
                  cityData
                    .filter(
                      (city) => city.state_code === addmember?.state
                    )
                    .map((city, index) => (
                      <option key={index} value={city.name}>
                        {city.name}
                      </option>
                    ))}
              </Select>
            </Form.Item>
          </Form >
          <div className="mt-5">
            <div className="">
              <Checkbox
                className="text-sm"
                onChange={() => setisConsentChecked(!isConsentChecked)}
                checked={isConsentChecked}
              >
                I hereby accept and consent to be governed by these{" "}
                <span className="text-primaryColor text-sm font-semibold cursor-pointer" onClick={() => setPrivacy(true)}>
                  Terms of Use
                </span>
              </Checkbox>
            </div>
            <div className="">
              <Checkbox
                className="text-sm"
                onChange={() => setisConsentChecked2(!isConsentChecked2)}
                checked={isConsentChecked2}
              >
                I hereby accept and consent to be governed by these{" "}
                <span className="text-primaryColor text-sm font-semibold cursor-pointer" onClick={() => setTermsOfUse(true)} >
                  Privacy Policy
                </span>
              </Checkbox>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className="border bg-primaryColor text-White w-[30%] py-3 mr-5 rounded-lg text-base"
              onClick={(e) => saveaddmember()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={termsOfUse}
        onOk={() => setTermsOfUse(false)}
        onCancel={() => setTermsOfUse(false)}
        centered
        styles={{
          content: {
              height: `${vh * 98}px`,
              width: modalWidth,
              // overflow:"scroll"
          },
          footer:{
            alignContent: "center",
            paddingRight: 10,
            height: "10%"
          },
          body:{
            height: "90%" 
          }
        }}
        className="w-full sm:w-8/12 md:w-10/12 consentModal"
      >
        <div className="consentBodyContainer">
          <div className="consentcont overflow-scroll">
            {/* <embed
              src={TermsAndConditions}
              type="application/pdf"
              className="w-full h-full"
            /> */}
            {/* <iframe
                          // height="95px"
                          // width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`}
                        /> */}
                        <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`} onLoadSuccess={onDocumentLoadSuccessprivacy} >
               {numPagesprivacy &&
          Array.from({ length: numPagesprivacy }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false} />
          )}      
      </Document>
                       
            
          </div>
        </div>

      </Modal>
      <Modal
        open={privacy}
        onOk={() => setPrivacy(false)}
        onCancel={() => setPrivacy(false)}
        centered
        footer={null}
        styles={{
          content: {
              height: `${vh * 98}px`,
              width: modalWidth,
              // overflow:"scroll"
          },
          footer:{
            alignContent: "center",
            paddingRight: 10,
            height: "10%"
          },
          body:{
            height: "90%" 
          }
        }}
      >
        <div className="consentBodyContainer">
          <div className="consentcont overflow-scroll">

          {/* <iframe
                          // height="95px"
                          // width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`}
                        /> */}
                          <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`} onLoadSuccess={onDocumentLoadSuccesstcPdf}>
               {numPagestcPDf &&
          Array.from({ length: numPagestcPDf }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false}  />
          )}      
      </Document> 
          
            {/* <embed
              src={PrivacyPolicy}
              type="application/pdf"
              className="w-full h-full"
            /> */}
          </div>

        </div>


      </Modal>
    </>
  );
}

export default AddFamilymember;
