import React from "react";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { Logout } from "../../../Redux/Action/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";

function DummyRoute() {
  const userData = useSelector((state) => state.authReducer);
  const { patientData, patientSession, isOpen, index } = userData;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    let dataObj = {
      sessionId: patientSession?.id,
      userCode: patientSession?.patientCode,
    };
    dispatch(Logout(dataObj));

    sessionStorage.clear();
    localStorage.clear();
    history.push('/');
    // window.location.reload();
    
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <div onClick={() => handleNavigation("/myprofile")}>My Profile</div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleNavigation("/manageprofile")}>
          Manage Profile
        </div>
      ),
    },
    {
      key: "11",
      label: (
        <div onClick={() => handleNavigation("/medical-summary")}>
          Medical Summary
        </div>
      ),
    },
    {
      key: "3",
      // type: "group",
      label: "My Health Records",
      children: [
        {
          key: "3-1",
          label: (
            <div onClick={() => handleNavigation("/medicalhistory")}>
              Medical History
            </div>
          ),
        },
        {
          key: "3-2",
          label: (
            <div onClick={() => handleNavigation("/myreports")}>My Reports</div>
          ),
        },
        {
          key: "3-3",
          label: (
            <div onClick={() => handleNavigation("/myprescription")}>
              My Prescription
            </div>
          ),
        },
        {
          key: "3-4",
          label: (
            <div onClick={() => handleNavigation("/mylabreports")}>
              My Lab Reports
            </div>
          ),
        },
        {
          key: "3-5",
          label: (
            <div onClick={() => handleNavigation("/myvitals")}>My Vitals</div>
          ),
        },
        {
          key: "3-6",
          label: (
            <div onClick={() => handleNavigation("/myappointments")}>
              My Appointments
            </div>
          ),
        },
      ],
    },
    {
      key: "4",
      label: (
        <div onClick={() => handleNavigation("/myrequests")}>My Requests</div>
      ),
    },
    {
      key: "5",
      label: "My Orders",
      children: [
        {
          key: "5-1",
          label: (
            <div onClick={() => handleNavigation("/laborder")}>Lab Orders</div>
          ),
        },
        {
          key: "5-2",
          label: (
            <div onClick={() => handleNavigation("/medicineorder")}>
              Medicine Orders
            </div>
          ),
        },
        {
          key: "5-3",
          label: (
            <div onClick={() => handleNavigation("/mymembership")}>
              My Membership
            </div>
          ),
        },
      ],
    },
    {
      key: "6",
      label: (
        <div onClick={() => handleNavigation("/mypayment")}>My Payments</div>
      ),
    },
    {
      key: "7",
      label: (
        <div onClick={() => handleNavigation("/mywallet")}>CureBay Wallet</div>
      ),
    },
    {
      key: "8",
      label: (
        <div onClick={() => handleNavigation("/addAddressbook")}>
          My Address Book
        </div>
      ),
    },
    {
      key: "9",
      label: <div onClick={() => handleNavigation("/feedback")}>Feedback</div>,
    },
    {
      key: "12",
      label: (
        <div onClick={() => handleNavigation("/change-password")}>
          Change Password
        </div>
      ),
    },
    {
      key: "13",
      danger: true,
      label: <div onClick={() => handleChange("logout")}>Log Out</div>,
    },
  ];

  return (
    <div>
      <Dropdown
        menu={{
          items: items,
        }}
      >
        <a
          className="cursor-pointer text-White text-xl font-bold bg-lightGreen text-secondaryColor w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex justify-center items-center rounded-full"
          onClick={(e) => e.preventDefault()}
        >
          {patientData && patientData.firstName
            ? patientData?.firstName[0].toUpperCase()
            : ""}
        </a>
      </Dropdown>
    </div>
  );
}

export default DummyRoute;
