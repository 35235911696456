
import React, {useRef} from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import http from '../../../../Redux/Services/http-common';
import { setLatLong, setAddressString, actioncustomPinCode } from '../../../../Redux/Action/userActions';
import { secureStorage } from "../../../../Redux/Reducer/authReducer";

function Mapp(props) {
    const dispatch = useDispatch();
    const {currentLocationcoords = {}, getAddressFromMap = () => {} } = props
    const coords = useSelector(state => state.authReducer.coords);
    const [value , setValue] = useState(0);
    function LatLong(position) {
        const { latitude, longitude } = position;
        console.log("coords", coords)
        var coordsObj = {
            lat: latitude,
            long: longitude
        }
        secureStorage.setItem('coords', coordsObj);

        dispatch(setLatLong(coordsObj));

    }
    console.log(props, "wcewhvuiewvbwjkbouwvguowev")
    

    function loadMap() {
        var apiUrl = 'MMI/accesstoken'
        http.get(apiUrl).then(async (response) => {
            let data = response.data;
            console.log("res", data)
            await loadScript("https://apis.mapmyindia.com/advancedmaps/api/" + data.access_token + "/map_sdk_plugins");
            await loadScript("https://apis.mapmyindia.com/advancedmaps/v1/" + data.access_token + "/map_load?v=1.3");
            // loadMapF();
        });
        function loadScript(src) {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.className = "mmiScript"
                script.onload = () => {
                    resolve(true)
                };
                document.head.appendChild(script);
            })
        }
        
    }

    // function loadMapF() {
    //     console.log(props, "sdvsdoivsdohvosdv")
    //     var centre = new window.L.LatLng(currentLocationcoords?.lat ? currentLocationcoords.lat : '', currentLocationcoords?.long ? currentLocationcoords.long : '');
    //     console.log(props,"cener", centre)
    //     let map = {}
    //     let options = {}
    //     // if(!window.MapmyIndia){
    //          map = new window.MapmyIndia.Map(document.getElementById("map"), {
    //             center: centre.lat ? centre : [20.2961 , 85.8245],
    //             zoom: 15,
    //             search: true,
    //             zoomControl: true,
    //             hybrid: true,
    //             location: true
    //         });
    //         sessionStorage.setItem("mapObject" , JSON.stringify(map))
    //          options = {
    //             map: map,
    //             // callback: callback_method,
    //             search: true,
    //             hyperLocal: true
    //         };
    //     // }

    //     // submt_map
    //     let placeResponse = ""
    //     if(currentLocationcoords?.lat){
    //         placeResponse = new window.MapmyIndia.placePicker({location:{lat:currentLocationcoords?.lat,lng:currentLocationcoords?.long}});
    //         console.log(placeResponse, "sdlhsdoivodsbvs")
    //         getAddressFromMap(placeResponse)
    //     } else{
    //         placeResponse = new window.MapmyIndia.placePicker(options);
    //         console.log(placeResponse, "sdlhsdoivodsbvs", "gdssdsdvsd")
    //         getAddressFromMap(placeResponse.getLocation())
    //     }
        
    //     map.on("dragend" , function(e){
    //         getAddressFromMap(placeResponse.getLocation())
    //     })
    //     document.getElementById('mmiPickerTop').style.visibility = "hidden";
    //     document.getElementById('Dv_mmiSearch_map').style.visibility = "visible";
    // }

    useEffect(() =>{
        console.log(props, "sdvsdoivsdohvosdv")

        let map;
        let script;
        let marker;
        var apiUrl = 'MMI/accesstoken'
        http.get(apiUrl).then(async (response) => {
            let data = response.data;
            console.log("res", data)
            await loadScript("https://apis.mapmyindia.com/advancedmaps/api/" + data.access_token + "/map_sdk_plugins");
            await loadScript("https://apis.mapmyindia.com/advancedmaps/v1/" + data.access_token + "/map_load?v=1.3");
            initMap();
            
        });
        function loadScript(src) {
            return new Promise((resolve) => {
                script = document.createElement("script");
                script.src = src;
                script.className = "mmiScript"
                script.onload = () => {
                    resolve(true)
                };
                document.head.appendChild(script);
            })
        }

        function initMap(){
            var centre = new window.L.LatLng(currentLocationcoords?.lat ? currentLocationcoords.lat : '', currentLocationcoords?.long ? currentLocationcoords.long : '');
            map = new window.MapmyIndia.Map( document.getElementById("map") , {
                center: centre.lat ? centre : [20.2961 , 85.8245],
                // center: [20.2961 , 85.8245],
                zoom: 15,
                search: true,
                zoomControl: true,
                hybrid: true,
                location: true
            });
            // document.getElementById('mmiPickerTop').style.visibility = "hidden";
            //     document.getElementById('Dv_mmiSearch_map').style.visibility = "visible";
            marker = new window.L.marker([currentLocationcoords?.lat ? currentLocationcoords?.lat : 20.2961 , currentLocationcoords?.long? currentLocationcoords?.long : 85.8245]).addTo(map);
            map.on('dragend', handleMapDragEnd);
            map.on('dragover', handleMapDragOver);
        }

        function handleMapDragOver(){
            const center = map.getCenter();
            const lat = center.lat;
            const lng = center.lng;
            marker.setLatLng([lat, lng]);
        }

        function handleMapDragEnd(e){
            const center = map.getCenter();
      const lat = center.lat;
      const lng = center.lng;
      console.log(`Map dragged to coordinates: ${lat}, ${lng}`);
      marker.setLatLng([lat, lng]);
      handleLocationChange(lat, lng);
        }

         function setMapLocation(lat, lng) {
            console.log(lat,lng,"ewnwwuwvhhhhhhhhhhhhhh")
            if (map) {
              map.setView([lat, lng], 14)
            //   if(marker){
            //     marker.setLatLng([lat, lng]);
            // }else{
            //     marker = window.L.marker([lat, lng]).addTo(map);
            // }
            // getAddressFromMap(placeResponse)
            }
            // let address = placePickerRef.current.getLocation()
            // console.log(address, "svidsvousdgvousdgouvgdouvsd")
           
             convertLatLongToAddress(lat,lng)

          }

        function handleLocationChange(lat,long){
            console.log(lat,long,"sfsiodgoudsgfoudsguosduosd")
            setMapLocation(lat,long)
        }

        handleLocationChange(currentLocationcoords?.lat, currentLocationcoords?.long)

    //     function loadMapF(){

    //          let options = {
    //             map: map,
    //             // callback: callback_method,
    //             search: true,
    //             hyperLocal: true
    //         };
    //     // }
    //     let marker = window.L.marker([currentLocationcoords?.lat, currentLocationcoords?.long]).addTo(map);
    //     // submt_map
    //     let placeResponse = ""
    //     if(currentLocationcoords?.lat){
    //         placeResponse = new window.MapmyIndia.placePicker({location:{lat:currentLocationcoords?.lat,lng:currentLocationcoords?.long}});
    //         console.log(placeResponse, "sdlhsdoivodsbvs")
    //         getAddressFromMap(placeResponse)
    //     } else{
    //         placeResponse = new window.MapmyIndia.placePicker(options);
    //         console.log(placeResponse, "sdlhsdoivodsbvs", "gdssdsdvsd")
    //         getAddressFromMap(placeResponse.getLocation())
    //     }
        
    //     map.on("dragend" , function(e){
    //         getAddressFromMap(placeResponse.getLocation())
    //     })
    //     // document.getElementById('mmiPickerTop').style.visibility = "hidden";
    //     // document.getElementById('Dv_mmiSearch_map').style.visibility = "visible"
    // }
        return () => {
            if(map){
                map.on('dradend', handleMapDragEnd);
                map.on('dragover', handleMapDragOver);
                map.remove();
            }
            if(script){
                document.head.removeChild(script);
            }
          };
    },[currentLocationcoords?.lat, currentLocationcoords?.long])

    // useEffect(() =>{
    //     loadMap()
    // },[])

    async function convertLatLongToAddress(lat,long){
        var apiUrl = "MMI/accesstoken";
        let response = await http.get(apiUrl)
        if(response.data?.access_token){
            let data = await  http.get(`https://apis.mapmyindia.com/advancedmaps/v1/${response.data?.access_token}/rev_geocode?lat=${lat}&lng=${long}`)
            if(data.data?.results?.length){
                getAddressFromMap(data.data?.results[0])
            }
        }
       
        
        //       if (!res.data.results[0].pincode) {
        //         setError("Not able to detect your location.");
        //         setLoading(false);
        //       } else {
        //         setError("");
        //         setLoading(false);
        //       }
        //     })
        //     .catch((err) => {
        //       setError("Error occured in MMI");
        //       setLoading(false);
        //     });
        // });
      }



    return (
        <>
        <div id="map" className="w-full mapContainer" style={{ height: '310px', zIndex: 50, width: "100%" }}></div>
        </>
    );
}
export default Mapp;
