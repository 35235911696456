// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Layouts from "../../../Layouts";
// import { DatePicker, Form, Input, Modal, Select } from "antd";
// import { CloudUploadOutlined } from "@ant-design/icons";

// import { Col, Row } from "antd";
// import Dragger from "antd/es/upload/Dragger";
// import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";

// import {
//   getReport,
//   uploadReport,
//   updateReport,
// } from "./../../../../Redux/Action/reportAction";
// import moment from "moment";
// const { TextArea } = Input;

// function MyReports() {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const reportList = useSelector((state) => state.reportReducer.reportList);

//   const [fromDate, setFromDate] = useState(
//     moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );
//   const [toDate, setToDate] = useState(
//     moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modal2Open, setModal2Open] = useState(false);
//   const [modal3Open, setModal3Open] = useState(false);
//   const [modal4Open, setModal4Open] = useState(false);
//   const [documentTypeNew, setDocumentTypeNew] = useState("");
//   const [mode, setMode] = useState("");
//   const [selectedReportId, setSelectedReportId] = useState("");
//   const [isChanged, setIsChanged] = useState(false);
//   const [isLoading, setLoading] = useState(false);
//   const [fileType, setFileType] = useState("");
//   const [patient, setPatient] = useState(userData);
//   const [fileInputValue, setfileInputValue] = useState("");
//   const [pickedDate, setPickedDate] = useState(moment().format("DD/MM/YYYY"));
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [title, setTitle] = useState("");
//   const [documentType, setDocumentType] = useState("");
//   const [notes, setNotes] = useState("");
//   const [docName, setdocName] = useState("");
//   useEffect(() => {
//     getReportList();
//   }, []);

//   const getReportList = () => {
//     let patient = userData;
//     const payload = {
//       patientCode: patient.code,
//       fromDate: fromDate,
//       toDate: toDate,
//     };

//     dispatch(getReport(payload));
//     // setopenNewEntryPopup(false);
//   };
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const showModal2 = (data) => {
//     setMode("updatereport");
//     setSelectedReportId(data.id);
//     setIsChanged(false);
//     setPickedDate(moment(data.createdDate).format("DD/MM/YYYY"));
//     if (data.title) {
//       setTitle(data.title);
//     }
//     if (data.reportType) {
//       setDocumentType(data.reportType);
//     }

//     if (data.documentType) {
//       setDocumentTypeNew(data.documentType);
//     }
//     if (data.notes) {
//       setNotes(data.notes);
//     }
//     if (data.docName) {
//       setdocName(data.docName);
//     }
//     setModal2Open(true);
//   };

//   const handleCancel2 = () => {
//     setModal2Open(false);
//   };

//   const showModal3 = () => {
//     setModal3Open(true);
//   };

//   const handleCancel3 = () => {
//     setModal3Open(false);
//   };

//   const showModal4 = () => {
//     setModal4Open(true);
//   };

//   const handleCancel4 = () => {
//     setModal4Open(false);
//   };
//   return (
//     <Layouts>
//       <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
//         <div className="md:w-[80%] w-[90%] mx-auto ">
//           <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
//             <div className=" md:flex justify-between items-center ">
//               <div className="font-medium md:text-2xl text-lg text-White">
//                 My Reports
//               </div>
//               <div className="md:flex gap-2 hidden">
//                 <div
//                   className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8 flex justify-center items-center cursor-pointer text-primaryColor font-medium text-3xl"
//                   onClick={showModal4}
//                 >
//                   +
//                 </div>
//                 <div
//                   className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8 flex items-center cursor-pointer"
//                   onClick={showModal3}
//                 >
//                   <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
//                 </div>
//                 <Select
//                   placeholder="Get reports for"
//                   className="md:h-12 h-8 w-[200px] text-base"
//                 >
//                   <option value="medication">Medication</option>
//                   <option value="condition">Condition</option>
//                   <option value="prescriptionType">Prescription Type</option>
//                 </Select>
//               </div>
//             </div>
//           </div>
//           <div className="flex gap-2 md:hidden mt-5">
//             <Select
//               placeholder="Get reports for"
//               className="h-12 w-full border border-gray rounded-md"
//             >
//               <option value="medication">Medication</option>
//               <option value="condition">Condition</option>
//               <option value="prescriptionType">Prescription Type</option>
//             </Select>
//             <div
//               className="bg-White rounded-md w-12 h-12 flex items-center cursor-pointer"
//               onClick={showModal3}
//             >
//               <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
//             </div>
//             <div
//               className="bg-White rounded-md w-12 h-12 flex justify-center items-center cursor-pointer text-primaryColor font-medium text-3xl"
//               onClick={showModal4}
//             >
//               +
//             </div>
//           </div>
//           <Row gutter={24} className="mt-5">
//             {reportList.map((user, i) => (
//               <Col xs={24} sm={24} md={12}>
//                 <div className="w-full rounded-md p-5 border-lightGray bg-White mb-5">
//                   <div className="text-primaryColor font-medium  text-lg w-full pb-3">
//                     {user.title}
//                   </div>
//                   <div className="flex text-textGray w-full justify-between gap-3">
//                     <div className="text-textGray text-xs md:text-sm">
//                       <label className="font-light">Given Date</label>
//                       <p className="font-medium mt-1">
//                         {moment(user.givenDate).format("MM/DD/YYYY")}
//                       </p>
//                     </div>
//                     <div className="text-textGray text-xs md:text-sm">
//                       <label className="font-light">Start Date</label>
//                       <p className="font-medium mt-1">
//                         {moment(user.createdDate).format("MM/DD/YYYY")}
//                       </p>
//                     </div>
//                     <div className="text-textGray text-xs md:text-sm">
//                       <label className="font-light">Report Type</label>
//                       <p className="font-medium mt-1"> {user.reportType}</p>
//                     </div>
//                   </div>
//                   <div className="flex gap-5 items-center font-medium mt-4">
//                     <div
//                       onClick={showModal}
//                       className="text-textGray underline text-xs md:text-sm cursor-pointer"
//                     >
//                       Edit Report
//                     </div>
//                     <div
//                       onClick={() => showModal2(user)}
//                       className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
//                     >
//                       View Report
//                     </div>
//                   </div>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </section>
//       <Modal
//         title="Edit Report"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         width="50%"
//       >
//         <Form
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//           layout="vertical"
//           autoComplete="off"
//         >
//           <Form.Item className="mb-0" label="Date" name="date">
//             <Input placeholder="Record on" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Title *" name="email">
//             <Input placeholder="Input placeholder" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Document Type *" name="phone">
//             <Input placeholder="Choose document type" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Upload Documents *" name="phone">
//             <Dragger className="h-[50px] p-0">
//               <CloudUploadOutlined className="p-0 m-0" />
//             </Dragger>
//           </Form.Item>
//           <Form.Item className="mb-0" label="Notes" name="subject">
//             <TextArea placeholder="Enter Notes" />
//           </Form.Item>
//         </Form>
//         <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//           <button
//             onClick={handleCancel}
//             className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleCancel}
//             className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//           >
//             Submit
//           </button>
//         </div>
//       </Modal>

//       <Modal
//         title={
//           <p className="font-semibold text-primaryColor text-xl">
//             View Reports
//           </p>
//         }
//         width={1000}
//         open={modal2Open}
//         onOk={showModal2}
//         onCancel={handleCancel2}
//         style={{ top: 20 }}
//         footer={[]}
//       >
//         <div>
//           {documentTypeNew == "png" || documentTypeNew == "jpg" ? (
//             <>
//               <img
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                 alt=""
//                 className="w-full"
//               />
//             </>
//           ) : (
//             <>
//               {documentTypeNew == "pdf" ? (
//                 <>
//                   <iframe
//                     width="100%"
//                     height="500px"
//                     src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                   ></iframe>
//                 </>
//               ) : (
//                 <img
//                   src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                   alt=""
//                   className="w-full"
//                 />
//               )}
//             </>
//           )}
//         </div>
//       </Modal>

//       <Modal
//         title="Filter Reports"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={modal3Open}
//         onOk={showModal3}
//         onCancel={handleCancel3}
//         width="50%"
//       >
//         <Form
//           layout="vertical"
//           autoComplete="off"
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//         >
//           <Form.Item className="mb-0" label="From Date" name="fromDate">
//             <DatePicker placeholder="fromDate " className="w-full" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="To Date" name="toDate">
//             <DatePicker placeholder="toDate" className="w-full" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Document Type" name="type">
//             <Select
//               placeholder="Please select document type"
//               className="text-primaryColor"
//               // optionFilterProp="children"
//               options={[
//                 {
//                   value: "1",
//                   label: "Prescription",
//                 },
//                 {
//                   value: "2",
//                   label: "Lab Report",
//                 },
//                 {
//                   value: "3",
//                   label: "Scan Report",
//                 },
//                 {
//                   value: "4",
//                   label: "MRI Scan",
//                 },
//                 {
//                   value: "5",
//                   label: "Ultrasound Report",
//                 },
//                 {
//                   value: "6",
//                   label: "Clinical Records",
//                 },
//                 {
//                   value: "7",
//                   label: "Health Records",
//                 },
//                 {
//                   value: "8",
//                   label: "Others",
//                 },
//               ]}
//             />
//           </Form.Item>
//         </Form>
//         <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//           <button
//             onClick={handleCancel3}
//             className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleCancel3}
//             className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//           >
//             Filter
//           </button>
//         </div>
//       </Modal>
//       <Modal
//         title="Add Reports"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={modal4Open}
//         onOk={showModal4}
//         onCancel={handleCancel4}
//         width="50%"
//       >
//         <Form
//           layout="vertical"
//           autoComplete="off"
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//         >
//           <Form.Item className="mb-0" label="Date" name="date">
//             <Input placeholder="Record on" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Title *" name="email">
//             <Input placeholder="Input placeholder" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Document Type *" name="phone">
//             <Input placeholder="Choose document type" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Upload Document *" name="phone">
//             <Input />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Notes *" name="phone">
//             <TextArea />
//           </Form.Item>
//         </Form>
//         <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//           <button
//             onClick={handleCancel4}
//             className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleCancel4}
//             className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//           >
//             Save
//           </button>
//         </div>
//       </Modal>
//     </Layouts>
//   );
// }

// export default MyReports;


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import { DatePicker, Form, Input, Modal, Select,message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";

import { Col, Row } from "antd";
import Dragger from "antd/es/upload/Dragger";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  uploadReport,
  updateReport,
  getReport,
} from "./../../../../Redux/Action/reportAction";
import moment from "moment";
import FamilyDropdown from "./FamilyDropDown";
import { encodeBase64File } from "../../../Helper/filebase64";
const { TextArea } = Input;

function MyReports() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const reportList = useSelector((state) => state.reportReducer.reportList);
  const patientCode = useSelector((state) => state.authReducer.patientCode);

  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  const [toDate, setToDate] = useState(
    moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [documentTypeNew, setDocumentTypeNew] = useState("");
  const [mode, setMode] = useState("");
  const [selectedReportId, setSelectedReportId] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [patient, setPatient] = useState(userData);
  const [fileInputValue, setfileInputValue] = useState("");
  const [pickedDate, setPickedDate] = useState(moment().format("DD/MM/YYYY"));
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [notes, setNotes] = useState("");
  const [docName, setdocName] = useState("");
  const [form] = Form.useForm();
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    getReportList();
  }, [patientCode]);

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  }, [])

  const getReportList = () => {
    let patient = userData;
    const payload = {
      patientCode: patient.code,
      fromDate: fromDate,
      toDate: toDate,
    };
    dispatch(getReport(payload))
    .then((res) => {
      setReportData(res);
    })
    .catch((err) => {
      console.log("error");
    });
  };

  const loadReportFor = (code) => {
    console.log(code, "reportListsdhvs");

    const payload = {
      patientCode: code,
      fromDate: fromDate,
      toDate: toDate,
    };

    setPatient({ code: code ? code : userData.code });
    // console.log(payload, "sdbsdjvbkdsjvs", moment(new Date()).format("DD/MM/YYYY"))
    dispatch(getReport(payload));
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const showModal2 = (data) => {
    setMode("updatereport");
    setSelectedReportId(data.id);
    setIsChanged(false);
    setPickedDate(moment(data.givenDate)); // Updated line
    if (data.title) {
      setTitle(data.title);
    }
    if (data.reportType) {
      setDocumentType(data.reportType);
    }

    if (data.documentType) {
      setDocumentTypeNew(data.documentType);
    }
    if (data.notes) {
      setNotes(data.notes);
    }
    if (data.docName) {
      setdocName(data.docName);
    }
    setModal2Open(true);
  };

  const handleCancel2 = () => {
    setModal2Open(false);
  };

  const showModal3 = () => {
    setModal3Open(true);
  };
  const changeDate = (date, dateString) => {
    console.log(dateString)
  setFromDate(dateString)
  };
  const changeDate2 = (date, dateString) => {
    console.log(dateString)
  setToDate(dateString)
  };
  const handleCancel3 = () => {
    let fromDate =form.getFieldsValue('fromDate')
    let toDate =form.getFieldsValue('toDate')

    setFromDate( moment(fromDate).format("DD/MM/YYYY"))
    setToDate( moment(toDate).format("DD/MM/YYYY"))
    getReportList()
    setModal3Open(false);
  };

  const showModal4 = () => {
    setModal4Open(true);
  };

  const handleCancel4 = () => {
    setModal4Open(false);
    form.resetFields();
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  
  const handleFileChange = async (info) => {
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!allowedFileTypes.includes(info.file.type)) {
      message.error('You can only upload JPG/PNG images or PDF files!');
      return;
    } else {
      const base64File = info.file.originFileObj;
      let b64File = await encodeBase64File(base64File);
      setSelectedFile(b64File)
      let orgType = info.file.type == 'image/jpeg' ? "jpeg" : info.file.type == 'image/png' ? "png" : info.file.type == 'application/pdf' ? "pdf" : "svg"
      setFileType(orgType)
    }
  };

  const handleFinish = (values) => {
    console.log("values", values);
    const payload = {
      patientCode: patient?.code,
      title: values.title,
      notes: values.notes,
      ePrescriptionGen: -1,
      ePrescriptionStatus: -1,
      status: 1,
      documentUploadByVisit: 0,
      documentRequired: "Y",
      reportType: values.reportType,
      createdBy: userData.code,
      modifiedBy: userData.code,
      enteredBy: userData.code,
      createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      givenDate: moment(values.date).format("YYYY-MM-DD hh:mm:ss"),
      patientName: userData.firstName,
      patientMobile: userData.mobile,
      visitId: 0,
      documentReceivedSource: "self",
      documentType: fileType,
      reportType: "MyReport",
      document: selectedFile,

    };
    // payload.documentType = fileType;
    // payload.reportType = documentType;
    // payload.document = selectedFile;
    dispatch(uploadReport(payload)).then((res) => {
      setLoading(false);
      getReportList();
      setModal4Open(false);
      form.resetFields();
    });
    console.log(payload);
  };

  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-lg text-White">
                My Reports
              </div>
              <div className="md:flex gap-2 hidden">
                <div
                  className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8 flex justify-center items-center cursor-pointer text-primaryColor font-medium text-3xl"
                  onClick={showModal4}
                >
                  +
                </div>
                <div
                  className="bg-lightBlue rounded-md md:w-12 w-4 md:h-12 h-8 flex items-center cursor-pointer"
                  onClick={showModal3}
                >
                  <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
                </div>
                {/* <FamilyDropdown
                  title="Get Reports For"
                  onSelect={loadReportFor}
                /> */}
              </div>
            </div>
          </div>
          <div className="flex gap-2 md:hidden mt-5">
          {/* <FamilyDropdown
                  title="Get Reports For"
                  onSelect={loadReportFor}
                /> */}
            <div
              className="bg-White rounded-md w-12 h-12 flex items-center cursor-pointer"
              onClick={showModal3}
            >
              <img src={FilterIcon} alt="Filter Icon" className="mx-auto" />
            </div>
            <div
              className="bg-White rounded-md w-12 h-12 flex justify-center items-center cursor-pointer text-primaryColor font-medium text-3xl"
              onClick={showModal4}
            >
              +
            </div>
          </div>
          <Row gutter={24} className="mt-5">
            {reportData?.map((user, i) => (
              <Col xs={24} sm={24} md={12}>
                <div className="w-full rounded-md p-5 border-lightGray bg-White mb-5">
                  <div className="text-primaryColor font-medium  text-lg w-full pb-3">
                    {user.title}
                  </div>
                  <div className="flex text-textGray w-full justify-between gap-3">
                    <div className="text-textGray text-xs md:text-sm">
                      <label className="font-light">Given Date</label>
                      <p className="font-medium mt-1">
                        {moment(user.givenDate).format("MM/DD/YYYY")}
                      </p>
                    </div>
                    <div className="text-textGray text-xs md:text-sm">
                      <label className="font-light">Start Date</label>
                      <p className="font-medium mt-1">
                        {moment(user.createdDate).format("MM/DD/YYYY")}
                      </p>
                    </div>
                    <div className="text-textGray text-xs md:text-sm">
                      <label className="font-light">Report Type</label>
                      <p className="font-medium mt-1"> {user.reportType}</p>
                    </div>
                  </div>
                  <div className="flex gap-5 items-center font-medium mt-4">
                    {/* <div
                      onClick={showModal}
                      className="text-textGray underline text-xs md:text-sm cursor-pointer"
                    >
                      Edit Report
                    </div> */}
                    <div
                      onClick={() => showModal2(user)}
                      className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
                    >
                      View Report
                    </div>
                  </div>
                </div>
              </Col>
            ))}
              {reportList && reportList.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No reports available!
                  </p>
                </div>
                 )}
          </Row>
        </div>
      </section>
      <Modal
        title="Edit Report"
        className="commonModal"
        centered={true}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="50%"
      >
        <Form
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item className="mb-0" label="Date" name="date">
          <DatePicker
            defaultValue={moment(pickedDate, "DD/MM/YYYY")}
            placeholder="Record on"
            className="w-full h-12"
            
          />
        </Form.Item>
        <Form.Item className="mb-0" label="Title *" name="title">
          <Input defaultValue={title} placeholder="Enter Title" />
        </Form.Item>
        <Form.Item
            className="mb-0"
            label="Document Type *"
            name="documentType"
          >
            {/* <Select placeholder="Choose document type" /> */}

            <select
               name="reportType"
               className="w-full h-12 outline-lightBlue2 p-2 border-lightBlue2 rounded-md"
               defaultValue={documentTypeNew}
            >
              <option disabled selected>
                --Select--
              </option>
              <option value="Prescription">Prescription</option>
              <option value="Lab Report">Lab Report</option>
              <option value="Scan Report">Scan Report</option>
              <option value="MRI Scan">MRI Scan</option>
              <option value="Ultrasound Report">Ultrasound Report</option>
              <option value="Clinical Records">Clinical Records</option>
              <option value="Health Records">Health Records</option>
              <option value="Others">Others</option>
            </select>
          </Form.Item>
          <Form.Item className="mb-0" label="Upload Documents *" name="phone">
            <Dragger className="h-[50px] p-0">
              <CloudUploadOutlined className="p-0 m-0" />
            </Dragger>
          </Form.Item>
          <Form.Item className="mb-0" label="Notes" name="subject">
            <TextArea placeholder="Enter Notes" />
          </Form.Item>
        </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
          >
            Cancel
          </button>
          <button
            onClick={handleCancel}
            className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title={
          <p className="font-semibold text-primaryColor text-xl">
            View Reports
          </p>
        }
        width={1000}
        open={modal2Open}
        onCancel={handleCancel2}
        style={{ top: 20 }}
        footer={[]}
      >
        <div>
          <div className="mb-3">
            <label className="font-semibold">Date:</label>
            <p>{moment(pickedDate).format("MM/DD/YYYY")}</p>
          </div>
          <div className="mb-3">
            <label className="font-semibold">Document Type:</label>
            <p>{documentType}</p>
          </div>
          <div className="mb-3">
            <label className="font-semibold">Notes:</label>
            <p>{notes}</p>
          </div>
          <div className="mb-3">
            <label className="font-semibold">Uploaded Document:</label>
            {documentTypeNew === "png" || documentTypeNew === "jpg" ? (
              <img
                src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                alt=""
               className="rounded-lg w-[50px] h-[50px]"
              />
              // <div>
              // </div>
            ) : documentTypeNew === "pdf" ? (
              <iframe
                title="PDF Viewer"
                width="100%"
                height="500px"
                src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
              ></iframe>
            ) : (
              <img
                src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                alt=""
                className="w-full"
              />
            )}
          </div>
        </div>
      </Modal>


      <Modal
        title="Filter Reports"
        className="commonModal"
        centered={true}
        footer={false}
        open={modal3Open}
        onOk={showModal3}
        onCancel={handleCancel3}
        width="50%"
        form={form}

      >
        <Form
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="From Date" name="fromDate">
            <DatePicker placeholder="fromDate " className="w-full"                         format="MM/DD/YYYY"
         onChange={changeDate}
/>
          </Form.Item>
          <Form.Item className="mb-0" label="To Date" name="toDate">
            <DatePicker placeholder="toDate" className="w-full"   format="MM/DD/YYYY"        onChange={changeDate2}
/>
          </Form.Item>
          {/* <Form.Item className="mb-0" label="Document Type" name="type">
            <Select
              placeholder="Please select document type"
              className="text-primaryColor"
              // optionFilterProp="children"
              options={[
                {
                  value: "1",
                  label: "Prescription",
                },
                {
                  value: "2",
                  label: "Lab Report",
                },
                {
                  value: "3",
                  label: "Scan Report",
                },
                {
                  value: "4",
                  label: "MRI Scan",
                },
                {
                  value: "5",
                  label: "Ultrasound Report",
                },
                {
                  value: "6",
                  label: "Clinical Records",
                },
                {
                  value: "7",
                  label: "Health Records",
                },
                {
                  value: "8",
                  label: "Others",
                },
              ]}
            />
          </Form.Item> */}
        </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel3}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
          >
            Cancel
          </button>
          <button
            onClick={handleCancel3}
            className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
          >
            Filter
          </button>
        </div>
      </Modal>
      <Modal
        title="Add Reports"
        className="commonModal"
        centered={true}
        footer={false}
        open={modal4Open}
        onOk={showModal4}
        onCancel={handleCancel4}
        width="50%"
      >
        <Form
          onFinish={handleFinish}
          form={form}
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="Date" name="date">
            <DatePicker placeholder="Record on " className="w-full h-12" />
          </Form.Item>

          <Form.Item className="mb-0 "
           name="title"
           label={
            <span>
              Title <span className="text-[red] ">*</span>
            </span>
          }
           >
            <Input placeholder="Enter Title" />
          </Form.Item>

          <Form.Item
            className="mb-0"
           name="documentType"
           label={
            <span>
              Document Type <span className="text-[red] ">*</span>
            </span>
          }
          >
            {/* <Select placeholder="Choose document type" /> */}

            <select
              name="reportType"
              className="w-full h-12 outline-lightBlue2 p-2 border-lightBlue2 rounded-md"
            >
              <option disabled selected>
                --Select--
              </option>
              <option value="Prescription">Prescription</option>
              <option value="Lab Report">Lab Report</option>
              <option value="Scan Report">Scan Report</option>
              <option value="MRI Scan">MRI Scan</option>
              <option value="Ultrasound Report">Ultrasound Report</option>
              <option value="Clinical Records">Clinical Records</option>
              <option value="Health Records">Health Records</option>
              <option value="Others">Others</option>
            </select>
          </Form.Item>
          <Form.Item className="mb-0"  
          name="document"
          label={
            <span>
              Upload Documents <span className="text-[red] ">*</span>
            </span>
          }
          
          >
            <Dragger className="h-[50px] p-0" onChange={handleFileChange}>
              <CloudUploadOutlined className="p-0 m-0" />
            </Dragger>
          </Form.Item>
          <Form.Item className="mb-0"
           name="notes"
           label={
            <span>
              Notes <span className="text-[red] ">*</span>
            </span>
          }
           >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item></Form.Item>
          <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
            <button
              onClick={handleCancel4}
              className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
            >
              Cancel
            </button>
            <button
              // onClick={handleSave}
              type="submit"
              className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
            >
              Save
            </button>
          </div>
        </Form>
      </Modal>
    </Layouts>
  );
}

export default MyReports;
