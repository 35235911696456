import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import Arrowimage from "../../Assets/Images/Icons/linearrow.png";
const { Panel } = Collapse;

const CustomPanelHeader = ({ headerText }) => {
  return (
    <div className="flex gap-4 items-center">
      <img src={Arrowimage} alt="images" className="h-[20px]" />
      <span>{headerText}</span>
    </div>
  );
};

const Collapse_surgery = (faqData) => {
  return (
    <Collapse
      accordion
      className="faqCollapse faqSecond"
      expandIconPosition="end"
    >
      {faqData?.data?.length &&
        faqData.data?.map(({ label, value }, i) => (
          <Panel header={<CustomPanelHeader headerText={label} />} key={i}>
            <p className="text-White font-extralight">{value}</p>
          </Panel>
        ))}
    </Collapse>
  );
};

export default Collapse_surgery;
