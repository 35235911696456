import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import { Col, Modal, Row, Select, message } from "antd";
import NodataImage from"../../../../Assets/Images/Icons/nodata.svg";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Hospital from "../../../../Assets/Images/HospitalB2C.webp";
import FilterIcon from "../../../../Assets/Images/ReportsFilter.svg";
import {
  getPatientmedicinedeliveryList,
  getPatientmedicinedeliveryListFilter,
  getPatientmedicinedeliveryListOnFilter,
} from "../../../../Redux/Action/patientAction";
import moment from "moment";
import {
  getLocalTime,
  getLocalDateWithTime,
} from "../../../Helper/LocalTimeFormat";
import { getAllProductByIds } from "../../../../Redux/Action/pharmacyAction";
import {
  AddtoCart,
  getCartDetails,
} from "../../../../Redux/Action/cartPlaceOrderAction";
import UploadPrescriptionHelper from "../../../Helper/uploadPrescription";
import FamilyDropdown from "../HealthRecords/FamilyDropDown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function B2cOrder({selectedPatient}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [list, setList] = useState([]);
  const [medicineList, setmedicineList] = useState([]);
  const [orderType, setOrderType] = useState('b2c')
  const [pageStart, setPageStart] = useState(1);
  const [prescriptionDialog, setPrescriptionDialog] = useState(false);
  const [prescriptionDoc, setPrescriptionDoc] = useState([]);
  const [reorderData, setReorderData] = useState([]);
  const [isDialogOpen, setDialog] = useState(false);
  const [cartData, setCartData] = useState({});
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [currSelectedOrder, setCurrSelectedOrder] = useState();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  }, [])

  const showModal1 = (medicineOrder) => {
    setModal1(true);
    setCurrSelectedOrder(medicineOrder);
  };

  const handleCancel1 = () => {
    setModal1(false);
  };

  const handleCancel2 = () => {
    setModal2(false);
  };

  const showModal2 = () => {
    setModal2(true);
  };
  const handleCancel3 = () => {
    setModal3(false);
  };

  const showModal3 = () => {
    setModal3(true);
  };

  const medicinedeliverylist = useSelector(
    (state) => state.patientmedicinedeliverylist
  );
  const { PatientmedicinedeliveryData, isLoading } = medicinedeliverylist;

  useEffect(() => {
    if(!selectedPatient){
        return
    }
    const payload = {
      patientId: selectedPatient,
      photoRequired: "Y",
      status: 1,
    };
    dispatch(getPatientmedicinedeliveryListOnFilter(payload, pageStart));
  }, [selectedPatient]);
  useEffect(() => {
    if (PatientmedicinedeliveryData?.data?.length) {
      setList(PatientmedicinedeliveryData?.data);
      setmedicineList(PatientmedicinedeliveryData?.data);
    } else if(PatientmedicinedeliveryData?.data?.length == 0){
        setList([]);
        setmedicineList([]);
    }
  }, [PatientmedicinedeliveryData?.data?.length]);
  const onPrescriptionOpenClick = (user) => {
    setPrescriptionDialog(true);
    setPrescriptionDoc(user.prescriptionDocArray);
  };
  const handleReorderClick = async (user) => {
    let productCode = [];
    productCode = user?.orderDetails.map((res) => {
      if (res.orderItems.length > 0) {
        return res.orderItems.map((code) => {
          return code.drugId;
        });
      }

      if (res.refundableAmountForItems.length > 0) {
        return res.refundableAmountForItems.map((code) => {
          return code.drugId;
        });
      }
    });
    console.log("dsvidsgvuodsgvdsuvsd", productCode)
    let productStringfy = JSON.stringify(...productCode);
    console.log("dsvidsgvuodsgvdsuvsd", productStringfy)

    let reorderItem = [];
    await dispatch(getAllProductByIds(productStringfy))
      .then((res) => {
        if (user?.orderStatus === "Refunded") {
          user?.orderDetails[0].refundableAmountForItems.forEach((item) => {
            let data = res.find((item1) => item1.id === item.drugId);
            data.quantity = item.quantity;
            data.drugName = item.drugName;
            reorderItem.push(data);
          });
        } else {
          user?.orderDetails[0].orderItems.forEach((item) => {
            let data = res.find((item1) => item1.id === item.drugId);
            data.quantity = item.quantity;
            data.drugName = item.drugName;
            reorderItem.push(data);
          });
        }
      })
      .catch((err) => {
        console.log(err, "lollll");
      });
    console.log("loldd", reorderItem);
    setReorderData(reorderItem);
    setDialog(true);
    setCartData(user);
  };
  const addtoCart = (e, user) => {
    e.preventDefault();
    // setIsAdding(true);
  
   
      // toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
  

    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (user?.prescriptionDoc) {
      prescriptionRequired = "Y";
      ePrescriptionRequired = "Y";
    } else {
      prescriptionRequired = "N";
      ePrescriptionRequired = "N";
    }
    let dataObj = {};

    let isAdded = false;
    setAddToCartLoading(true);
    let productDetail = reorderData.map((res) => {
      return {
        drugCode: res.id,
        drugName: res.drugName,
        unitPrice: res.medicineRate,
        drugCategory: res?.drugCategory,
        discountAmount: res?.drugsInfoDiscountedRate
          ? res?.drugsInfoDiscountedRate
          : 0.0,
        totalAmount: res.medicineRate * res.quantity,
        quantity: res.quantity,
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        prescriptionRequired: res.prescriptionRequired == "Yes" ? "Y" : "N",
        ePrescriptionRequired: res.ePrescriptionRequired == "Yes" ? "Y" : "N",
        medicineTypeOfSell: res?.medicineTypeOfSell,
        membershipCode: null,
        membershipName: null,
        membershipCard: null,
        membershipDiscountPercentage: 0.0,
      };
    });
    console.log(user, "sdvsdhvosdhvodsv", productDetail);
    if (cartList && cartList.patientMedicineOrder) {
      cartList.patientMedicineOrder.forEach((element) => {
        productDetail.map((res) => {
          element.patientMedicineOrderDetailsList.push(res);
        });
      });
      dataObj = cartList;
    } else {
      dataObj = {
        cartId: cartList && cartList.id ? cartList.cartId : "",
        createdBy: userData.code,
        createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        modifiedBy: userData.code,
        modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
        orderId: null,
        patientId: userData.code,
        status: 1,
        labOrdersYN: false,
        drugsOrdersYN: true,
        totalAmount: 0,
        patientMedicineOrder: [
          {
            orderId: "",
            patientId: userData.code,
            prescriptionId: null,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            txnId: "",
            totalAmount: reorderData[0].medicineRate,
            address1: null,
            address2: null,
            address3: null,
            city: null,
            state: null,
            country: null,
            pincode: null,
            deliveryAddress1: null,
            deliveryAddress2: null,
            deliveryAddress3: null,
            deliveryCity: null,
            deliveryState: null,
            deliveryCountry: null,
            deliveryZipcode: null,
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            status: 1,
            procedureStatus: -1,
            payMode: "E",
            collectionStatus: null,
            paymentLinkForPatient: "N",
            discountCouponCode: null,
            patientName: userData.firstName,
            patientGender: userData?.gender,
            patientMobile: userData?.mobile,
            docName: user?.prescriptionDoc,
            patientMedicineOrderDetailsList: productDetail,
          },
        ],
      };
    }

    console.log(dataObj, "dvcsdhovihdsou");
    if (
      dataObj?.patientMedicineOrder?.length &&
      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.length > 0
    ) {
      let id =
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
          (item) => item.drugCode
        );
      id.map((item, index, self) => {
        if (self.indexOf(item) === index) {
        } else {
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
            self.indexOf(item)
          ].quantity +=
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              index
            ].quantity;
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
            self.indexOf(item)
          ].totalAmount =
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].quantity *
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[
              self.indexOf(item)
            ].unitPrice;
        }
      });

      let res =
        dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.filter(
          (item, i, self) => {
            if (id.indexOf(item.drugCode) == i) {
              return item;
            }
          }
        );

      dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList = res;
    }
    console.log(dataObj, "sdaugvugvudsgvuodw");
    dispatch(AddtoCart(dataObj)).then(async (res) => {
      console.log(res, "sdvdsivhosdugvuodvs");
      message.success("Added to cart");
      if (user?.prescriptionDocArray?.length) {
        await UploadPrescriptionHelper(res, user, "key");
      }
      setAddToCartLoading(false);
      setDialog(false);
    });
  };


  if(isLoading){
    return(
        <div className="mt-5">
          <Row >
              <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
              <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    }
  />
              </div>
          </Row>
        </div>
    )

  }

  return (
          <div className="mt-5">
            <ToastContainer/>
            <Row gutter={24}>
              {medicineList?.length > 0 &&
                medicineList
                  .sort((a, b) => {
                    return (
                      moment(b.createdOn, "yyyy-MM-DD HH:mm:ss") -
                      moment(a.createdOn, "yyyy-MM-DD HH:mm:ss")
                    );
                  })
                  .map((user, i) => (
                    <Col xs={24} sm={24} md={12} lg={12} xxl={8}>
                      <div className="w-full rounded-md p-4 bg-White my-3">
                        <div className="flex justify-between gap-3">
                          <div className=" text-right text-primaryColor text-sm md:text-base font-medium mt-1">
                            Number of items :{" "}
                            <span className="text-secondaryColor font-medium">
                              {user.totalOrderItems}
                            </span>
                          </div>
                          <div className=" text-right text-primaryColor text-sm md:text-base font-medium mt-1">
                            <span className="text-secondaryColor font-medium">
                              ₹ {user.orderAmount.toFixed(2)}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between text-textGray w-full gap-3 mt-4">
                          <div className="text-textGray text-xs md:text-sm">
                            <label className="font-light">Order ID</label>
                            <p className="font-medium mt-1">
                              {user.patientOrderId}
                            </p>
                          </div>
                          <div className="text-textGray text-xs md:text-sm">
                            <label className="font-light">Placed On</label>
                            <p className="font-medium mt-1">
                              {getLocalDateWithTime(user.paymentDateTime)}
                            </p>
                          </div>
                          <div className="text-textGray text-xs md:text-sm">
                            <label className="font-light">
                              Schedule Delivery
                            </label>
                            <p className="font-medium mt-1">
                              {getLocalDateWithTime(user.scheduledDelivery)}
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div className="flex gap-5 items-center font-medium mt-4">
                            {user.prescriptionDocArray &&
                              user.prescriptionDocArray.length > 0 &&
                              user.prescriptionDocArray[0] !== "" && (
                                <div
                                  onClick={() => onPrescriptionOpenClick(user)}
                                  className="text-textGray underline text-xs md:text-sm cursor-pointer"
                                >
                                  View Prescription
                                </div>
                              )}
                            <div
                              onClick={() => showModal1(user)}
                              className="text-[#005D8D] underline text-xs md:text-sm cursor-pointer"
                            >
                              View Details
                            </div>
                            <div
                              onClick={(e) => handleReorderClick(user)}
                              className="text-secondaryColor underline text-xs md:text-sm cursor-pointer"
                            >
                              Reorder
                            </div>
                            {/* <p><MdOutlinePictureAsPdf className="text-xs md:text-xl cursor-pointer text-lightBlue2"/></p> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
               {medicineList && medicineList.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No medicine order available!
                  </p>
                </div>
              )}
            </Row>
            <Modal
              title="View Order details"
              className="commonModal"
              centered="true"
              open={modal1}
              onCancel={() => setModal1(false)}
              width="60%"
              footer={[]}
            >
              <div className="flex justify-between items-center mb-2 md:mb-3">
                <div>
                  <p class="text-sm md:text-base font-medium mb-1 text-primaryColor">
                    Number of items :{" "}
                    <span className="text-secondaryColor">
                      {currSelectedOrder?.totalOrderItems}
                    </span>
                  </p>
                </div>
                <div>
                  <p class="text-sm md:text-base font-medium mb-1 text-primaryColor hidden md:block">
                    Amount Paid :{" "}
                    <span className="text-secondaryColor">
                      ₹ {currSelectedOrder?.amount}
                    </span>
                  </p>
                  <p class="text-sm md:text-base font-medium mb-1 text-primaryColor block md:hidden">
                    <span className="text-secondaryColor">
                      ₹ {currSelectedOrder?.amount}
                    </span>
                  </p>
                </div>
                <div className="hidden md:block">
                  <div className="">
                    <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor">
                      <span>{currSelectedOrder?.orderStatus}</span>
                    </p>
                  </div>
                  <div>
                    {/* <p className="text-sm font-medium mb-4  text-gray-500">
                      Order ID: <span>REF637UND153851</span>
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="block md:hidden mb-3">
                <p className="text-sm font-medium bg-lightGreen px-5 py-1 rounded-full text-secondaryColor w-fit">
                  <span>{currSelectedOrder?.orderStatus}</span>
                </p>
              </div>
              <div className="grid grid-cols-2 md:flex gap-3 justify-between ">
                <div className="mb-3">
                  <p class="text-xs font-light mb-1 text-textGray">Order ID</p>
                  <p class="text-xs md:text-sm font-medium text-textGray">
                    {currSelectedOrder?.patientOrderId}
                  </p>
                </div>
                <div className="mb-3">
                  <p class="text-xs font-light mb-1 text-textGray">
                    Placed On{" "}
                  </p>
                  <p class="text-xs md:text-sm font-medium text-textGray">
                    {currSelectedOrder?.paymentDateTime}
                  </p>
                </div>
                <div className="mb-3 hidden md:block">
                  <p class="text-xs font-light mb-1 text-textGray">
                    Scheduled Delivery
                  </p>
                  <p class="text-xs md:text-sm font-medium text-textGray">
                    {currSelectedOrder?.scheduledDelivery}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 md:flex justify-between md:items-center">
                <div className="mb-3 md:hidden block">
                  <p class="text-xs font-light mb-1 text-textGray">
                    Scheduled Delivery
                  </p>
                  <p class="text-xs md:text-sm font-medium text-textGray">
                    {currSelectedOrder?.scheduledDelivery}
                  </p>
                </div>
                <div className="hidden md:block">
                  <p class="text-xs font-light mb-1 text-textGray">
                    Delivery Address
                  </p>
                  <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
                    {currSelectedOrder?.deliveryAddress1}{" "}
                    {currSelectedOrder?.deliveryAddress2},{" "}
                    {currSelectedOrder?.deliveryCity},{" "}
                    {currSelectedOrder?.deliveryState} -{" "}
                    {currSelectedOrder?.deliveryPinCode}
                  </p>
                </div>
                {/* <div className="flex justify-end md:block">
                  <button class="text-White text-sm font-medium h-8 px-3 rounded-md bg-primaryColor">
                    Reorder
                  </button>
                </div> */}
              </div>
              <div className="md:hidden block">
                <p class="text-xs font-light mb-1 text-textGray">
                  Delivery Address
                </p>
                <p class="text-xs md:text-sm  mb-2 font-medium text-textGray">
                  {currSelectedOrder?.deliveryAddress1}{" "}
                  {currSelectedOrder?.deliveryAddress2},{" "}
                  {currSelectedOrder?.deliveryCity},{" "}
                  {currSelectedOrder?.deliveryState} -{" "}
                  {currSelectedOrder?.deliveryPinCode}
                </p>
              </div>
              <div className="mt-5 overflow-x-auto">
                <table className=" border-collapse border border-gray w-full h-full mx-auto ">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-gray-400 p-2">ITEM NAME</th>
                      <th className="border border-gray-400 p-2">QTY</th>
                      <th className="border border-gray-400 p-2">UNIT PRICE</th>
                      <th className="border border-gray-400 p-2">
                        UNIT DISCOUNTED PRICE
                      </th>
                      <th className="border border-gray-400 p-2">
                        TOTAL AMOUNT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
                      (drug, idx) => (
                        <tr key={idx}>
                          <td className="border border-gray-400 p-2">
                            {drug?.drugName}
                          </td>
                          <td className="border border-gray-400 p-2">
                            {drug?.quantity}
                          </td>
                          <td className="border border-gray-400 p-2">
                            ₹ {drug?.unitPrice}
                          </td>
                          <td className="border border-gray-400 p-2">
                            ₹ {drug?.discountAmount}
                          </td>
                          <td className="border border-gray-400 p-2">
                            ₹ {drug?.totalAmount}
                          </td>
                        </tr>
                      )
                    )}
                    {currSelectedOrder?.orderDetails[0]?.orderItems?.map(
                      (drug, idx) => (
                        <tr key={idx}>
                          <td className="border border-gray-400 p-2">
                            {drug?.drugName}
                          </td>
                          <td className="border border-gray-400 p-2">
                            {drug?.quantity}
                          </td>
                          <td className="border border-gray-400 p-2">
                            ₹ {drug?.unitPrice}
                          </td>
                          <td className="border border-gray-400 p-2">
                            ₹ {drug?.discountAmount}
                          </td>
                          <td className="border border-gray-400 p-2">
                            ₹ {drug?.totalAmount}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </Modal>
            <Modal
              title="Order Details"
              className="commonModal"
              centered="true"
              open={isDialogOpen}
              onCancel={() => setDialog(false)}
              footer={[]}
            >
              <div className="overflow-x-auto">
                <table
                  className="border-collapse border border-gray w-full h-full mx-auto "
                >
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-gray-400 p-2">ITEM NAME</th>
                      <th className="border border-gray-400 p-2">QTY</th>
                      <th className="border border-gray-400 p-2">UNIT PRICE</th>
                      {/* <th className="border border-gray-400 p-2">
                        UNIT DISCOUNTED PRICE
                      </th>
                      <th className="border border-gray-400 p-2">
                        TOTAL AMOUNT
                      </th> */}
                    </tr>
                  </thead>
                  {reorderData.map((item, i) => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            <td className="border border-gray-400 p-2">
                              {item.drugName}
                            </td>
                            <td className="border border-gray-400 p-2">
                              {item.quantity}
                            </td>
                            <td className="border border-gray-400 p-2">
                              ₹ {parseFloat(item.medicineRate).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
                </table>
              </div>
              <div>
                <button
                  disabled={addToCartLoading}
                  onClick={(e) => addtoCart(e, cartData)}
                  class="text-White text-sm font-semibold px-8 w-full mt-5 h-10 rounded-md bg-secondaryColor"
                >
                  Add to cart
                </button>
              </div>
            </Modal>
            <Modal
              title={
                <p className="font-bold text-primaryColor text-xl">
                  Prescription
                </p>
              }
              open={prescriptionDialog}
              onCancel={() => setPrescriptionDialog(false)}
              footer={[]}
              width="60%"
            >
              <div className="flex flex-col text-center justify-center">
                {prescriptionDoc?.length &&
                  prescriptionDoc?.map((res) => {
                    return (
                      <div className="w-auto">
                        {res.split(".")[1].toUpperCase() == "PDF" ? (
                          <iframe
                            style={{ width: "100%", height: "500px" }}
                            src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                            alt="profile image"
                          />
                        ) : (
                          <img
                            style={{ width: "100%", height: "500px" }}
                            src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                            alt="profile image"
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </Modal>
          </div>
  );
}

export default B2cOrder;
