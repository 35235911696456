import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import newLogo from "../../Assets/Images/newLogo.png";
import { jsPDF } from "jspdf";
// import rximg from "../Assets/Images/rx.png"
import { getPostConsultation } from "../../../Redux/Action/doctorAction";
import doctorService from "../../../Redux/Services/doctorService";
import { IMG_URL } from "../../../config/constant";

function EprescriptionsView(props) {
  const { postConsultation } = useSelector(
    (state) => state.doctorAppointmentList
  );
  const { patientLabTestsList, patientDrugPrescriptionList } =
    postConsultation ?? {};
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const search_query = new URLSearchParams(search).get("q");
  // const id =  new URLSearchParams(search).get('id');
  const dispatch = useDispatch();
  const [locationObj, setLocationObj] = useState(
    JSON.parse(localStorage.getItem("locationObj"))
  );
  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };
  const toast = useRef(null);
  const [labTestList, setLabTestList] = useState([]);
  const [stamp, setStamp] = useState([]);
  const [signature, setSignature] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currUser,setCurrUser] = useState(null);
  const [reading1RE, setReReading1] = useState();
  const [reading2RE, setReReading2] = useState();
  const [reading3RE, setReReading3] = useState();
  const [readingFinalRE, setReReadingFinal] = useState();
  const [reading1LE, setLeReading1] = useState();
  const [reading2LE, setLeReading2] = useState();
  const [reading3LE, setLeReading3] = useState();
  const [readingFinalLE, setLeReadingFinal] = useState();
  const [eyeWearUser, setEyeWearUser] = useState(false);
  const id = props.userID;

  useEffect(() => {
    dispatch(getPostConsultation(id || ""))
      .then((result) => {
        console.log(result);
        console.log(result[0].patientLabTestsList);
        setCurrUser(result[0])
        if (
          result[0].patientLabTestsList &&
          result[0].patientLabTestsList.length
        ) {
          const labData = result[0].patientLabTestsList;
          const unique = [
            ...new Set(labData.map((item) => item.labParentName)),
          ];
          console.log(unique);
          const labArray = [];
          for (let s = 0; s < unique.length; s++) {
            const labFilter = labData.filter(
              (x) => x.labParentName == unique[s]
            );
            const payload = {
              parent: unique[s],
              data: labFilter,
            };
            labArray.push(payload);
          }

          console.log(labArray);
          setLabTestList(labArray);
        }

        if (result[0].eyeReadingMain) {
          setEyeWearUser(true);
          const leftEyeData =
            result[0].eyeReadingMain && result[0].eyeReadingMain.leftEye;
          const rightEyeData =
            result[0].eyeReadingMain && result[0].eyeReadingMain.rightEye;
          setLeReadingFinal(leftEyeData.finalReading);
          setLeReading1(leftEyeData.readingList[0]);
          setLeReading2(leftEyeData.readingList[1]);
          setLeReading3(leftEyeData.readingList[2]);
  
          setReReadingFinal(rightEyeData.finalReading);
          setReReading1(rightEyeData.readingList[0]);
          setReReading2(rightEyeData.readingList[1]);
          setReReading3(rightEyeData.readingList[2]);
         
        }else{
          setEyeWearUser(false)
        }

        if (result.length == 0) {
          toast.current.show({
            severity: "info",
            summary: "Info Message",
            detail: "No Prescription Available",
            life: 3000,
          });
          //   setTimeout(() => {
          //     history.push(APP_ROUTES.DASHBOARD);
          //   }, 2000);
        } else {
          setLoading(true);
          //download();
        }
      })
      .catch((error) => {});
    return () => {};
  }, [id]);

  useEffect(() => {
    if (postConsultation?.patientName && search_query == "download") {
      setTimeout(() => {
        download();
      }, 2000);
    }
  }, [id, postConsultation?.patientName]);

  useEffect(() => {
    if (postConsultation?.patientName) {
      console.log(postConsultation);
      doctorService.getBase64Img(postConsultation?.userSignature).then(
        (res) => {
          console.log(res);
          if (res.data) {
            setSignature("data:image/jpg;base64," + res.data.fileData);
            console.log(signature);
          }
        },
        (err) => {
          console.log(err);
        }
      );

      doctorService.getBase64Img(postConsultation?.hospitalStamp).then(
        (res) => {
          console.log(res);
          if (res.data) {
            setStamp("data:image/jpg;base64," + res.data.fileData);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [postConsultation?.patientName]);

  const download = () => {
    if (
      postConsultation.patientDocumentList &&
      postConsultation.patientDocumentList[0].docName.split(".")[1] !=
        "string" &&
      postConsultation.patientDocumentList[0].docName
    ) {
      doctorService
        .imagetoData(postConsultation.patientDocumentList[0].docName)
        .then((res) => {
          var base64;
          if (postConsultation.patientDocumentList[0].documentType == "pdf") {
            base64 = "data:application/pdf;base64,";
          } else {
            base64 = "data:image/png;base64,";
          }
          //alert(JSON.stringify(res.data.fileData))
          var FileSaver = require("file-saver");
          FileSaver.saveAs(
            base64 + res.data.fileData,
            postConsultation.patientDocumentList[0].docName
          );
          // history.goBack();
        });
    } else {
      const input = document.getElementById("abc");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "A4",
        userUnit: "px",
      });
      pdf
        .html(input, {
          html2canvas: { scale: 0.5, allowTaint: false },
          margin: [1, 1, 1, 6],
          autoPaging: true,
        })
        .then(() => {
          const fileName =
            "e-Prescription-" + postConsultation?.patientName + ".pdf";
          pdf.save(fileName);
        });
    }
  };
  return (
    <>
      {/* breadcrumbs */}
      {postConsultation &&
      postConsultation?.patientDocumentList &&
      postConsultation?.patientDocumentList[0]?.docName.split(".")[1] !=
        "string" ? (
        <div>
          {postConsultation.patientDocumentList[0].documentType == "pdf" ? (
            <div style={{ height: "100vh" }}>
              <object
                data={IMG_URL + postConsultation.patientDocumentList[0].docName}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a
                    href={
                      IMG_URL + postConsultation.patientDocumentList[0].docName
                    }
                  >
                    to the PDF!
                  </a>
                </p>
              </object>{" "}
            </div>
          ) : (
            <img
              src={IMG_URL + postConsultation.patientDocumentList[0].docName}
              width="100%"
              height="100%"
              className="ml-auto mr-auto w-auto"
            />
          )}
        </div>
      ) : (
        <>
          <div id="abc" className="w-full">
            <div
              className="bg-lightBlue p-3 md:p-5 justify-center mb-2  border border-[#a5ceff] rounded-md"
              // style={{ width: "1330px" }}
            >
              <div className="px-2">
                <div className="bg-[#cee4ff] flex flex-col px-5 py-3 rounded-lg">
                  <p className="font-bold text-primaryColor text-base">
                    {" "}
                    {postConsultation?.userSalutation}{" "}
                    {postConsultation?.userName}
                  </p>
                  <p className="text-primaryColor">
                    {postConsultation?.userQualification}
                  </p>
                  <p className="text-primaryColor">
                    Reg No. :{" "}
                    <span>{postConsultation?.userMCIVerification}</span>
                  </p>
                </div>

                <div>
                  <div className="w-full mt-6 grid grid-cols-1 md:grid-cols-3 justify-around gap-1">
                    <p className="text-primaryColor">
                      <span className="font-bold">Name : </span>
                      <span className="text-primaryColor">
                        {postConsultation?.patientsalutation +
                          postConsultation?.patientName}
                      </span>
                    </p>
                    <p className="text-primaryColor">
                      <span className="font-bold">Age : </span>
                      <span className="text-primaryColor">
                        {postConsultation?.patientAge}
                      </span>
                    </p>
                    <p className="text-primaryColor">
                      <span className="font-bold">Hospital : </span>
                      <span className="text-primaryColor">
                        {postConsultation?.patientHospitalName}
                      </span>
                    </p>
                  </div>
                  <div className="w-full my-2 md:my-3 grid grid-cols-1 md:grid-cols-3 justify-around gap-1">
                    <p className="text-primaryColor">
                      <span className="font-bold">Phone : </span>
                      <span className="text-primaryColor">
                        {postConsultation?.patientMobileNo}
                      </span>
                    </p>
                    <p className="text-primaryColor">
                      <span className="font-bold">Visit Type : </span>
                      <span className="text-primaryColor">
                        {postConsultation?.consultationType === "Q"
                          ? "Quick Consultation"
                          : postConsultation?.consultationType === "V"
                          ? "Video Consultation"
                          : postConsultation?.consultationType === "I"
                          ? "In-person Consultation"
                          : postConsultation?.consultationType === "A"
                          ? "Audio Consultation"
                          : "Quick Consultation"}
                      </span>
                    </p>
                    <p className="text-primaryColor">
                      <span className="font-bold">Date : </span>
                      <span className="text-primaryColor">
                        {moment(postConsultation?.visitDate).format(
                          "DD/MM/yyyy"
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="bg-[#a5ceff] w-full h-[1px] my-3"></div>
                  <p className="my-3">
                    <span className="text-primaryColor underline font-bold">
                      Chief Complaint :{" "}
                    </span>
                    <span className="text-primaryColor pl-3">
                      {postConsultation?.consultReason}
                    </span>
                  </p>
                  <div className="my-3">
                    <p>
                      <span className="text-primaryColor underline font-bold">
                        Primary Diagonsis :{" "}
                      </span>
                    </p>
                    <p className="text-primaryColor pt-2">
                      ICD-10: {postConsultation?.primarySymptoms}{" "}
                    </p>
                    <p className="text-primaryColor">
                      Description : {postConsultation?.primaryDiagnosis}{" "}
                    </p>
                  </div>
                  {(postConsultation?.secondarySymptoms ||
                    postConsultation?.secondaryDiagnosis) && (
                    <div className="my-3">
                      <p>
                        <span className="text-primaryColor underline font-bold">
                          Secondary Diagonsis :{" "}
                        </span>
                      </p>
                      <p className="text-primaryColor pt-2">
                        ICD-10: {postConsultation?.secondarySymptoms}{" "}
                      </p>
                      <p className="text-primaryColor">
                        Description : {postConsultation?.secondaryDiagnosis}{" "}
                      </p>
                    </div>
                  )}
                  {postConsultation?.symptoms && (
                    <p className="my-3">
                      <span className="text-primaryColor underline font-bold">
                        Symptoms :{" "}
                      </span>
                      <span className="text-primaryColor pl-3">
                        {postConsultation?.symptoms}
                      </span>
                    </p>
                  )}
                   {currUser?.symptoms && (
                    <p className="my-3">
                      <span className="text-primaryColor underline font-bold">
                        Symptoms :{" "}
                      </span>
                      <span className="text-primaryColor pl-3">
                        {currUser?.symptoms}
                      </span>
                    </p>
                  )}
                    {currUser?.site ||
                  currUser?.onset ||
                  currUser?.radiation ||
                  currUser?.characteristics ||
                  currUser?.associatedSymptoms ||
                  currUser?.timing ||
                  currUser?.exacerbatingFactors ||
                  currUser?.relievingFactors ||
                  currUser?.treatmentTaken ||
                  // currUser.comments ||
                  currUser?.severity ? (
                  <p className="underline underline-offset-1">
                    <b className="underline underline-offset-1">
                      History of Presenting Illness:
                    </b>{" "}
                  </p>
                ) : null}
                   <div className="grid grid-cols-2 gap-5">
                  {currUser?.site && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Site: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.site}
                      </p>
                    </div>
                  )}
                  {currUser?.onset && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Onset: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.onset}
                      </p>
                    </div>
                  )}

                  {currUser?.characteristics && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Character:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.characteristics}
                      </p>
                    </div>
                  )}
                  {currUser?.radiation && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Radiation:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.radiation}
                      </p>
                    </div>
                  )}

                  {currUser?.associatedSymptoms && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Associated Symptoms:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.associatedSymptoms}
                      </p>
                    </div>
                  )}

                  {currUser?.timing && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Timing: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.timing}
                      </p>
                    </div>
                  )}
                  {currUser?.exacerbatingFactors && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Exacerbating Factors:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.exacerbatingFactors}
                      </p>
                    </div>
                  )}
                  {currUser?.relievingFactors && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Relieving Factors:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.relievingFactors}
                      </p>
                    </div>
                  )}
                  {currUser?.severity && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Severity: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.severity}
                      </p>
                    </div>
                  )}
                  {currUser?.treatmentTaken && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Any Treatment Taken? :{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.treatmentTaken}
                      </p>
                    </div>
                  )}
                  {currUser?.comments && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Comments: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.comments}
                      </p>
                    </div>
                  )}
                </div>
                  <div className="my-3 overflow-x-scroll scrollWidth">
                    <p className="my-3">
                      <span className="text-primaryColor underline font-bold">
                        Medicines Prescribed :{" "}
                      </span>
                    </p>
                    <div className="overflow-x-auto">
                      <table className="border-gray border w-full text-left my-3 w-full">
                        <thead className="text-primaryColor">
                          <tr>
                            <th className="w-[50%] px-3 py-2">Medication</th>
                            <th className="px-3 py-2">Dosage</th>
                            <th className="px-3 py-2">Qty</th>
                            <th className="px-3 py-2">Instruction</th>
                            <th className="px-3 py-2">Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {postConsultation?.patientDrugPrescriptionList?.map(
                            (medicine, idx) => (
                              <tr className="w-full border-gray border text-primaryColor">
                                {/* <td className="">{medicine.medicine_id}</td> */}
                                <td className="px-3 py-2">
                                  <div>
                                    <p>{medicine.drugName}</p>
                                    <p>{medicine.composition}</p>
                                  </div>
                                </td>
                                <td className="px-3 py-2">{medicine.dosage}</td>
                                <td className="px-3 py-2">{medicine.quantity}</td>
                                <td className="px-3 py-2">{medicine.duration}</td>
                                <td className="px-3 py-2">
                                  {medicine.instruction === "0"
                                    ? "After Meal"
                                    : "Before Meal"}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-2  mb-5">
                    {postConsultation?.patientLabTestsList?.length && (
                      <p className="mb-3">
                       <span className="text-primaryColor underline font-bold">
                          Lab Test Prescribed :
                        </span>
                      </p>
                    )}
                    {postConsultation?.patientLabTestsList?.length && (
                      <div className="flex items-center">
                        <div className="">
                          <div className="text-primaryColor font-bold">
                            Test Name:
                          </div>
                        </div>
                        {postConsultation?.patientLabTestsList?.map(
                          (labres, i) => (
                            <div className=" " key={i}>
                              <div className="text-sm  text-primaryColor ml-2">
                                {labres?.labTestName}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  {eyeWearUser && (
                 <>
                 <div className="border border-gray-200 my-3 rounded-lg p-5 mt-10 hidden sm:block">
                   <div className="flex">
                     <div className="newtable-list mb-4 pl-4">
                       <ul className="font-bold text-base">
                         <li className="" >Rd1</li>
                         <li  className="">Rd2</li>
                         <li  className="">Rd3</li>
                         <li  className="">Final</li>
                       </ul>
                     </div>
                     <div className="">
                       <div
                         className="w-full rounded-lg flex justify-evenly items-center p-3"
                         style={{ background: "#EFFAFF" }}
                       >
                         <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                           Right Eye
                         </h6>
                         <div
                           className="border-l h-6"
                           style={{
                             borderColor: "rgba(0, 93, 141, 0.34)",
                           }}
                         ></div>
                         <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                           Left Eye
                         </h6>
                       </div>
                       <div className="grid grid-cols-2 gap-8 mt-5">
                         <div className="border border-gray-200  rounded-lg pb-3">
                           <table className="newtable">
                             <tr>
                               <th>Sph</th>
                               <th>Cyl</th>
                               <th>Axis</th>
                               <th>Add Power</th>
                               <th>VA</th>
                             </tr>

                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading1RE && reading1RE.sph
                                       ? reading1RE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading1RE && reading1RE.cyl
                                       ? reading1RE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading1RE && reading1RE.axis
                                       ? reading1RE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading1RE && reading1RE.addPower
                                       ? reading1RE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading1RE && reading1RE.vA
                                       ? reading1RE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading2RE && reading2RE.sph
                                       ? reading2RE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading2RE && reading2RE.cyl
                                       ? reading2RE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading2RE && reading2RE.axis
                                       ? reading2RE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading2RE && reading2RE.addPower
                                       ? reading2RE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading2RE && reading2RE.vA
                                       ? reading2RE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading3RE && reading3RE.sph
                                       ? reading3RE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading3RE && reading3RE.cyl
                                       ? reading3RE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading3RE && reading3RE.axis
                                       ? reading3RE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading3RE && reading3RE.addPower
                                       ? reading3RE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading3RE && reading3RE.vA
                                       ? reading3RE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     readingFinalRE && readingFinalRE.sph
                                       ? readingFinalRE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     readingFinalRE && readingFinalRE.cyl
                                       ? readingFinalRE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     readingFinalRE && readingFinalRE.axis
                                       ? readingFinalRE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     readingFinalRE && readingFinalRE.addPower
                                       ? readingFinalRE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     readingFinalRE && readingFinalRE.vA
                                       ? readingFinalRE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                           </table>
                         </div>
                         <div className="border border-gray-200  rounded-lg pb-3">
                           <table className="newtable">
                             <tr>
                               <th>Sph</th>
                               <th>Cyl</th>
                               <th>Axis</th>
                               <th>Add Power</th>
                               <th>VA</th>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading1LE && reading1LE.sph
                                       ? reading1LE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading1LE && reading1LE.cyl
                                       ? reading1LE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading1LE && reading1LE.axis
                                       ? reading1LE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading1LE && reading1LE.addPower
                                       ? reading1LE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading1LE && reading1LE.vA
                                       ? reading1LE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading2LE && reading2LE.sph
                                       ? reading2LE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading2LE && reading2LE.cyl
                                       ? reading2LE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading2LE && reading2LE.axis
                                       ? reading2LE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading2LE && reading2LE.addPower
                                       ? reading2LE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading2LE && reading2LE.vA
                                       ? reading2LE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading3LE && reading3LE.sph
                                       ? reading3LE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading3LE && reading3LE.cyl
                                       ? reading3LE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading3LE && reading3LE.axis
                                       ? reading3LE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading3LE && reading3LE.addPower
                                       ? reading3LE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading3LE && reading3LE.vA
                                       ? reading3LE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     readingFinalLE && readingFinalLE.sph
                                       ? readingFinalLE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     readingFinalLE && readingFinalLE.cyl
                                       ? readingFinalLE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     readingFinalLE && readingFinalLE.axis
                                       ? readingFinalLE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     readingFinalLE && readingFinalLE.addPower
                                       ? readingFinalLE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     readingFinalLE && readingFinalLE.vA
                                       ? readingFinalLE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                           </table>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>

                 <div className="border border-gray-200 my-3 rounded-lg p-5 px-1 mt-10 block sm:hidden">
                   {/* <div className="flex"> */}
                     <div className="newtable-list mb-4 pl-4">
                      
                     </div>
                     <div className="">
                       <div className="grid grid-cols-1 gap-8 mt-5">
                         <div>
                         <div
                         className="w-full rounded-lg flex justify-evenly items-center p-3"
                         style={{ background: "#EFFAFF" }}
                       >
                         <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                           Right Eye
                         </h6> </div>
                         <div className="flex" >
                           <ul className="font-bold text-base mt-12 mr-1">
                         <li className="text-sm mb-2 font-light" >Rd1</li>
                         <li  className="text-sm mb-2 font-light">Rd2</li>
                         <li  className="text-sm mb-2 font-light">Rd3</li>
                         <li  className="text-sm font-light">Final</li>
                       </ul>
                         <div className="border border-gray-200  rounded-lg pb-3">
                           <table className="newtable">
                             <tr>
                               <th>Sph</th>
                               <th>Cyl</th>
                               <th>Axis</th>
                               <th>Add Power</th>
                               <th>VA</th>
                             </tr>

                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading1RE && reading1RE.sph
                                       ? reading1RE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading1RE && reading1RE.cyl
                                       ? reading1RE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading1RE && reading1RE.axis
                                       ? reading1RE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading1RE && reading1RE.addPower
                                       ? reading1RE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading1RE && reading1RE.vA
                                       ? reading1RE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading2RE && reading2RE.sph
                                       ? reading2RE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading2RE && reading2RE.cyl
                                       ? reading2RE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading2RE && reading2RE.axis
                                       ? reading2RE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading2RE && reading2RE.addPower
                                       ? reading2RE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading2RE && reading2RE.vA
                                       ? reading2RE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading3RE && reading3RE.sph
                                       ? reading3RE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading3RE && reading3RE.cyl
                                       ? reading3RE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading3RE && reading3RE.axis
                                       ? reading3RE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading3RE && reading3RE.addPower
                                       ? reading3RE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading3RE && reading3RE.vA
                                       ? reading3RE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     readingFinalRE && readingFinalRE.sph
                                       ? readingFinalRE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     readingFinalRE && readingFinalRE.cyl
                                       ? readingFinalRE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     readingFinalRE && readingFinalRE.axis
                                       ? readingFinalRE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     readingFinalRE && readingFinalRE.addPower
                                       ? readingFinalRE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     readingFinalRE && readingFinalRE.vA
                                       ? readingFinalRE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                           </table>
                         </div>
                         </div>
                         </div>
                         <div>
                         <div
                         className="w-full rounded-lg flex justify-evenly items-center p-3"
                         style={{ background: "#EFFAFF" }}
                       >
                         <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                           Left Eye
                         </h6> </div>
                         <div className="flex">
                           <ul className="font-bold text-base mt-12 mr-1">
                         <li className="text-sm mb-2 font-light" >Rd1</li>
                         <li  className="text-sm mb-2 font-light">Rd2</li>
                         <li  className="text-sm mb-2 font-light">Rd3</li>
                         <li  className="text-sm font-light">Final</li>
                       </ul>
                         <div className="border border-gray-200  rounded-lg pb-3">
                           <table className="newtable">
                             <tr>
                               <th>Sph</th>
                               <th>Cyl</th>
                               <th>Axis</th>
                               <th>Add Power</th>
                               <th>VA</th>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading1LE && reading1LE.sph
                                       ? reading1LE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading1LE && reading1LE.cyl
                                       ? reading1LE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading1LE && reading1LE.axis
                                       ? reading1LE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading1LE && reading1LE.addPower
                                       ? reading1LE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading1LE && reading1LE.vA
                                       ? reading1LE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading2LE && reading2LE.sph
                                       ? reading2LE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading2LE && reading2LE.cyl
                                       ? reading2LE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading2LE && reading2LE.axis
                                       ? reading2LE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading2LE && reading2LE.addPower
                                       ? reading2LE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading2LE && reading2LE.vA
                                       ? reading2LE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     reading3LE && reading3LE.sph
                                       ? reading3LE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     reading3LE && reading3LE.cyl
                                       ? reading3LE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     reading3LE && reading3LE.axis
                                       ? reading3LE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     reading3LE && reading3LE.addPower
                                       ? reading3LE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     reading3LE && reading3LE.vA
                                       ? reading3LE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                             <tr>
                               <td>
                                 <input
                                   name="sph"
                                   id="sph"
                                   value={
                                     readingFinalLE && readingFinalLE.sph
                                       ? readingFinalLE.sph
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="cyl"
                                   id="cyl"
                                   value={
                                     readingFinalLE && readingFinalLE.cyl
                                       ? readingFinalLE.cyl
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="axis"
                                   id="axis"
                                   value={
                                     readingFinalLE && readingFinalLE.axis
                                       ? readingFinalLE.axis
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="addPower"
                                   id="addPower"
                                   value={
                                     readingFinalLE && readingFinalLE.addPower
                                       ? readingFinalLE.addPower
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   name="vA"
                                   id="vA"
                                   value={
                                     readingFinalLE && readingFinalLE.vA
                                       ? readingFinalLE.vA
                                       : ""
                                   }
                                   editable={false}
                                   type="text"
                                 />
                               </td>
                             </tr>
                           </table>
                         </div>
                         </div>
                         </div>

                       </div>
                     </div>
                   {/* </div> */}
                 </div>

                 </>
                )}

                <div className="grid grid-cols-2 gap-5">
                  {currUser?.frame && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Frame: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.frame}
                      </p>
                    </div>
                  )}
                  {currUser?.coating && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">Coating: </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.coating}
                      </p>
                    </div>
                  )}
                  {currUser?.nvAssessment && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        NV Assessment:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.nvAssessment}
                      </p>
                    </div>
                  )}
                  {currUser?.dvAssessment && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        DV Assessment:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.dvAssessment}
                      </p>
                    </div>
                  )}
                  {currUser?.treatmentOption && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Treatment Option:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.treatmentOption}
                      </p>
                    </div>
                  )}
                  {currUser?.surgeryRecommendation && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        Any Surgery Recommendation?:{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {currUser?.surgeryRecommendation}
                      </p>
                    </div>
                  )}
                </div>
                  {postConsultation?.recommendation && (
                    <div className="mb-5">
                      <p className="underline underline-offset-1">
                        <b className="underline underline-offset-1">
                          Recommendation :
                        </b>{" "}
                      </p>
                      <p className="text-base font-montserrat  font-thin ">
                        {postConsultation?.recommendation}
                      </p>
                    </div>
                  )}
                  {postConsultation?.followUpVisitDate && (
                    <div>
                      <p className=" my-2">
                        <b className="underline underline-offset-1">
                          Scheduled follow up visit date :
                        </b>{" "}
                        {moment(postConsultation?.followUpVisitDate).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </div>
                  )}
                  
              <div className="flex  justify-between">
                <div className="mt-6">
                  {postConsultation?.hospitalStamp &&
                    <img
                      onClick={download}
                      src={process.env.REACT_APP_IMG_BASEURL+postConsultation?.hospitalStamp}
                      alt="Stamp"
                      width={120}
                    />
                  }
                </div>

                <div className="mt-6 mb-1">
                  {signature && (
                    <>
                      <img
                        onClick={download}
                        src={signature}
                        alt="Signature"
                        width={120}
                      />
                      <p className=" text-primaryColor text-base">
                        Dr.{postConsultation?.userName}
                      </p>
                      <p className=" text-primaryColor text-base">
                        {postConsultation?.userQualification}{" "}
                        {postConsultation?.userSpecialityDept}
                      </p>
                      <p className=" text-primaryColor text-base">
                        Regn No. {postConsultation?.userMCIVerification}
                      </p>
                    </>
                  )}
                </div>
              </div>

                  <div className="grid grid-cols-1 border bg-[#fcf7f7] border-[#ffaeae] rounded-lg">
                    <div className="p-3">
                      {postConsultation?.consultationType === "I" ? (
                        <span> </span>
                      ) : (
                        <p className="text-textGray text-sm ">
                          <b>Disclaimer :</b>
                          <ul className="list-disc ml-8 mt-2">
                            <li>
                              The information and advice provided here is
                              provisional in nature as it is based on the
                              limited information made available by the patient
                            </li>
                            <li>
                              The patient is advised to visit in person for
                              thorough examination at the earliest
                            </li>
                            <li>
                              The information is confidential in nature and for
                              recipient's use only
                            </li>
                            <li>
                              The Prescription is generated on a
                              Teleconsultation
                            </li>
                            <li>Not valid for medico - legal purpose</li>{" "}
                          </ul>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mb-5 w-full">
            <button
              onClick={() => download()}
              className="font-medium text-rubik text-White border bg-primaryColor border-brand-secondary mt-4 rounded-md text-sm px-2 py-2"
            >
              Download E- prescription
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default EprescriptionsView;
