import React, { useEffect, useRef, useState, startTransition } from "react";
import Layouts from "../../Layouts";
import ePrescription from "../../../Assets/Images/Icons/action1.svg";
import savedIcon from "../../../Assets/Images/Icons/action2.svg";
import repeatIcon from "../../../Assets/Images/Icons/action3.svg";
import Delivery from "../../../Assets/Images/Icons/Delivery.svg";
import Truck from "../../../Assets/Images/Icons/truck.svg";
import Price from "../../../Assets/Images/Icons/Price.svg";
import Location from "../../../Assets/Images/Icons/Location.svg";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import axios from "axios";
import Bottles from "../../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../../Assets/pharamacyImages/Satchels.png";
import Tablet from "../../../Assets/pharamacyImages/Tablets.png";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import CalenderSvg from "../../../Assets/Images/Calender.svg";
import ContactList from "../../../Assets/Images/contactList.svg";
import Doctor from "../../../Assets/Images/Doctor.svg";
import LocateIcon from "../../../Assets/Images/locate.svg";
import Drug from "../../../Assets/Images/Drug.svg";
import prescriptionIcon from "../../../Assets/Images/PrescriptionIcon.svg";
import prescriptionNote from "../../../Assets/Images/PrescriptionNote.svg";
import DoctorPrescription from "../../../Assets/Images/DoctorPrescription.jpg";
import { FaFileUpload } from "react-icons/fa";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import http from "../../../Redux/Services/http-common";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Search from "antd/es/input/Search";
import { setAddressString, setLatLong } from "../../../Redux/Action/userActions";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import { Drawer, Modal, Segmented, Upload } from "antd";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CartDocImg from "../../../Assets/Images/CarDocImg.svg";
import moment from "moment";
import { AddtoCart, getCartDetails } from "../../../Redux/Action/cartPlaceOrderAction";
import SideBar from "./UploadPrescription";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { debounce } from "lodash";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import Loader from "../../Shared/loader";
import { EnglishText } from '../../PageRouting/EnglishText';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Medicine() {
  const [isAdding, setIsAdding] = useState(-1);
  const locationDropdownRef = useRef(null);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [topratedMedicine, setTopratedMedicine] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Categories");
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const [medicineSearched, setmedicineSearched] = useState([]);
  const [choosetoCart, setchoosetoCart] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [screenPos, setScreenPos] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [prescriptionType, setPrescriptionType] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [value, setValue] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [newPinCode, setPinCode] = useState("");
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    isMMILoading: false,
    locationError: "",
  });
  const [loading,setLoading]=useState(false)

  const ToggleSideBar = () => {
    if (userData?.id) {
      // (e) => redirectTo(e, APP_ROUTES.UPLOADED_PRESCRIPTION)
      setIsSidebarOpen(!isSidebarOpen);
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
  };
  console.log(moment().format('YYYY-MM-DD HH:mm:ss'),"currentdate")

  const handleUploadPrescriptionClick = async (file, radiobutton) => {
    let data = {};
    for (let i = 0; i < file.length; i++) {
      data = {
        patientCode: userData.code,
        document: file[i].b64File,
        documentType: file[i].type,
        status: 1,
        createdBy: userData.code,
        modifiedBy: userData.code,
        conversionNeeded: radiobutton[i] == "original" ? false : true,
        documentReceivedSource: "B2C",
        givenDate:moment().format('YYYY-MM-DD HH:mm:ss')
      };
      await http.post(`${process.env.REACT_APP_BASEURL}PatientDocument/`, data);
    }
  };

  const onChange = (item) => {
    setValue(item);
  };

  const handleModal = () => {
    setIsModalOpen(false);
  };
  const { cartList } = useSelector((state) => state.cartReducer);
  console.log(cartList);

  useEffect(() => { }, []);

  const handleClick = (action) => {
    if (action === "bookAnAppointment") {
      if (Object.keys(userData).length === 0) {
        history.push("/");
      } else {
        history.push("/services/book-an-appointment");
      }
    } else if (action === "uploadPrescription") {
      if (Object.keys(userData).length === 0) {
        history.push("/");
      } else {
        // setIsDrawerOpen(true);
        setIsSidebarOpen(true);
      }
    } else if (action === "viewSamplePrescription") {
      if (Object.keys(userData).length === 0) {
        history.push("/");
      } else {
        setIsModalOpen(true);
      }
    }
  };

  const PrescriptionModal = () => {
    return (
      <Modal
        centered
        width={"30%"}
        open={isModalOpen}
        onOk={handleModal}
        onCancel={handleModal}
        footer={[]}
      >
        <div className="flex justify-center items-center">
          <img src={DoctorPrescription} alt="prescription" />
        </div>
      </Modal>
    );
  };

  const handleScroll = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    handleScroll();
  }, []);

  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  const handleChange = (e) => {
    setPrescriptionType(e.target.value);
  };

  const handleNavigation = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
  };
  const resolvePharamcyImageq = (Image) => {
    let PharamncyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharamncyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharamncyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharamncyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharamncyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharamncyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharamncyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharamncyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharamncyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharamncyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharamncyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharamncyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharamncyImage = Tablet;
    } else {
      PharamncyImage = defaultMed;
    }
    // console.log(PharamncyImage);
    return PharamncyImage;
  };

  const debouncedSearch = debounce(async (currSearch) => {
    // Check if the search term length is at least 3 characters
    if (currSearch.length >= 3 && currSearch !== lastSearch) {
      setLastSearch(currSearch);
      const res = await axios.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${currSearch}&pageNo=1&pageSize=20`
      );
      console.log(res.data);
      setmedicineSearched(res.data);
      setIsOpen(true);
    }
  }, 500);

  const handleSearchKeyPress = async (e) => {
    console.log(e);
    // if(event.target.value.length > 2){
    // let res3 = await axios.get(
    //   `https://patient.curebay.com/es/api/v1/medicine?freeTextSearch=${event.target.value}&pageNo=1&pageSize=20`
    // );
    // console.log(res3);
    // setmedicineSearched(res3.data);
    // setIsOpen(true);}
    const currSearchTerm = e.target.value;
    setSearchTerm(currSearchTerm);
    if (currSearchTerm.length >= 3) {
      debouncedSearch(currSearchTerm);
    } else if (currSearchTerm.length === 0) {
      setSearchTerm("");
      setLastSearch("");
      setIsOpen(false);
    }
  };
  async function gettopMedicineData(medicineCode, type) {
    var raw = JSON.stringify(medicineCode);

    dispatch(getAllProductByIds(raw))
      .then((res) => {
        // if (type == "recentMedicine") {
        setTopratedMedicine(res);
        console.log(res, "result");
        // } else if (type == "orderMedicines") {
        //   setRecentlyOrderMedicine(res);
        // }
      })
      .catch((err) => { });
  }
  const seletedSearch = (event) => {
    console.log(event);
    let x = [];
    x.push(event);
    setmedicineSearched(x);
    window.scrollTo({
      top: 480,
      behavior: "smooth",
    });
    setIsOpen(false);
  };
  const addTocart = (item) => {
    
    // history.push('/medicine');
    history.push(`/medicine/${item.seoNameId}`, { medicine: item });
    history.push(`/medicine/${item.seoNameId}`, { medicine: item });
  };
  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder) {
        for (const product of cartList?.patientMedicineOrder) {
          if (product && product?.patientMedicineOrderDetailsList) {
            for (const drug of product?.patientMedicineOrderDetailsList) {
              ids.push(drug?.drugCode);
            }
          }
        }
      }
    }
    return ids;
  };
  const drugIDsInCart = getProductsIDs();

  const handleImage = (images, type) => {
    console.log(type);
    if (typeof images === "string") {
      // const imagesArr = JSON.parse(images);
      // console.log(imagesArr);
      // return imagesArr[0];
      let result = images.replace(/[[\]]/g, "");
      let medicineImage = result.split(",");
      console.log(medicineImage);
      if (medicineImage.length !== 0) {
        if (medicineImage.length === 1 && medicineImage[0] === "") {
          return resolvePharamcyImageq(type);
        } else {
          return medicineImage[0];
        }
      } else {
        console.log(images);
        console.log("hi from inside else");
        return resolvePharamcyImageq(type);
      }
    } else {
      console.log("hi from else");
      console.log(resolvePharamcyImageq(type));
      return resolvePharamcyImageq(type);
    }
  };
  const addTocart2 = (e, data, index, quantity = 1) => {
    if (Object.keys(userData).length === 0) {
      history.push(APP_ROUTES.HOME);
    } else {
      // e.preventDefault();
      setIsAdding(index);

      let prescriptionRequired = "N";
      let ePrescriptionRequired = "N";

      if (data?.prescriptionRequired == "No") {
        prescriptionRequired = "N";
        // ePrescriptionRequired = "N";
      } else if (data?.prescriptionRequired == "Yes") {
        prescriptionRequired = "Y";
        // ePrescriptionRequired = "Y";
      }
      let calculatedAmount = data?.drugsInfoDiscountedRate
        ? data?.drugsInfoDiscountedRate
        : data.medicineRate;
      const totalAmount = calculatedAmount * quantity;
      // data.discountPrice !== 0
      //   ? (data.unitPrice - data.discountPrice) * quantity
      //   :
      let dataObj = {};

      let isAdded = false;

      if (cartList && cartList.patientMedicineOrder) {
        cartList.totalAmount = cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount);
        cartList.patientMedicineOrder.forEach((element) => {
          element.patientMedicineOrderDetailsList.push({
            drugCode: data.id,
            drugName: data.medicineName,
            drugCategory: data.drugCategory,
            unitPrice: data.medicineRate,
            discountAmount: 0.0,
            totalAmount: totalAmount,
            quantity: quantity,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            prescriptionRequired: prescriptionRequired,
            ePrescriptionRequired: ePrescriptionRequired,
            medicineTypeOfSell: data?.medicineTypeOfSell,
            membershipCode: null,
            membershipName: null,
            membershipCard: null,
            membershipDiscountPercentage: 0.0,
          });
        });
        dataObj = cartList;
      } else {
        dataObj = {
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          status: 1,
          labOrdersYN: false,
          drugsOrdersYN: true,
          totalAmount: cartList?.totalAmount
            ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
            : parseFloat(totalAmount),
          patientMedicineOrder: [
            {
              orderId: "",
              patientId: userData.code,
              prescriptionId: null,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              txnId: "",
              totalAmount: totalAmount,
              address1: null,
              address2: null,
              address3: null,
              city: null,
              state: null,
              country: null,
              pincode: null,
              deliveryAddress1: null,
              deliveryAddress2: null,
              deliveryAddress3: null,
              deliveryCity: null,
              deliveryState: null,
              deliveryCountry: null,
              deliveryZipcode: null,
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              status: 1,
              procedureStatus: -1,
              payMode: "E",
              collectionStatus: null,
              paymentLinkForPatient: "N",
              discountCouponCode: null,
              patientName: userData.firstName,
              patientGender: userData?.gender,
              patientMobile: userData?.mobile,
              patientMedicineOrderDetailsList: [
                {
                  drugCode: data.id,
                  drugName: data.medicineName,
                  drugCategory: data.drugCategory,
                  unitPrice: data.medicineRate,
                  discountAmount: 0.0,
                  totalAmount: totalAmount,
                  quantity: quantity,
                  cartId: cartList && cartList.id ? cartList.cartId : "",
                  createdBy: userData.code,
                  createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  modifiedBy: userData.code,
                  modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  orderId: null,
                  patientId: userData.code,
                  prescriptionRequired: prescriptionRequired,
                  ePrescriptionRequired: ePrescriptionRequired,
                  medicineTypeOfSell: data?.medicineTypeOfSell,
                  membershipCode: null,
                  membershipName: null,
                  membershipCard: null,
                  membershipDiscountPercentage: 0.0,
                },
              ],
            },
          ],
        };
      }
      setLoading(true)

      dispatch(AddtoCart(dataObj)).then((res) => {
        // window.dataLayer.push({ ecommerce: null });
        // window.dataLayer.push({
        //   event: "add_to_cart",
        //   ecommerce: {
        //     value: data.medicineRate,
        //     items: [data]
        //   }
        // })
        setIsAdding(-1);
      });
      dispatch(getCartDetails(userData.code));
      setLoading(false)

    }
  };

 
  // const addTocart2 = (e, data, index, quantity = 1) => {
  //   if (Object.keys(userData).length === 0) {
  //         history.push(APP_ROUTES.HOME);
  //       } else{
  //         toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
  //       }

  // }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter keypress here
      setIsOpen(false);
      window.scrollTo({
        top: 480,
        behavior: "smooth",
      });
      // You can perform any action you want here, such as submitting a form, fetching data, etc.
    }
  };

  const onSearch = () => {
    setIsOpen(false);
    window.scrollTo({
      top: 480,
      behavior: "smooth",
    });
  };


  const Tablets = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    http.get(`${process.env.REACT_APP_BASEURL}Drug/commonDrug`).then((res) => {
      gettopMedicineData(res.data.data, "orderMedicines");
    });
  }, []);
  const [showTopSearch, setShowTopSearch] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const homeBanner = document.querySelector(".home-banner");
      const topSearch = document.querySelector(".topsearch");

      if (homeBanner.getBoundingClientRect().top <= 0) {
        setShowTopSearch(true);
      } else {
        setShowTopSearch(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLocationClick = () => {
    setOpen((prev) => !prev);
  };

  function showPosition(position) {
    const { coords } = position;
    var coordsObj = {
      lat: coords.latitude,
      long: coords.longitude,
    };
    dispatch(setLatLong(coordsObj));
    var apiUrl = "MMI/accesstoken";
    http.get(apiUrl).then((response) => {
      let data = response.data;
      // const res = await http.get(
      //   `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
      // );
      axios
        .get(
          `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
        )
        .then((res) => {
          if (!res.data.results[0].pincode) {
            sessionStorage.setItem("customPinCode", "751009");
            // dispatch(
            //   actioncustomPinCode({
            //     pincode: "751009",
            //     location: "current",
            //   })
            // )
            setError({
              isMMILoading: false,
              locationError: "Not able to detect your location.",
            });
            setOpen(false);
          } else {
            sessionStorage.setItem(
              "customPinCode",
              res.data.results[0].pincode
            );
            // dispatch(
            //   actioncustomPinCode({
            //     pincode: res.data.results[0].pincode,
            //     location: "current",
            //   })
            // )
            setError({
              isMMILoading: false,
            });
            setOpen(false);
          }
        })
        .catch((err) => {
          setError({
            locationError: "Error occured in MMI",
            isMMILoading: false,
          });
        });
      // let addressString = res.data.results[0]?.city;
      // dispatch(setAddressString(addressString));
    });
    secureStorage.setItem("coords", coordsObj);
    // setMapOpen(!mapOpen);
  }

  function getLocation() {
    setError({
      isMMILoading: true,
      locationError: "",
    });
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(showPosition);
        } else {
        }
      } else if (result.state === "prompt") {
        // showButtonToEnableMap();
      } else if (result.state === "denied") {
        setError({
          locationError: "Please allow browser to detect location",
          isMMILoading: false,
        });
      }
      // Don't do anything if the permission was denied.
    });
  }
  const handleSavedAddressClick = () => {
    setError({
      locationError: "",
    });

    if (patientinfoData?.pinCode) {
      let address1 = patientinfoData?.address1;
      let address2 = patientinfoData?.address2;
      let city = patientinfoData?.city;
      let pinCode = patientinfoData?.pinCode;
      const pincodePackage = require("pincode-lat-long");
      let coords = pincodePackage.getlatlong(pinCode);
      // console.log(coords ,"dvjsdbvsbovdf")
      if (coords == undefined) {
        setError({
          locationError: "Please use valid pincode",
        });
        return;
      }
      let coordsObj = {
        lat: coords.lat,
        long: coords.long,
      };
      dispatch(setLatLong(coordsObj));
      dispatch(setAddressString(city));
      sessionStorage.setItem("customPinCode", pinCode);
      // dispatch(
      //   actioncustomPinCode({ pincode: pinCode, location: "profileaddress" })
      // );
      setOpen(false);

      // setLocation();
    }
  };

  const handlePinCodeChange = (e) => {
    const re = /^[0-9]*$/; //rules
    if (re.test(e.target.value)) {
      e.target.value.length <= 6 && setPinCode(e.target.value);
    }

    if (e.target.value.length == 0) {
      setError("");
      return;
    }

    if (e.target.value.length == 6) {
      const pincode = require("pincode-lat-long");
      let coords = pincode.getlatlong(e.target.value);
      if (coords == undefined) {
        setError({
          locationError: "Please add valid pincode",
        });
        return;
      }
      setOpen(false);
      setError({
        locationError: "",
      });
      let coordsObj = {
        lat: coords.lat,
        long: coords.long,
      };
      dispatch(setLatLong(coordsObj));
      sessionStorage.setItem("customPinCode", e.target.value);
    }
  };

  const LocationSearch = (
    <div className="">
      <button className="no" onClick={handleLocationClick}>
        <div className=" flex text-left relative items-center top-0.5 ">
          <LocationMarkerIcon
            style={{
              height: "15px",
              color: "#5dbb63",
              marginRight: "10px",
            }}
          />
          <p className="text-sm  font-semibold text-black-700">
            {sessionStorage.getItem("customPinCode")}
          </p>
        </div>
      </button>
      {open ? (
        <div
          ref={locationDropdownRef}
          style={{
            minHeight: "fit-content",
            zIndex: 10,
            boxShadow: "0 6px 12px -4px rgb(11 18 25 / 20%)",
            borderRadius: "5px",
          }}
          className="block md:w-[400px]  absolute bg-White top-14 bottom-0 left-0 p-5"
        >
          <div className="p-2">
            <div className="text-left">
              <h1 className="text-primaryColor text-base font-medium">
                Where do you want the delivery?
              </h1>
              <span className="text-textGray text-sm font-light">
                Get access to your Addresses and Orders
              </span>
              <div
                className={`${patientinfoData?.address1 === null ||
                  patientinfoData?.address2 === null ||
                  patientinfoData?.address1 === undefined ||
                  patientinfoData?.address2 === undefined ||
                  patientinfoData?.city === undefined ||
                  patientinfoData?.pinCode === undefined
                  ? "hidden"
                  : "block"
                  }`}
              >
                <div
                  className="cursor-pointer  w-full p-4 mt-2 bg-lightBlue border border-[#bee0ff] rounded  mb-1"
                  onClick={handleSavedAddressClick}
                >
                  <p className="text-primaryColor  text-sm font-normal ">
                    {patientinfoData?.address1 +
                      ", " +
                      patientinfoData?.address2 +
                      ", " +
                      patientinfoData?.city +
                      ", " +
                      patientinfoData?.pinCode}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-1 py-1">
            <div className="text-left ">
              <h1 className="text-primaryColor text-base font-medium">
                Enter Pincode
              </h1>
              <span className="text-textGray text-sm font-light block">
                Select pincode to see product availability.
              </span>
              <input
                className="border border-gray h-[50px] text-sm w-full py-1 pl-2 my-3 rounded"
                onChange={handlePinCodeChange}
                placeholder="Enter Pincode"
                value={newPinCode}
              />
              <div
                className="flex gap-1 items-center cursor-pointer content-center bg-lightGreen"
                onClick={getLocation}
              >
                <img
                  src={LocateIcon}
                  alt=""
                  draggable={false}
                  className="h-8 w-8"
                />
                <p className="text-secondaryColor  flex items-center text-base font-medium">
                  Detect my location{" "}
                  {error.isMMILoading && (
                    <div className="loader ml-2 float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                  )}
                </p>
              </div>
              {error.locationError && (
                <div className="text-xs font-semibold text-red-500">
                  {" "}
                  {error.locationError}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
    {loading && <Loader/> }
    <ToastContainer />
    <Helmet> 
   <title>{"Medicines | Curebay"}</title>
<meta name='description' content={EnglishText.PHARMACY_META_DESCRIPTION} />
<meta name='title' content={'Medicines | Curebay'} />
<link rel="canonical" href={window.location.href} /> 
  </Helmet>
      <div onClick={() => handleKeyPress("Enter")}>
        <PrescriptionModal />
        <Layouts>
          <div className="w-full h-[75px] bg-[#bfe4ff] py-3 fixed z-10">
            <div className="container mx-auto">
              <div className="md:w-[45%] mx-auto">
                {
                  <Search
                    value={searchTerm}
                    className="uniqueSearch apptWhite bg-White"
                    placeholder="Search Medicines"
                    onChange={handleSearchKeyPress}
                    onKeyPress={handleKeyPress}
                    enterButton
                    onSearch={onSearch}
                    addonBefore={LocationSearch}
                  />
                }
                {medicineSearched.length > 0 && isOpen ? (
                  <div className="relative w-full min-h-[10rem] mt-2">
                  <div className=" flex  absolute  w-full bg-White max-h-[20rem] overflow-y-scroll rounded-2xl scrollWidth">
                      <div className="w-full flex flex-col gap-1">
                        {medicineSearched.map((medicine, idx) => (
                          <div className="w-full p-1 rounded-lg ">
                            <p
                              onClick={() => seletedSearch(medicine)}
                              className="py-1 px-16 hover:bg-lightGray hover:text-primaryColor rounded-lg  w-full"
                            >
                              {medicine.medicineName}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
            <div className="container mx-auto pt-[70px]">
              <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
              <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
                <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                  <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                    One stop destination for all your{" "}
                    <span className="text-secondaryColor">
                      {" "}
                      prescribed needs
                    </span>
                    !
                  </h1>
                  <p className="text-white md:text-White font-light text-sm md:text-lg mb-5">
                    Genuine Medicines & Essentials at the Best Price.
                  </p>
                </div>
                <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                  <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Medicine.webp"
                      alt="images"
                      className="w-full h-full image-boxshadow rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {searchTerm && medicineSearched.length !== 0 ?(
            <section className="bg-lightGray">
              <div className="container mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-10">
                  {medicineSearched.length !== 0 && (
                    medicineSearched.map((item, idx) => (
                      <div key={idx} className="bg-White rounded-lg p-5 ">
                        <div className="bg-lightBlue h-[150px] flex justify-center items-center rounded-lg cursor-pointer">
                          <img
                            // src={resolvePharamcyImageq(item.medicineTypeOfSell)}
                            src={handleImage(
                              item.medicineImages,
                              item.medicineTypeOfSell
                            )}
                            alt="images"
                            className="w-[90px] h-[90px] rounded-lg object-fill"
                          />

                          {/* <img
                          src={item?.medicineImages &&JSON.parse(item.medicineImages)?.length > 0 ? JSON.parse(item.medicineImages[0]) : resolvePharamcyImageq(item.medicineTypeOfSell)}
                          alt="images"
                          className="w-[90px] h-[90px] rounded-lg object-fill"
                        /> */}
                        </div>
                        <div className="pt-3">
                          <div
                            onClick={() => addTocart(item)}
                            className=" cursor-pointer"
                          >
                            <h5 className="text-primaryColor text-lg mb-2 font-medium text-ellipsis overflow-hidden text-nowrap">
                              {item.medicineName}
                            </h5>
                            <div className="text-secondaryColor text-lg font-semibold flex items-baseline">
                              <div className="flex w-fit">
                                {" "}
                                ₹{item.medicineRate}
                              </div>
                              <div className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                                {item.medicineTypeOfSell}
                              </div>
                            </div>
                          </div>
                          <button
                            className={`${!drugIDsInCart.includes(item.id)
                              ? "bg-primaryColor text-White"
                              : "bg-White text-secondaryColor border border-secondaryColor hover:bg-secondaryColor hover:text-White"
                              }  rounded-lg py-2 text-center w-full mt-5`}
                            onClick={(e) => addTocart2(e, item, idx)}
                            disabled={
                              isAdding !== -1 ||
                              drugIDsInCart.indexOf(item.id) !== -1
                            }
                          >
                            {!drugIDsInCart.includes(item.id)
                              ? "Add to Cart"
                              : "Added"}
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </section>
          ): medicineSearched.length === 0 && searchTerm !== "" ? (
            <div class=" w-full h-[inherit] flex gap-5 flex-col justify-center my-12 items-center text-center ">
              <div>
                <img src={NodataImage} alt="emptyData" className="w-[60px]" />
              </div>
              <p className="text-center font-light item-center text-base text-textGray">
                No results found
              </p>
            </div>
          ):""}
          <section className="bg-White">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              {Object.keys(userData).length !== 0 && (
                <>
                  <div>
                    <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
                      Quick <span className="text-secondaryColor">Actions</span>
                    </h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    <div
                      onClick={() => handleNavigation("/e-prescription")}
                      className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite cursor-pointer"
                    >
                      <div className="flex items-center gap-5">
                        <div className="w-[20%]">
                          <img
                            src={ePrescription}
                            alt="images"
                            className="w-full "
                          />
                        </div>
                        <div className="w-[75%]">
                          <h5 className="text-primaryColor text-lg font-semibold ">
                            CureBay e-Prescription
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => handleNavigation("/saved-prescriptions")}
                      className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite cursor-pointer"
                    >
                      <div className="flex items-center gap-5">
                        <div className="w-[20%]">
                          <img
                            src={savedIcon}
                            alt="images"
                            className="w-full "
                          />
                        </div>
                        <div className="w-[75%]">
                          <h5 className="text-primaryColor text-lg font-semibold ">
                            Saved Prescriptions
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => handleNavigation("/medicineorder")}
                      className="bg-White hover:bg-primaryColor rounded-xl p-3 md:p-5 common-shadow hoverWhite cursor-pointer"
                    >
                      <div className="flex items-center gap-5">
                        <div className="w-[20%]">
                          <img
                            src={repeatIcon}
                            alt="images"
                            className="w-full "
                          />
                        </div>
                        <div className="w-[75%]">
                          <h5 className="text-primaryColor text-lg font-semibold ">
                            Repeat your past order
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
          <section className="bg-primaryColor">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-White leading-normal">
                Upload your{" "}
                <span className="text-secondaryColor"> Prescription</span>
              </h2>
              <div className="w-full md:flex gap-5 mt-16">
                <div className="bg-[#00345a] rounded-md md:w-[45%]">
                  <h3 className="text-White text-lg md:text-2xl font-medium text-center pt-8 mb-3">
                    What is a valid{" "}
                    <span className="text-secondaryColor">Prescription</span>?
                  </h3>
                  <p className="text-White text-sm md:text-base font-light text-center">
                    A prescription contains
                  </p>
                  <div className="my-3">
                    <div class="flex">
                      <div class=" w-2/4 py-5 border-r border-textGray border-b">
                        <div class="flex justify-center">
                          <img
                            src={CalenderSvg}
                            className="w-14"
                            alt="calender"
                          />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          Prescription Date
                        </p>
                      </div>
                      <div class="  w-2/4 py-5 border-textGray border-b">
                        <div class="flex justify-center">
                          <img src={Doctor} className="w-14 " alt="doctor" />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          {" "}
                          Doctor Details
                        </p>
                      </div>
                    </div>
                    <div class="flex">
                      <div class=" flex-col w-2/4 py-5 border-r border-textGray">
                        <div class="flex justify-center">
                          <img
                            src={ContactList}
                            className="w-14 text-center"
                            alt="contactlist"
                          />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          Patient Details
                        </p>
                      </div>
                      <div class="flex-col w-2/4 py-5">
                        <div class="flex justify-center">
                          <img src={Drug} className="w-14  " alt="drug" />
                        </div>
                        <p className="text-White text-center text-sm md:text-base font-normal mt-3">
                          Medicine Details
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="w-[90%] md:w-[60%] brightnessWhite flex items-center justify-center gap-2 bg-lightBlue group hover:bg-secondaryColor hover:text-White text-primaryColor rounded-md py-3 mt-3 mb-7 "
                      onClick={() => handleClick("viewSamplePrescription")}
                    >
                      <img
                        src={prescriptionIcon}
                        alt="presc"
                        className="text-White "
                      />
                      <span className="font-medium ">
                        View sample prescription
                      </span>
                    </button>
                  </div>
                </div>
                <div className="md:w-[55%]">
                  <div className="bg-White p-7 rounded-md">
                    <div className="md:flex gap-5 items-center">
                      <div className="flex justify-center w-full md:w-auto">
                        <img
                          src={prescriptionNote}
                          alt="medicineimg"
                          className="w-[15rem] h-[15rem]"
                        />
                      </div>
                      <div>
                        <div>
                          <h3 className="text-primaryColor mb-3 text-lg md:text-xl font-semibold">
                            Not able to understand your prescription?
                          </h3>
                          <p className="text-textGray text-sm md:text-base font-normal">
                            We are here to help you
                          </p>
                        </div>

                        <div className="flex justify-center mt-8">
                          <button
                            className="w-full flex items-center justify-center gap-2 bg-primaryColor text-White rounded-md py-3 "
                            onClick={() => handleClick("uploadPrescription")}
                          >
                            <FaFileUpload />
                            <span className="font-medium ">
                              Upload Prescription{" "}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#00345a] mt-5 rounded-md md:flex justify-between ">
                    <div className="px-7 py-5">
                      <p className="font-medium text-xl mb-2 text-White">
                        Don't have a prescription
                      </p>
                      <p className="text-White font-light text-sm ">
                        please call us or book an appointment with our doctors
                      </p>
                      <div className="md:flex w-full mt-6 gap-5">
                        <button
                          className="w-fit bg-White mb-3 md:mb-0 py-2 px-5 rounded-md text-primaryColor text-sm font-medium"
                          onClick={() => window.open("tel:+918335000999")}
                        >
                          +91-8335 000 967
                        </button>
                        <button
                          className="w-fit bg-White  py-2 px-5 rounded-md text-primaryColor text-sm font-medium"
                          onClick={() => handleClick("bookAnAppointment")}
                        >
                          Book an appointment
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <img
                        src={CartDocImg}
                        alt="img"
                        className="w-full h-full hidden md:block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="bg-White">
            <div className="container mx-auto py-[50px] md:py-[90px]">
              <div>
                <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
                  Our <span className="text-secondaryColor">medical</span>{" "}
                  products
                </h2>
              </div>
              <div className="flex items-center justify-center gap-5">
                <div className="w-[90%] md:w-[40%] mx-auto">
                  <Segmented
                    className="commonSegment"
                    options={["Categories", "Health Conditions"]}
                    block
                    onChange={(option) => setSelectedOption(option)}
                  />
                </div>
              </div>
              {selectedOption === "Categories" && (
                <div className="mt-10">
                  <div className="flex justify-center items-center gap-5 flex-wrap">
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories1}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Baby Care
                      </h6>
                    </div>
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories2}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Health Drinks
                      </h6>
                    </div>
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories3}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Women Care
                      </h6>
                    </div>
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories4}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Health Devices
                      </h6>
                    </div>
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories5}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Home Essentials
                      </h6>
                    </div>
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories6}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Prescribed Meds
                      </h6>
                    </div>
                    <div className="w-[47%] md:w-[20%] bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={categories7}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Personal Care
                      </h6>
                    </div>
                  </div>
                </div>
              )}
              {selectedOption === "Health Conditions" && (
                <div className="mt-10">
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-5">
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition1}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Baby Care
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition2}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Liver Care
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition3}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Stomach Care
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition4}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Respiratory
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition5}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Diabetic
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition6}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Eye Care
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition7}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Pain Relief
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition8}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Cold & Cough
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition9}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Cardiac
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition10}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Wonder Care
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition11}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Blood Pressure
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition12}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Cholesterol
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition13}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Sleep Aids
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition14}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Kidney Care
                      </h6>
                    </div>
                    <div className="w-full bg-lightBlue p-4 md:p-7 flex justify-center flex-col rounded-xl items-center gap-5">
                      <div className="h-[60px]">
                        <img
                          src={Condition14}
                          alt="images"
                          className="w-full h-full"
                        />
                      </div>
                      <h6 className="text-primaryColor text-sm md:text-lg font-medium">
                        Bones, Joint & Musle
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section> */}
          <section className="bg-primaryColor">
            <div className="container mx-auto py-[50px] md:py-[100px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-White leading-normal mb-[50px]">
                Top Selling{" "}
                <span className="text-secondaryColor"> Medicine</span>
              </h2>
              <div className="">
                <Slider {...Tablets} className="w-full">
                  {topratedMedicine &&
                    topratedMedicine.map((item, idx) => (
                      <div
                        key={idx}
                        className="bg-White rounded-lg topCardwidth p-5 cursor-pointer"
                      >
                        <div
                          onClick={() => addTocart(item)}
                          className="bg-lightBlue p-5"
                        >
                          <img
                            src={
                              item?.medicineImages?.length > 0
                                ? item.medicineImages[0]
                                : resolvePharamcyImageq(item.medicineTypeOfSell)
                            }
                            alt="images"
                            className="w-full h-[150px] rounded-t-lg object-contain"
                          />
                        </div>
                        <div onClick={() => addTocart(item)} className="pt-3">
                          <h5 className="text-primaryColor text-lg mb-1 font-semibold text-ellipsis overflow-hidden text-nowrap">
                            {item.medicineName}
                          </h5>
                          <div className="flex items-baseline">
                            <p className="text-secondaryColor text-lg font-semibold ">
                              ₹{item.medicineRate}
                            </p>
                            <p className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                              {item.medicineTypeOfSell}
                            </p>
                          </div>
                        </div>
                        <button
                          className={`${!drugIDsInCart.includes(item.id)
                            ? "bg-primaryColor text-White"
                            : "bg-White text-secondaryColor border border-secondaryColor hover:bg-secondaryColor hover:text-White"
                            }  rounded-lg py-2 text-center w-full mt-5`}
                          onClick={(e) => addTocart2(e, item, idx)}
                          disabled={
                            isAdding !== -1 ||
                            drugIDsInCart.indexOf(item.id) !== -1
                          }
                        >
                          {!drugIDsInCart.includes(item.id)
                            ? "Add to Cart"
                            : "Added"}
                        </button>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </section>
          <section className="bg-lightGray">
            <div className="container mx-auto py-[90px]">
              <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
                Why choose CureBay for <br />
                <span className="text-secondaryColor"> your Medicines</span>?
              </h2>
              <div className="md:w-[70%] mx-auto grid md:grid-cols-2 gap-5">
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow  hoverWhite">
                  <div className="mb-5">
                    <img src={Truck} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Rapid 3-Hour Delivery
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Swiftly at your doorstep for your health needs.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Price} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Assured Best Prices for All
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Best price guarantee & savings on every medicine.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Delivery} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Free Doorstep Delivery
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Zero-Cost doorstep delivery, medicines delivered free,
                    directly to you.
                  </p>
                </div>
                <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                  <div className="mb-5">
                    <img src={Location} alt="images" />
                  </div>
                  <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                    Real-Time Order Tracking
                  </h5>
                  <p className="text-textGray md:leading-loose text-base">
                    Live order tracking & watch your medicine's journey to your
                    door in real-time.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layouts>
        <SideBar
          isSidebarOpen={isSidebarOpen}
          handleUploadPrescriptionClick={handleUploadPrescriptionClick}
          ToggleSideBar={ToggleSideBar}
        />
      </div>
    </>
  );
}

export default Medicine;
