import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import ScrollTop from "../../Assets/Images/Icons/scrolldown.svg";
import SubHeader from "../Shared/SubHeader";
import Help from "../Pages/Help";
import { Link, useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
// import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import { SpeedDial } from "primereact/speeddial";
import Fab from "@mui/material/Fab";
import { useSelector } from "react-redux";
const Layouts = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.patientData);
  const items = [
    {
      label: "Add",
      icon: <FileCopyIcon />,
      command: () => {
        history.push(APP_ROUTES.CARESATHI);
      },
    },
    {
      label: "Update",
      icon: <SaveIcon />,
      command: () => {
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
    {
      label: "ddddd",
      icon: <SaveIcon />,
      command: () => {
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
    {
      label: "ddssdcs",
      icon: <SaveIcon />,
      command: () => {
        // toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
      },
    },
  ];
  // Function to handle scroll event
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 200; // Adjust this value to set the height threshold
    if (scrollTop > threshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling behavior
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSareSathiClick = () => {
    if (Object.keys(userData).length === 0) {
      // history.push("/");
      if (location.pathname === "/") {
        window.location.reload();
      } else {
        history.push("/");
      }
    } else {
      history.push(APP_ROUTES.CARESATHI);
    }
  };

  // useEffect(() => {
  //   window.fwSettings = {
  //     widget_id: 1070000000324,
  //     position: "bottom-left",
  //   };

  //   if (typeof window.FreshworksWidget !== "function") {
  //     class n {
  //       constructor() {
  //         n.q.push(arguments);
  //       }
  //     }
  //     n.q = [];
  //     window.FreshworksWidget = n;
  //   }

  //   const script = document.createElement("script");
  //   script.src = "https://ind-widget.freshworks.com/widgets/1070000000324.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div>
      <div className="pb-[5rem]">
        <Header />
      </div>
      {/* <SubHeader/> */}
      <div>{children}</div>
      <Footer />
      {isVisible && (
        <div
          className="fixed right-0 bottom-0 cursor-pointer hidden sm:block"
          onClick={handleClick}
        >
          <img
            src={ScrollTop}
            alt="images"
            className="w-[90px] h-[50px] md:w-full"
          />
        </div>
      )}
      {/* <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'fixed', bottom: 4, right: 2 }}
        icon={<SupportAgentIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial> */}
      <div style={{ position: "fixed", bottom: 10, right: 10, zIndex: 800 }}>
        <Fab
          style={{ backgroundColor: "#004171", border: "1px solid #ffff" }}
          aria-label="add"
          onClick={handleSareSathiClick}
        >
          <SupportAgentIcon style={{ color: "#ffff" }} />
        </Fab>
      </div>
      {/* <SpeedDial model={items} radius={120} showIcon = {<SupportAgentIcon style={{color: "#ffff"}} />} type="quarter-circle" direction="up-left" style={{position: "fixed", bottom: 10, right: 10, backgroundColor: "#004171", borderRadius:"50%" }} /> */}
      {/* <div onClick={handleSareSathiClick} className="fixed right-0 bottom-0 cursor-pointer ">
        <p>Care Sathi</p>
      </div> */}
    </div>
  );
};

export default Layouts;
