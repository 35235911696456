import React from "react";
import Layouts from "../../Layouts";
import PersonalizedAssistant from "../../../Assets/Images/Icons/PersonalizedAssistant.svg";
import EmotionalSupport from "../../../Assets/Images/Icons/EmotionalSupport.svg";
import Continuous from "../../../Assets/Images/Icons/Continuous.svg";
import Comperhensive from "../../../Assets/Images/Icons/Comperhensive.svg";
import PieceMind from "../../../Assets/Images/Icons/PieceMind.svg";
import SubHeader from "../../Shared/SubHeader";


function Concierge() {
  return (
    <Layouts>
      <SubHeader />
      <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                <span className="text-secondaryColor">Compassionate</span>{" "}Care
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                Embark on a healing journey with CureBay's Concierge Service, where compassionate support extends from
                admission to discharge. From navigating medical procedures to providing emotional solace and unwavering
                companionship, we ensure you never face your recovery journey alone. Experience the reassurance of
                dedicated assistance, fostering hope and resilience every step of the way.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Concierge%20Service.webp" alt="images" className="w-full h-full object-cover image-boxshadow rounded-xl" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
            Know about our{" "}
            <span className="text-secondaryColor">Concierge Services</span>
          </h2>
          <div className="md:flex gap-5 justify-center flex-wrap">
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={PersonalizedAssistant} alt="images" />
              </div>
              <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                Personalized Assistance
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                End-to-end support like pre-op & post-op care from our dedicated
                team ensures personalized care tailored to your needs.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={EmotionalSupport} alt="images" />
              </div>
              <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                Emotional Support
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                Our compassionate team provides empathetic care, addressing your
                emotional well-being throughout your healing journey.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Continuous} alt="images" />
              </div>
              <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
              Continuous Companionship
              </h5>
              <p className="text-textGray md:leading-loose text-base">
              Never feel alone-our team is by your side, providing continuous support and companionship.
                {/* Never feel alone or overwhelmed our team is by your side,
                providing continual support and companionship. */}
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Comperhensive} alt="images" />
              </div>
              <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                Comprehensive Guidance
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                From medical procedures to administrative tasks, we offer
                guidance and assistance in every aspect of your healthcare
                journey.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={PieceMind} alt="images" />
              </div>
              <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                Peace of Mind
              </h5>
              <p className="text-textGray md:leading-loose text-base">
                With CureBay’s Concierge Service, find reassurance and
                confidence, knowing that you are in capable hands from start to
                finish.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Concierge;
