import Search from "antd/es/input/Search";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import LocateIcon from "../../Assets/Images/locate.svg";
import {
  getPatientDetails,
  patientaddresslists,
} from "../../Redux/Action/UserprofileActions";
import hospitalservice from "../../Redux/Services/hospitalservice";
import doctorService from "../../Redux/Services/doctorService";
import { secureStorage } from "../../Redux/Reducer/authReducer";
import axios from "axios";
import http from "../../Redux/Services/http-common";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import {
  setAddressString,
  setLatLong,
  actioncustomPinCode,
  //actioncustomRadius
} from "../../Redux/Action/userActions";
import { debounce } from "lodash";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { CloseOutlined } from "@ant-design/icons";
import "../../Assets/Css/slide_vertical.css";

function SubHeader(props) {
  const history = useHistory();
  const prevScrollY = useRef(0);
  const menuRef = useRef();
  const locationDropdownRef = useRef(null);
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(false);
  const pincodelocation = useSelector(
    (state) => state.authReducer.pincodelocation
  );
  const address = useSelector((state) => state.authReducer.address);
  const userData = useSelector((state) => state.authReducer.patientData);
  const dispatch = useDispatch();
  const [mapOpen, setMapOpen] = useState(false);
  const [screen, setscreen] = useState(window.innerWidth);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;
  const addressinfo = useSelector((state) => state.patientaddresslist);
  const { patientaddressinfoData } = addressinfo;
  const commonHealtList = useSelector((state) => state.doctspecialitylist);
  const { doctspecialityData } = commonHealtList;
  const [searchData, setSearchData] = useState();
  const coords = useSelector((state) => state.authReducer.coords);
  const customPinCode = useSelector((state) => state.authReducer.customPinCode);
 // const customRadius = useSelector((state) => state.authReducer.customRadius);
  const [text, setSearchText] = useState("");
  const [newPinCode, setPinCode] = useState("");
  const [allOpen, setAllOpen] = useState(false);
  const [banner, setBanner] = useState("");
  const [searchedLabtest, setSearchedLabtest] = useState([]);
  const [pincoder, setPincode] = useState("");
  const [radiusselected, setRadiusselected] = useState("25");

  const [error, setError] = useState({
    isMMILoading: false,
    locationError: "",
  });

 

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBanner(location.pathname);
  }, [location]);

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      //  console.log(`You changed the page to: ${location.pathname}`)
      setSearchText("");
    });
  }, [history]);

  // useEffect(() => {

  //   handleSavedAddressClick()
  // }, [patientinfoData]);

  useEffect(() => {
    if (userData) {
      dispatch(getPatientDetails(userData.code)).then((res) => {
        if (res?.pinCode && pincodelocation != "map") {
          let city = res?.city;
          let pinCode = res.pinCode;
          dispatch(setAddressString(city));
          const pincodePackage = require("pincode-lat-long");
          let coords = pincodePackage.getlatlong(pinCode);
          // console.log(coords ,"dvjsdbvsbovdf")
          if (coords == undefined) {
            setError({
              locationError: "Please use valid pincode in profile",
            });
            sessionStorage.setItem("customPinCode", "751009");
            return;
          }
          if (!sessionStorage.getItem("customPinCode")) {
            sessionStorage.setItem("customPinCode", pinCode);
          }

          // dispatch(
          //   actioncustomPinCode({
          //     pincode: pinCode,
          //     location: "profileaddress",
          //   })
          // );
          dispatch(
            // actioncustomRadius({
            //   pincode: radiusselected,
            //   location: "profileaddress",
            // })
          );
        } else {
          if (!sessionStorage.getItem("customPinCode")) {
            sessionStorage.setItem("customPinCode", "751009");
          }
        }
        // }
      });

      dispatch(patientaddresslists(userData.code));
    }
  }, [userData.code]);
  const [searchLists, setSearchLists] = useState("");
  const handleMenuClick = (e, data) => {
    e.preventDefault();
    setSearchLists(data);
    const { text, code } = data;
    // selectedText(text, code);
    setSearchOpen(false);
    console.log(data, "data");
  };
  const selectedText = (val, code, e, i = 0) => {
    // setSearchOpen(!searchOpen);
    setSearchText(val);
    console.log(val, "valueSearch");
    redirectTo(e, val, i);
  };

  // useEffect(() => {
  //   const handleOutSideClick = (e) => {
  //     if (!menuRef?.current?.contains(e.target)) {
  //       setSearchOpen(false);
  //     }
  //   };

  //   if (searchOpen) {
  //     window.addEventListener("mousedown", handleOutSideClick);
  //   } else {
  //     window.removeEventListener("mousedown", handleOutSideClick);
  //   }
  // }, [searchOpen]);
 

  const DEBOUNCE_DELAY = 300;
  const debouncedSearch = useRef(
    debounce(async (coords, searchTerm, radiusselected) => {
      if (searchTerm) {
        // Initialize separate arrays for different types of data
        let api_list_doctor = [];
        let api_list_hospital = [];
        let api_list_lab = [];
        let api_list_medicine = [];
        let api_list_surgery = [];

        // console.log(coords,"coooordssss1")

        // Make API calls for doctors
        const resDoctor = await doctorService.esgetDoctorslist(
          coords,
          searchTerm,
          1,
          40,
          radiusselected
        );
        resDoctor.data?.forEach((search) => {
          api_list_doctor.push({
            code: search.userId,
            text: search.userName,
            symptoms: search.symptoms,
            specialities: search.specialities,
          });
        });

        // Make API calls for hospitals
        const resHospital = await hospitalservice.esgetHopitalslist(
          coords,
          searchTerm,
          1,
          40,
          radiusselected
        );
        resHospital.data?.forEach((search) => {
          api_list_hospital.push({
            code: search.code,
            text: search.hospitalName,
          });
        });

        // Make API calls for lab
        const resLab = await axios.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?freeTextSearch=${searchTerm}&source=b2c&radius=${radiusselected}`
        );

        resLab.data?.forEach((search) => {
          api_list_lab.push({
            code: search.labTestCode,
            text: search.testDisplayName,
            otherNames: search.testKnownAs,
          });
        });

        // Make API calls for medicine
        const resMedicine = await axios.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${searchTerm}&pageNo=1&pageSize=20`
        );
        resMedicine.data?.forEach((search) => {
          api_list_medicine.push({
            code: search.id,
            text: search.medicineName,
            symptoms: "",
          });
        });

        // Make API calls for surgery
        // const resSurgery = await axios.get(`${process.env.REACT_APP_ELASTIC_BASEURL}surgeries?freeTextSearch=${searchTerm}&pageNo=1&pageSize=200`);
        // resSurgery.data?.forEach((search, index) => {
        //   api_list_surgery.push({
        //     code: search.surgeryId,
        //     text: search.surgeryName,
        //     seoName: search.seoNameId,
        //     index: index,
        //   });
        // });

        // Concatenate all lists
        let api_list = [
          ...api_list_doctor,
          ...api_list_hospital,
          ...api_list_lab,
          ...api_list_medicine,
          ...api_list_surgery,
        ];

        // Set the concatenated list as the search data
        setSearchData(api_list);
        // setSearchData( api_list_doctor,
        //   api_list_hospital,
        // );
        setSearchOpen(true);
      } else {
        setSearchOpen(false);
      }
    }, DEBOUNCE_DELAY)
  ).current;

  const onSearchText = (e) => {
    setSearchText(e.target.value);
    setSearchData([]);
    const searchTerm = e.target.value;

    debouncedSearch(coords, searchTerm, radiusselected);
  };
 

  const removeText = (e, text) => {
    // e.preventDefault();
    setAllOpen(false);
    setSearchText("");
  };

  const redirectTo = (e, text, i) => {
    if (text.length == 0) return;

    console.log(text, "text2");
    e.preventDefault();
    setMapOpen(false);
    setIsTextSelected(true);
    setAllOpen(false);
    setSearchOpen(true);
    setSearchText("");

    console.log(text, "text");

    if (text) {
      history.push(`/all/${text}`);
    }

    // if (location.pathname == "/" || location.pathname == "/why_curebay") {
    //   console.log(searchData, i, "dvsdvusdguvids");
    //   if (!text) {
    //     return;
    //   }
    //   if (searchData.find((res) => res.text == text)?.seoName) {
    //     history.push(
    //       `/surgerydetails/${
    //         searchData.find((res) => res.text == text).seoName
    //       }`
    //     );
    //   } else if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.ALL_SEARCH,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.ALL_SEARCH });
    //   }
    // } else if (location.pathname === "/hospital") {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.HOSPITAL,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.HOSPITAL });
    //   }
    //   // })seoNameId
    // } else if (
    //   location.pathname === "/surgery" ||
    //   location.pathname.includes("surgery")
    // ) {
    //   if (!text) {
    //     return;
    //   }
    //   if (searchData[i]?.seoName) {
    //     history.push(`/surgerydetails/${searchData[i]?.seoName}`);
    //   }
    // } else if (location.pathname === "/find-doctors") {
    //   doctorService.esgetDoctorslist(coords, text, 1, 20).then((res) => {
    //     let data = res?.data.map((search) => {
    //       return {
    //         code: search?.code,
    //         text: search?.userName,
    //         symptoms: search?.symptoms,
    //       };
    //     });
    //     setSearchData([]);
    //     setSearchData(data);
    //   });

    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.FINDDOCTORS,
    //       search: text,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.FINDDOCTORS });
    //   }
    //   // })
    // } else if (location.pathname === "/all") {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.ALL_SEARCH,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.ALL_SEARCH });
    //   }
    // } else if (
    // //   location.pathname === "/labtests" ||
    //   location.pathname === "/services/lab"
    // ) {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.LAB,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.LAB });
    //   }
    // } else if (
    // //   location.pathname === "/medicine" ||
    //   location.pathname === "/services/order-medicines"
    // ) {
    //   if (text) {
    //     history.push({
    //       pathname: APP_ROUTES.MEDICINE,
    //       search: `?search_query=${text}`,
    //     });
    //   } else {
    //     history.push({ pathname: APP_ROUTES.MEDICINE });
    //   }
    //   // })
    // }
  };

  var customClass;
  customClass =
    "absolute w-full md:w-7/12 lg:w-7/12 -top-1 md:top-0 lg:top-15 flex flex-wrap justify-center mt-2 md:p-3 lg:p-6 bg-brand-secondary  ";
  var isIOS = (function () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isIOS;
  })();

  function getLocation() {
    setError({
      isMMILoading: true,
      locationError: "",
    });
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        if (window.navigator.geolocation) {
          window.navigator.geolocation.getCurrentPosition(showPosition);
        } else {
        }
      } else if (result.state === "prompt") {
        // showButtonToEnableMap();
      } else if (result.state === "denied") {
        setError({
          locationError: "Please allow browser to detect location",
          isMMILoading: false,
        });
      }
      // Don't do anything if the permission was denied.
    });
  }

  function showPosition(position) {
    const { coords } = position;
    var coordsObj = {
      lat: coords.latitude,
      long: coords.longitude,
    };
    dispatch(setLatLong(coordsObj));
    var apiUrl = "MMI/accesstoken";
    http.get(apiUrl).then((response) => {
      let data = response.data;
      // const res = await http.get(
      //   `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
      // );
      axios
        .get(
          `https://apis.mapmyindia.com/advancedmaps/v1/${data.access_token}/rev_geocode?lat=${coords.latitude}&lng=${coords.longitude}`
        )
        .then((res) => {
          dispatch(
            actioncustomPinCode({
              pincode: res.data.results[0].pincode,
              location: "current",
            })
          );
          if (!res.data.results[0].pincode) {
            sessionStorage.setItem("customPinCode", "751009");
            dispatch(
              actioncustomPinCode({
                pincode: "751009",
                location: "current",
              })
            );
            setError({
              isMMILoading: false,
              locationError: "Not able to detect your location.",
            });
            setOpen(false);
          } else {
            sessionStorage.setItem(
              "customPinCode",
              res.data.results[0].pincode
            );
            // dispatch(
            //   actioncustomPinCode({
            //     pincode: res.data.results[0].pincode,
            //     location: "current",
            //   })
            // )
            setError({
              isMMILoading: false,
            });
            setOpen(false);
          }
        })
        .catch((err) => {
          setError({
            locationError: "Error occured in MMI",
            isMMILoading: false,
          });
        });
      // let addressString = res.data.results[0]?.city;
      // dispatch(setAddressString(addressString));
    });
    secureStorage.setItem("coords", coordsObj);
    // setMapOpen(!mapOpen);
  }

  const setLocation = () => {
    setMapOpen(!mapOpen);
  };

  const handleSavedAddressClick = () => {
    setError({
      locationError: "",
    });

    if (patientinfoData?.pinCode) {
      let address1 = patientinfoData?.address1;
      let address2 = patientinfoData?.address2;
      let city = patientinfoData?.city;
      let pinCode = patientinfoData?.pinCode;
      setPincode(pinCode);
      const pincodePackage = require("pincode-lat-long");
      let coords = pincodePackage.getlatlong(pinCode);
      // console.log(coords ,"dvjsdbvsbovdf")
      if (coords == undefined) {
        setError({
          locationError: "Please use valid pincode",
        });
        return;
      }
      let coordsObj = {
        lat: coords.lat,
        long: coords.long,
      };
      dispatch(setLatLong(coordsObj));

      dispatch(setAddressString(city));
      sessionStorage.setItem("customPinCode", pinCode);
      dispatch(
        actioncustomPinCode({ pincode: pinCode, location: "profileaddress" })
      );
      setOpen(false);

      // setLocation();
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && !scroll) {
        setScroll(true);
      }
      if (currentScrollY < 5) {
        setScroll(false);
      }

      prevScrollY.current = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  const closeOpenMenus = (e) => {
    if (
      locationDropdownRef.current &&
      open &&
      !locationDropdownRef.current.contains(e.target)
    ) {
      setOpen(false);
      setError({
        locationError: "",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", closeOpenMenus);
    return () => window.removeEventListener("mousedown", closeOpenMenus);
  }, [open]);

  const [scroll, setScroll] = useState(false);

  const onFocused = () => {
    setIsReadOnly(false);
  };

  const handlePinCodeChange = (e) => {
    const re = /^[0-9]*$/; //rules
    if (re.test(e.target.value)) {
      e.target.value.length <= 6 && setPinCode(e.target.value);
    }

    if (e.target.value.length == 0) {
      setError("");
      return;
    }

    if (e.target.value.length == 6) {
      const pincode = require("pincode-lat-long");

      let coords = pincode.getlatlong(e.target.value);
      if (coords == undefined) {
        setError({
          locationError: "Please add valid pincode",
        });
        return;
      }
      setOpen(false);
      setError({
        locationError: "",
      });
      let coordsObj = {
        lat: coords.lat,
        long: coords.long,
      };

      dispatch(setLatLong(coordsObj));
      sessionStorage.setItem("customPinCode", e.target.value);
      dispatch(
        actioncustomPinCode({
          pincode: e.target.value,
          location: "searchbar",
        })
      );
    }
  };

  const handleRadiusChange =(e) =>{
    setRadiusselected(e.target.value)
    sessionStorage.setItem("customRadius", e.target.value);
    // dispatch(
    //   actioncustomRadius({
    //     radius: e.target.value,
    //     location: "searchbar",
    //   })
    // );
  }
 

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const delayExecution = (mls) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve("ok"), mls);
    });
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <div className="w-full h-[75px] bg-[#bfe4ff] py-3 sticky top-[80px] z-[999]">
      {/* <Search
        className="apptSearch apptWhite md:w-[60%]"
        placeholder="Search by name, specialty or symptom"
      /> */}

      <div
        className={` ${
          props.visible
        } flex justify-center sticky top-16 lg:top-10 z-10 ${
          location.pathname == "/" ? "pb-0 sm:pb-0" : "pb-20 sm:pb-0"
        }`}
      >
        <div
          // style={{backgroundColor:"#4FAFD9"}}
          className={
            isIOS
              ? customClass
              : "absolute w-full  flex flex-wrap justify-center sm:pl-0 pl-2 sm:pr-0 pr-2"
          }
        >
          <div
            onClick={() => {
              setMapOpen(!mapOpen);
              // setSearchOpen(false);
            }}
            className={
              !scroll
                ? "hidden w-full md:mx-10 text-brand-secondary mb-2 flex "
                : "hidden"
            }
          >
            {/* <LocationMarkerIcon className="h-5 mr-2 relative" /> */}
            <p className=" relative text-base">Home - {address}</p>
          </div>
          <div className="relative bg-White flex items-center rounded-full py-3 pl-3 w-full max-w-2xl h-12 justify-between">
            {/* <ClickAwayListener onClickAway={handleClickAway}> */}
            <div>
              <button
                // onClick={() => {
                //   setMapOpen(!mapOpen);
                //   setSearchOpen(false);
                // }}
                className="no"
                onClick={handleClick}
              >
                <div className=" flex text-left relative items-center top-0.5 ">
                  {/* <p className="text-gray-600 text-xs  font-medium">Location</p> */}
                  <LocationMarkerIcon
                    style={{
                      height: "21px",
                      color: "#5dbb63",
                      marginRight: "10px",
                    }}
                  />
                  <p className="text-sm  font-semibold text-black-700">
                    {sessionStorage.getItem("customPinCode")}
                  </p>
                </div>
              </button>
              {open ? (
                <div
                  ref={locationDropdownRef}
                  style={{
                    minHeight: "fit-content",
                    zIndex: 10,
                    boxShadow: "0 6px 12px -4px rgb(11 18 25 / 20%)",
                    borderRadius: "5px",
                  }}
                  className="block md:w-[400px]  absolute bg-White top-14 bottom-0 left-0 p-5"
                >
                  <div className="p-2">
                    <div>
                      <h1 className="text-primaryColor text-base font-medium">
                        Where do you want the delivery?
                      </h1>
                      <span className="text-textGray text-sm font-light">
                        Get access to your Addresses and Orders
                      </span>
                      <div
                        className={`${
                          patientinfoData?.address1 === null ||
                          patientinfoData?.address2 === null ||
                          patientinfoData?.address1 === undefined ||
                          patientinfoData?.address2 === undefined ||
                          patientinfoData?.city === undefined ||
                          patientinfoData?.pinCode === undefined
                            ? "hidden"
                            : "block"
                        }`}
                      >
                        <div
                          className="cursor-pointer  w-full p-4 mt-2 bg-lightBlue border border-[#bee0ff] rounded  mb-1"
                          onClick={handleSavedAddressClick}
                        >
                          <p className="text-primaryColor  text-sm font-normal ">
                            {patientinfoData?.address1 +
                              ", " +
                              patientinfoData?.address2 +
                              ", " +
                              patientinfoData?.city +
                              ", " +
                              patientinfoData?.pinCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-1 py-1">
                    <div className="">
                      <h1 className="text-primaryColor text-base font-medium">
                        Enter Pincode or Radius
                      </h1>
                      <span className="text-textGray text-sm font-light">
                        Select pincode or Radius to see product availability.
                      </span>
                      <div className="flex  gap-2">
                        <div>
                          <input
                            className="border border-gray h-[50px] text-sm w-full py-1 pl-2 my-3 rounded"
                            onChange={handlePinCodeChange}
                            placeholder="Enter Pincode"
                            value={newPinCode}
                          />
                        </div>
                        <div  >
                          <select
                            name="radius"
                            id="radius"
                            value={radiusselected}
                            onChange={handleRadiusChange}
                           className="border border-gray h-[50px]  text-sm  py-1 pl-2 my-3 rounded sm:w-[180px] w-[120px] text-textGray"
                          >
                             <option className=" text-sm font-light" >Select Services within</option>
                            <option value="5" >5 km</option>
                            <option value="10" >10km</option>
                            <option value="15" >15km</option>
                            <option value="25" >25km</option>
                          </select>
                        </div>
                      </div>

                      <div
                        className="flex gap-1 items-center cursor-pointer content-center bg-lightGreen"
                        onClick={getLocation}
                        role="button"
                      >
                        <img
                          src={LocateIcon}
                          alt=""
                          draggable={false}
                          className="h-8 w-8"
                        />
                        <p className="text-secondaryColor  flex items-center text-base font-medium">
                          Detect my location{" "}
                          {error.isMMILoading && (
                            <div className="loader ml-2 float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                          )}
                        </p>
                      </div>
                      {error.locationError && (
                        <div className="text-xs font-semibold text-red-500">
                          {" "}
                          {error.locationError}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* </ClickAwayListener> */}
            <div className="ml-4 "></div>
            <Menu
              as="div"
              className="ml-3 z-10 relative w-full cursor-pointer "
            >
              <form autoComplete="off">
                <input
                  onKeyPress={(e) =>
                    e.key == "Enter" ? redirectTo(e, text) : ""
                  }
                  readOnly={isReadOnly}
                  autoCorrect="off"
                  spellCheck={false}
                  onFocus={() => setIsReadOnly(false)}
                  id="filed1"
                  //autoFocus={true}
                  onChange={onSearchText}
                  value={text}
                  type="text"
                  className="text-sm focus:outline-none lg:w-96 w-28 text-gray-700"
                />
                {!text.length && (
                  <label
                    htmlFor="filed1"
                    style={{ position: "absolute", top: 0, left: 0 }}
                  >
                    <div className="sentence cursor-text">
                      <span>Search for</span>
                      <div class="slidingVertical">
                        <span className="w-max"> Doctors</span>
                        <span className="w-max"> Hospitals</span>
                        <span className=" w-max">
                          {screen > 500
                            ? "Medicines or Healthcare Products"
                            : "Medicines"}
                        </span>
                        <span className="w-max"> Lab Tests</span>
                        <span className="w-max"> Surgery</span>
                      </div>
                    </div>
                  </label>
                )}
              </form>
              {text?.length > 0 && searchData?.length > 0 && searchOpen && (
                <Menu.Items
                  static
                  className="origin-top-right absolute h-auto max-h-96 overflow-hidden overflow-y-scroll scroll-bar bg-White ring-1 ring-gray-600 ring-opacity-5 -left-24 md:-left-4 w-64 md:w-full mt-4 shadow-lg py-0 bg-White ring-1 ring-black ring-opacity-5 focus:outline-none pl-2 pr-2 "
                >
                  <Menu.Item>
                    {({ active }) => (
                      <div className="w-full">
                        {text.length > 0 &&
                          searchData
                            ?.filter(
                              (v, i, a) =>
                                a.findIndex((v2) => v2?.text === v?.text) === i
                            )
                            .map((data, i) => (
                              <>
                                <div
                                  key={i}
                                  onClick={(e) => {
                                    selectedText(data.text, data.code, e, i);
                                    // setSearchOpen(false);
                                  }}
                                  className="flex flex-col content-center cursor-pointer hover:bg-gray-100  text-secondaryColor px-2 my-1"
                                >
                                  {data.text ? (
                                    <>
                                      <div className=" font-medium text-sm mt-2 ml-2 flex">
                                        <div className="w-8">
                                          <SearchIcon
                                            className="h-6 p-1 rounded-full mr-2 w-6"
                                            color={"#AEAEAE"}
                                          />
                                        </div>
                                        {data.specialities ? (
                                          <div className=" flex flex-col gap-2 justify-between ">
                                            <div className="text-secondaryColor">
                                              {data.text + " "}
                                            </div>
                                            <div className="text-secondaryColor text-xs font-normal">
                                              {data.specialities + " "}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="text-secondaryColor">
                                            {data.text + " "}
                                          </div>
                                        )}
                                      </div>
                                      {data.otherNames && (
                                        <div
                                          style={{
                                            display: "flex",
                                            marginLeft: "40px",
                                          }}
                                        >
                                          <div className="text-xs font-medium">
                                            <span className="text-xs font-bold text-gray-800">
                                              Also know as:{" "}
                                            </span>
                                            {" " + data.otherNames}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                </div>
                                {data.text ? (
                                  <hr className=" text-gray-100 my-1" />
                                ) : null}
                              </>
                            ))}
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              )}
            </Menu>

            <div className="flex items-center">
              {text.length > 0 && (
                <CloseOutlined
                  onClick={(e) => removeText(e, text)}
                  style={{ color: "#838383", height: "20px" }}
                  className="mr-5 cursor-pointer"
                />
              )}

              <button
                onClick={(e) => redirectTo(e, text)}
                className="bg-primaryColor rounded-full focus:outline-none w-15 h-auto py-3 px-4 text-center"
              >
                <SearchIcon color={"white"} className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
