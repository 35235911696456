import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import Search from "antd/es/input/Search";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import female_icon from "../../../Assets/Images/Female-Vector-icon.svg";
import male_icon from "../../../Assets/Images/Male-Vector-icon.svg";
import doctorImage from "../../../Assets/Images/doctorImage.svg";
import http from "../../../Redux/Services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorslist } from "../../../Redux/Action/doctorAction";
import noDataFound from "../../../Assets/Images/No data-found.svg";
import VideoCall from "../../../Assets/Images/Icons/videocall.svg";
import LocationImages from "../../../Assets/Images/Icons/locationblue.svg";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from 'antd';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function FindDoctors() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { speciality, healthConcerns } = location;
  const search_params = useLocation().search;
  const doctor_type = new URLSearchParams(search_params).get("doctor_type");
  const url_id_param = new URLSearchParams(search_params).get("id");
  const [pageStart, setPageStart] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [doctorData1, setsearchData] = useState([]);
  const [doctorSearch, setDoctorSearch] = useState("");
  const [doctorDataLocal, setsearchDataLocal] = useState([]);
  const { coords } = useSelector((state) => state.authReducer);
  const doctorlist = useSelector((state) => state.doctorlist);
  const customPinCode = useSelector((state) => state.authReducer.customPinCode);
  const { doctorData, isLoading } = doctorlist;

  const handleCardClick = (e, doctor) => {
    e.preventDefault();
    console.log(doctor.seoNameId, "seoNameId");

     history.push(`/doctors/${doctor.seoNameId}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSearchDoctor = (e) => {
    setDoctorSearch(e.target.value);
  };

  useEffect(() => {
    let searchKey = "";

    if (speciality || healthConcerns) {
      searchKey = speciality ? speciality : healthConcerns;
    } else {
      searchKey = "";
    }
    //getPinCode();
    if (doctor_type == "surgery" && url_id_param !== null) {
      http
        .get(
          `${process.env.REACT_APP_BASEURL}user/surgeryDoctor?surgeryIdentifier=${url_id_param}`
        )
        .then((res) => {
          console.log(res, "sdvldsgivugdsuovsdgv");
          if (res.data.length === 0) {
            //history.goBack();

            setHasMore(false);
          } else {
            if (doctorData.length) {
              setsearchData([...res.data]);
              setsearchDataLocal([...doctorData, ...res.data]);
            } else {
              setsearchData(res.data);
              setsearchDataLocal(res.data);
            }
            setPageStart(pageStart + 1);
            setHasMore(false);
          }
        });
    } else {
      dispatch(
        getDoctorslist(coords, doctorSearch ? doctorSearch : searchKey, 1, 300,customPinCode?customPinCode:"")
      ).then((res) => {
        if (res.length === 0) {
          //history.goBack();

          setHasMore(false);
        } else {
          if (doctorData.length) {
            if (res.length < 4) {
              setHasMore(false);
              return;
            }
            setsearchData([...doctorData, ...res]);
            setsearchDataLocal([...doctorData, ...res]);
          } else {
            setsearchData(res);
            setsearchDataLocal(res);
          }
          setPageStart(pageStart + 1);
          setHasMore(true);
        }
      });
    }
  }, [doctorSearch, dispatch]);

  const handleImgError = (e) => {
    e.target.src = doctorImage;
  };

  const DoctorCard = ({ doctor }) => {
    return (
      <div className="bg-White p-3 md:p-5 rounded-lg flex flex-col justify-between">
      
        <div className="flex gap-4">
          <div className="w-[75px] h-[75px]">
            <img
              src={
                doctor.userPhoto
                  ? `${process.env.REACT_APP_IMG_BASEURL}${doctor.userPhoto}`
                  : doctorImage
              }
              alt={doctor.userName}
              className="rounded-full bg-lightBlue text-xs  w-[65px] h-[65px] object-contain"
              onError={handleImgError}
            />
          </div>
          <div className="w-full">
            <div className="flex justify-between w-full items-baseline mb-2">
              <div className="w-[75%]">
                <h6 className="text-primaryColor uppercase text-base font-semibold">
                  {doctor.userName}
                </h6>
                <p className="text-textGray text-xs font-medium mb-1.5 flex">
                  {doctor.specialities}
                </p>
                <p className="text-textGray text-xs font-medium mb-1.5 flex">
                  {doctor.yearOfExperience} years of exp.
                </p>
              </div>
              <div className="w-[20%] flex justify-end">
                <img src={VideoCall} alt="images" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <p
            style={{ width: "max-content" }}
            className="text-textGray text-xs font-medium mb-1.5 flex gap-2 items-center"
          >
            <img src={LocationImages} alt="images" className="w-3" />
            {doctor.city}
          </p>
          <p className="text-secondaryColor text-sm cursor-pointer font-medium">
            ₹ {doctor.videoConsultCharges}
          </p>
        </div>
        <div className="flex gap-3 mt-5">
          <button
            onClick={(e) => handleCardClick(e, doctor)}
            className="hover:text-secondaryColor text-primaryColor text-xs md:text-sm border border-primaryColor hover:border-secondaryColor w-full py-2 rounded-lg "
          >
            View Profile
          </button>
          <button
            className="hover:bg-secondaryColor bg-primaryColor rounded-lg text-White text-xs md:text-sm w-full py-2 "
            onClick={(e) => handleCardClick(e, doctor)}
          >
            Book Consultation
          </button>
        </div>
      </div>
    );
  };

//   const [openFilter, setOpenFilter] = useState(false);
//   const [selectedFilter, setSelectedFilter] = useState(null);
//   const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
 

//   const toggleFilter = () => {
//     setOpenFilter(!openFilter);
//   };

//   const handleFilterClick = (filter) => {
//     setSelectedFilter(filter);
//   };


// const filterOptions = {
//   Gender: ['Male', 'Female'],
//   Experience: ['0-5 years', '5-10 years', '10-20 years'],
//   'Consultation Type': ['In-Person', 'Video'],
//   Price: ['₹0-₹300', '₹301-₹600','₹601-₹901','₹901-₹1200','Above ₹1200'],
  
// };

// const handleCheckboxChange = (option) => {
//   if (selectedCheckboxes.includes(option)) {
//     setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== option));
//   } else {
//     setSelectedCheckboxes([...selectedCheckboxes, option]);
//   }
// };

// const removeCheckbox = (option) => {
//   setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== option));
// };
// const items = [
//   { key: '1', label: '1' },
//   { key: '2', label: '2' },
//   { key: '3', label: '3' }
// ];

// const [visible, setVisible] = useState(false);

// const menu = (
//   <Menu onClick={() => setVisible(false)}>
//     {items.map(item => (
//       <Menu.Item key={item.key}>{item.label}</Menu.Item>
//     ))}
//   </Menu>
// );
  return (
    <Layouts>
        <ToastContainer/>
      <section className="bg-lightGray w-full">
        <div className="container mx-auto py-[20px] md:py-[50px]">
          <div className="flex md:w-[80%] mb-[20px] md:mb-[40px] mx-auto bg-White rounded-full">
            <div className="w-full md:w-[80%]">
              <Search
                className="apptSearch apptWhite bg-White"
                placeholder="Search Doctors"
                onChange={handleSearchDoctor}
              />
            </div>
            <button className="hidden md:block bg-primaryColor rounded-full text-White text-xs md:text-lg w-[20%]">
              Look for Doctors
            </button>
          </div>
          {doctorData.length === 0 ? null : (
            <>
            <h1 className="text-primaryColor text-center text-xl md:text-3xl font-bold mb-10 !leading-snug">
              <span className="text-secondaryColor">All</span> Doctors
            </h1>
             {/* <div className="bg-[#004171] text-White p-2 flex flex-col items-center justify-center mb-4 rounded-md">
 <div className="flex gap-2 " onClick={toggleFilter}>
    {Object.entries(filterOptions).map(([title, options]) => (
      <div
        key={title}
        className="flex gap-2 hover:bg-gray px-2 py-1 hover:rounded-2xl"
        onClick={() => handleFilterClick(title)}
      >
        <div className="text-base">{title}</div>
        <div>
          {openFilter && selectedFilter === title ? (
            <UpOutlined className="mt-1 text-sm" />
          ) : (
            
            <DownOutlined className="mt-1 text-sm" />
          )}
        </div>
      </div>
    ))}
    <Dropdown
      overlay={menu}
      visible={visible}
      onVisibleChange={setVisible}
      trigger={['click']}
    >
      <div className="hover:bg-gray px-2 py-1 hover:rounded-2xl">
       Specialist{' '}
        {visible ? (
          <UpOutlined className="mt-1 text-sm" />
        ) : (
          <DownOutlined className="mt-1 text-sm" />
        )}
      </div>
    </Dropdown>
  </div>

  {openFilter && selectedFilter && (
    <div className="flex gap-4 mt-3 mb-3">
      {filterOptions[selectedFilter].map((option, index) => (
        <label key={index} className="block">
          <input
            type="checkbox"
            value={option}
            checked={selectedCheckboxes.includes(option)}
            onChange={() => handleCheckboxChange(option)}
            className=""
          />{' '}
          {option}
        </label>
      ))}
    </div>
  )}

  {selectedCheckboxes.length > 0 && (
    <div className="flex gap-3 mb-3">
      {selectedCheckboxes.map((checkbox, index) => (
        <div key={index} className="flex bg-White text-[#121212] px-3 py-1 rounded-2xl">
          <div>{checkbox}</div>
          <button onClick={() => removeCheckbox(checkbox)} className="ml-2">
            &times;
          </button>
        </div>
      ))}
    </div>
  )}
</div> */}

            </>
          )}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-5 w-full overflow-hidden">
            {doctorData?.map((doctor, index) => (
              <DoctorCard key={index} doctor={doctor} />
            ))}
          </div>
        </div>
        {doctorData.length === 0 && (
          <div className="w-full flex flex-col mb-4 items-center justify-around">
            <img
              className="h-24 lg:h-28 mt-5 lg:mt-0 "
              src={noDataFound}
              alt="No Diagnostics Appointments Available"
            />
            <h4 className="font-medium  text-brand-lightgreen text-md">
              Sorry, we don't have a specialist right now but we will be adding
              soon.
            </h4>
          </div>
        )}
      </section>
    </Layouts>
  );
}

export default FindDoctors;
