import React, { useState, useEffect, useRef } from "react";
import Layouts from "../../Layouts";
import membership from "../../../Assets/Images/Membership/membership.jpg";
import SingleplanImage from "../../../Assets/Images/Membership/singlePlan.png";
import info from "../../../Assets/Images/info.png";
import leftarrow from "../../../Assets/Images/leftarrowmembership.png";
import rightarrow from "../../../Assets/Images/rightarrow.png";
import TwinplanImage from "../../../Assets/Images/Membership/twinPlan.png";
import FamilyplanImage from "../../../Assets/Images/Membership/familyPlan.png";
import DiabeticImage from "../../../Assets/Images/Membership/diabetics.jpg";
import stethescope from "../../../Assets/Images/Membership/StethescopeChecking.jpg";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import leftArrow from "../../../Assets/Images/Membership/leftArrow.svg";
import rightArrow from "../../../Assets/Images/Membership/rightArrow.svg";
import membershipimg from "../../../Assets/Images/membershipimg.svg";
import Slider from "react-slick";
import { Link, useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import Regisisterform from "../../Authentications/Regisisterform";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { encodeBase64File } from "../../Helper/filebase64";
import {
  getPatientfamilymembers,
  editPatientDetails,
  getPatientDetails,
} from "../../../Redux/Action/UserprofileActions";
import steth from "../../../Assets/Images/avatar.png";
import camera from "../../../Assets/Images/camera.svg";
// import { toast } from "react-toastify";
import http from "../../../Redux/Services/http-common";
import { onMemberbuy } from "../../../Redux/Action/packages";
import AddFamilymember from "../PostLogin/AddFamilyMember";
import { MultiSelect } from "primereact/multiselect";
import Accordion from "../../Shared/Accordian";
import { Option } from "antd/es/mentions";
import state from "../../Helper/state.json";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import sucessvectoricon from "../../../Assets/Images/sucessvectoricon.svg";
import SubHeader from "../../Shared/SubHeader";
import dayjs from "dayjs";
import Loader from "../../Shared/loader";
import { EnglishText } from "../../PageRouting/EnglishText";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Membership() {
  const hiddenFileInput = React.useRef(null);
  const history = useHistory();
  const firstContentRef = useRef(null);
  const secondContentRef = useRef(null);
  const [selectedMembersPhoto, setSelectedMembersPhoto] = useState([]);
  const needHelp = useSelector((state) => state.needheplReducer.needHelp);
  const patientData = useSelector((state) => state.authReducer.patientData);
  const dispatch = useDispatch();

  const [initialRender, setInitialRender] = useState(true);
  const location = useLocation();
  const Need = location?.state?.from || "";
  const currentUser = location?.state?.user || "";
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [selectedValue, setSelectedValue] = useState("");
  const [membersNumber, setmembersNumber] = useState(0);
  const [amount, setAmount] = useState("");
  const [memberAmount, setMemberAmount] = useState("");
  const [memberdescription, setMemberdescription] = useState("");
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentDate1 = moment(); // Get the current date and time
  const futureDate2 = currentDate1.add(1, "year"); // Add 1 year to the current date
  const [type, setType] = useState("");
  const [isUserReadyToPay, setIsUserReadyToPay] = useState(false);
  const [selectedPayMode, setSelectedPayMode] = useState({
    wallet: false,
    card: false,
  });
  const walletinfo = useSelector((state) => state.patientwallet);
  const [errorDuringPay, setErrorDuringPay] = useState("");
  const [actualAmount, setActualAmount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [loadingDuringPayment, setLoadingDuringPayment] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isCheckbox, setisCheckbox] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [options, setOptions] = useState([]);
  const familymemberinfo = useSelector((state) => state.familymembers);
  const { FamilymembersData, isLoading } = familymemberinfo;
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(false);
  const [refresh, setIsrefresh] = useState(false);
  const [date, setDate] = useState();
  const [conditionModal, setconditionModal] = useState(false);
  const [membershiptype, setMembership] = useState();

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const validateMobileNumber = (_, value) => {
    if (!value || /^\d{10}$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Mobile number should be a 10-digit number")
    );
  };

  const maxDate = moment();

  const disabledDate = (current) => {
    return current && current > maxDate;
  };

  useEffect(() => {
    // Set the stateData and cityData from the imported JSON data
    // setStateData(states.stateData);
    setCityData(state.cityData);
  }, []);

  useEffect(() => {
    // Set the stateData and cityData from the imported JSON data
    setStateData(state.stateData);
    setCityData(state.cityData);
  }, []);

  const handleChange = (e) => {
    if (e.target.name == "mobile" && e.target.value.length == 11) {
      return;
    }
    setaddmember({ ...addmember, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   dispatch(getAllStates())
  //     .then((result) => {
  //       setStateData(result);
  //     })
  //     .catch((error) => {});
  // }, []);

  console.log(patientinfoData, "patientlist");
  const defaultMember = {
    label: patientinfoData.firstName,
    value: patientinfoData.code,
  };
  useEffect(() => {
    dispatch(getPatientDetails(patientData.code));
    dispatch(getPatientfamilymembers(patientData.code));
  }, [patientData]);

  // useEffect(() => {
  //   if (patientinfoData == patientinfoData) {
  //     form.setFieldsValue(patientinfoData);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (patientData == patientData) {
  //     form.setFieldsValue(patientData);
  //   }
  // }, []);
  // console.log(patientData, "patientData");
  const dateObject = patientData?.dob
    ? dayjs(patientData.dob, "DD/MM/YYYY")
    : "";
  useEffect(() => {
    const dateObject = patientData?.dob
      ? dayjs(patientData.dob, "DD/MM/YYYY")
      : "";
  }, []);

  const changeDate = (date, dateString) => {
    setaddmember({ ...addmember, dob: dateString });
    setDate(dateString);
  };

  // useEffect(() => {
  //   if (membersNumber == 3 && selectedMembers.length == 1) {
  //     setShowCheckbox(true);
  //   }
  // });

  useEffect(() => {
    const selectedoptions = FamilymembersData.map((item) => ({
      label: item.firstName,
      value: item.code,
    }));

    selectedoptions.push({
      label: patientinfoData.firstName,
      value: patientinfoData.code,
      disabled: true,
      selected: true,
    });
    console.log(selectedoptions, "Sdvisdhviodshvoidshoidsvv");
    setOptions(selectedoptions);
    console.log("FamilyData", FamilymembersData);
    console.log("PatientData", patientinfoData);

    setSelectedMembers([defaultMember.value]);
  }, [FamilymembersData, patientinfoData]);

  // useEffect(() => {
  //   let discountedAmount = 0;
  //   let actualAmount = 0;
  //   actualAmount = amount;
  //   setActualAmount(memberAmount);
  //   setDiscountedAmount(discountedAmount);
  //   // });
  // }, []);

  useEffect(() => {
    let photos = [];
    if (patientinfoData && selectedMembers?.length) {
      photos.push(patientinfoData);
      let result = FamilymembersData.filter((res) => {
        if (selectedMembers.find((res1) => res1 == res.code)) {
          return res;
        }
      });
      console.log(
        result,
        selectedMembers,
        FamilymembersData,
        "sdvoisdovisdhovhsdouvhsd"
      );
      if (result?.length) {
        photos.push(...result);
      }
      if (photos?.length) {
        setSelectedMembersPhoto(photos);
      }
    }
  }, [patientinfoData, selectedMembers, FamilymembersData]);

  useEffect(() => {
    console.log(
      localStorage.getItem("masterServiceType"),
      localStorage.getItem("paymentStatus"),
      localStorage.getItem("trancationid"),
      "redirectAfterTxn"
    );
    let serviceType = localStorage.getItem("masterServiceType");
    if (
      localStorage.getItem("paymentStatus") &&
      localStorage.getItem("trancationid")
    ) {
      redirectAfterTxn(localStorage.getItem("trancationid"));
    }
  }, []);

  // useEffect(() => {
  //   // Set the stateData and cityData from the imported JSON data
  //   setStateData(states.stateData);
  //   setCityData(states.cityData);
  // }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IsPaymentTypeModalOpen, setIsPaymentTypeModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState({
    singleprimecure: true,
    twinprimecure: false,
    familyprimecure: false,
    singlewellness: true,
    twinwellness: false,
    familywellness: false,
    singlewellnesspro: true,
    twinwellnesspro: false,
    familywellnesspro: false,
  });
  const [isMobile, setIsMobile] = useState(false);

  const toggleList = (id) => {
    setIsOpen({
      ...isOpen,
      [id]: !isOpen[id],
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [profile, setProfile] = useState({
    patientCode: patientinfoData.code,
    membership: selectedValue,
    membershipCode: selectedValue,
    membershipName: memberdescription,
    fromDate: currentDate.toISOString().slice(0, 10) + " 00:00:00",
    toDate: futureDate2.toISOString().slice(0, 10) + " 00:00:00",
    createdBy: patientinfoData.code,
    modifiedBy: patientinfoData.code,
    status: 1,
    familyMember1: "",
    totalAmount: amount,
    txnId: "",
    sourceChannel: "B2C",
    masterServiceType: "Membership",
    discountPercentage: 0,
    curebayFee: amount,
    firstName: patientinfoData.firstName,
    lastName: patientinfoData.lastName,
    mobileNo: patientinfoData.mobile,
    email: patientinfoData.email,
    dob: dateObject,
    gender: patientinfoData.gender,
    address1: patientinfoData.address1,
    address2: patientinfoData.address2,
    city: patientinfoData.city,
    state: patientinfoData.state,
    pincode: patientinfoData.pinCode,
  });

  const [addmember, setaddmember] = useState({
    firstName: "",
    lastName: "",
    mobile: null,
    relation: "",
    gender: "",
    dob: moment(new Date()).format("MM/DD/yyyy"),
    // dob:new Date(),
    // dob:new Date().toISOString().slice(0, 10) + " 00:00:00",
    bloodGroup: "",
    state: "",
    city: "",
    pinCode: "",
    email: null,
    address1: "",
    address2: "",
    status: 1,
    code: patientinfoData.code,
    modifiedBy: patientinfoData.code,
    createdBy: patientinfoData.code,
    parentCode: patientinfoData.code,
    // photoname: "",
    salutation: "",
  });

  const optionTemplate = (option) => {
    return (
      <div className="p-multiselect-checkbox">
        {/* <input
        type="checkbox"
        checked={selectedMembers.includes(option.value)}
        onChange={() => handleChangenew(option.value)}
      /> */}
        <label>{option.label}</label>
      </div>
    );
  };

  const handleChangenew = (e, value) => {
    console.log(membersNumber, e.value.length, "membersNumber");
    // if (membersNumber == 4) {

    //   if (e.value.length == 3 || e.value.length == 2 || e.value.length == 1) {
    //     setShowCheckbox(true);
    //     setIsValidationError(true);
    //   } else if (e.value.length == 4) {
    //     setShowCheckbox(false);
    //     setIsValidationError(true);
    //   }
    // }

    // else {
    //   setShowCheckbox(false);
    //   setIsValidationError(true);
    // }

    // console.log(e.value,showCheckbox,e.value.length,isValidationError, "dsviosdhovsdosgdivs")
    if (e.value?.length <= membersNumber) {
      // setSelectedMembers(value);
      // setIsValidationError(e.value.length !== membersNumber);

      //  setSelectedMembers(e.value);
      setSelectedMembers(e.value);

      // if (!isSubmitClicked) {
      //   setIsValidationError(e.value.length !== membersNumber+1);

      // }

      const updatedProfile = {
        familyMember1: "",
        familyMember2: "",
        familyMember3: "",
        familyMember4: "",
        familyMember5: "",
        familyMember6: "",
        familyMember7: "",
        familyMember8: "",
      };
      value.forEach((value, index) => {
        if (index !== 0) {
          const key = `familyMember${index}`;
          updatedProfile[key] = value;
        }
      });
      setProfile({ ...profile, ...updatedProfile });
    } else {
    }
  };

  const redirectAfterTxn = async (txnid) => {
    console.log(txnid, "dsvihsdoivhsdoivhsdoivgosduguovsd");
    // try {
    let patientCode = JSON.parse(localStorage.getItem("patientCode"));
    let profile = JSON.parse(localStorage.getItem("profile"));
    let payMode = localStorage.getItem("amount");
    let totalamount = localStorage.getItem("totalamount");
    let sourceChannel = localStorage.getItem("sourceChannel");
    let discountPercentage = 0;
    let masterServiceType = localStorage.getItem("masterServiceType");
    let membershipProductType = localStorage.getItem("membershipProductType");
    let membership = localStorage.getItem("membership");
    let membershipName = localStorage.getItem("membershipName");
    let membershipCode = localStorage.getItem("membershipCode");

    let statuscode;
    if (localStorage.getItem("paymentStatus") === "success") {
      statuscode = 1;
    } else if (localStorage.getItem("paymentStatus") === "pending") {
      statuscode = 30;
    } else if (localStorage.getItem("paymentStatus") === "failure") {
      statuscode = 31;
    } else if (localStorage.getItem("walletstatus") === "success") {
      statuscode = 1;
    }

    let payload = {
      patientCode: patientCode,
      membership: membership,
      membershipCode: membershipCode,
      membershipName: membershipName,
      fromDate: profile?.fromDate,
      toDate: profile?.toDate,
      photo: profile?.photo,
      familyMember1: profile?.familyMember1,
      familyMember2: profile?.familyMember2,
      familyMember3: profile?.familyMember3,
      familyMember4: profile?.familyMember4,
      familyMember5: profile?.familyMember5,
      familyMember6: profile?.familyMember6,
      familyMember7: profile?.familyMember7,
      familyMember8: profile?.familyMember8,

      totalAmount: totalamount,
      modifiedBy: profile?.modifiedBy,
      createdBy: profile?.createdBy,
      curebayFee: profile?.curebayFee,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      mobileNo: profile?.mobileNo,
      email: profile?.email,
      dob: patientData.dob,
      gender: profile?.gender,
      address1: profile?.address1,
      address2: profile?.address2,
      city: profile?.city,
      state: profile?.state,
      pincode: profile?.pincode,
      payMode: payMode,
      status: statuscode,
      sourceChannel: sourceChannel,
      txnId: txnid ? txnid : null,
      discountPercentage: discountPercentage,
      masterServiceType: masterServiceType,
      membershipProductType: membershipProductType,
    };

    try {
      dispatch(onMemberbuy(payload)).then((res) => {
        setPaymentDone(true);
        localStorage.removeItem("paymentStatus");
        // localStorage.removeItem("trancationid");
        localStorage.removeItem("masterServiceType");
        localStorage.removeItem("totalAmount");
        localStorage.removeItem("trancationid");
        localStorage.removeItem("saveCon");
        localStorage.removeItem("saveLab");
        localStorage.removeItem("redirectUrl");
        localStorage.removeItem("patient");
        // localStorage.removeItem("amount");
        localStorage.removeItem("actualAmount");
        localStorage.removeItem("packageCode");
        localStorage.removeItem("totalamount");
        localStorage.removeItem("membership");
        localStorage.removeItem("membershipProductType");
        localStorage.removeItem("payMode");
      });
    } catch (err) {
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("saveCon");
      localStorage.removeItem("saveLab");
      localStorage.removeItem("masterServiceType");
      localStorage.removeItem("redirectUrl");
      localStorage.removeItem("patient");
      localStorage.removeItem("amount");
      localStorage.removeItem("actualAmount");
      localStorage.removeItem("packageCode");
      localStorage.removeItem("payMode");
      localStorage.removeItem("totalamount");
      localStorage.removeItem("membership");
      localStorage.removeItem("membershipProductType");
    }

    //   history.push(APP_ROUTES.MEDICINE_ORDERPLACE);
    // } catch (err) {
    //   console.error(err);
    // } finally {
    //   setTimeout(() => {
    // 	setIsLoading(false);
    //   }, 1000);
    // }
  };

  const DoPay = async () => {
    setErrorDuringPay("");
    if (!selectedPayMode.wallet && !selectedPayMode.card) {
      toast("Please select payment mode.");
      return;
    }

    if (selectedPayMode.wallet) {
      if (
        memberAmount - discountedAmount >
        walletinfo?.patientwalletinfoData?.balance
      ) {
        toast("Balance is not sufficient.");
        return;
      } else {
        setLoadingDuringPayment(true);

        let payload = {
          patientCode: patientinfoData?.code,
          // patientCode: selectedValue,
          // membershipCode: membershipCode,
          // actualAmount: actualAmount,
          // discountAmount: actualAmount - discountedAmount,
          amount: memberAmount,
          // createdBy : selectedValue,
        };

        let data = await http.put(
          `${process.env.REACT_APP_BASEURL}api/v1/payments/wallet/transaction`,
          payload
        );
        // console.log(JSON.stringify(data.data.statusCode), "dsjfsduhfoshfoisd");
        console.log("qqqqqqqq", data);
        if (data.data.statusCode == 200) {
          // let d = new Date();
          // let txdId = tId ? tId : d.getTime().toString();

          // setLoadingDuringPayment(false);
          setIsUserReadyToPay(false);

          localStorage.setItem("redirectUrl", APP_ROUTES.MEMBERSHIP);

          localStorage.setItem(
            "patientCode",
            JSON.stringify(patientinfoData.code)
          );
          localStorage.setItem("profile", JSON.stringify(profile));
          localStorage.setItem("status", "1");
          localStorage.setItem("sourceChannel", "B2C");
          localStorage.setItem("masterServiceType", "Membership");
          localStorage.setItem("totalamount", memberAmount);

          localStorage.setItem("trancationid", data.data.data.transcationId);

          localStorage.setItem("payMode", "CureBay wallet");
          localStorage.setItem("walletstatus", "success");
          localStorage.setItem("membershipProductType", membershiptype);
          localStorage.setItem("membership", type);

          localStorage.setItem("membershipName", profile?.membershipName);
          localStorage.setItem("membershipCode", profile?.membershipCode);
          localStorage.setItem(
            "paymentRemarks",
            "Your Order placed successfully"
          );
          redirectAfterTxn(data.data.data.transcationId);
        } else {
          setLoadingDuringPayment(false);
          setErrorDuringPay("Something went wrong try again.");
        }
      }
    }

    if (selectedPayMode.card) {
      localStorage.setItem("redirectUrl", APP_ROUTES.MEMBERSHIP);

      localStorage.setItem("patientCode", JSON.stringify(patientinfoData.code));
      localStorage.setItem("profile", JSON.stringify(profile));
      // localStorage.setItem("status", "1");
      localStorage.setItem("sourceChannel", "B2C");
      // localStorage.setItem("discountPercentage", "0");
      localStorage.setItem("totalamount", memberAmount);
      localStorage.setItem("masterServiceType", "Membership");
      localStorage.setItem("membershipProductType", membershiptype);
      localStorage.setItem("membership", type);
      localStorage.setItem("membershipName", profile?.membershipName);
      localStorage.setItem("membershipCode", profile?.membershipCode);

      console.log(selectedPayMode, "Sbvisdhovhsdobgsobgsuobs");

      const url =
        process.env.REACT_APP_PAYU_BASEURL +
        `patientId=${patientinfoData.code}&amount=${memberAmount}&firstname=${patientinfoData.firstName}&email=${patientinfoData.email}&phone=${patientinfoData.mobile}&productinfo=membershipPayment&udf1=Patient&service_provider=payu_paisa`;

      window.location.replace(url);
      // history.push("https://localhost:3000/medicine_orderplace?status=success&trancationid=5fbdfde8fa20dfdf7d7c&productinfo=cartpayment&remarks=Thank%20You.%20Your%20order%20status%20is%20success.%20Your%20Transaction%20ID%20for%20this%20transaction%20is%205fbdfde8fa20dfdf7d7c.%20We%20have%20received%20a%20payment%20of%20Rs.%20599.00.%20Your%20order%20will%20soon%20be%20shipped.%20&Mode=CC")
      // redirectAfterTxn("7575757858767");
      // redirectAfterTxn(localStorage.getItem("trancationid"));
    }
    // console.log(url,"url")
  };

  const changeHandler = async (file) => {
    let b64File = await encodeBase64File(file);
    setaddmember({ ...addmember, ["photo"]: b64File });
  };

  const changeHandlernew = async (file, res, index) => {
    console.log(res, "sdvihsdovhdsovhodishviodsv", index);
    let b64File = await encodeBase64File(file);
    console.log(res, "sdvdsviudsgiuvgdiuvds");
    setProfile({ ...profile, ["photo"]: b64File });
    res.photo = b64File;
    dispatch(editPatientDetails(res.id, res)).then((res) => {
      if (index) {
        dispatch(getPatientfamilymembers(patientinfoData?.code));
      } else {
        dispatch(getPatientDetails(patientData.code));
      }
    });
  };
  const showModal = (e, amount, type, familyno) => {
    console.log(e, amount, type, "ddfffff");
    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "membership_detail_view",
      ecommerce: {
        currency: "INR",
        value: amount,
        items: [
          {
            item_id: type+familyno,
            item_name: e+type,
            index: 0,
            item_brand: "CureBay",
            item_category: "membership",
            item_category2: "",
          },
        ],
      },
    });
    if (e == "Diabetes Care") {
      setActiveIndex(0);
    }
    console.log(activeIndex, "index");
    setmembersNumber(familyno);
    setMembership(type);
    console.log(membersNumber);

    setType(e);
    setMemberAmount(amount);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setIsChecked(false);
    setSelectedMembers([defaultMember.value]);
  };

  const showPaymentTypeModal = (values) => {
    console.log("values", values);
    if (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.gender ||
      !values.dob ||
      !values.pinCode ||
      !values.state ||
      !values.city ||
      !values.addressBookAddress1 ||
      !values.addressBookAddress2
    ) {
      message.error("Please fill out all mandatory fields");
      return;
    }

    if (!isChecked) {
      message.error("Please select consent checkbox");
      return;
    }
    if (membershiptype === "WellnessPro") {
      const transformedArray = selectedMembers.map((value) =>
        // console.log(value,"gvhbhjbhjbhjbhj")
        ({ patientId: value })
      );

      // patientService
      // .checktestapi(transformedArray)
      http
        .post(
          `${process.env.REACT_APP_CHE}Patient_Membership/planValid`,
          transformedArray
        )
        // .post(
        //   `https://api-stage.curebay.in/dhp/Patient_Membership/planValid`,
        //   transformedArray
        // )

        .then((res) => {
          console.log(res, "hello");

          if (
            res.status === 200 &&
            res.data &&
            res.data.message === "Successfully"
          ) {
            const patients = res.data.data;
            let allValid = true;
            const invalidPatientsInfo = [];

            patients.forEach((patient) => {
              if (patient.message === "INVALID") {
                allValid = false;
                invalidPatientsInfo.push({
                  id: patient.patientId,
                  name: patient.patientName,
                });
              }
              console.log(
                `Patient ID: ${patient.patientId}, Message: ${patient.message}`
              );
            });

            let invalidPatientsStr = invalidPatientsInfo
              .map((info) => info.name || info.id)
              .join(", ");

            if (!allValid) {
              toast(
                `Following patients are not eligible for Insurance as they do not have valid membership: ${invalidPatientsStr}`
              );
              setIsPaymentTypeModalOpen(false);
            } else {
              setIsPaymentTypeModalOpen(true);
            }
          } else {
            alert("No data received!");
            return;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          alert("An error occurred while fetching data.");
          return;
        });
    } else {
      setIsPaymentTypeModalOpen(true);
    }

    // toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
  };

  const handleOk2 = () => {
    setIsPaymentTypeModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsPaymentTypeModalOpen(false);
  };

  const addFamilyMember = (e, user) => {
    e.preventDefault();
    setshowaddmemberpopup(true);
  };

  const refreshFamilyMembers = () => {
    setIsrefresh(!refresh);

    dispatch(getPatientfamilymembers(patientData.code));
  };
  useEffect(() => {
    dispatch(getPatientfamilymembers(patientData.code));
  }, []);

  useEffect(() => {
    if (Need === "renew") {
      let members = [currentUser.familyMember1];
      setSelectedMembers(members);
      setActiveIndex(
        currentUser.membership == "Single"
          ? 0
          : currentUser.membership == "Twin"
          ? 1
          : 2
      );
      setmembersNumber(
        currentUser.membership == "Single"
          ? 0
          : currentUser.membership == "Twin"
          ? 1
          : 3
      );
      setMemberAmount(currentUser.totalAmount);
      setType(currentUser.membershipProductType);
      setIsModalOpen(true);
    }
  }, []);

  const Plans = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const images = [
    {
      id: "wellness1",
      src: SingleplanImage,
      price: "399",
      price2: "550",
      price3: "699",
      type: 0,
    },
    {
      id: "wellness2",
      src: TwinplanImage,
      price: "599",
      price2: "1100",
      price3: "1299",
      type: 1,
    },
    {
      id: "wellness3",
      src: FamilyplanImage,
      price: "999",
      price2: "2200",
      price3: "2299",
      type: 3,
    },
  ];
  const loginUser = {};
  const getMembership = () => {
    // if(loginUser != {}){

    // }
    // else{
    history.push({
      pathname: APP_ROUTES.HOME,
      // state: { background: location, login: true },
    });
    // }
  };
  const membershipplan = [
    {
      type: "PrimeCure",
      label: "PrimeCure",
      src: SingleplanImage,
    },
    {
      type: "Wellness",
      label: "Wellness",
      src: TwinplanImage,
    },
    {
      type: "Wellness Pro",
      label: "Wellness Top-up Plan",
      src: FamilyplanImage,
    },
    // {
    //   type: "DiabetesCare",
    //   label: "Diabetes Care",
    //   src: "DiabetesplanImage",

    // },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? membershipplan.length - 1 : prevIndex - 1
      );
    } else if (direction === "right") {
      setActiveIndex((prevIndex) =>
        prevIndex === membershipplan.length - 1 ? 0 : prevIndex + 1
      );
    }
  };
  const [selectedPlanLabel, setSelectedPlanLabel] = useState("");

  // const handleArrowClick = (direction) => {
  //   if (direction === "right") {
  //     setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  //   } else if (direction === "left") {
  //     setActiveIndex((prevIndex) =>
  //       prevIndex === 0 ? images.length - 1 : prevIndex - 1
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (initialRender && Need != "Plans") {
  //     setInitialRender(false);
  //     firstContentRef.current.scrollIntoView({ behavior: "smooth" });

  //     return;
  //   }
  //   if (!initialRender || Need == "Plans") {
  //     secondContentRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [needHelp]);

  const clicktype = (index) => {
    const img = document.getElementById(`${index}input`);
    img.click();
  };

  const profileImageViewer = () => {
    return (
      <div className="grid grid-cols-2 gap-2">
        {selectedMembersPhoto?.length > 0 &&
          selectedMembersPhoto.map((res, index) => {
            if (selectedMembersPhoto[0].photoName) {
              return (
                <div className="text-center border rounded-md" key={index}>
                  <img
                    src={selectedMembersPhoto[0]?.photoName}
                    className="w-28 h-28 sm:w-40 sm:h-40"
                    style={{ height: "150px", width: "150px" }}
                    alt={`Profile of ${selectedMembersPhoto[0].firstName}`}
                  />
                  <div className="text-sm font-semibold">{res.firstName}</div>
                </div>
              );
            } else if (index === selectedMembersPhoto.length - 1) {
              // Checking if it's the last element
              return (
                <div className="text-center border rounded-md" key={index}>
                  <img
                    src={
                      addmember.photo
                        ? `${"data:image;base64,"}` + addmember.photo
                        : addmember.photo
                        ? addmember.photo
                        : steth
                    }
                    alt="stethescope"
                    className="lg:w-24 w-20 lg:h-24 h-20 mt-6 rounded-full"
                  />
                  <div className="-mt-4 justify-center flex left-14">
                    <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          changeHandler(e.target.files[0]);
                        }}
                        className="hidden"
                      />
                      <img
                        src={camera}
                        alt="camera"
                        className="w-8 bg-primaryColor rounded-full"
                      />
                    </label>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <div className="flex flex-col px-8 pb-8 pt-3 border_style justify-center items-center rounded-lg text-center cursor-pointer">
                    <div className="sm:w-36 w-full">
                      <div
                        className="relative text-center m-auto"
                        onClick={() => {
                          clicktype(index);
                        }}
                      >
                        <img
                          src={
                            profile.photo
                              ? `${"data:image;base64,"}` + profile.photo
                              : profile.photo
                              ? profile.photo
                              : steth
                          }
                          alt="stethescope"
                          className="lg:w-24 w-20 lg:h-24 h-20 rounded-full m-auto"
                        />
                        <div className="justify-end flex absolute bottom-8 left-14">
                          <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                            <img
                              src={camera}
                              alt="camera"
                              className="w-5 bg-primaryColor"
                            />
                          </label>
                        </div>
                        <div className="mt-4 text-sm font-semibold">
                          {res.firstName}
                        </div>
                      </div>
                      <input
                        className="hidden"
                        id={`${index}input`}
                        ref={hiddenFileInput}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          changeHandlernew(e.target.files[0], res, index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    );
  };

  if (paymentDone) {
    let status = "";
    let amount = JSON.parse(localStorage.getItem("amount"));
    let id = localStorage.getItem("trancationid");
    if (id) {
      status = `Thank You. Your order status is success. Your Transaction ID for this transaction is ${id}. We have received a payment of Rs. ${amount}.`;
    }
    let time = setTimeout(() => {
      // localStorage.removeItem("trancationid");
      localStorage.removeItem("amount");
      localStorage.removeItem("trancationid");
      clearTimeout(time);
    }, 1000);

    ///////////////////////////////////////////////////////////

    return (
      <>
        <div className="flex flex-col items-center justify-center">
          <img className="sm:h-28 h-14 w-32" src={sucessvectoricon} alt="#" />
          <h5
            className="sm:text-2xl text-xl font-bold mt-5 mb-4 text-center"
            style={{ color: "#66B889" }}
          >
            You have successfully purchased the Membership!
          </h5>
          <h6 className="sm:text-lg text-base font-semibold mb-10 text-center">
            Thank you for purchasing membership.
          </h6>
          {/* <p className=" text-brand-primary text-lg font-normal text-center  mb-2">
            {" "}
            {status}
          </p> */}
          <button
            onClick={() => {
              history.push("/mymembership");
              window.location.reload();
            }}
            type="button"
            className="font-semibold text-base text-white sm:px-10 px-8 sm:py-3 py-1 rounded-md"
            style={{ backgroundColor: "#005D8D" }}
          >
            Go to membership
          </button>
          {/* <button
            // onClick={() => history.push(USERPROFILE_ROUTES.PATIENTMEPACKAGEORDERS)}
            style={{ backgroundColor: "#66B889", borderRadius: "5px" }}
            className=" text-white p-2 mr-2 font-semibold text-md"
          >
            My Packages
          </button> */}
        </div>
      </>
    );
  }

  const handleChange1 = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Layouts>
      {/* <Helmet>
          <title>{"HomePage | Curebay"}</title>
          <meta name='description' content={EnglishText.HOME_META_DESCRIPTION} />
          <meta name='title' content={'HomePage | Curebay'} />
          <link rel="canonical" href={window.location.href} />
        </Helmet> */}
      {loadingDuringPayment && <Loader />}
      <SubHeader />
      <section
        ref={firstContentRef}
        className="home-banner bg-White md:min-h-[60vh] py-8 "
      >
        <ToastContainer />
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Annual Health{" "}
                <span className="text-secondaryColor">Membership</span>!
              </h1>
              <p className="text-white md:text-White font-extralight text-sm md:text-lg">
                Discover holistic rural healthcare through CureBay's Annual
                Health Memberships. Beyond insurance, our program offers
                wellness benefits, unlimited doctor consultations, and discounts
                on medicines, tests, and ambulances. Choose from Single, Twin,
                or Family Cards for accessible, affordable, and tailored
                healthcare advancing the journey towards healthcare equality.
              </p>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img
                  src={membership}
                  alt="images"
                  className="w-full h-full object-cover image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primaryColor mt-10" id="membershipCart">
        <div className="container mx-auto py-[50px] md:py-[90px] ">
          <h2 className="text-center text-3xl md:text-5xl font-semibold text-White leading-normal sm:mb-[50px] mb-8">
            Annual Health{" "}
            <span className="text-secondaryColor"> Membership Plans</span>
          </h2>
          <div className="mb-10 md:mb-0">
            <div className="md:w-[90%] mx-auto">
              <div className="flex gap-5 justify-center">
                <h2 className="relative text-center text-2xl md:text-4xl font-semibold text-White leading-normal sm:mb-[50px] mb-4">
                  {membershipplan?.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        display: index === activeIndex ? "inline" : "none",
                      }}
                    >
                      {item?.label}
                    </span>
                  ))}
                </h2>
              </div>

              <div className="flex sm:flex-row flex-col sm:gap-5 gap-2">
                <div className="relative flex items-center justify-center">
                  <div className="bg-white rounded-full p-2 hidden sm:block">
                    <img
                      src={leftarrow}
                      alt="Left Arrow"
                      className="cursor-pointer"
                      //className="cursor-pointer rounded-full mb-12 absolute right-0 transform translate-x-1/2 -translate-y-1/2 mr-6"

                      onClick={() => handleArrowClick("left")}
                    />
                  </div>
                </div>

                <div>
                  {activeIndex === 0 ? (
                    <div className="flex sm:flex-row flex-col gap-5">
                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
            <img
              src={SingleplanImage}
              alt="images"
              className="rounded-xl w-full h-full"
             
            />
          
          </div> */}
                          <div className="flex justify-between flex-col">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0 ">
                                    <span className="text-[#676C80]">
                                      Single
                                    </span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>

                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("singleprimecure")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.singleprimecure ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>

                                {!isOpen.singleprimecure && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {/* Always show list on larger screens or if isOpen is true on mobile */}
                                {(isMobile && isOpen.singleprimecure) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 space-y-2">
                                    <li>Unlimited quick consultations</li>
                                    <li>
                                      100% Discount on 2 Specialist
                                      Consultations
                                    </li>
                                    <li>
                                      15% Discount on Medicines
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Pathology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      15% Discount on Radiology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Dental Treatments
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      25% Discount on Ambulances
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>Free Full Body Check-up#</li>
                                    <li>
                                      Daily Hospital Cash ₹ 1000/- for 30 days
                                    </li>
                                    <li>
                                      ₹ 25,000/- for 9 specified Critical
                                      Illnesses
                                    </li>
                                    <li>
                                      Personal Accidental Insurance of
                                      ₹1,00,000/-
                                    </li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                            {(isMobile && isOpen.singleprimecure) ||
                            !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal("Single", "699", "PrimeCure", "1")
                                  }
                                  role="button"
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 699/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “one member”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
   <img
     src={TwinplanImage}
     alt="images"
     className="rounded-xl w-full h-full"
    
   />
  
  </div> */}
                          <div className="flex justify-between flex-col ">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0 ">
                                    <span className="text-[#676C80]">Twin</span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("twinprimecure")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.twinprimecure ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {!isOpen.twinprimecure && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}

                                {(isMobile && isOpen.twinprimecure) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 space-y-2">
                                    <li>Unlimited quick consultations</li>
                                    <li>
                                      100% Discount on 2 Specialist
                                      Consultations
                                    </li>
                                    <li>
                                      15% Discount on Medicines
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Pathology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      15% Discount on Radiology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Dental Treatments
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      25% Discount on Ambulances
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>Free Full Body Check-up#</li>
                                    <li>
                                      Daily Hospital Cash ₹ 1000/- for 30 days
                                    </li>
                                    <li>
                                      ₹ 25,000/- for 9 specified Critical
                                      Illnesses
                                    </li>
                                    <li>
                                      Personal Accidental Insurance of
                                      ₹1,00,000/-
                                    </li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                            {(isMobile && isOpen.twinprimecure) || !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal("Twin", "1299", "PrimeCure", "2")
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 1299/-
                                </div>
                                <div className="sm:mt-6 mt-3  mb-2 flex ">
                                  <div>
                                    <img
                                      src={info}
                                      alt="images"
                                      className="mt-2"
                                    />
                                  </div>
                                  <div className="ml-1">
                                    <span className="sm:text-base text-xs ">
                                      This membership is for{" "}
                                      <span className="text-[#004171] font-semibold">
                                        “two members”
                                      </span>
                                      .
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="bg-White rounded-xl">
                        {/* <div className="md:flex w-full"> */}
                        {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
<img
src={FamilyplanImage}
alt="images"
className="rounded-xl w-full h-full"

/>

</div> */}
                        <div className="flex justify-between flex-col ">
                          <div className="p-4 md:p-8 ">
                            <div className="flex flex-col">
                              <div className="flex justify-between sm:justify-center  ">
                                <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0  text-center">
                                  <span className="text-[#676C80]">Family</span>
                                  <span className="text-[#42BA85] sm:ml-2 ml-1">
                                    Membership
                                  </span>
                                </h5>
                                {isMobile ? (
                                  <div
                                    onClick={() =>
                                      toggleList("familyprimecure")
                                    }
                                    className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                  >
                                    <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                      {isOpen.familyprimecure ? (
                                        <UpOutlined className="text-[#000000] text-sm" />
                                      ) : (
                                        <DownOutlined className="text-[#000000] text-sm" />
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {!isOpen.familyprimecure && isMobile ? (
                                ""
                              ) : (
                                <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                              )}
                              {(isMobile && isOpen.familyprimecure) ||
                              !isMobile ? (
                                <ul className="list-disc text-textGray text-sm md:text-lg pl-5 space-y-2">
                                  <li>Unlimited quick consultations</li>
                                  <li>
                                    100% Discount on 2 Specialist Consultations
                                  </li>
                                  <li>
                                    15% Discount on Medicines
                                    <span className="text-[#EE2222]">* </span>
                                  </li>
                                  <li>
                                    20% Discount on Pathology Tests
                                    <span className="text-[#EE2222]">* </span>
                                  </li>
                                  <li>
                                    15% Discount on Radiology Tests
                                    <span className="text-[#EE2222]">* </span>
                                  </li>
                                  <li>
                                    20% Discount on Dental Treatments
                                    <span className="text-[#EE2222]">* </span>
                                  </li>
                                  <li>
                                    25% Discount on Ambulances
                                    <span className="text-[#EE2222]">* </span>
                                  </li>
                                  <li>Free Full Body Check-up#</li>
                                  <li>
                                    Daily Hospital Cash ₹ 1000/- for 30 days
                                  </li>
                                  <li>
                                    ₹ 25,000/- for 9 specified Critical
                                    Illnesses
                                  </li>
                                  <li>
                                    Personal Accidental Insurance of ₹1,00,000/-
                                  </li>
                                </ul>
                              ) : null}
                            </div>
                          </div>
                          {(isMobile && isOpen.familyprimecure) || !isMobile ? (
                            <div className="sm:p-6 p-2">
                              <div
                                onClick={() =>
                                  showModal("Family", "2299", "PrimeCure", "4")
                                }
                                className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                              >
                                Buy @ ₹ 2299/-
                              </div>
                              <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                <img src={info} alt="images" className="" />
                                <span className="sm:text-base text-xs ml-2">
                                  This membership is for{" "}
                                  <span className="text-[#004171] font-semibold">
                                    “four members”
                                  </span>
                                  .
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  ) : activeIndex === 1 ? (
                    <div className="flex sm:flex-row flex-col gap-5">
                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
    <img
      src={SingleplanImage}
      alt="images"
      className="rounded-xl w-full h-full"
     
    />
  </div> */}
                          <div className="flex justify-between flex-col ">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0 text-center">
                                    <span className="text-[#676C80]">
                                      Single
                                    </span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("singlewellness")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.singlewellness ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>

                                {!isOpen.singlewellness && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {(isMobile && isOpen.singlewellness) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 leading-loose">
                                    <li>Unlimited quick consultations</li>
                                    <li>
                                      100% Discount on 2 Specialist
                                      Consultations
                                    </li>
                                    <li>
                                      15% Discount on Medicines
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Pathology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      15% Discount on Radiology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Dental Treatments
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      25% Discount on Ambulances
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>Free Full Body Check-up#</li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                            {(isMobile && isOpen.singlewellness) ||
                            !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal("Single", "399", "Wellness", "1")
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 399/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “one member”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
<img
src={TwinplanImage}
alt="images"
className="rounded-xl w-full h-full"

/>
</div> */}
                          <div className="flex justify-between flex-col ">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className="text-base md:text-2xl font-medium sm:mb-4 mb-0  text-center">
                                    <span className="text-[#676C80]">Twin</span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() => toggleList("twinwellness")}
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.twinwellness ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {!isOpen.twinwellness && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {(isMobile && isOpen.twinwellness) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 leading-loose">
                                    <li>Unlimited quick consultations</li>
                                    <li>
                                      100% Discount on 2 Specialist
                                      Consultations
                                    </li>
                                    <li>
                                      15% Discount on Medicines
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Pathology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      15% Discount on Radiology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Dental Treatments
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      25% Discount on Ambulances
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>Free Full Body Check-up#</li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                            {(isMobile && isOpen.twinwellness) || !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal("Twin", "599", "Wellness", "2")
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 599/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “two members”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
<img
src={FamilyplanImage}
alt="images"
className="rounded-xl w-full h-full"
id={images[activeIndex].id}
/>
</div> */}
                          <div className="flex justify-between flex-col ">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0 text-center">
                                    <span className="text-[#676C80]">
                                      Family
                                    </span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("familywellness")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.familywellness ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {!isOpen.familywellness && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {(isMobile && isOpen.familywellness) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 leading-loose">
                                    <li>Unlimited quick consultations</li>
                                    <li>
                                      100% Discount on 2 Specialist
                                      Consultations
                                    </li>
                                    <li>
                                      15% Discount on Medicines
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Pathology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      15% Discount on Radiology Tests
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      20% Discount on Dental Treatments
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>
                                      25% Discount on Ambulances
                                      <span className="text-[#EE2222]">* </span>
                                    </li>
                                    <li>Free Full Body Check-up#</li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                            {(isMobile && isOpen.familywellness) ||
                            !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal("Family", "999", "Wellness", "4")
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 999/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “four members”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeIndex === 2 ? (
                    <div className="flex sm:flex-row flex-col gap-5">
                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
        <img
          src={SingleplanImage}
          alt="images"
          className="rounded-xl w-full h-full"
         
        />
    
      </div> */}
                          <div className="flex justify-between flex-col ">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0 text-center">
                                    <span className="text-[#676C80]">
                                      Single
                                    </span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("singlewellnesspro")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.singlewellnesspro ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {!isOpen.singlewellnesspro && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {(isMobile && isOpen.singlewellnesspro) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 leading-loose">
                                    <li>Existing Wellness Benefits</li>

                                    <li>
                                      Daily Hospital Cash ₹ 1000/- for 30 days
                                    </li>
                                    <li>
                                      ₹ 25,000/- for 9 specified Critical
                                      Illnesses##
                                    </li>
                                    <li>
                                      Personal Accidental Insurance of
                                      ₹1,00,000/-
                                    </li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                            {(isMobile && isOpen.singlewellnesspro) ||
                            !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal(
                                      "Single",
                                      "550",
                                      "WellnessPro",
                                      "1"
                                    )
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 550/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “one member”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          {/* <div className="imageShadow bg-[#015781] rounded-xl rounded-br-none md:w-[35%] relative">
 <img
   src={TwinplanImage}
   alt="images"
   className="rounded-xl w-full h-full"

 />

</div> */}
                          <div className="flex justify-between flex-col">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0">
                                    <span className="text-[#676C80]">Twin</span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("twinwellnesspro")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb-0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.twinwellnesspro ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {!isOpen.twinwellnesspro && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {(isMobile && isOpen.twinwellnesspro) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 leading-loose">
                                    <li>Existing Wellness Benefits</li>

                                    <li>
                                      Daily Hospital Cash ₹ 1000/- for 30 days
                                    </li>
                                    <li>
                                      ₹ 25,000/- for 9 specified Critical
                                      Illnesses##
                                    </li>
                                    <li>
                                      Personal Accidental Insurance of
                                      ₹1,00,000/-
                                    </li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>

                            {(isMobile && isOpen.twinwellnesspro) ||
                            !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal(
                                      "Twin",
                                      "1100",
                                      "WellnessPro",
                                      "2"
                                    )
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 1100/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “two members”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="bg-White rounded-xl">
                        <div className="md:flex w-full">
                          <div className="flex justify-between flex-col">
                            <div className="p-4 md:p-8 ">
                              <div className="flex flex-col">
                                <div className="flex justify-between sm:justify-center  ">
                                  <h5 className=" text-base md:text-2xl font-medium sm:mb-4 mb-0 text-center">
                                    <span className="text-[#676C80]">
                                      Family
                                    </span>
                                    <span className="text-[#42BA85] sm:ml-2 ml-1">
                                      Membership
                                    </span>
                                  </h5>
                                  {isMobile ? (
                                    <div
                                      onClick={() =>
                                        toggleList("familywellnesspro")
                                      }
                                      className="flex items-center cursor-pointer sm:mb-3 mb:0"
                                    >
                                      <div className="rounded-full border border-primaryColor  w-5 h-5 flex justify-center items-center">
                                        {isOpen.familywellnesspro ? (
                                          <UpOutlined className="text-[#000000] text-sm" />
                                        ) : (
                                          <DownOutlined className="text-[#000000] text-sm" />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {!isOpen.familywellnesspro && isMobile ? (
                                  ""
                                ) : (
                                  <div className=" border-[0.5px] border-[#f3f4f6] sm:mb-4 mb-3 sm:mt-0 mt-3"></div>
                                )}
                                {(isMobile && isOpen.familywellnesspro) ||
                                !isMobile ? (
                                  <ul className="list-disc text-textGray text-sm md:text-lg pl-5 leading-loose">
                                    <li>Existing Wellness Benefits</li>

                                    <li>
                                      Daily Hospital Cash ₹ 1000/- for 30 days
                                    </li>
                                    <li>
                                      ₹ 25,000/- for 9 specified Critical
                                      Illnesses##
                                    </li>
                                    <li>
                                      Personal Accidental Insurance of
                                      ₹1,00,000/-
                                    </li>
                                  </ul>
                                ) : null}
                              </div>
                            </div>

                            {(isMobile && isOpen.familywellnesspro) ||
                            !isMobile ? (
                              <div className="sm:p-6 p-2">
                                <div
                                  onClick={() =>
                                    showModal(
                                      "Family",
                                      "2200",
                                      "WellnessPro",
                                      "4"
                                    )
                                  }
                                  className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl py-3 md:py-6 cursor-pointer"
                                >
                                  Buy @ ₹ 2200/-
                                </div>
                                <div className="sm:mt-6 mt-3 text-center flex justify-center items-center mb-2">
                                  <img src={info} alt="images" className="" />
                                  <span className="sm:text-base text-xs ml-1">
                                    This membership is for{" "}
                                    <span className="text-[#004171] font-semibold">
                                      “four members”
                                    </span>
                                    .
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="relative flex items-center justify-center  ">
                  <div className="bg-white rounded-full p-2 hidden sm:block">
                    <img
                      src={rightarrow}
                      alt="Right Arrow"
                      className="cursor-pointer"
                      //className="cursor-pointer rounded-full mb-12 absolute right-0 transform translate-x-1/2 -translate-y-1/2 ml-16"
                      onClick={() => handleArrowClick("right")}
                    />
                  </div>
                </div>

                <div className="flex justify-center gap-8 w-full max-w-xl sm:hidden">
                  <div className="bg-white rounded-full p-2">
                    <div className="rounded-full border-2 border-White  w-10 h-10 flex justify-center items-center">
                      <img
                        src={leftarrow}
                        alt="Left Arrow"
                        className="cursor-pointer rounded-full mr-1"
                        onClick={() => handleArrowClick("left")}
                      />
                    </div>
                  </div>
                  <div className="bg-white rounded-full p-2">
                    <div className="rounded-full border-2 border-White  w-10 h-10 flex justify-center items-center">
                      <img
                        src={rightarrow}
                        alt="Right Arrow"
                        className="cursor-pointer rounded-full ml-1"
                        onClick={() => handleArrowClick("right")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-White">
        <div className="container mx-auto  py-[50px] md:py-[90px] ">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
            <span className="text-secondaryColor">Diabetes Care</span> by
            CureBay
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="md:hidden block w-full md:h-[400px]  rounded ">
              <img
                src={stethescope}
                alt="images"
                className="w-full h-full object-cover image-boxshadow rounded-xl"
              />
            </div>
            <div className="flex flex-col">
              <p className="text-lg leading-loose text-textGray">
                Discover and manage diabetes effectively with CureBay's
                comprehensive DiabetesCare plan. Empowering you to take control
                of your health, our specialized program provides personalized
                solutions for diabetes management. From education and monitoring
                to treatment and support, we're here to help you live your best
                life while effectively managing your diabetes.
              </p>
              <div>
                <h3 className="text-[#42BA85] font-bold text-2xl py-3">
                  Benefits :
                </h3>
                <ul className="list-disc text-textGray text-sm md:text-lg pl-5 space-y-3">
                  <li>Free unlimited quick consultations.</li>
                  <li>
                    100% off on 1 personalized nutrition/diet and 2 specialists
                    consultations.
                  </li>
                  <li>
                    15% discount on prescribed medicines (min. purchase ₹ 500).
                  </li>
                  <li>
                    20% off on Pathology Tests, 15% off on Radiology Tests.
                  </li>
                  <li>Free Full Body Check-up worth ₹ 1250.</li>
                  <li>Diabetes package Worth ₹ 1750, Just at ₹ 699</li>
                  <li>
                    Periodic complete Haemogram, HbA1C, Blood Glucose Fasting,
                    Lipid Profile, Liver Function Test, Kidney Function Test,
                    Urine R/M Extended.
                  </li>
                  <li>
                    Monthly online sessions on nutrition, exercise and
                    counselling sessions for decreasing stress
                  </li>
                </ul>
              </div>
              <div
                onClick={() =>
                  showModal("Diabetes Care", "499", "DiabetesCare", "1")
                }
                className="bg-[#005D8D] hover:bg-secondaryColor text-White text-base md:text-lg text-center rounded-xl mt-8 p-6 py-3 md:py-6 cursor-pointer"
              >
                Buy @ ₹ 499/-
              </div>
            </div>
            <div className="hidden md:block w-full md:h-[400px]  rounded ">
              <img
                src={stethescope}
                alt="images"
                className="w-full h-full object-cover image-boxshadow rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightGray">
        <div className="container md:w-[60%] mx-auto py-[50px] md:py-[90px]">
          <div className="bg-White common-shadow p-5 md:p-6 text-textGray text-[7px] md:text-xs rounded-xl text-justify">
            <div className="font-medium text-[9px] md:text-xs pb-2">
              *All membership plans are valid for a year. Conditions apply.
            </div>
            <div className="font-medium text-secondaryColor">
              #34 Full Body Check-up parameters include:
            </div>
            <div className="text-[9px] md:text-xs">
              KIDNEY PROFILE-Uric Acid, DIABETES PROFILE-Blood Sugar
              (Fasting/Random), HEART PROFILE-Cholesterol, LIVER PROFILE-SGOT,
              CBC - HB, RBC, PCV, MCV, MCH, MCHC, RDW(CV), RDW-SD, TLC,
              Neutrophils, Lymphocytes, Monocytes, Eosinophils, Basophils,
              Absolute Neutrophils, Absolute Lymphocytes, Absolute Monocytes,
              Absolute Eosinophils, Absolute Basophils, Platelet, MPV, PCT, PDW,
              P-LCR, P-LCC, Mentzer Index
            </div>
            <div className="font-medium text-secondaryColor mt-2">
              ## 9 Specified Critical Illnesses include:
            </div>
            <div className="text-[9px] md:text-xs">
              Cancer of specified severity, Kidney failure requiring regular
              dialysis, Multiple Sclerosis with persistent symptoms, Major
              Organ/Bone marrow Transplant, Open Heart Replacement or Repair of
              Heart Valves, Open Chest CABG (Coronary Artery Bypass Graft),
              Stroke resulting in permanent symptoms, Permanent Paralysis of
              Limbs, Myocardial Infarction (First Heart Attack of specified
              severity)
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={
          <div className="flex justify-between items-center w-full bg-primaryColor rounded-md text-White">
            <p className="">
              Buy {membershiptype} Pack - {type} Membership
            </p>
          </div>
        }
        centered={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
        footer={false}
        width="70%"
        className="commonModal"
      >
        <div className="md:flex ">
          <div className=" items-center bg-white">
            <div className="w-full flex justify-end">
              <button
                className="text-secondaryColor hover:underline cursor-pointer bg-transparant font-semibold text-base  mb-4  "
                onClick={(e) => addFamilyMember(e, "")}
              >
                {" "}
                + Add a Family Member
              </button>
            </div>
            <Form
              initialValues={{
                salutation: patientData?.salutation,
                firstName: patientData?.firstName,
                lastName: patientData?.lastName,
                mobile: patientData?.mobile,
                email: patientData?.email,
                date: patientData?.date,
                gender: patientData?.gender,
                dob: patientData ? dateObject : new Date(),
                pinCode: patientData?.pinCode,
                addressBookAddress1: patientData?.addressBookAddress1,
                addressBookAddress2: patientData?.addressBookAddress2,
                state: patientData?.state,
                city: patientData?.city,
              }}
              layout="vertical"
              autoComplete="off"
              onFinish={showPaymentTypeModal}
              form={form}
              className="CommonForm"
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8}>
                  <div className="flex justify-center w-auto h-[100px] rounded-lg text-primaryColor">
                    <div className="">
                      {selectedMembersPhoto[0]?.photoName ? (
                        <div className="text-center rounded-md" key={0}>
                          <img
                            src={selectedMembersPhoto[0]?.photoName}
                            className="w-10 h-10 sm:w-40 sm:h-40"
                            style={{ height: "96px", width: "96px" }}
                            alt={`Profile of ${selectedMembersPhoto[0].firstName}`}
                          />
                          {/* <div className="text-sm font-semibold">
                              {selectedMembersPhoto[0].firstName}
                            </div> */}
                        </div>
                      ) : 0 === selectedMembersPhoto.length - 1 ? (
                        <div className="text-center rounded-md" key={0}>
                          <img
                            src={
                              addmember.photo
                                ? `${"data:image;base64,"}` + addmember.photo
                                : addmember.photo
                                ? addmember.photo
                                : steth
                            }
                            alt="stethescope"
                            className="lg:w-24 w-20 lg:h-24 h-20 rounded-full"
                          />
                          <div className="-mt-4 justify-center flex left-14">
                            <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  changeHandler(e.target.files[0]);
                                }}
                                className="hidden"
                              />
                              <img
                                src={camera}
                                alt="camera"
                                className="w-8 bg-primaryColor rounded-full"
                              />
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div key={0}>
                          <div className="flex flex-col pt-3 border_style justify-center items-center rounded-lg text-center cursor-pointer">
                            <div className="sm:w-36 w-full">
                              <div
                                className="relative text-center m-auto"
                                onClick={() => {
                                  clicktype(0);
                                }}
                              >
                                <img
                                  src={
                                    profile.photo
                                      ? `${"data:image;base64,"}` +
                                        profile.photo
                                      : profile.photo
                                      ? profile.photo
                                      : steth
                                  }
                                  alt="stethescope"
                                  className="lg:w-24 w-20 lg:h-24 h-20 rounded-full m-auto"
                                />
                                <div className="justify-end flex absolute bottom-8 left-14">
                                  <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                                    <img
                                      src={camera}
                                      alt="camera"
                                      className="w-5 bg-primaryColor"
                                    />
                                  </label>
                                </div>
                                <div className="mt-4 text-sm font-semibold">
                                  {selectedMembersPhoto[0]?.firstName}
                                </div>
                              </div>
                              <input
                                className="hidden"
                                id={`${0}input`}
                                ref={hiddenFileInput}
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  changeHandlernew(
                                    e.target.files[0],
                                    selectedMembersPhoto[0],
                                    0
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Image section */}
                    {/* <div className="">
                        <img
                          src={
                            addmember.photo
                              ? `${"data:image;base64,"}` + addmember.photo
                              : addmember.photo
                                ? addmember.photo
                                : steth
                          }
                          alt="stethescope"
                          className="lg:w-24 w-20 lg:h-24 h-20 mt-6 rounded-full"
                        />
                        <div className="-mt-4 justify-center  flex  left-14">
                          <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                changeHandler(e.target.files[0]);
                              }}
                              className="hidden"
                            />
                            <img
                              src={camera}
                              alt="camera"
                              className="w-8 bg-primaryColor rounded-full"
                            />
                          </label>
                        </div>
                      </div> */}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        First Name <span className="text-danger">*</span>
                      </span>
                    }
                    name="firstName"
                  >
                    <Input placeholder="Enter First name" className="h-12" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Last Name <span className="text-danger">*</span>
                      </span>
                    }
                    name="lastName"
                  >
                    <Input placeholder="Enter Last name" className="h-12" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Mobile Number" name="mobile">
                    <Input
                      placeholder="Enter Number"
                      className="h-12"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Email ID <span className="text-danger">*</span>
                      </span>
                    }
                    name="email"
                  >
                    <Input placeholder="Enter Email ID" className="h-12" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        To Date <span className="text-danger">*</span>
                      </span>
                    }
                    name="date"
                  >
                    {/* <Input
                      id="todate"
                      name="todate"
                      className="h-12"
                      value={moment(futureDate2).format("DD/MM/yyyy")}
                    /> */}
                    <div className="border border-gray  mt- rounded-md">
                      <input
                        id="todate"
                        name="todate"
                        className="focus:outline-none w-full h-12 py-2 px-3 text-xs rounded-md text-gray-600  peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440"
                        dropdownMode="select"
                        // minDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd/MM/yyyy"
                        readOnly
                        value={moment(futureDate2).format("MM/DD/YYYY")}
                        // onSelect={changetoDate}
                        placeholder="DD/MM/YYYY"
                        disabledKeyboardNavigation={true}
                        autoFocus={false}
                        disabled={true} // Disabling input
                        // disabledDate={(current) => current && current < moment().endOf('day')}
                        // suffixIcon={null}
                      />
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Select Gender <span className="text-danger">*</span>
                      </span>
                    }
                    name="gender"
                  >
                    <Select
                      placeholder="Select Gender"
                      className="h-12"
                      options={[
                        { value: "", label: "Select Gender" },
                        { value: "M", label: "Male" },
                        { value: "F", label: "Female" },
                        { value: "O", label: "Others" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Date of Birth <span className="text-danger">*</span>
                      </span>
                    }
                    name="dob"
                  >
                    {/* <Input placeholder="DOB" className="h-12" /> */}
                    <DatePicker
                      placeholder="Please Select Your Date of Birth"
                      format="MM/DD/YYYY"
                      disabledDate={disabledDate}
                      className="w-full"
                      // value={patientData?.dob ? patientData.dob : ""}
                      onChange={changeDate}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Pincode <span className="text-danger">*</span>
                      </span>
                    }
                    name="pinCode"
                  >
                    <Input
                      placeholder="Enter Pincode"
                      className="h-12"
                      maxLength={6}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Delivery Address 1{" "}
                        <span className="text-danger">*</span>
                      </span>
                    }
                    name="addressBookAddress1"
                  >
                    <TextArea placeholder="Type Address" className="h-12" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Delivery Address 2{" "}
                        <span className="text-danger">*</span>
                      </span>
                    }
                    name="addressBookAddress2"
                  >
                    <TextArea placeholder="Type Address" className="h-12" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Select State <span className="text-danger">*</span>
                      </span>
                    }
                    name="state"
                  >
                    <select
                      id="state"
                      name="state"
                      value={addmember?.state}
                      placeholder="Select State"
                      //   disabled={!showinput}
                      className=" w-full h-12 border border-gray  rounded-md"
                      // className="border-b-2 border-gray-300 text-xs mt-2 w-full bg-transparent text-gray-900 focus:outline-none"
                      onChange={handleChange}
                    >
                      {
                        <option disabled={true} value={""}>
                          Select State
                        </option>
                      }
                      {stateData.map((state) => (
                        <option key={state.code} value={state.code}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Select City <span className="text-danger">*</span>
                      </span>
                    }
                    name="city"
                  >
                    <Select
                      showSearch
                      placeholder="Select City"
                      style={{ height: 50 }}
                    >
                      {addmember.state &&
                        cityData
                          .filter((city) => city.state_code === addmember.state)
                          .map((city, index) => (
                            <option key={index} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}></Col>
                <Col span={8}></Col>
                <Col xs={24} sm={24} md={8}>
                  {/* {activeIndex !== 0 && membersNumber + 1 > 0 && ( */}
                  <div className="flex bg-lightGreen p-5 rounded-md">
                    <div className="w-full   mt- rounded-md">
                      <label className="text-secondaryColor font-semibold text-sm">
                        Select Family Member{" "}
                      </label>{" "}
                      <span className="text-danger">*</span>
                      <div className="border border-secondaryColor mt-2 rounded-md">
                        {/* <Select
    mode="tags"
    style={{
      width: '100%',
    }}
    onChange={handleChange1}
    tokenSeparators={[',']}
    options={options}
  /> */}
                        <MultiSelect
                          value={selectedMembers}
                          onChange={(e) => handleChangenew(e, e.value)}
                          options={options}
                          optionLabel="label"
                          optionValue="value"
                          placeholder="Select Family Member"
                          className="w-full h-12 p-2 rounded-md"
                          panelClassName="mb-2 border bg-White p-2  rounded-md"
                          name="member"
                          itemTemplate={optionTemplate}
                        />
                      </div>
                      {isValidationError && (
                        <label
                          for="address1"
                          className=" left-0 -top-3.5 text-red-600 text-xs peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                        >
                          Please select {membersNumber}{" "}
                          {membersNumber == 1 ? "member" : "members"}
                        </label>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                </Col>
                {/* <Col span={24}>
                  <Accordion
                    items={selectedMembers}
                    familyMembesrsDetails={FamilymembersData}
                  />
                </Col> */}
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "You must consent to the terms and conditions",
                      },
                    ]}
                  >
                    <div className="flex items-start">
                      <input
                        className="mt-1"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <p className="ml-2 text-sm">
                        I have read the terms and conditions and I with my full
                        knowledge give my
                        <strong
                          className="cursor-pointer text-primaryColor"
                          onClick={() => setconditionModal(true)}
                        >
                          {" "}
                          Consent?
                        </strong>
                      </p>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item className=" md:flex justify-center items-center">
                    <Button
                      htmlType="submit"
                      className="bg-primaryColor text-White w-full md:w-96 h-[50px]"
                      size="large"
                    >
                      Buy Now
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        open={conditionModal}
        title={<p className="text-center font-bold text-2xl">Consent forms</p>}
        centered
        footer={null}
        onCancel={() => setconditionModal(false)}
        width={900}
        style={{ padding: "5px" }}
      >
        <div className="conditiondiv p-8">
          <h5 className="font-normal text-base text-justify mb-2 border p-6 ">
            I confirm that I am in good health and have not currently or in the
            past 5 years been suffering or receiving medication in respect of
            high blood pressure, diabetes, or any other serious illness. I also
            confirm that I have never been postponed or declined for Critical
            Illness coverage and that I have never been diagnosed or received
            medical care for any of the following conditions: -Medical care for
            any of the following conditions: 1. Stroke (including Transient
            Ischemic attack) 2. Hepatitis B or C 3. Alcoholism 4. Drug Abuse 5.
            Cancer or any Tumour 6. Melanoma 7. Abnormal Kidney Functions 8.
            Alzheimer’s or Senile Dementia 9. Recurrent Human Papilloma Virus
            (HPV) or Sexually Transmitted Disease (within the past 5 years) 10.
            Acquired Immune Deficiency Syndrome (AIDS), AIDS Related Complex
            (ARC). Human Immunodeficiency, infection (symptomatic or
            asymptomatic) 11. Any Disease or Disorder of the Nervous System 12.
            Heart Attack 13. Diabetes 14. Hypertension - I, the undersigned, on
            behalf of all persons proposed to be insured, hereby declare and
            warrant that the above statements are true, accurate and complete. I
            desire to effect an insurance as described herein with the Company
            and I agree that this proposal and declarations hereto shall be the
            basis of contract between me and the Company and I agree to accept a
            Policy subject to the conditions prescribed by the Company. - I
            agree that the Policy shall become voidable at the option of the
            Company, in the event of any untrue or incorrect statement,
            misrepresentation, non-description or non-disclosure in any material
            particular in the proposal from / personal statement, declaration
            and connected documents, or any material information has been
            withheld by me or anyone acting or my behalf to obtain any benefit
            under this Policy. - I understand that the information provided by
            me will form the basis of the insurance policy, is subject to the
            Board approved underwriting policy of the insurer and that the
            policy will come into force only after full payment of the premium
            chargeable. - I further declare that I will notify in writing any
            change occurring in the occupation or general health of the life to
            be insured/proposer after the proposal has been submitted but before
            communication of the risk acceptance by the company - I declare that
            I consent to the company seeking medical information from any doctor
            or hospital who/which at any time has attended on the person to be
            insured/proposer or from any past or present employer concerning
            anything which affects the physical or mental health of the person
            to be insured/proposer and seeking information from any insurer to
            whom an application for insurance on the person to be
            insured/proposer has been made for the purpose of underwriting the
            proposal and/or claim settlement. - I authorize the company to share
            information pertaining to my proposal including the medical records
            of the insured/proposer for the sole purpose of underwriting the
            proposal and/or claims settlement and with any Governmental and/or
            Regulatory authority.
          </h5>
        </div>
      </Modal>

      <Modal
        title="Add a Family Member"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        width={1200}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshFamilyMembers();
        }}
        style={{ top: 20 }}
        footer={[]}
      >
        <AddFamilymember
          refresh={refresh}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshFamilyMembers();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            toast(message);
            refreshFamilyMembers();
          }}
        />
      </Modal>

      <Modal
        title={
          <p className="font-semibold text-primaryColor text-xl">
            Select Payment Mode
          </p>
        }
        open={IsPaymentTypeModalOpen}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[]}
        className="w-11/12 md:w-96"
      >
        <div
          className="text-base  text-center mb-4 bg-lightGray cursor-pointer rounded-md p-2"
          style={{
            backgroundColor: "#e5e6e7",
            border: selectedPayMode.wallet ? "2px solid #66B889" : "",
          }}
          onClick={() => setSelectedPayMode({ wallet: true, card: false })}
        >
          CureBay wallet - Rs{" "}
          {walletinfo?.patientwalletinfoData?.balance &&
            walletinfo?.patientwalletinfoData?.balance.toFixed(2)}{" "}
        </div>
        <div
          className="text-base text-center mb-4 bg-lightGray cursor-pointer rounded-md p-2"
          style={{
            backgroundColor: "#e5e6e7",
            border: selectedPayMode.card ? "2px solid #66B889" : "",
          }}
          onClick={() => setSelectedPayMode({ wallet: false, card: true })}
        >
          Debit/Credit Cards/UPI & Others
        </div>
        <Button
          className="bg-secondaryColor text-White w-52 flex mx-auto justify-center"
          size="large"
          onClick={DoPay}
        >
          Pay
        </Button>
      </Modal>
    </Layouts>
  );
}

export default Membership;
