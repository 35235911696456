import React from "react";
import Layouts from "../Layouts";
import DoctorConsImage from "../../Assets/Images/Icons/doctor-consultation.svg";
import PharmacyImage from "../../Assets/Images/Icons/pharmacy-icon.svg";
import DiagnosisImage from "../../Assets/Images/Icons/diagnosis-icon.svg";
import SurgeryImage from "../../Assets/Images/Icons/surgery-icons.png";
import ConciergeImage from "../../Assets/Images/Icons/concierge-icons.svg";
import PrevArrow from "../../Assets/Images/left_arrow.png";
import NextArrow from "../../Assets/Images/right_arrow.png";
import Slider from "react-slick";
import SubHeader from "../Shared/SubHeader";

function WhyCurebay() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        healths: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Layouts>
      <SubHeader />
      <div className="">
        <section className="home-banner min-h-[60vh] py-8 ">
          <div className="container mx-auto">
            <div className="bg-primaryColor absolute left-0 md:w-[70%] h-[60vh] rounded-e-xl"></div>
            <div className="md:grid grid-cols-2 gap-5 min-h-[60vh] items-center">
              <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                  From Vision to{" "}
                  <span className="text-secondaryColor">Victory</span>!
                </h1>
                <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                  Amidst the COVID-19 upheaval, the glaring healthcare chasm
                  between urban and rural India sparked a transformative
                  realization. With bustling cities boasting specialized
                  hospitals while rural communities, comprising 65% of the
                  population, grapple with basic medical services, the urgency
                  for change was undeniable. This realization spurred action in
                  2021. Thus, CureBay was born—a beacon of hope leveraging
                  technology and empathy to deliver top-notch healthcare to
                  remote corners. More than bridging geographical gaps, CureBay
                  embodies a mission to democratize healthcare, touching
                  thousands of lives, fostering empowerment, and dismantling
                  barriers to access and affordability.
                </p>
              </div>
              <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Why%20CureBay%20Banner.webp"
                    alt="images"
                    className="w-full h-full object-cover image-boxshadow rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-primaryColor md:bg-White">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <div className="mb-10">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-White md:text-primaryColor leading-normal mb-5">
                Healthcare <span className="text-secondaryColor">Harmony</span>
              </h2>
              <p className="text-center text-White md:text-textGray md:leading-loose text-sm md:text-lg">
                Revolutionizing Access with CureBay’s Innovative Hybrid Model
              </p>
            </div>
            <div className="hidden md:block md:grid grid-cols-3 gap-5">
              <div class="bg-White hover:bg-primaryColor rounded-lg common-shadow hoverWhite">
                <div className="w-full justify-end ">
                  <div className="w-full md:h-[230px]">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/our_eclinic.png"
                      alt="images"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                  <div className=" p-5 rounded-2xl">
                    <h6 className="text-xl font-bold text-primaryColor leading-normal mb-3">
                      Our <span className="text-secondaryColor">eClinics</span>
                    </h6>
                    <p className="text-textGray text-sm md:text-base">
                      Strategically located in remote areas, our eClinics blend
                      advanced medical facilities with traditional healthcare,
                      providing check-ups, health education, online and
                      in-person consultations. Skilled nurses and pharmacists
                      form our rural healthcare backbone, ensuring specialised
                      care and improved well-being.
                    </p>
                  </div>
                </div>
              </div>
              <div class="bg-White hover:bg-primaryColor rounded-lg common-shadow hoverWhite">
                <div className="w-full justify-end ">
                  <div className="w-full h-[230px]">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Swasthya_Mitra.png"
                      alt="images"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                  <div className="  p-5  rounded-2xl">
                    <h6 className="text-xl font-bold text-primaryColor leading-normal mb-3">
                      <span className="text-secondaryColor">Swasthya</span>{" "}
                      Mitras
                    </h6>
                    <p className="text-textGray text-sm md:text-base ">
                      Digital Franchises are individuals empowered through
                      training. They play a vital role in providing quality &
                      reliable healthcare to communities while creating
                      opportunities for themselves. Swasthya Mitras facilitate
                      easier access to medical facilities within these
                      communities.
                    </p>
                  </div>
                </div>
              </div>
              <div class="bg-White hover:bg-primaryColor rounded-lg common-shadow hoverWhite">
                <div className="w-full justify-end ">
                  <div className="w-full h-[230px]">
                    <img
                      src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Network_healthcare.png"
                      alt="images"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                  <div className=" p-5  rounded-2xl">
                    <h6 className="text-xl font-bold text-primaryColor leading-normal mb-3">
                      Network of{" "}
                      <span className="text-secondaryColor">
                        Healthcare Providers
                      </span>
                    </h6>
                    <p className="text-textGray text-sm md:text-base">
                      Our network of healthcare providers, including skilled and
                      compassionate professionals from top cities, is the
                      cornerstone of our services. They ensure optimal medical
                      attention in rural areas, guiding patients through
                      diagnoses, treatments, and supporting their healthcare
                      journeys.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden mb-10">
              <Slider {...settings}>
                <div>
                  <div className="md:flex w-full relative">
                    <div className="md:absolute md:w-[60%] h-full">
                      <img
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/our_eclinic.png"
                        alt="images"
                        className="w-full h-full object-cover rounded-2xl"
                      />
                    </div>
                    <div className="w-full  flex justify-end z-10">
                      <div className="bg-White md:w-[45%] md:h-[400px]  px-[30px] py-[30px] md:py-[50px] rounded-2xl">
                        <h6 className="text-xl md:text-2xl font-bold text-primaryColor leading-normal mb-3">
                          Our{" "}
                          <span className="text-secondaryColor">eClinics</span>
                        </h6>
                        <p className="text-textGray text-sm md:text-lg">
                          Strategically located in remote areas, our eClinics
                          blend advanced medical facilities with traditional
                          healthcare, providing check-ups, health education,
                          online and in-person consultations. Skilled nurses and
                          pharmacists form our rural healthcare backbone,
                          ensuring specialised care and improved well-being.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="md:flex w-full relative">
                    <div className="md:absolute md:w-[60%] h-full">
                      <img
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Network_healthcare.png"
                        alt="images"
                        className="w-full h-full object-cover rounded-2xl"
                      />
                    </div>
                    <div className="w-full  flex justify-end z-10">
                      <div className="bg-White md:w-[45%] md:h-[400px] px-[30px] py-[30px] md:py-[50px] rounded-2xl">
                        <h6 className="text-xl md:text-2xl font-bold text-secondaryColor leading-normal mb-3">
                          Network of{" "}
                          <span className="text-primaryColor">Healthcare</span>{" "}
                          Providers
                        </h6>
                        <p className="text-textGray text-sm md:text-lg">
                          Our network of healthcare providers, including skilled
                          and compassionate professionals from top cities, is
                          the cornerstone of our services. They ensure optimal
                          medical attention in rural areas, guiding patients
                          through diagnoses, treatments, and supporting their
                          healthcare journeys.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="md:flex w-full relative">
                    <div className="md:absolute md:w-[60%] h-full">
                      <img
                        src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Swasthya_Mitra.png"
                        alt="images"
                        className="w-full h-full object-cover rounded-2xl"
                      />
                    </div>
                    <div className="w-full  flex justify-end z-10">
                      <div className="bg-White md:w-[45%] md:h-[400px] px-[30px] py-[30px] md:py-[50px] rounded-2xl">
                        <h6 className="text-xl md:text-2xl font-bold text-primaryColor leading-normal mb-3">
                          Swasthya{" "}
                          <span className="text-secondaryColor">Mitras</span>
                        </h6>
                        <p className="text-textGray text-sm md:text-lg">
                          Digital Franchises are individuals empowered through
                          training. They play a vital role in providing quality
                          & reliable healthcare to communities while creating
                          opportunities for themselves. Swasthya Mitras
                          facilitate easier access to medical facilities within
                          these communities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section className="bg-lightGray">
          <div className="container mx-auto py-[90px]">
            <div className="mb-10">
              <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-5">
                Why Choose <span className="text-secondaryColor">CureBay</span>?
              </h2>
              <p className="text-center text-textGray md:leading-loose text-lg">
                Innovative Services Ensure Seamless Care
              </p>
            </div>
            <div className="md:flex gap-5 justify-center flex-wrap">
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={DoctorConsImage} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Doctor Consultations
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Experience expert medical advice from the comfort of your home
                  through our online doctor consultations & in-person doctor
                  consultations at our eClinics ensuring timely and accessible
                  healthcare support.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={PharmacyImage} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Pharmacy
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Get genuine medications at an affordable price, ensuring
                  hassle-free and convenient delivery to your doorstep for the
                  pharmaceutical care you need.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={DiagnosisImage} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Diagnostics
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Access state-of-the-art diagnostic services through our
                  extensive range of NABL Accredited fully automated labs,
                  ensuring accurate and swift results.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={SurgeryImage} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Surgery & Hospitalization
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  {/* Access state-of-the-art diagnostic services through our
                  extensive range of NABL Accredited fully automated labs,
                  ensuring accurate and swift results. */}
                  Experience seamless coordination for surgeries and hospitalization, with our network of healthcare providers ensuring quality care tailored to your needs.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 md:w-[32%] hoverWhite">
                <div className="mb-5">
                  <img src={ConciergeImage} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Concierge Service
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  CureBay’s Concierge Service ensures complete support from
                  admission to discharge, offering assistance in medical
                  procedures, emotional support, and continual companionship.
                  Experience a caring recovery journey with us, never feeling
                  alone or hopeless.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layouts>
  );
}

export default WhyCurebay;
