import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import Tablet from "../../../Assets/Images/tablets.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import Bottles from "../../../Assets/pharamacyImages/Bottles.png";
import Capsules from "../../../Assets/pharamacyImages/Capsules.png";
import Creams from "../../../Assets/pharamacyImages/Creams.png";
import Curebay from "../../../Assets/pharamacyImages/Curebay.png";
import Devices from "../../../Assets/pharamacyImages/Devices.png";
import Dispensers from "../../../Assets/pharamacyImages/Dispensers.png";
import Droplets from "../../../Assets/pharamacyImages/Droplets.png";
import Emulsions from "../../../Assets/pharamacyImages/Emulsions.png";
import Injections from "../../../Assets/pharamacyImages/Injections.png";
import Lotions from "../../../Assets/pharamacyImages/Lotions.png";
import Satchels from "../../../Assets/pharamacyImages/Satchels.png";
import defaultMed from "../../../Assets/pharamacyImages/Medicines.jpg";
import Noteimage from "../../../Assets/Images/Icons/note.svg";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import ReliableImage from "../../../Assets/Images/Cart/reliable.svg";
import SecureImage from "../../../Assets/Images/Cart/safe-secure.svg";
import GenuineImage from "../../../Assets/Images/Cart/geniun-products.svg";
import http from "../../../Redux/Services/http-common";
import { AddtoCart, getCartDetails } from "../../../Redux/Action/cartPlaceOrderAction";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import Loader from "../../Shared/loader";
import { Helmet } from 'react-helmet';
import { EnglishText } from '../../PageRouting/EnglishText';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function ProductInfoPage(props) {

  const userData = useSelector((state) => state.authReducer.patientData);
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const dispatch = useDispatch();
  const mediDeatais = location?.state?.medicine;
  const [recentlyOrderMedicine, setRecentlyOrderMedicine] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [expectedDelivery, setExpectedDelivery] = useState();
  const [grandTotal, setGrandTotal] = useState();
  const [currIdx, setCurrIdx] = useState(0);
  const [alternateMedicine, setAlternativeMedicineData] = useState([]);
  const [pharmaProductsList, setpharmaProductsList] = useState();
  const [loading,setLoading]=useState(false)

  const handleScroll = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    handleScroll();
    const medicineTotal =
      cartList?.patientMedicineOrder?.length > 0 &&
      cartList?.patientMedicineOrder[0]?.totalAmount;
    const labTotal =
      cartList?.patientLabTestsOrder?.length > 0 &&
      cartList.patientLabTestsOrder[0]?.totalAmount;
    const grandTotal = medicineTotal + labTotal;
    setGrandTotal(grandTotal);
  }, []);

  useEffect(() => {
    const hour = new Date().getHours();
    console.log(typeof hour);
    if (hour > 20 || hour < 8) {
      setExpectedDelivery("11 AM");
    } else {
      if (hour > 12) {
        const time = hour - 12;
        setExpectedDelivery(`${time + 3} PM`);
      } else {
        const time = hour + 3;
        if (time > 12) {
          setExpectedDelivery(`${time - 12} PM`);
        } else {
          setExpectedDelivery(`${time} AM`);
        }
      }
    }
  }, []);

  const cartCount = (list) => {
    let count = 0;
    list?.patientMedicineOrder?.forEach((item) => {
      count = count + item.patientMedicineOrderDetailsList.length;
    });

    list?.patientLabTestsOrder?.forEach((item) => {
      count = count + item.patientLabTestsOrderDetailsList.length;
    });
    //  list?.patientLabTestsOrder.forEach(item => {
    //   count
    //  });
    return count;
  };

  const searchMedicine = async () => {
    const payload = {
      medicineId: mediDeatais.seoNameId,
    };
    const data = await http.get(
      `${process.env.REACT_APP_ELASTIC_BASEURL}medicine/${mediDeatais.seoNameId}`
    );
    if (data.data) {
      setpharmaProductsList(data.data);
      const alternateMedicine = await http.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}medicine/alternativeMedicine?medicineId=${data.data.id}&content=${data.data.composition} `
      );
      if (alternateMedicine.data) {
        setAlternativeMedicineData(alternateMedicine.data);
      } else {
        setAlternativeMedicineData([]);
      }
      setpharmaProductsList(data.data);
    } else {
      setpharmaProductsList({});
    }

    window.scrollTo(0, 0);
  };

  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const addTocart = (e, data, index, quantity = 1) => {
    if (Object.keys(userData).length === 0) {
      history.push(APP_ROUTES.HOME);
    } else {
      // e.preventDefault();

   
        // toast("We are currently in the process of upgrading our website, apologies for the inconvenience");
      
      setIsAdding(index);

      let prescriptionRequired = "N";
      let ePrescriptionRequired = "N";

      if (data?.prescriptionRequired == "No") {
        prescriptionRequired = "N";
        // ePrescriptionRequired = "N";
      } else if (data?.prescriptionRequired == "Yes") {
        prescriptionRequired = "Y";
        // ePrescriptionRequired = "Y";
      }
      let calculatedAmount = data?.drugsInfoDiscountedRate
        ? data?.drugsInfoDiscountedRate
        : data.medicineRate;
      const totalAmount = calculatedAmount * quantity;
      // data.discountPrice !== 0
      //   ? (data.unitPrice - data.discountPrice) * quantity
      //   :
      let dataObj = {};

      let isAdded = false;

      if (cartList && cartList.patientMedicineOrder) {
        cartList.totalAmount = cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount);
        cartList.patientMedicineOrder.forEach((element) => {
          element.patientMedicineOrderDetailsList.push({
            drugCode: data.id,
            drugName: data.medicineName,
            drugCategory: data.drugCategory,
            unitPrice: data.medicineRate,
            discountAmount: 0.0,
            totalAmount: totalAmount,
            quantity: quantity,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            prescriptionRequired: prescriptionRequired,
            ePrescriptionRequired: ePrescriptionRequired,
            medicineTypeOfSell: data?.medicineTypeOfSell,
            membershipCode: null,
            membershipName: null,
            membershipCard: null,
            membershipDiscountPercentage: 0.0,
          });
        });
        dataObj = cartList;
      } else {
        dataObj = {
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          status: 1,
          labOrdersYN: false,
          drugsOrdersYN: true,
          totalAmount: cartList?.totalAmount
            ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
            : parseFloat(totalAmount),
          patientMedicineOrder: [
            {
              orderId: "",
              patientId: userData.code,
              prescriptionId: null,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              txnId: "",
              totalAmount: totalAmount,
              address1: null,
              address2: null,
              address3: null,
              city: null,
              state: null,
              country: null,
              pincode: null,
              deliveryAddress1: null,
              deliveryAddress2: null,
              deliveryAddress3: null,
              deliveryCity: null,
              deliveryState: null,
              deliveryCountry: null,
              deliveryZipcode: null,
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              status: 1,
              procedureStatus: -1,
              payMode: "E",
              collectionStatus: null,
              paymentLinkForPatient: "N",
              discountCouponCode: null,
              patientName: userData.firstName,
              patientGender: userData?.gender,
              patientMobile: userData?.mobile,
              patientMedicineOrderDetailsList: [
                {
                  drugCode: data.id,
                  drugName: data.medicineName,
                  drugCategory: data.drugCategory,
                  unitPrice: data.medicineRate,
                  discountAmount: 0.0,
                  totalAmount: totalAmount,
                  quantity: quantity,
                  cartId: cartList && cartList.id ? cartList.cartId : "",
                  createdBy: userData.code,
                  createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  modifiedBy: userData.code,
                  modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  orderId: null,
                  patientId: userData.code,
                  prescriptionRequired: prescriptionRequired,
                  ePrescriptionRequired: ePrescriptionRequired,
                  medicineTypeOfSell: data?.medicineTypeOfSell,
                  membershipCode: null,
                  membershipName: null,
                  membershipCard: null,
                  membershipDiscountPercentage: 0.0,
                },
              ],
            },
          ],
        };
      }
      setLoading(true)
      dispatch(AddtoCart(dataObj)).then((res) => {
        // window.dataLayer.push({ ecommerce: null });
        // window.dataLayer.push({
        //   event: "add_to_cart",
        //   ecommerce: {
        //     value: data.medicineRate,
        //     items: [data]
        //   }
        // })
        setIsAdding(-1);
      });
      dispatch(getCartDetails(userData.code));
      setLoading(false)

    }
  };

  useEffect(() => {
    dispatch(getCartDetails(userData.code));
    searchMedicine();
  }, [mediDeatais.seoNameId]);

  const openModal = () => {
    setIsOpen(true);
  };

  const resolvePharamcyImageq = (Image) => {
    let PharmacyImage;
    if (Image.toUpperCase().includes("BOTTLE")) {
      PharmacyImage = Bottles;
    } else if (
      Image.toUpperCase().includes("CAPSULE") ||
      Image.toUpperCase().includes("CAPSULES")
    ) {
      PharmacyImage = Capsules;
    } else if (
      Image.toUpperCase().includes("CREAM") ||
      Image.toUpperCase().includes("CREAMS")
    ) {
      PharmacyImage = Creams;
    } else if (Image.toUpperCase().includes("CUREBAY")) {
      PharmacyImage = Curebay;
    } else if (Image.toUpperCase().includes("DEVICES")) {
      PharmacyImage = Devices;
    } else if (Image.toUpperCase().includes("DISPENSERS")) {
      PharmacyImage = Dispensers;
    } else if (Image.toUpperCase().includes("DROPLETS")) {
      PharmacyImage = Droplets;
    } else if (Image.toUpperCase().includes("EMULSIONS")) {
      PharmacyImage = Emulsions;
    } else if (
      Image.toUpperCase().includes("INJECTION") ||
      Image.toUpperCase().includes("INJECTIONS")
    ) {
      PharmacyImage = Injections;
    } else if (Image.toUpperCase().includes("LOTIONS")) {
      PharmacyImage = Lotions;
    } else if (Image.toUpperCase().includes("SATCHELS")) {
      PharmacyImage = Satchels;
    } else if (
      Image.toUpperCase().includes("TABLET") ||
      Image.toUpperCase().includes("TABLETS")
    ) {
      PharmacyImage = Tablet;
    } else {
      PharmacyImage = defaultMed;
    }
    return PharmacyImage;
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const getProductsIDs = () => {
    let ids = [];
    if (cartList) {
      if (cartList?.patientMedicineOrder) {
        for (const product of cartList?.patientMedicineOrder) {
          if (product && product?.patientMedicineOrderDetailsList) {
            for (const drug of product?.patientMedicineOrderDetailsList) {
              ids.push(drug?.drugCode);
            }
          }
        }
      }
    }
    return ids;
  };

  const drugIDsInCart = getProductsIDs();

  const [medicineImages, setMedicineImages] = useState();
  const [selectedImage, setSelectedImage] = useState(true);
  const [big, setBig] = useState(null);

  useEffect(() => {
    if (typeof mediDeatais?.medicineImages === "string") {
      let result = mediDeatais?.medicineImages.replace(/[[\]]/g, "");
      let medicineImage = result.split(",");
      setMedicineImages(medicineImage);
      console.log(medicineImage);
      if (medicineImage?.length !== 0) {
        if (medicineImage.length === 1 && medicineImage[0] === "") {
          const medicineImg = resolvePharamcyImageq(
            mediDeatais.medicineTypeOfSell
          );
          setBig(medicineImg);
          setMedicineImages("");
        } else {
          setCurrIdx(0);
          setBig(medicineImage[0]);
        }
      }
      // else{
      //   console.log(mediDeatais.medicineTypeOfSell)
      //   const medicineImg = resolvePharamcyImageq(mediDeatais.medicineTypeOfSell);
      //   console.log(medicineImg);
      //   setBig(medicineImg);
      // }
    } else if (Array.isArray(mediDeatais.medicineImages)) {
      const imageArr = mediDeatais?.medicineImages;
      if (imageArr?.length > 0) {
        setCurrIdx(0);
        setBig(imageArr[0]);
      } else {
        const medicineImg = resolvePharamcyImageq(
          mediDeatais.medicineTypeOfSell
        );
        setBig(medicineImg);
      }
    } else {
      const medicineImg = resolvePharamcyImageq(mediDeatais.medicineTypeOfSell);
      setBig(medicineImg);
    }
  }, []);

  const Silder = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <img src={PrevArrow} alt="icons" />,
    nextArrow: <img src={NextArrow} alt="icons" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handlePrevClick = () => {
  //   const idx = currIdx;
  //   if(currIdx !== 0){
  //     setCurrIdx( idx - 1);
  //   }
  //   const imageUrl = medicineImages.find((item,idx)=> idx === (idx !== 0? idx-1:idx));
  //   setBig(imageUrl);
  // };

  const handlePrevClick = () => {
    const newIdx = currIdx - 1;
    if (newIdx >= 0) {
      setCurrIdx(newIdx);
      setBig(medicineImages[newIdx]);
    }
  };

  const handleNextClick = () => {
    const newIdx = currIdx + 1;
    if (newIdx < medicineImages.length) {
      setCurrIdx(newIdx);
      setBig(medicineImages[newIdx]);
    }
  };

  // const handleNextClick = () => {
  //   if (startIndex + 3 < medicineImages?.length) {
  //     setCurrIdx(startIndex + 1);
  //   }
  // };

  const handleImageClick = (imageUrl) => {
    setBig(imageUrl);
    setSelectedImage(false);
  };

  async function getMedicineData(medicineCode, type) {
    if (medicineCode?.length) {
      var raw = JSON.stringify(medicineCode);

      dispatch(getAllProductByIds(raw))
        .then((res) => {
          if (type == "orderMedicines") {
            setRecentlyOrderMedicine(res);
          }
        })
        .catch((err) => {});
    } else {
    }
  }

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_BASEURL}PatientRecent/Search?patientId=${userData?.code}&category=1`
      )
      .then((res) => {
        let drugCode = res.data.data.map((item) => item.itemCode);
        getMedicineData(drugCode, "recentMedicine");
        // setPreviouslyBrowsedMedicine(res.data.data)
      });
  }, [userData]);

  useEffect(() => {
    http.get(`${process.env.REACT_APP_BASEURL}Drug/commonDrug`).then((res) => {
      getMedicineData(res.data.data, "orderMedicines");
    });
  }, [userData]);

  return (
    <Layouts>
      {loading&& <Loader/>}
      <ToastContainer/>
      <Helmet>
        <title>
          {pharmaProductsList?.medicineName.length > 30
            ? `${pharmaProductsList?.medicineName.substring(0, 43)}…`
            : pharmaProductsList?.medicineName}
        </title>
        <meta
          name="description"
          content={
            pharmaProductsList?.composition.charAt(0).toUpperCase() +
            pharmaProductsList?.composition.slice(1) +
            pharmaProductsList?.medicineName +
            " " +
            pharmaProductsList?.medicineType.charAt(0).toUpperCase() +
            pharmaProductsList?.medicineType.slice(1) +
            " " +
            pharmaProductsList?.drugForm.charAt(0).toUpperCase() +
            pharmaProductsList?.drugForm.slice(1)
          }
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className="bg-lightGray py-8 ">
        <div className=" w-[90%] mx-auto">
          <div className="w-full bg-primaryColor rounded-md px-8 py-5">
            <div className="font-medium md:text-2xl text-White">
              View Medicine
            </div>
          </div>
          <div className="w-full md:flex relative gap-4 mt-5">
            <div className="md:w-[70%]">
              <div className="bg-White w-full  rounded-lg md:flex p-7 gap-5">
                <div className="md:w-[40%] border border-gray h-[250px]  rounded-lg md:h-[250px] md:p-7">
                  {/* {big ? (
                    <img
                      src={resolvePharamcyImageq(
                        mediDeatais.medicineTypeOfSell
                      )}
                      alt="Tablet"
                      className="w-full h-full rounded-lg object-contain"
                    />
                  ) : ( */}
                  <div className="flex justify-center">
                    <img
                      src={big}
                      alt="Tablet"
                      className="w-[150px] h-[150px] rounded-lg object-fill bg-[transparent]"
                    />
                  </div>

                  {/* )} */}
                  <div className="flex items-center justify-center gap-2 mt-2 w-full overflow-x-auto scrollWidth">
                    {medicineImages?.length > 0 && (
                      <button
                        onClick={handlePrevClick}
                        className={`disabled:opacity-50 text-primaryColor font-bold bg-lightBlue w-[40px] h-[40px] rounded-full ${
                          currIdx === 0 ? "text-gray-400" : ""
                        }`}
                        disabled={currIdx === 0}
                      >
                        <LeftOutlined />
                      </button>
                    )}
                    {medicineImages?.length > 0 &&
                      medicineImages
                        ?.slice(currIdx, currIdx + 3)
                        .map((imageUrl, index) => (
                          <img
                            key={index}
                            src={imageUrl}
                            alt={`img${index}`}
                            onClick={() => handleImageClick(imageUrl)}
                            className="w-14 h-14 border border-lightGray rounded-sm p-2 "
                          />
                        ))}
                    {medicineImages?.length > 0 && (
                      <button
                        onClick={handleNextClick}
                        className={` disabled:opacity-50 text-primaryColor font-bold bg-lightBlue w-[40px] h-[40px] rounded-full ${
                          currIdx + 3 >= medicineImages?.length
                            ? "text-gray-400"
                            : ""
                        }`}
                        disabled={currIdx + 1 >= medicineImages?.length}
                      >
                        <RightOutlined />
                      </button>
                    )}
                  </div>
                </div>
                <div className="md:w-[70%]">
                  <p className="font-semibold text-xl md:text-2xl text-primaryColor mb-2">
                    {mediDeatais.medicineName}
                  </p>
                  <p className="text-textGray font-light text-sm md:text-base">
                    {mediDeatais.manufacturer}
                  </p>
                  <div className="md:w-[60%] flex justify-between my-5">
                    <div>
                      <p className="font-semibold text-secondaryColor text-2xl">
                        ₹ {mediDeatais.medicineRate}
                      </p>
                      <p className="text-textGray text-xs">
                        Inclusive of all taxes
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-[150px] h-[40px] ">
                      <button
                        className="w-[35%] h-full text-3xl font-light border border-textGray rounded-s-md"
                        onClick={(e) => {
                          if (quantity > 1) setQuantity(quantity - 1);
                        }}
                      >
                        -
                      </button>
                      <div className="w-[50%] flex justify-center items-center h-full border-x-0 border-y border-textGray text-xl text-primaryColor font-medium">
                        {quantity}
                      </div>
                      <button
                        className="w-[35%] h-full text-3xl font-light border border-textGray rounded-e-md"
                        onClick={(e) =>
                          setQuantity((prevState) => prevState + 1)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mb-3">
                    <p className="text-textGray font-medium text-xs mb-0">
                      Select Pack Size :
                    </p>
                    <div className="flex items-center gap-3">
                      <button className="bg-lightBlue text-xs font-medium text-primaryColor border-none rounded-full px-3 py-1.5">
                        {mediDeatais.medicineTypeOfSell}
                      </button>
                    </div>
                  </div>
                  <button
                    className={`${
                      !drugIDsInCart.includes(mediDeatais.id)
                        ? "bg-primaryColor text-White"
                        : "bg-White text-secondaryColor border border-secondaryColor hover:bg-secondaryColor hover:text-White"
                    } text-center w-full rounded-lg text-lg py-2.5 my-3`}
                    onClick={(e) => addTocart(e, mediDeatais)}
                    disabled={
                      isAdding !== -1 ||
                      drugIDsInCart.indexOf(mediDeatais.id) !== -1
                    }
                  >
                    {!drugIDsInCart.includes(mediDeatais.id)
                      ? "Add to Cart"
                      : "Added"}
                  </button>
                </div>
              </div>
              <div className="bg-White w-full my-3 rounded-lg">
                <div className="p-7">
                  <p className="font-semibold text-base md:text-lg text-primaryColor">
                    Product Information
                  </p>
                  <div className="">
                    <div className="flex gap-4 my-3">
                      <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                        Contains <div>:</div>
                      </p>
                      <p className=" text-textGray text-sm w-[75%]">
                        {pharmaProductsList?.composition}
                      </p>
                    </div>
                    <div className="flex gap-4 my-3">
                      <p className="font-semibold text-textGray text-sm flex justify-between w-[25%]">
                        Medicine Type <div>:</div>
                      </p>
                      <p className="text-textGray text-sm  w-[75%]">{pharmaProductsList?.medicineType}</p>
                    </div>
                    {pharmaProductsList?.drugForm && (
                      <div className="flex gap-4 my-3">
                        <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                          Drug Form <div>:</div>
                        </p>
                        <p className="capitalize text-textGray text-sm w-[75%]">
                          {pharmaProductsList?.drugForm}
                        </p>
                      </div>
                    )}
                    <div className="flex gap-4 my-3">
                      <p className="font-semibold text-textGray text-sm  flex justify-between w-[25%]">
                        Prescription<div>:</div>
                      </p>
                      <p className="capitalize text-textGray text-sm w-[75%]">{pharmaProductsList?.prescriptionRequired}</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <img src={Noteimage} alt="images" />
                    <p className="text-sm md:text-sm font-light text-textGray">
                      All the products are packed and stored safely and delivery
                      will be on time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-White w-full rounded-lg mb-5 md:mb-0">
                {alternateMedicine?.length > 0 && (
                  <div className="">
                    <div className="bg-[#E1EDFF] w-full px-7 py-3 rounded-t-lg">
                      <div className="text-primaryColor text-base md:text-lg font-semibold ">
                        Medicine from other Brands{" "}
                        <span className="text-secondaryColor">({alternateMedicine?.length})</span>
                      </div>
                      <p className="mb-0 text-primaryColor font-light text-sm">
                        with similar composition
                      </p>
                    </div>
                    <div className="p-7 grid grid-cols-1 md:grid-cols-3 gap-5 h-[270px] overflow-y-auto">
                      {alternateMedicine
                        ?.sort((a, b) =>
                          a.medicineName > b.medicineName
                            ? 1
                            : b.medicineName > a.medicineName
                            ? -1
                            : 0
                        )
                        .map((product, i) => (
                          <div
                            key={i}
                            className="bg-White rounded-md common-shadow p-3"
                          >
                            <div>
                              <div className="flex justify-between gap-5">
                                <h5 className="text-primaryColor font-medium mb-2">
                                  {product?.medicineName}{" "}
                                </h5>
                                <h6 className="text-secondaryColor font-medium">
                                  ₹{product?.medicineRate}{" "}
                                </h6>
                              </div>
                              <p className="text-textGray text-xs font-light">
                                {" "}
                                {product?.medicineTypeOfSell} |{" "}
                                {product?.manufacturer}{" "}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="md:w-[30%] ">
              <div className="bg-[#e1edff] w-full rounded-lg p-5 sticky top-0 mb-3">
                <div className="bg-White rounded-md p-5">
                  <div className="flex flex-wrap items-center justify-between">
                    <div className="text-primaryColor font-medium text-lg ">
                      {cartCount(cartList) <= 9 ? cartCount(cartList) : "9+"}{" "}
                      item<span>s</span> in the cart
                    </div>
                    <div className="text-primaryColor font-medium text-lg ">
                      ₹{" "}
                      {cartList?.patientMedicineOrder?.length > 0 &&
                      cartList?.patientLabTestsOrder?.length > 0
                        ? (
                            cartList?.patientMedicineOrder[0]?.totalAmount +
                            cartList.patientLabTestsOrder[0]?.totalAmount
                          ).toFixed(2)
                        : cartList?.patientMedicineOrder?.length > 0
                        ? cartList?.patientMedicineOrder[0]?.totalAmount.toFixed(
                            2
                          )
                        : cartList?.patientLabTestsOrder?.length > 0
                        ? cartList.patientLabTestsOrder[0]?.totalAmount.toFixed(
                            2
                          )
                        : 0}
                    </div>
                  </div>
                  <button
                    onClick={() => handleNavigation("/cart")}
                    className="text-center w-full rounded-lg text-base py-2.5 mt-3 bg-secondaryColor text-White"
                  >
                    View Cart
                  </button>
                </div>
                <div className="mt-5 font-medium text-textGray text-sm">
                  Earliest Delivery By{" "}
                  <span className="text-secondaryColor">
                    {expectedDelivery}
                  </span>
                </div>
                <div className="flex justify-between gap-3 mt-8">
                  <div className="text-center flex flex-col justify-center items-center gap-3 text-primaryColor text-xs ">
                    <img src={SecureImage} alt="images" className="w-[65px]" />
                    Secure <br /> Payment
                  </div>
                  <div className="text-center flex flex-col justify-center items-center gap-3  text-primaryColor text-xs ">
                    <img
                      src={ReliableImage}
                      alt="images"
                      className="w-[65px]"
                    />
                    Trusted by 1 <br />
                    Lakh Users
                  </div>
                  <div className="text-center flex flex-col justify-center items-center gap-3  text-primaryColor text-xs ">
                    <img src={GenuineImage} alt="images" className="w-[65px]" />
                    Genuine <br /> Products
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="py-[50px] md:py-[100px]">
            <h2 className="text-center text-2xl md:text-3xl font-semibold text-primaryColor leading-normal mb-[50px]">
              Frequently purchased{" "}
              <span className="text-secondaryColor"> Medicine</span>
            </h2>
            <Slider {...Silder} className="w-full pb-6">
              {recentlyOrderMedicine &&
                 recentlyOrderMedicine?.map((item, idx) => (
                    <div key={idx}>
                      <div className="bg-White rounded-lg topCardwidth rounded-lg p-5 cursor-pointer">
                        <div
                          onClick={() => handleClick()}
                          className="bg-lightBlue p-2"
                        >
                          <img
                            src={
                              item?.medicineImages?.length > 0
                                ? item.medicineImages[0]
                                : resolvePharamcyImageq(item.medicineTypeOfSell)
                            }
                            alt="images"
                            className="w-full h-[80px] rounded-t-lg object-contain"
                          />
                        </div>
                        <div onClick={() => handleClick()} className="pt-3">
                          <h5 className="text-primaryColor text-lg mb-1 font-semibold text-ellipsis overflow-hidden text-nowrap">
                            {item.medicineName}
                          </h5>
                          <div className="flex items-baseline">
                            <p className="text-secondaryColor text-lg font-semibold ">
                              ₹{item.medicineRate}
                            </p>
                            <p className="text-textGray text-sm font-light pl-1 text-ellipsis overflow-hidden text-nowrap">
                              {item.medicineTypeOfSell}
                            </p>
                          </div>
                        </div>
                        <button
                          className={`${
                            !drugIDsInCart.includes(item.id)
                              ? "bg-primaryColor text-White"
                              : "bg-White text-secondaryColor border border-secondaryColor hover:bg-secondaryColor hover:text-White"
                          } cursor-pointer rounded-lg py-2 text-center w-full mt-5`}
                          onClick={(e) => addTocart(e, item, idx)}
                          // disabled={
                          //   isAdding !== -1 ||
                          //   drugIDsInCart.indexOf(item.id) !== -1
                          // }
                          disabled={isAdding !== -1 || drugIDsInCart.includes(item.id)}
                        >
                          {!drugIDsInCart.includes(item.id)
                            ? "Add to Cart"
                            : "Added"}
                        </button>
                      </div>
                    </div>
                  ))}
            </Slider>
          </section>
        </div>
      </section>
    </Layouts>
  );
}

export default ProductInfoPage;
