// import moment from "moment";
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getReport, uploadReport } from "../../../../Redux/Action/reportAction";
// import { DatePicker, Form, Input, Modal, Select, message } from "antd";
// import { CloudUploadOutlined } from "@ant-design/icons";
// import Dragger from "antd/es/upload/Dragger";
// import { Upload, Button, Checkbox } from "antd";
// import { UploadOutlined } from "@ant-design/icons";

// function SummaryReports(props) {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const reportList = useSelector((state) => state.reportReducer.reportList);
//   console.log(reportList);

//   const { TextArea } = Input;

//   const [fromDate, setFromDate] = useState(
//     moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );
//   const [toDate, setToDate] = useState(
//     moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );

//   const [dateFormat, setDateFormat] = useState(
//     moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
//   );

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [modal2Open, setModal2Open] = useState(false);
//   const [modal3Open, setModal3Open] = useState(false);
//   const [modal4Open, setModal4Open] = useState(false);
//   const [documentTypeNew, setDocumentTypeNew] = useState("");
//   const [mode, setMode] = useState("");
//   const [selectedReportId, setSelectedReportId] = useState("");
//   const [isChanged, setIsChanged] = useState(false);
//   const [isLoading, setLoading] = useState(false);
//   const [fileType, setFileType] = useState("");
//   const [patient, setPatient] = useState(userData);
//   const [fileInputValue, setfileInputValue] = useState("");
//   const [pickedDate, setPickedDate] = useState(moment().format("DD/MM/YYYY"));
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [title, setTitle] = useState("");
//   const [documentType, setDocumentType] = useState("");
//   const [notes, setNotes] = useState("");
//   const [docName, setdocName] = useState("");
//   const [selectedDataSource, setSelectedDataSource] = useState([]);
//   const [form] = Form.useForm();
//   const [currData,setCurrData] = useState();


//   useEffect(() => {
//     getReportList();
//   }, [props.patCode]);

//   const getReportList = () => {
//     let patient = userData;
//     const payload = {
//       patientCode: props.patCode,
//       fromDate: fromDate,
//       toDate: toDate,
//     };

//     dispatch(getReport(payload));
//     // setopenNewEntryPopup(false);
//   };
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const showModal2 = (data) => {
//     setCurrData(data);
//     setMode("updatereport");
//     setSelectedReportId(data.id);
//     setIsChanged(false);
//     setPickedDate(moment(data.createdDate).format("DD/MM/YYYY"));
//     if (data.title) {
//       setTitle(data.title);
//     }
//     if (data.reportType) {
//       setDocumentType(data.reportType);
//     }

//     if (data.documentType) {
//       setDocumentTypeNew(data.documentType);
//     }
//     if (data.notes) {
//       setNotes(data.notes);
//     }
//     if (data.docName) {
//       setdocName(data.docName);
//     }
//     setModal2Open(true);
//   };

//   const handleCancel2 = () => {
//     setModal2Open(false);
//   };

//   const showModal3 = () => {
//     setModal3Open(true);
//   };

//   const handleCancel3 = () => {
//     setModal3Open(false);
//   };

//   const showModal4 = () => {
//     setModal4Open(true);
//   };

//   const handleCancel4 = () => {
//     setModal4Open(false);
//   };

//   const dummyRequest = ({ file, onSuccess }) => {
//     setTimeout(() => {
//       onSuccess("ok");
//     }, 0);
//   };

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e && e.fileList;
//   };

//   const handleSave = (values) => {
//     console.log("values", values);
//     form
//       .validateFields()
//       .then((values) => {
//         // Dispatch an action or update state with form values
//         console.log("Form values:", values);
//         // Reset the form after successful save
//         form.resetFields();
//         setModal4Open(false);
//       })
//       .catch((errorInfo) => {
//         console.log("Validation failed:", errorInfo);
//       });
//   };

//   const handleFinish = (values) => {
//     console.log("values", values);
//     const payload = {
//       patientCode: props.patCode,
//       title: values.title,
//       notes: values.notes,
//       ePrescriptionGen: -1,
//       ePrescriptionStatus: -1,
//       status: 1,
//       documentUploadByVisit: 0,
//       documentRequired: "N",
//       reportType: "prescription",
//       createdBy: userData.code,
//       modifiedBy: userData.code,
//       enteredBy: userData.code,
//       createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
//       givenDate: moment(values.date, "DD/MM/YYYY").format(
//         "YYYY-MM-DD hh:mm:ss"
//       ),
//       patientName: userData.firstName,
//       patientMobile: userData.mobile,
//       visitId: 0,
//       documentReceivedSource: "self",
//     };
//     payload.documentType = fileType;
//     payload.reportType = documentType;
//     payload.document = selectedFile;
//     dispatch(uploadReport(payload)).then((res) => {
//       setLoading(false);
//       getReportList();
//       message.success("Report added")
//       // Close the modal after saving the data
//       setModal4Open(false);
//     }).catch((error)=>{
//       message.error("Error occurred..");
//     });

//     console.log(payload);
//   };

//   return (
//     <>
//       <div className="bg-White rounded-md ">
//         <div className="bg-[#bee0ff] px-5 py-3 rounded-md flex justify-between items-center">
//           <h5 className=" text-lg font-medium">My Reports</h5>
//           <div
//             className=" text-sm underline cursor-pointer"
//             onClick={showModal4}
//           >
//             Add Report
//           </div>
//         </div>
//         <div className="p-5 h-[300px] overflow-auto">
//           {reportList.map((user, i) => (
//             <div className="bg-White common-shadow p-5 mb-5" key={i}>
//               <div className="text-primaryColor flex justify-between items-center text-lg font-semibold mb-1">
//                 {user.title}
//                 <p className="text-base font-normal text-secondaryColor">
//                   {user.documentReceivedSource === "self" ? "MyRef" : "CureBay"}
//                 </p>
//               </div>
//               <div className="flex flex-wrap text-textGray w-full justify-between gap-3">
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Date</label>
//                   <p className="font-medium mt-1">
//                     {moment(user.givenDate).format("MM/DD/YYYY")}
//                   </p>
//                 </div>
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Start Date</label>
//                   <p className="font-medium mt-1">
//                     {moment(user.createdDate).format("MM/DD/YYYY")}
//                   </p>
//                 </div>
//                 <div className="text-textGray text-sm">
//                   <label className="font-light">Report Type</label>
//                   <p className="font-medium mt-1">{user.documentType}</p>
//                 </div>
//               </div>

//               <div className="flex gap-5 justify-end items-center font-medium mt-2">
//                 {/* <div
//                   onClick={showModal}
//                   className="text-[#005D8D] underline cursor-pointer"
//                 >
//                   Edit Report
//                 </div> */}
//                 <div
//                   onClick={() => showModal2(user)}
//                   className="text-secondaryColor underline cursor-pointer"
//                 >
//                   View Report
//                 </div>
//               </div>
//             </div>
//           ))}

//         </div>
//       </div>
//       <Modal
//         title="Edit Report"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         width="50%"
//       >
//         <Form
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//           layout="vertical"
//           autoComplete="off"
//         >
//           <Form.Item className="mb-0" label="Date" name="date">
//             <Input placeholder="Record on" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Title *" name="email">
//             <Input placeholder="Input placeholder" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Document Type *" name="phone">
//             <Input placeholder="Choose document type" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Upload Documents *" name="phone">
//             <Dragger className="h-[50px] p-0">
//               <CloudUploadOutlined className="p-0 m-0" />
//             </Dragger>
//           </Form.Item>
//           <Form.Item className="mb-0" label="Notes" name="subject">
//             <TextArea placeholder="Enter Notes" />
//           </Form.Item>
//         </Form>
//         <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//           <button
//             onClick={handleCancel}
//             className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleCancel}
//             className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//           >
//             Submit
//           </button>
//         </div>
//       </Modal>

//       <Modal
//         title="Filter Reports"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={modal3Open}
//         onOk={showModal3}
//         onCancel={handleCancel3}
//         width="50%"
//       >
//         <Form
//           layout="vertical"
//           onFinish={handleSave}
//           autoComplete="off"
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//         >
//           <Form.Item className="mb-0" label="From Date" name="fromDate">
//             <DatePicker placeholder="fromDate " className="w-full" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="To Date" name="toDate">
//             <DatePicker placeholder="toDate" className="w-full" />
//           </Form.Item>
//           <Form.Item className="mb-0" label="Document Type" name="type">
//             <Select
//               placeholder="Please select document type"
//               className="text-primaryColor"
//               // optionFilterProp="children"
//               options={[
//                 {
//                   value: "1",
//                   label: "Prescription",
//                 },
//                 {
//                   value: "2",
//                   label: "Lab Report",
//                 },
//                 {
//                   value: "3",
//                   label: "Scan Report",
//                 },
//                 {
//                   value: "4",
//                   label: "MRI Scan",
//                 },
//                 {
//                   value: "5",
//                   label: "Ultrasound Report",
//                 },
//                 {
//                   value: "6",
//                   label: "Clinical Records",
//                 },
//                 {
//                   value: "7",
//                   label: "Health Records",
//                 },
//                 {
//                   value: "8",
//                   label: "Others",
//                 },
//               ]}
//             />
//           </Form.Item>
//         </Form>
//         <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//           <button
//             onClick={handleCancel3}
//             className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleCancel3}
//             className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//           >
//             Filter
//           </button>
//         </div>
//       </Modal>
//       <Modal
//         title="Add Reports"
//         className="commonModal"
//         centered={true}
//         footer={false}
//         open={modal4Open}
//         onOk={showModal4}
//         onCancel={handleCancel4}
//         width="50%"
//       >
//         <Form
//           onFinish={handleFinish}
//           layout="vertical"
//           autoComplete="off"
//           className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
//         >
//           <Form.Item className="mb-0" label="Date" name="date">
//             <DatePicker placeholder="Record on " className="w-full h-12" />
//           </Form.Item>

//           <Form.Item className="mb-0" label="Title *" name="title">
//             <Input placeholder="Enter Title" />
//           </Form.Item>


//           <Form.Item
//             className="mb-0"
//             label="Report Type *"
//             name="documentType"
//             initialValue="Report" // Set the default value here
//           >
//             <Input placeholder="Choose Report type" />
//           </Form.Item>
//           {/* <Form.Item
//             className="mb-0"
//             label="Data Source*"
//             name="dataSource
//             "
//             initialValue={[]}
//           >
//             <Checkbox.Group
//               options={[
//                 { label: 'Self', value: 'self' },
//                 { label: 'Curebay', value: 'curebay' },
//               ]}
//               onChange={(values) => {
//                 const newValue = values.length > 0 ? [values[values.length - 1]] : [];
//                 setSelectedDataSource(newValue);
//               }}
//               value={selectedDataSource}
//             />
//           </Form.Item> */}
//           <Form.Item className="mb-0" label="Upload Documents *" name="phone">
//             <Dragger className="h-[50px] p-0">
//               <CloudUploadOutlined className="p-0 m-0" />
//             </Dragger>
//           </Form.Item>
//           <Form.Item></Form.Item>
//           <Form.Item></Form.Item>
//           <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//             <button
//               onClick={handleCancel4}
//               className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
//             >
//               Cancel
//             </button>
//             <button
//               // onClick={handleSave}
//               type="submit"
//               className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
//             >
//               Save
//             </button>
//           </div>
//         </Form>
//       </Modal>

//       <Modal
//         title={
//           <p className="font-semibold text-primaryColor text-xl">
//             View Reports
//           </p>
//         }
//         width={1000}
//         open={modal2Open}
//         onCancel={handleCancel2}
//         style={{ top: 20 }}
//         footer={[]}
//       >
//         <div>
//           <div className="mb-3">
//             <label className="font-semibold">Date:</label>
//             <p>{moment(pickedDate).format("MM/DD/YYYY")}</p>
//           </div>
//           <div className="mb-3">
//             <label className="font-semibold">Document Type:</label>
//             <p>{documentType}</p>
//           </div>
//           <div className="mb-3">
//             <label className="font-semibold">Notes:</label>
//             <p>{notes}</p>
//           </div>
//           <div className="mb-3">
//             {console.log(currData)}
//             <label className="font-semibold">Uploaded Document:</label>
//             {documentTypeNew === "png" || documentTypeNew === "jpg" || documentTypeNew === "jpeg" ?

//              (
//               <img
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                 alt=""
//                 className="rounded-lg w-[50px] h-[50px]"
//               />
//               // <div>
//               // </div>
//             ) : documentTypeNew === "pdf" ? (
//               <iframe
//                 title="PDF Viewer"
//                 width="100%"
//                 height="500px"
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//               ></iframe>
//             ) : (
//               <img
//                 src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
//                 alt=""
//                 className="w-full"
//               />
//             )}
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// }

// export default SummaryReports;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReport, uploadReport } from "../../../../Redux/Action/reportAction";
import { DatePicker, Form, Input, Modal, Select, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { Upload, Button, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { encodeBase64File } from "../../../Helper/filebase64";
import { ToastContainer, toast } from "react-toastify";
import { Option } from "antd/es/mentions";

function SummaryReports(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const reportList = useSelector((state) => state.reportReducer.reportList);
  console.log(reportList);

  const { TextArea } = Input;

  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );
  const [toDate, setToDate] = useState(
    moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );

  const [dateFormat, setDateFormat] = useState(
    moment(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)).format("MM/DD/YYYY")
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [documentTypeNew, setDocumentTypeNew] = useState("");
  const [mode, setMode] = useState("");
  const [selectedReportId, setSelectedReportId] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [patient, setPatient] = useState(userData);
  const [reportData, setReportData] = useState([]);
  const [fileInputValue, setfileInputValue] = useState("");
  const [pickedDate, setPickedDate] = useState(moment().format("DD/MM/YYYY"));
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [notes, setNotes] = useState("");
  const [docName, setdocName] = useState("");
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  const [form] = Form.useForm();
  const [currData, setCurrData] = useState();

  useEffect(() => {
    getReportList();
  }, [props.patCode]);

  const getReportList = () => {
    let patient = userData;
    const payload = {
      patientCode: props.patCode,
      fromDate: fromDate,
      toDate: toDate,
    };

    dispatch(getReport(payload))
      .then((res) => {
        setReportData(res);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal2 = (data) => {
    setCurrData(data);
    setMode("updatereport");
    setSelectedReportId(data.id);
    setIsChanged(false);
    setPickedDate(moment(data.createdDate).format("MM/DD/YYYY"));
    if (data.title) {
      setTitle(data.title);
    }
    if (data.reportType) {
      setDocumentType(data.reportType);
    }

    if (data.documentType) {
      setDocumentTypeNew(data.documentType);
    }
    if (data.notes) {
      setNotes(data.notes);
    }
    if (data.docName) {
      setdocName(data.docName);
    }
    setModal2Open(true);
  };

  const handleCancel2 = () => {
    setModal2Open(false);
  };

  const showModal3 = () => {
    setModal3Open(true);
  };

  const handleCancel3 = () => {
    setModal3Open(false);
  };

  const showModal4 = () => {
    setModal4Open(true);
  };

  const handleCancel4 = () => {
    setModal4Open(false);
    form.resetFields();
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSave = (values) => {
    console.log("values", values);
    form
      .validateFields()
      .then((values) => {
        // Dispatch an action or update state with form values
        console.log("Form values:", values);
        // Reset the form after successful save
        form.resetFields();
        setModal4Open(false);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleFileChange = async (info) => {
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!allowedFileTypes.includes(info.file.type)) {
      message.error('You can only upload JPG/PNG images or PDF files!');
      return;
    } else {
      const base64File = info.file.originFileObj;
      let b64File = await encodeBase64File(base64File);
      setSelectedFile(b64File)
      let orgType = info.file.type == 'image/jpeg' ? "jpeg" : info.file.type == 'image/png' ? "png" : info.file.type == 'application/pdf' ? "pdf" : "svg"
      setFileType(orgType)
    }
  };

  const handleFinish = (values) => {
    console.log("values", values);
    if(!values.title){
      message.error('Please Enter Title');
 
    }
    if(!values.selectedFile){
      message.error('Please Upload Document');
 
    }
    if(!values.notes){
      message.error('Please Enter Notes');
 
    }
    const payload = {
      patientCode: props.patCode,
      title: values.title,
      notes: values.notes,
      ePrescriptionGen: -1,
      ePrescriptionStatus: -1,
      status: 1,
      documentUploadByVisit: 0,
      documentRequired: "Y",
      createdBy: userData.code,
      modifiedBy: userData.code,
      enteredBy: userData.code,
      createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      givenDate: moment(values.date).format("YYYY-MM-DD hh:mm:ss"),
      patientName: userData.firstName,
      patientMobile: userData.mobile,
      visitId: 0,
      documentReceivedSource: "self",
      documentType: fileType,
      report:values.report,
      reportType: "MyReport",
      document: selectedFile,
    };
    // payload.documentType = fileType;
    // payload.reportType = documentType;
    // payload.document = selectedFile;
    dispatch(uploadReport(payload))
      .then((res) => {
        setLoading(false);
        getReportList();
        // message.success("Report added");
        // Close the modal after saving the data
        setModal4Open(false);
        setTimeout(() => {
          toast('Report uploaded Successfully');
        }, 500);
        form.resetFields();
      })
      .catch((error) => {
        message.error("Error occurred..");
      });

    console.log(payload);
  };

  return (
    <>
      <div className="bg-White rounded-md ">
        <ToastContainer />
        <div className="bg-[#bee0ff] px-5 py-3 rounded-md flex justify-between items-center">
          <h5 className=" text-lg font-medium">My Reports</h5>
          <div
            className=" text-sm underline cursor-pointer"
            onClick={showModal4}
          >
            Add Report
          </div>
        </div>
        <div className="p-5 h-[300px] overflow-auto">
          {reportData?.map((user, i) => (
            <div className="bg-White common-shadow p-5 mb-5" key={i}>
              <div className="text-primaryColor flex justify-between items-center text-lg font-semibold mb-1">
                {user.title}
                {/* <p className="text-base font-normal text-secondaryColor">
                  {user.documentReceivedSource === "self" ? "MyRef" : "CureBay"}
                </p> */}
              </div>
              <div className="flex flex-wrap text-textGray w-full justify-between gap-3">
                <div className="text-textGray text-sm">
                  <label className="font-light">Date</label>
                  <p className="font-medium mt-1">
                    {moment(user.givenDate).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="text-textGray text-sm">
                  <label className="font-light">Start Date</label>
                  <p className="font-medium mt-1">
                    {moment(user.createdDate).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="text-textGray text-sm">
                  <label className="font-light">Report Type</label>
                  <p className="font-medium mt-1">{user.documentType}</p>
                </div>
              </div>

              <div className="flex gap-5 justify-end items-center font-medium mt-2">
                {/* <div
                  onClick={showModal}
                  className="text-[#005D8D] underline cursor-pointer"
                >
                  Edit Report
                </div> */}
                <div
                  onClick={() => showModal2(user)}
                  className="text-secondaryColor underline cursor-pointer"
                >
                  View Report
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title="Edit Report"
        className="commonModal"
        centered={true}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="50%"
      >
        <Form
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item className="mb-0" label="Date" name="date">
            <Input placeholder="Record on" />
          </Form.Item>
          <Form.Item className="mb-0" label="Title *" name="email">
            <Input placeholder="Input placeholder" />
          </Form.Item>

          <Form.Item className="mb-0" label="Document Type *" name="phone">
            <Input placeholder="Choose document type" />
          </Form.Item>
          <Form.Item className="mb-0" label="Upload Documents *" name="phone">
            <Dragger className="h-[50px] p-0">
              <CloudUploadOutlined className="p-0 m-0" />
            </Dragger>
          </Form.Item>
          <Form.Item className="mb-0" label="Notes" name="subject">
            <TextArea placeholder="Enter Notes" />
          </Form.Item>
        </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
          >
            Cancel
          </button>
          <button
            onClick={handleCancel}
            className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title="Filter Reports"
        className="commonModal"
        centered={true}
        footer={false}
        open={modal3Open}
        onOk={showModal3}
        onCancel={handleCancel3}
        width="50%"
      >
        <Form
          layout="vertical"
          onFinish={handleSave}
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="From Date" name="fromDate">
            <DatePicker placeholder="fromDate " className="w-full" />
          </Form.Item>
          <Form.Item className="mb-0" label="To Date" name="toDate">
            <DatePicker placeholder="toDate" className="w-full" />
          </Form.Item>
          <Form.Item className="mb-0" label="Document Type" name="type">
            <Select
              placeholder="Please select document type"
              className="text-primaryColor"
              // optionFilterProp="children"
              options={[
                {
                  value: "1",
                  label: "Prescription",
                },
                {
                  value: "2",
                  label: "Lab Report",
                },
                {
                  value: "3",
                  label: "Scan Report",
                },
                {
                  value: "4",
                  label: "MRI Scan",
                },
                {
                  value: "5",
                  label: "Ultrasound Report",
                },
                {
                  value: "6",
                  label: "Clinical Records",
                },
                {
                  value: "7",
                  label: "Health Records",
                },
                {
                  value: "8",
                  label: "Others",
                },
              ]}
            />
          </Form.Item>
        </Form>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={handleCancel3}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
          >
            Cancel
          </button>
          <button
            onClick={handleCancel3}
            className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
          >
            Filter
          </button>
        </div>
      </Modal>
      <Modal
        title="Add Reports"
        className="commonModal"
        centered={true}
        footer={false}
        open={modal4Open}
        onOk={showModal4}
        onCancel={handleCancel4}
        width="40%"
      >
        <Form
          onFinish={handleFinish}
          form={form}
          layout="vertical"
          autoComplete="off"
          className="CommonForm grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5"
        >
          <Form.Item className="mb-0" label="Date" name="date">
            <DatePicker placeholder="Record on " className="w-full h-12" />
          </Form.Item>

          <Form.Item
            className="mb-0"
            label={
              <span>
                Title <span style={{ color: "red" }}>*</span>
              </span>
            }
            name="title"
          >
            <Input placeholder="Enter Title" />
          </Form.Item>

          <Form.Item
            className="mb-0"
            label={
              <span>
                Report Type <span style={{ color: "red" }}>*</span>
              </span>
            }
            name="report"
          >
            <Select placeholder="Choose Report type">
              <Option value="Prescription">Prescription</Option>
              <Option value="Lab Report">Lab Report</Option>
              <Option value="Scan Report">Scan Report</Option>
              <Option value="MRI Scan">MRI Scan</Option>
              <Option value="Ultrasound Report">Ultrasound Report</Option>
              <Option value="Clinical Records">Clinical Records</Option>
              <Option value="Health Records">Health Records</Option>
              <Option value="Others">Others</Option>
            </Select>
          </Form.Item>

          {/* <Form.Item
            className="mb-0"
            label="Data Source*"
            name="dataSource
            "
            initialValue={[]}
          >
            <Checkbox.Group
              options={[
                { label: 'Self', value: 'self' },
                { label: 'Curebay', value: 'curebay' },
              ]}
              onChange={(values) => {
                const newValue = values.length > 0 ? [values[values.length - 1]] : [];
                setSelectedDataSource(newValue);
              }}
              value={selectedDataSource}
            />
          </Form.Item> */}
          <Form.Item
            className="mb-0"
            label={
              <span>
                Upload Documents <span style={{ color: "red" }}>*</span>
              </span>
            }
            name="document"
          >
            <Dragger className="h-[50px] p-0" onChange={handleFileChange}>
              <CloudUploadOutlined className="p-0 m-0" />
            </Dragger>
          </Form.Item>

          <Form.Item className="mb-0"
           name="notes"
           label={
            <span>
              Notes <span className="text-[red] ">*</span>
            </span>
          }
           >
            <Input placeholder="" />
          </Form.Item>

          <Form.Item></Form.Item>
          <Form.Item></Form.Item>

          <div className="grid grid-cols-2 items-center gap-5 mt-2 mb-2">
            <button
              onClick={handleCancel4}
              className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base"
            >
              Cancel
            </button>
            <button
              // onClick={handleSave}
              type="submit"
              className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
            >
              Save
            </button>
          </div>
        </Form>
      </Modal>

      <Modal
        title={
          <p className="font-semibold text-primaryColor text-xl">
            View Reports
          </p>
        }
        width={1000}
        open={modal2Open}
        onCancel={handleCancel2}
        style={{ top: 20 }}
        footer={[]}
      >
        <div>
          <div className="mb-3">
            <label className="font-semibold">Date:</label>
            <p>{moment(pickedDate).format("MM/DD/YYYY")}</p>
          </div>
          <div className="mb-3">
            <label className="font-semibold">Document Type:</label>
            <p>{documentType}</p>
          </div>
          {/* <div className="mb-3">
            <label className="font-semibold">Notes:</label>
            <p>{notes}</p>
          </div> */}
          <div className="mb-3">
            {console.log(currData)}
            <label className="font-semibold">Uploaded Document:</label>
            {documentTypeNew === "png" ||
              documentTypeNew === "jpg" ||
              documentTypeNew === "jpeg" ? (
              <img
                src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                alt=""
                className="rounded-lg w-[50px] h-[50px]"
              />
            ) : // <div>
              // </div>
              documentTypeNew === "pdf" ? (
                <iframe
                  title="PDF Viewer"
                  width="100%"
                  height="500px"
                  src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                ></iframe>
              ) : (
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL}${docName}`}
                  alt=""
                  className="w-full"
                />
              )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SummaryReports;
