import React, { useEffect } from 'react';
import Layouts from '../../Layouts';
import Tick from '../../../Assets/Images/tick-1@2x.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function EnquireFormSubmit() {
  const history = useHistory();

  useEffect(() =>{
    setTimeout(() => {
      history.push('/myrequests');
      }, 4000);
  },[])

  const handleGoBack = () =>{
    history.push('/myrequests');
  }

  return (
    <Layouts>
       <div style={{height: "50vh", display: "flex",flexDirection:"column", justifyContent:"center",alignItems: "center"}} >
            <img  height= "80px" width = "80px" src = {Tick} />
        <h1 className='text-xl mt-8' >Thank you for using our Hospital Service. Your enquiry has been submitted successfully.</h1>
        <h1 className='text-xl mb-4'> Our Customer Support will get in tough with you shortly.</h1>
        <button onClick={handleGoBack} style={{ backgroundColor: "#66B889", borderRadius: "5px" }} className=" text-white p-2 mr-2 font-semibold text-xs">Go to My Request</button>
        </div>
    </Layouts>
  )
}

export default EnquireFormSubmit
