import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartTimeLine from "../cart/CartTimeLine";
import Address from "./Address";
import UploadPrescription from "./UploadPrescription";
import {
  getCartData,
  getUploadedPrescription,
  getEPrescrptionList,
  uploadPrescriptionDoc,
  deleteUploadedPrescripiton,
} from "./cartApi/cartapis";
import SideBar from "./cartModels/SideBar";
import EPrescriptionListModel from "./cartModels/EPrescriptionListModel";
import SavedPrescriptionListModel from "./cartModels/SavedPrescriptionListModel";
import ViewUploadedPrescriptionModel from "./cartModels/ViewUploadedPrescriptionModel";
import http from "../../../Redux/Services/http-common";
import CartPrice from "./cartPrice";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import Layouts from "./../../Layouts";

const Checkout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector((state) => state.authReducer.patientData);
  // const selectpatientdata = location.state.patient;
  // console.log(selectpatientdata,"qwertyui")
  const [uploadedPrescriptionList, setUploadedPrescriptionList] = useState({
    prescriptionUploaded: false,
    prescriptionDoc: [],
    docType: "",
    loading: false,
  });
  const [cartData, setCartData] = useState({});
  const [ePrescriptionList, setEPrescriptionsList] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEPrescriptionDialogOpen, setIsEPrescriptionDialogOpen] =
    useState(false);
  const [isSavedPrescriptionDialogOpen, setIsSavedPrescriptionDialogOpen] =
    useState(false);
  const [selectedEPrescription, setSelectedEPrescription] = useState([]);
  const [savedPrescriptionList, setSavedPrescriptionList] = useState([]);
  const [selectedSavedPrescription, setSelectedSavedPrescription] = useState(
    []
  );
  const [prescriptionUrl, setPrescriptionUrl] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handlegetCartData();
  }, []);

  useEffect(() => {
    // if(!JSON.parse(localStorage.getItem("preferTime"))){
    //   history.push('/cart')
    // } else if(!JSON.parse(localStorage.getItem("preferValue"))){
    //   history.push('/cart')
    // } else  if(!JSON.parse(localStorage.getItem("preferDate"))){
    //   history.push('/cart')
    // }
  }, []);

  useEffect(() => {
    getUploadedPrescriptionHandle();
  }, [cartData]);

  useEffect(() => {
    let prescptionDoc = [];
    http
      .get(
        `${process.env.REACT_APP_BASEURL}PatientDocument/list/filter?status=1&patientCode=${userData.code}&documentRequired=Y`
      )
      .then((res) => {
        res.data.forEach((item) => {
          if (item.visitId != 0) {
            http
              .get(
                `${process.env.REACT_APP_BASEURL}Visit/list?id=${item.visitId}&visitSummary=Y`
              )
              .then((result) => {
                prescptionDoc.push(result.data);
              });
          } else if (!item.conversionNeeded) {
            prescptionDoc.push([item]);
          }
        });
        setSavedPrescriptionList(prescptionDoc);
      })
      .catch((err) => { });
  }, [userData]);
  // const handlegetCartData = () => {
  //   // Fetch cart data here (example)
  //   // Replace this with your actual data fetching logic
  //   setTimeout(() => {
  //     const fetchedCartData = { price: 100 }; // Replace with actual cart data
  //     setCartData(fetchedCartData);
  //     setLoading(false);
  //   }, 2000); // Simulating a 2-second delay for data fetching
  // };

  useEffect(() => {
    handlegetCartData();
  }, []);
  const handlegetCartData = () => {
    getCartData(userData.code).then((res) => {
      if (res.data?.length) {
        setTimeout(() => {
          //const fetchedCartData = { price: 100 }; // Replace with actual cart data
          setCartData(res.data[0]);
          setLoading(false);
        }, 100);
        // setCartData(res.data[0]);
      } else {
      }
      console.log(res.data[0], "sdvudsgvuidsgviudsgvds");
    });
  };

  const checkPrescriptionRequired = () => {
    if (cartData?.patientMedicineOrder?.length) {
      let list =
        cartData?.patientMedicineOrder[0].patientMedicineOrderDetailsList?.map(
          (res) => res
        );
      let uploadPres = list?.filter(
        (element) => element.prescriptionRequired == "Y"
      );
      console.log(uploadPres, list, "sdvisdhvoisdhoihdsovdsuovhgsduvs");
      if (uploadPres?.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getUploadedPrescriptionHandle = () => {
    if (!cartData?.patientMedicineOrder?.length) {
      return;
    }
    let query = {
      orderId: cartData.patientMedicineOrder[0].orderId,
      orderType: "drugOrder",
    };
    getUploadedPrescription(query).then((getres) => {
      if (getres.data.length) {
        if (getres?.data[0]?.prescriptionDocName) {
          let docType = getres?.data[0]?.prescriptionDocName?.split(".")[1];
          let docName = getres?.data[0]?.prescriptionDocName?.split(",");
          localStorage.setItem("prescriptionDoc", JSON.stringify(docName));
          setUploadedPrescriptionList({
            prescriptionUploaded: true,
            prescriptionDoc: docName,
            docType: docType,
          });
        } else {
          setUploadedPrescriptionList({
            prescriptionUploaded: false,
            prescriptionDoc: [],
            docType: "",
            loading: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    let payload = {
      patientId: userData.code,
      visitSummary: "Y",
      status: 1,
      documentRequired: "Y",
    };
    getEPrescrptionList(payload).then(
      (res) => {
        if (res.data?.length) {
          let dataForPrescription = res.data.map((res1) => {
            if (res1.patientDrugPrescriptionList?.length) {
              return res1.patientDrugPrescriptionList.map((res2) => {
                return {
                  ...res2,
                  ...res1,
                };
              });
            }
          });
          let result = dataForPrescription.filter((item) =>
            Array.isArray(item)
          );

          setEPrescriptionsList(result);
        }
      },
      (err) => {
        console.log("Error : " + JSON.stringify(err));
      }
    );
  }, []);

  const handleUploadPrescriptionClick = (Incomingfiles, type = "drugOrder") => {
    let files = Object.keys(Incomingfiles).map((res) => Incomingfiles[res]);
    console.log(files, "dsvdsvhdsovhdso");
    let totalSize = 0;
    files.forEach((res) => {
      totalSize += res.size;
    });
    setUploadedPrescriptionList({
      loading: true,
    });
    let cart = cartData;
    cart.drugsOrdersYN = true;
    let docCollection = [];
    files.forEach((res) => {
      docCollection.push({
        prescriptionDoc: res.b64File,
        prescriptionDocType: res.type,
      });
    });
    console.log(docCollection, "Sdvsdvodshvodshovids");
    cart.patientMedicineOrder[0].prescriptionDocCollection = docCollection;
    cart.patientMedicineOrder[0].savedImagesKeys = null;
    uploadPrescriptionDoc(cart.patientMedicineOrder[0].orderId, cart)
      .then((res) => {
        let param = {
          orderId: cart.patientMedicineOrder[0].orderId,
          orderType: "drugOrder",
        };
        getUploadedPrescription(param)
          .then((getres) => {
            if (getres.data.length) {
              let docType = getres.data[0].prescriptionDocName.split(".")[1];
              let docName = getres?.data[0]?.prescriptionDocName?.split(",");
              localStorage.setItem("prescriptionDoc", JSON.stringify(docName));
              setUploadedPrescriptionList({
                prescriptionUploaded: true,
                prescriptionDoc: docName,
                docType: docType,
                loading: false,
              });
              handlegetCartData();
            }
            // return true;
          })
          .catch((err) => {
            setUploadedPrescriptionList({
              loading: false,
            });
          });
      })
      .catch((err) => {
        setUploadedPrescriptionList({
          loading: false,
        });
      });
  };

  const ToggleSideBar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleEPrescriptionModelToggleClick = () => {
    setIsEPrescriptionDialogOpen(!isEPrescriptionDialogOpen);
  };

  const handleSavedPrescriptionModelToggleClick = () => {
    setIsSavedPrescriptionDialogOpen(!isSavedPrescriptionDialogOpen);
  };

  const handleEPrescriptionSelectClick = (key, checked, base64) => {
    if (selectedEPrescription.length && checked) {
      setSelectedEPrescription([...selectedEPrescription, { key, base64 }]);
    } else if (selectedEPrescription.length && !checked) {
      let update = selectedEPrescription.filter((res) => res.key != key);
      setSelectedEPrescription(update);
    } else {
      setSelectedEPrescription([
        {
          key,
          base64,
        },
      ]);
    }
    console.log(key, checked, base64, "dvsdvhiudsgvdsv");
  };

  const handleSelectPrescriptionClick = (type) => {
    let cart = cartData;
    let docCollection = [];
    let savedImagesKeys = [];
    if (type == "savePrescription") {
      selectedSavedPrescription.forEach((res) => {
        if (res.image) {
          savedImagesKeys.push(res.image);
        } else {
          docCollection.push({
            prescriptionDoc: res.base64,
            prescriptionDocType: "pdf",
          });
        }
      });
    } else if (type == "ePrescription") {
      selectedEPrescription.forEach((res) => {
        docCollection.push({
          prescriptionDoc: res.base64,
          prescriptionDocType: "pdf",
        });
      });
    }
    cart.patientMedicineOrder[0].prescriptionDocCollection = docCollection;
    cart.patientMedicineOrder[0].savedImagesKeys = savedImagesKeys.join();

    console.log(cart, "patientDrugOrdersvidsn");
    uploadPrescriptionDoc(cart.patientMedicineOrder[0].orderId, cart)
      .then((res) => {
        let param = {
          orderId: cart.patientMedicineOrder[0].orderId,
          orderType: "drugOrder",
        };
        getUploadedPrescription(param)
          .then((getres) => {
            console.log(getres, "sdglsouvsoduvhlsajbvjsld");
            if (getres.data.length) {
              let docType = getres.data[0].prescriptionDocName.split(".")[1];
              let docName = getres?.data[0]?.prescriptionDocName?.split(",");
              localStorage.setItem("prescriptionDoc", JSON.stringify(docName));
              setUploadedPrescriptionList({
                prescriptionUploaded: true,
                prescriptionDoc: docName,
                docType: docType,
                loading: false,
              });
              handlegetCartData();
            }
            setIsSavedPrescriptionDialogOpen(false);
            setIsEPrescriptionDialogOpen(false);
            setSelectedSavedPrescription([]);
            setSelectedEPrescription([]);
          })
          .catch((err) => {
            setUploadedPrescriptionList({
              loading: false,
            });
            setIsSavedPrescriptionDialogOpen(false);
            setIsEPrescriptionDialogOpen(false);
            setSelectedSavedPrescription([]);
            setSelectedEPrescription([]);
          });
      })
      .catch((err) => {
        setUploadedPrescriptionList({
          loading: false,
        });
      });
  };

  const handlePrescriptionSelectClick = (key, checked, base64) => {
    if (selectedSavedPrescription.length && checked) {
      setSelectedSavedPrescription([
        ...selectedSavedPrescription,
        { key, base64 },
      ]);
    } else if (selectedSavedPrescription.length && !checked) {
      let update = selectedSavedPrescription.filter((res) => res.key != key);
      setSelectedSavedPrescription(update);
    } else {
      setSelectedSavedPrescription([
        {
          key,
          base64,
        },
      ]);
    }
    console.log(key, checked, base64, "dvsdvhiudsgvdsvsdvds");
  };

  const handleOriginalPrescriptionSelectClick = (key, checked, image) => {
    if (selectedSavedPrescription.length && checked) {
      setSelectedSavedPrescription([
        ...selectedSavedPrescription,
        { key, image },
      ]);
    } else if (selectedSavedPrescription.length && !checked) {
      let update = selectedSavedPrescription.filter((res) => res.key != key);
      setSelectedSavedPrescription(update);
    } else {
      setSelectedSavedPrescription([
        {
          key,
          image,
        },
      ]);
    }
    console.log(key, checked, image, "dvsdvhiudsgvdsv");
  };

  const handleSavePrescriptionClick = () => {
    setIsSavedPrescriptionDialogOpen(true);
  };

  const ViewPrescription = (res) => {
    setPrescriptionUrl(res);
    setOpen(true);
  };

  const handlePrescriptionDelete = (e, res) => {
    e.stopPropagation();
    console.log(res, "SDvdsvbdsivbdsouvds");
    let param = {
      orderId: cartData.patientMedicineOrder[0].orderId,
      keysForDelete: res,
    };
    deleteUploadedPrescripiton(param).then((res) => {
      handlegetCartData();
    });
    // /dhp/Prescription/deletePrescription?orderId=121932&keysForDelete=abc.jpg,sdf.pdf
  };

  const goToReviewPage = async () => {
    if (checkPrescriptionRequired() && uploadedPrescriptionList.prescriptionDoc?.length == 0) {
      toast("Prescription is required to order.")
      return;
    }
    let address = JSON.parse(localStorage.getItem("selectedAddress"));
    if (!address) {
      toast("Please select address");
      return;
    }
    if (!address.lattitude || !address.longitude) {
      toast("Address is not complete, Please edit or add new address.");
      return;
    }
    try {
      if (cartData?.patientMedicineOrder?.length) {
        const data = await http.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}pharmacies/count?latitude=${address.lattitude}&longitude=${address.longitude}`
        );
        if (!data.data) {
          toast("We can't deliver medicine order to selected address.");
          return;
        }
      }
      if (cartData?.patientLabTestsOrder?.length) {
        const data = await http.get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}labs/count?latitude=${address.lattitude}&longitude=${address.longitude}`
        );
        if (!data.data) {
          toast("We don't have Lab partner nearby.");
          return;
        }
      }
    } catch (error) {
      toast("Something went wrong");
      return
    }
    history.push("/cart/review");
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
    // history.push({
    //   pathname: '/cart/review',
    //   state:{ selectpatientdata:selectpatientdata}

    // });
  };

  const handleAppointmentClick = () => {
    if (userData?.id) {
      // (e) => redirectTo(e, APP_ROUTES.UPLOADED_PRESCRIPTION)
      history.push({ pathname: APP_ROUTES.DOCTORS });
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
  };

  return (
    <>
      <Layouts>
        <ToastContainer />
        <section className="bg-lightGray md:py-8 py-6 md:px-28">
          <div className="mb-5">
            <CartTimeLine />
          </div>
          <div className="md:flex gap-5">
            <div className="md:w-[65%] md:min-h-[70vh]  h-full">
              <Address />
              <div className="bg-White p-8 rounded-lg mt-5">
                <UploadPrescription
                  checkPrescriptionRequired={checkPrescriptionRequired}
                  uploadedPrescriptionList={uploadedPrescriptionList}
                  ePrescriptionList={ePrescriptionList}
                  ToggleSideBar={ToggleSideBar}
                  handleEPrescriptionModelToggleClick={
                    handleEPrescriptionModelToggleClick
                  }
                  savedPrescriptionList={savedPrescriptionList}
                  handleSavePrescriptionClick={handleSavePrescriptionClick}
                  patientMedicineOrder={cartData?.patientMedicineOrder ?? [0]}
                  ViewPrescription={ViewPrescription}
                  handlePrescriptionDelete={handlePrescriptionDelete}
                  handleAppointmentClick={handleAppointmentClick}
                />
              </div>
            </div>
            <div className="md:w-[35%] md:min-h-[70vh]  h-full bg-White p-8 rounded-lg">
              {loading ? null : (
                // <div>Loading...</div> // Display a loading spinner or message here
                <CartPrice
                  handleProceed={goToReviewPage}
                  cartData={cartData}
                  getCartData={handlegetCartData}
                  patient={userData?.code}
                  readOnly={true}
                  buttonText="Review"
                />
              )}

              {/* {cartData && <CartPrice
         handleProceed = {goToReviewPage}
         cartData = {cartData}
         getCartData = {handlegetCartData}
         patient = {userData?.code}
         readOnly = {true}
         buttonText = "Review"
        />} */}
            </div>
            <SideBar
              isSidebarOpen={isSidebarOpen}
              handleUploadPrescriptionClick={handleUploadPrescriptionClick}
              ToggleSideBar={ToggleSideBar}
              isCart={true}
            />
            <EPrescriptionListModel
              isOpen={isEPrescriptionDialogOpen}
              data={ePrescriptionList}
              handleClose={handleEPrescriptionModelToggleClick}
              handleEPrescriptionSelectClick={handleEPrescriptionSelectClick}
              handleSelectPrescriptionClick={handleSelectPrescriptionClick}
            />
            <SavedPrescriptionListModel
              isOpen={isSavedPrescriptionDialogOpen}
              data={savedPrescriptionList}
              handleClose={handleSavedPrescriptionModelToggleClick}
              handleSelectPrescriptionClick={handleSelectPrescriptionClick}
              handlePrescriptionSelectClick={handlePrescriptionSelectClick}
              handleOriginalPrescriptionSelectClick={
                handleOriginalPrescriptionSelectClick
              }
            />
            {prescriptionUrl && (
              <ViewUploadedPrescriptionModel
                isOpen={isOpen}
                prescriptionUrl={prescriptionUrl}
                handleClose={() => setOpen(false)}
              />
            )}
          </div>
        </section>
      </Layouts>
    </>
  );
};

export default Checkout;
