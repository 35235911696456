import React, { useState, useEffect, useRef } from "react";
// import close from "../Assets/Images/closeee.svg";

// import FamilyDropdown from "./Familydropdown";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, message } from "antd";
import moment from "moment";
import { patientaddallergy, patientEditallergy } from "../../../../../Redux/Action/UserprofileActions";
import { getPatientallergylist } from "../../../../../Redux/Action/UserprofileActions";
// import { getPatientallergylist } from "../../../../../Redux/Action/UserprofilehealthrecordAction";
import { getLocalTime } from "../../../../Helper/LocalTimeFormat";
import { Form, Input } from "antd";
import { toast } from "react-toastify";

function AddAllergy(props) {

  const dispatch = useDispatch();
  // const addallergy = useSelector((state) => state.addallergy);
  const [addallergies, setAddallergy] = useState([]);
  const [givenDate, setGivenDate] = useState("")
  const [givenDateShow, setGivenDateShow] = useState("")
  const [memberCode, setMemberCode] = useState("");
  const formRef = useRef()

  const goBack = () => {
    props.closePopup();
  };

  // editallergy
  useEffect(() => {
    if (props?.editallergy) {
      setAddallergy(props?.editallergy)
      setGivenDateShow(moment(props?.editallergy?.givenDate).format("MM/DD/yyyy"))
    }
  }, []);

  const patientCode = useSelector(state => state.authReducer.patientCode);

  const handleChange = (e) => {
    setAddallergy({ ...addallergies, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, dateString) => {
    const Currdate = new Date();
    const hours = String(Currdate.getHours()).padStart(2, '0');
    const minutes = String(Currdate.getMinutes()).padStart(2, '0');
    const seconds = String(Currdate.getSeconds()).padStart(2, '0');
    const constructedDate = dateString +` ${hours}:${minutes}:${seconds}`;
    console.log(constructedDate)
    const formattedDate = moment(constructedDate).format('YYYY-MM-DD HH:mm:ss');
    console.log(formattedDate);
    setGivenDate(formattedDate);
    setGivenDateShow(formattedDate);
  };


  const patientdata = localStorage.getItem("patientprofile");
  // const saveallergy = (e) => {
  //   e.preventDefault();
  //   const formValues = formRef.current.getFieldsValue();
  //   console.log('Form values:', formValues);
  //   const { chemicalAllergy, drugAllergy, foodAllergy } = formValues;
  //   if (chemicalAllergy !== "" && drugAllergy !== "" && foodAllergy !== "" && givenDate !== "") {
  //     let patient = localStorage.getItem("patientprofile");
  //     const data = [{
  //       "chemicalAllergy": chemicalAllergy,
  //       "createdBy": patientCode,
  //       "createdDate": "",
  //       "drugAllergy": drugAllergy,
  //       "foodAllergy": foodAllergy,
  //       "fromDate": "",
  //       "givenDate": givenDate,
  //       "id": "",
  //       "modifiedBy": patientCode,
  //       "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
  //       "patientCode": patientCode,
  //       "status": 1,
  //       "toDate": ""
  //     }]

  //     dispatch(patientEditallergy(data)).then((result) => {
  //       // dispatch(getPatientallergylist(patientCode));
  //       props.closePopup()
  //     }, [patientCode, dispatch]);
  //   }
  //   else {
  //     let patient = localStorage.getItem("patientprofile");
  //     const data = [{
  //       "chemicalAllergy": addallergies.chemicalAllergy,
  //       "createdBy": props.patient ? props.patient : patientCode,
  //       "createdDate": "",
  //       "drugAllergy": addallergies.drugAllergy,
  //       "foodAllergy": addallergies.foodAllergy,
  //       "fromDate": "",
  //       "givenDate": givenDate,
  //       "modifiedBy": props.patient ? props.patient : patientCode,
  //       "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
  //       "patientCode": props.patient ? props.patient : patientCode,
  //       "status": 1,
  //       "toDate": ""
  //     }]
  //     console.log("lol3", data)
  //     dispatch(patientaddallergy(data)).then((result) => {
  //       // dispatch(getPatientallergylist(props.patient ? props.patient : patientCode));
  //       props.closePopup()
  //     })
  //   }
  // };

  const saveallergy = (e) => {
    e.preventDefault();
    const formValues = formRef.current.getFieldsValue();
    console.log('Form values:', formValues);
    console.log(givenDate);
    const { chemicalAllergy, drugAllergy, foodAllergy } = formValues;
    setAddallergy({...addallergies,chemicalAllergy,drugAllergy,foodAllergy});
    if(!givenDateShow) {
      message.error("please select a date");
      return;
    } 
    else if (props?.editallergy !== undefined) {
      console.log(props.editallergy);
      let patient = localStorage.getItem("patientprofile");
      const data = [{
        "chemicalAllergy": !addallergies.chemicalAllergy || chemicalAllergy ? props?.editallergy?.chemicalAllergy : addallergies.chemicalAllergy || chemicalAllergy,
        "createdBy": props.patCode,
        "createdDate": "",
        "drugAllergy": !addallergies.drugAllergy || drugAllergy ? props?.editallergy?.drugAllergy : addallergies.drugAllergy,
        "foodAllergy": !addallergies.foodAllergy || foodAllergy ? props?.editallergy?.foodAllergy : addallergies.foodAllergy,
        "fromDate": "",
        "givenDate":   !givenDate ? props?.editallergy?.givenDate : givenDate,
        "id": props?.editallergy?.id,
        "modifiedBy":props.patCode,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": props.patCode,
        "status": 1,
        "toDate": ""
      }]
      dispatch(patientEditallergy(data)).then((result) => {
        dispatch(getPatientallergylist(props.patCode));
        props.loadData();
        props.closePopup();
        setAddallergy([]);
        message.success("Edited Successfully");
        formRef.current.resetFields();
      })
    }
    else {
      let patient = localStorage.getItem("patientprofile");
      console.log('HI');
      const data = [{
        "chemicalAllergy": chemicalAllergy || addallergies.chemicalAllergy ,
        "createdBy": props.patCode,
        "createdDate": "",
        "drugAllergy": drugAllergy || addallergies.drugAllergy,
        "foodAllergy":  foodAllergy || addallergies.foodAllergy,
        "fromDate": "",
        "givenDate": givenDate,
        "modifiedBy":props.patCode,
        "modifiedDate": moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        "patientCode": props.patCode,
        "status": 1,
        "toDate": ""
      }]
      console.log("lol3", data)
      dispatch(patientaddallergy(data)).then((result) => {
        dispatch(getPatientallergylist(props.patCode));
        props.loadData()
        formRef.current.resetFields();
        message.success("Added Successfully");
        setAddallergy([]);
        props.closePopup()
      })
    }
  };

  return (
    <>
      <Form
        ref={formRef}
        className="CommonForm"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Record on" name="givenDate">
          {/* <DatePicker style={{ width: '100%', height: '50px' }}
                      placeholder="Select date"
                    /> */}
          <div className="flex ">
            <DatePicker className="w-full h-[50px]" onChange={handleDateChange} />
          </div>
        </Form.Item>
        <Form.Item label="Drug Allergies" name="drugAllergy">
          <Input placeholder="Enter Drug Allergies" />
        </Form.Item>
        <Form.Item label="Food Allergies" name="foodAllergy">
          <Input placeholder="Enter Food Allergies " />
        </Form.Item>
        <Form.Item label="Chemical Allergies" name="chemicalAllergy">
          <Input placeholder="Enter Chemical Allergies" />
        </Form.Item>
        <Form.Item>
          <button
            onClick={saveallergy}
            className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
            type="primary"
            htmlType="submit"
          >
            Save details
          </button>
        </Form.Item>
      </Form>
    </>
  );
}
export default AddAllergy;
