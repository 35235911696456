import CancelIcon from "@mui/icons-material/Cancel";
import ePrescription from "../../../Assets/Images/Cart/eprescription.svg";
import uploadPrescription from "../../../Assets/Images/Cart/upload.svg";
import savedPrescription from "../../../Assets/Images/Cart/saved.svg";
import CartDocImg from "../../../Assets/Images/realdoctor.svg";
import { useHistory } from "react-router-dom";
// import "./medicine.scss";
const UploadPrescription = (props) => {
  const {
    checkPrescriptionRequired,
    uploadedPrescriptionList,
    ePrescriptionList,
    ToggleSideBar,
    handleEPrescriptionModelToggleClick,
    savedPrescriptionList,
    handleSavePrescriptionClick,
    patientMedicineOrder,
    ViewPrescription,
    handlePrescriptionDelete,
    handleAppointmentClick,
  } = props;
  console.log(uploadedPrescriptionList, "divosdhvoidsivdss");
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };
  return (
    (uploadedPrescriptionList.prescriptionDoc?.length !== 0 ||
      checkPrescriptionRequired()) && (
      <div className="">
        {checkPrescriptionRequired() ? (
          <div className="text-center font-semibold text-xl text-primaryColor">
            Upload Prescriptions{" "}
          </div>
        ) : null}
        {checkPrescriptionRequired() ? (
          <div>
            <div className="grid  grid-cols-3 gap-3 md:gap-5  mt-5">
              <div
                className="flex flex-col md:flex-row items-center gap-3 md:gap-5 p-2 md:p-4 md:my-4 uploaddiv1 cursor-pointer bg-[#dfecff] common-shadow border  border-[#c3dcff] rounded-md"
                onClick={ToggleSideBar}
              >
                <div className="bg-[#88baff] rounded-full w-[50px] h-[50px] p-3 flex justify-center ">
                  <img
                    src={uploadPrescription}
                    alt="medicine_icon1"
                    className="h-6"
                  />
                </div>

                <div className="flex ">
                  <p className="text-xs md:text-sm text-white sm:w-auto text-center md:text-left">
                    <span className="font-semibold">Browse </span>
                    <br />
                    <span className="text-textGray">Prescription</span>
                  </p>
                </div>
              </div>

              {ePrescriptionList?.length ? (
                <div
                  onClick={() => handleEPrescriptionModelToggleClick()}
                  className="flex flex-col md:flex-row items-center md:my-4 gap-3 md:gap-5 p-2 md:p-4 uploaddiv1 cursor-pointer bg-[#E4D0FF] common-shadow border  border-[#cfacff] rounded-md"
                >
                  <div className="bg-[#ba87ff] rounded-full w-[50px] h-[50px] p-3 flex justify-center ">
                    <img
                      src={ePrescription}
                      alt="medicine_icon2"
                      className=" h-6"
                    />
                  </div>

                  <div className="flex">
                    <p className="text-xs md:text-sm text-white sm:w-auto text-center md:text-left">
                      <span className="font-semibold">CureBay </span>
                      <br />
                      <span className="text-textGray">ePrescription</span>
                    </p>
                  </div>
                </div>
              ) : null}
              {savedPrescriptionList?.length ? (
                <div
                  onClick={() => {
                    handleSavePrescriptionClick();
                  }}
                  className="flex flex-col md:flex-row items-center gap-3 md:gap-5 p-2 md:p-4 md:my-4 uploaddiv1 cursor-pointer bg-[#ffecdb] common-shadow border  border-[#edb37c] rounded-md"
                >
                  <div className="bg-[#f1aa66] rounded-full w-[50px] h-[50px] p-3 flex justify-center ">
                    <img
                      src={savedPrescription}
                      alt="medicine_icon3"
                      className="h-6"
                    />
                  </div>

                  <div className="flex">
                    <p className="text-xs md:text-sm  text-white sm:w-auto text-center md:text-left">
                      <span className="font-semibold">Saved </span>
                      <br />
                      <span className="text-textGray">Prescription</span>
                    </p>
                    <div></div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {uploadedPrescriptionList.prescriptionUploaded &&
        patientMedicineOrder &&
        patientMedicineOrder?.length > 0 ? (
          <div style={{ width: "fit-content" }} className="mt-4 relative">
            <div>
              <div className="grid grid-cols-3 gap-4 sm:flex flex-wrap ">
                {uploadedPrescriptionList.prescriptionDoc.map((res) => {
                  let type = res.split(".")[1];
                  if (type == "pdf") {
                    return (
                      <div
                        className="relative cursor-pointer relative rounded shadow-md  border-grey-900"
                        style={{ width: "68px" }}
                        onClick={() => ViewPrescription(res)}
                      >
                        <CancelIcon
                          onClick={(e) => handlePrescriptionDelete(e, res)}
                          style={{ color: "black" }}
                          className="z-10 absolute -right-2 -top-2 cursor-pointer"
                        />

                        <iframe
                          height="95px"
                          width="68px"
                          className="border rounded cursor-pointer mr-4"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                        />
                        <div
                          className="absolute top-0"
                          style={{ height: "95px", width: "68px" }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div
                          className="relative cursor-pointer relative rounded shadow-md  border-grey-900"
                          style={{ width: "68px" }}
                          onClick={() => ViewPrescription(res)}
                        >
                          <CancelIcon
                            onClick={(e) => handlePrescriptionDelete(e, res)}
                            style={{ color: "black" }}
                            className="z-10 absolute -right-2 -top-2 cursor-pointer"
                          />
                          <img
                            style={{ height: "95px", width: "68px" }}
                            className="border rounded cursor-pointer mr-4"
                            src={`${process.env.REACT_APP_IMG_BASEURL}${res}`}
                          />
                          <div
                            className="absolute top-0"
                            style={{ height: "95px", width: "68px" }}
                          />
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div className="bg-lightBlue mt-5 rounded-md md:flex justify-between relative">
          <div className="px-3 md:px-7 py-5">
            <p className="font-medium text-base mb-2 text-primaryColor">
              Don’t have Prescription?
            </p>
            <p className="text-primaryColor font-light text-sm ">
              Please call us or book an appointment with our doctors
            </p>
            <div className="flex w-full mt-6 gap-5">
              <button
                className="w-full md:w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-2 md:px-5 rounded-md text-White text-xs md:font-medium"
                onClick={() => window.open("tel:+918335000999")}
              >
                +91-8335 000 999
              </button>
              <button
                className="w-full md:w-fit bg-primaryColor mb-3 md:mb-0 py-2 px-2 md:px-5 rounded-md text-White text-xs md:font-medium"
                onClick={() =>
                  handleNavigation("/services/book-an-appointment")
                }
              >
                Book Appointment
              </button>
            </div>
          </div>
          <div className="md:absolute bottom-0 right-0">
            <img src={CartDocImg} alt="img" className="w-full h-[160px]" />
          </div>
        </div>
      </div>
    )
  );
};

export default UploadPrescription;
