import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import PrescriptionImage from "../../../Assets/Images/prescriptin.svg";
import DoctorPrescription from "../../../Assets/Images/DoctorPrescription.jpg"
import Search from "antd/es/input/Search";
import { CalendarOutlined } from "@ant-design/icons";
import { Modal, Select } from "antd";
import { useHistory,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../Redux/Services/http-common"
import moment from "moment";
import { getAllProductByIds } from "../../../Redux/Action/pharmacyAction"
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import noDataFound from "../../../Assets/Images/No data-found.svg"
import { Sidebar } from "primereact/sidebar";
import { PrescriptionTempate, getbase64 } from "../../Helper/prescriptionTemplate";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { AddtoCart } from "../../../Redux/Action/cartPlaceOrderAction";
import UploadPrescriptionHelper from "../../Helper/uploadPrescription";
function Savedprescription() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()
    const { cartList } = useSelector((state) => state.cartReducer);
    const userData = useSelector((state) => state.authReducer.patientData);
    const [savedPrescription, setSavedPrescription] = useState([]);
    const [selectedPres, setSelectedPres] = useState(null);
    const [originalPrescription, setOriginalPrescription] = useState("");
    const [selectedPrescription, setSelectedPrescription] = useState({});
    const [prescribedList, setPrescribedList] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [screen, setscreen] = useState(window.innerWidth);
    const [showBar, setShowBar] = useState(false);
    const [visible, setVisible] = useState(false);
    const [prescriptionName, setPrescriptionName] = useState("");
    const [currObject, setCurrObject] = useState();
    const [currSelectedList, setCurrSelectedList] = useState([]);
    const [isAdding, setIsAdding] = useState(-1);
    const search = useLocation().search;
    const visitidParam = new URLSearchParams(search).get("visitId");
    const [addingMultipleMedicineLoader, setAddingMultipleMedicineLoader] = useState(false)
    useEffect(() => {
        loadPrescriptions();
    }, []);

    const defaultPresList = (savedPrescription) => {
        console.log(savedPrescription)
        const result = savedPrescription.filter(res => res.conversionNeeded === true);
        console.log(result);
        setCurrSelectedList(result);
    }

    const redirectTo = (data) => {
        if (userData?.id) {
          history.push({ pathname: APP_ROUTES.UPLOADED_PRESCRIPTION, state: data });
        } else {
          history.push({
            pathname: APP_ROUTES.LOGIN,
            state: { background: location, login: true },
          });
        }
      };

      function getProductsIDs() {
        let ids = [];
        if (cartList) {
          if (cartList.patientMedicineOrder) {
            for (const product of cartList.patientMedicineOrder) {
              if (product && product.patientMedicineOrderDetailsList) {
                for (const drug of product.patientMedicineOrderDetailsList) {
                  ids.push(drug.drugCode);
                }
              }
            }
          }
        }
        return ids;
      }

      const drugIDsInCart = getProductsIDs();

      const addMultipleToCart = (e, data, index) => {
        if (!userData.code) {
          redirectTo(e, {
            pathname: APP_ROUTES.LOGIN,
            state: { background: location, login: true },
          });
        }
        e.preventDefault();
        e.stopPropagation()
        // setIsAdding(true);
       
        let prescriptionRequired = "Y";
        let ePrescriptionRequired = "Y";
        //   :
        let dataObj = {};
    
        let isAdded = false;
        setAddingMultipleMedicineLoader(true)
        let productDetail = prescribedList.medicines.map(res =>{
          return  {
            drugCode: res.id,
            drugName: res.medicineName,
            unitPrice: res.medicineRate,
      drugCategory: res?.drugCategory,
            discountAmount: res?.drugsInfoDiscountedRate ? res?.drugsInfoDiscountedRate : 0.00,
            totalAmount: res.medicineRate * 1,
            quantity: 1,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            prescriptionRequired: prescriptionRequired,
            ePrescriptionRequired: ePrescriptionRequired,
            medicineTypeOfSell: res?.medicineTypeOfSell,
            membershipCode:null,
            membershipName:null,
            membershipCard :null,
            membershipDiscountPercentage: 0.00
          }
        })
    
        if (cartList && cartList.patientMedicineOrder) {
          cartList.patientMedicineOrder.forEach((element) => {
            productDetail.map(res =>{
              element.patientMedicineOrderDetailsList.push(res);
            })
          });
          dataObj = cartList;
        } else {
          dataObj = {
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            status: 1,
            labOrdersYN: false,
            drugsOrdersYN: true,
            totalAmount: 0,
            patientMedicineOrder: [
              {
                orderId: "",
                patientId: userData.code,
                prescriptionId: null,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                txnId: "",
                totalAmount: prescribedList.medicines[0].medicineRate,
                address1: null,
                address2: null,
                address3: null,
                city: null,
                state: null,
                country: null,
                pincode: null,
                deliveryAddress1: null,
                deliveryAddress2: null,
                deliveryAddress3: null,
                deliveryCity: null,
                deliveryState: null,
                deliveryCountry: null,
                deliveryZipcode: null,
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                status: 1,
                procedureStatus: -1,
                payMode: "E",
                collectionStatus: null,
                paymentLinkForPatient: "N",
                discountCouponCode: null,
                patientName: userData.firstName,
                patientGender: userData?.gender,
                patientMobile: userData?.mobile,
                // docName: state?.detail?.prescriptionDoc,
                patientMedicineOrderDetailsList: productDetail
              },
            ],
          };
        }
    
        console.log(dataObj, "dvcsdhovihdsou")
        if(dataObj?.patientMedicineOrder?.length && dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.length > 0){
          let id = dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(item => item.drugCode);
          id.map((item , index , self) =>{
            if(self.indexOf(item) === index){
           } else{
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].quantity += dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[index].quantity
            dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].totalAmount = dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].quantity * dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList[self.indexOf(item)].unitPrice
             }
    
       })
    
          let res = dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList.filter((item , i , self) =>{
            if(id.indexOf(item.drugCode) == i){
              return item
            }
          })
    
          dataObj.patientMedicineOrder[0].patientMedicineOrderDetailsList = res
        }
        dispatch(AddtoCart(dataObj)).then(async (res) => {
          let data = await getbase64()
          console.log(data, "sdvdsvhdsuvhsd")
            await UploadPrescriptionHelper(res , data , "base64")
        setAddingMultipleMedicineLoader(false)
        // setAddToCartLoading(false)
        // setDialog(false)
      }).catch(err =>{
        setAddingMultipleMedicineLoader(false)
      })
    }

    const handleTypeChange = (value) => {
            if (value === "Converted Prescription") {
                const result = savedPrescription.filter(res => res.conversionNeeded === true);
                console.log(result);
                setCurrSelectedList(result);
            }
            else {
                const result = savedPrescription.filter(res => res.conversionNeeded === false);
                console.log(result);
                setCurrSelectedList(result);
            }
    }



    const handleDateSelectDate = (value) => {
        loadPrescriptions(value);
    }

    async function loadPrescriptions(selectPrevDate = 0) {
        let now = new Date();
        let backdate = 0;
        if (selectPrevDate === 1) {
            backdate = new Date(now.setDate(now.getDate() - 30));
        } else if (selectPrevDate === 2) {
            backdate = new Date(now.setDate(now.getDate() - 60));
        } else if (selectPrevDate === 3) {
            backdate = new Date(now.setDate(now.getDate() - 90));
        }
        try {
            if (selectPrevDate === 0) {
                const res = await http.get(`${process.env.REACT_APP_BASEURL}PatientDocument/list/filter?status=1&patientCode=${userData.code}&documentRequired=Y`);
                setSavedPrescription(res.data);
                defaultPresList(res.data)
            } else {
                const res = await http.get(`${process.env.REACT_APP_BASEURL}PatientDocument/list/filter?status=1&patientCode=${userData.code}&documentRequired=Y&fromDate=${moment(backdate).format("MM/DD/yyyy")}&toDate=${moment(Date.now()).format("MM/DD/yyyy")}`);
                setSavedPrescription(res.data);
                defaultPresList(res.data)
            }

        } catch (error) {
            console.log(error)
        }

    }

    function handlePrescriptionNameChange(e, id) {
        console.log(e.target, "sdvsdhvosdhvos", id)
        if (e.target.id === id) {
            setPrescriptionName({
                name: e.target.value,
                id: id
            })
        }
    }

    function handleInputClick(e) {
        e.stopPropagation();
        
    }

    async function handlePrescriptionNameSubmit(e) {
        e.stopPropagation();
        try {
            await http.get(`${process.env.REACT_APP_BASEURL}PatientDocument/updatePrescName?id=${prescriptionName.id}&prescriptionName=${prescriptionName.name}&patientCode=${userData.code}`);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadPrescriptions()
    }, [userData]);

    useEffect(() =>{
      if(currSelectedList.length && visitidParam){
        let selectedPrescription = currSelectedList.filter(res => res.visitId == visitidParam)
        handleClick(...selectedPrescription)
      }
    },[visitidParam,currSelectedList])

    async function handleClick(res1) {
        if (res1.visitId !== 0) {
            setOriginalPrescription("");
            try {
                const res = await http.get(`${process.env.REACT_APP_BASEURL}Visit/list?id=${res1.visitId}&visitSummary=Y&documentRequired=Y&vitalRequired=Y`)
                setSelectedPrescription(res.data)
                let prescribedItems = {
                    medicines: [],
                    labtest: [],
                };

                if (res.data[0].patientDrugPrescriptionList?.length) {
                    const temp = JSON.stringify(res.data)
                    const mData = JSON.parse(temp)
                    let medicineCode = mData[0].patientDrugPrescriptionList.map((drug) => {
                        return drug.drugCode;

                    });
                    console.log(medicineCode, "doihsodhvsdv");
                    var raw = JSON.stringify(medicineCode);
                    dispatch(getAllProductByIds(raw))
                        .then((res) => {
                            if (res?.length) {
                                prescribedItems.medicines = res;
                                setPrescribedList(prescribedItems);
                            }
                        })
                        .catch((err) => {

                        });
                }
                else {
                    setPrescribedList([]);
                }
                if (screen <= 650) {
                    if (res1.id === isActive) {
                        setIsActive(false);
                    } else {
                        setIsActive(res1.id);
                    }
                    setVisible(true);
                } else {
                    setVisible(true);
                }

            } catch (error) {
                console.log(error)
            }
        } else if (!res1.conversionNeeded) {
            setOriginalPrescription(res1.docName);
            if (screen <= 650) {
                if (res1.id === isActive) {
                    setIsActive(false);
                } else {
                    setIsActive(res1.id);
                }
            } else {
                setVisible(true);
            }
        }
    }
   
    const handleModal = () => {
        setIsModalOpen(false);
    }

    const handleModalOpen = (e,type, obj) => {
        e.stopPropagation()
        console.log(type);
        if (type === 'Ready to View') {
            setIsModalOpen(true);
        }
        setCurrObject(obj);
    }

    const addtoCart = (e, data, index, quantity = 1) => {
        if (!userData.code) {
          redirectTo(e, {
            pathname: APP_ROUTES.LOGIN,
            state: { background: location, login: true },
          });
        }
        e.preventDefault();
        setIsAdding(index);
    
        let prescriptionRequired = "N";
        let ePrescriptionRequired = "N";
    
        if (data?.prescriptionRequired == "No") {
          prescriptionRequired = "N";
          // ePrescriptionRequired = "N";
        } else if (data?.prescriptionRequired == "Yes") {
          prescriptionRequired = "Y";
          // ePrescriptionRequired = "Y";
        }
        let calculatedAmount = data?.drugsInfoDiscountedRate
          ? data?.drugsInfoDiscountedRate
          : data.medicineRate;
        const totalAmount = calculatedAmount * quantity;
        // data.discountPrice !== 0
        //   ? (data.unitPrice - data.discountPrice) * quantity
        //   :
        let dataObj = {};
    
        let isAdded = false;
    
        if (cartList && cartList.patientMedicineOrder) {
          cartList.patientMedicineOrder.forEach((element) => {
            element.patientMedicineOrderDetailsList.push({
              drugCode: data.id,
              drugName: data.medicineName,
              drugCategory: data.drugCategory,
              unitPrice: data.medicineRate,
              discountAmount: 0.0,
              totalAmount: totalAmount,
              quantity: quantity,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              orderId: null,
              patientId: userData.code,
              prescriptionRequired: prescriptionRequired,
              ePrescriptionRequired: ePrescriptionRequired,
              medicineTypeOfSell: data?.medicineTypeOfSell,
              membershipCode: null,
              membershipName: null,
              membershipCard: null,
              membershipDiscountPercentage: 0.0,
            });
          });
          dataObj = cartList;
        } else {
          dataObj = {
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            status: 1,
            labOrdersYN: false,
            drugsOrdersYN: true,
            totalAmount: totalAmount,
            patientMedicineOrder: [
              {
                orderId: "",
                patientId: userData.code,
                prescriptionId: null,
                cartId: cartList && cartList.id ? cartList.cartId : "",
                txnId: "",
                totalAmount: totalAmount,
                address1: null,
                address2: null,
                address3: null,
                city: null,
                state: null,
                country: null,
                pincode: null,
                deliveryAddress1: null,
                deliveryAddress2: null,
                deliveryAddress3: null,
                deliveryCity: null,
                deliveryState: null,
                deliveryCountry: null,
                deliveryZipcode: null,
                createdBy: userData.code,
                createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                modifiedBy: userData.code,
                modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                status: 1,
                procedureStatus: -1,
                payMode: "E",
                collectionStatus: null,
                paymentLinkForPatient: "N",
                discountCouponCode: null,
                patientName: userData.firstName,
                patientGender: userData?.gender,
                patientMobile: userData?.mobile,
                patientMedicineOrderDetailsList: [
                  {
                    drugCode: data.id,
                    drugName: data.medicineName,
                    drugCategory: data.drugCategory,
                    unitPrice: data.medicineRate,
                    discountAmount: 0.0,
                    totalAmount: totalAmount,
                    quantity: quantity,
                    cartId: cartList && cartList.id ? cartList.cartId : "",
                    createdBy: userData.code,
                    createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                    modifiedBy: userData.code,
                    modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                    orderId: null,
                    patientId: userData.code,
                    prescriptionRequired: prescriptionRequired,
                    ePrescriptionRequired: ePrescriptionRequired,
                    medicineTypeOfSell: data?.medicineTypeOfSell,
                    membershipCode: null,
                    membershipName: null,
                    membershipCard: null,
                    membershipDiscountPercentage: 0.0,
                  },
                ],
              },
            ],
          };
        }
        dispatch(AddtoCart(dataObj)).then((res) => {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              value: data.medicineRate,
              items: [data],
            },
          });
          setIsAdding(-1);
        });
      };

    const PrescriptionModal = ({ currObject }) => {

        return (
            <div>
                <Modal
                    width={'40%'}
                    style={{ overflowY: 'hidden' }}
                    open={isModalOpen}
                    onOk={handleModal}
                    onCancel={handleModal}
                    footer={[]}
                >
                    <div className="sm:p-8 p-4 mt-4 flex justify-center items-center">
                        <img src={process.env.REACT_APP_IMG_BASEURL + currObject?.docName} alt="DoctorPrescription" className="sm:w-[80%] w-[100%] sm:h-[50%] h-[100%]" />
                    </div>
                    <div className="flex items-center justify-between mt-4 sm:mt-0">
                        <p className="text-textGray font-medium sm:text-[18px] text-xs my-3">When did you consulted with doctor last time?</p>
                        <button
                            onClick={() => history.push('/services/book-an-appointment')}
                            className="text-White font-semibold bg-primaryColor sm:text-base text-xs py-3 px-4 rounded-xl"
                        >
                            Book an Appointment
                        </button>
                    </div>
                </Modal>
            </div>
        )
    }



    return (
        <Layouts>
            <PrescriptionModal currObject={currObject} />
            <section className="bg-lightGray">
                <div className="container mx-auto py-[50px] md:py-[90px]">
                    <h1 className="text-primaryColor text-center text-2xl md:text-4xl font-bold mb-5 !leading-snug">
                        Saved Prescription
                    </h1>
                    <div className="bg-White rounded-xl sm:p-8 p-3">
                        <div className="md:flex justify-end items-center gap-5  space-y-3">
                            <div>
                            <h5 className="text-secondaryColor text-2xl font-bold">
                                Prescription Type
                            </h5>
                            </div>
                            <div>
                            <Select
                                onChange={handleTypeChange}
                                defaultValue="Select on option"
                               
                                style={{
                                    width: 200,
                                    height: 40,
                                }}
                                options={[
                                    {
                                        value: "Converted Prescription",
                                        label: "Converted Prescription",
                                    },
                                    {
                                        value: "Original Prescription",
                                        label: "Original Prescription",
                                    },
                                ]}
                            />
                            </div>
                            <div>
                            <Select
                                onChange={handleDateSelectDate}
                                defaultValue="All"
                                style={{
                                    width: 100,
                                    height: 40,
                                }}
                                options={[
                                    {
                                        value: 1,
                                        label: "1 Month",
                                    },
                                    {
                                        value: 2,
                                        label: "2 Month",
                                    },
                                    {
                                        value: 3,
                                        label: "3 Month",
                                    },
                                ]}
                            />
                            </div>
                        </div>
                        {savedPrescription?.length > 0 ?
                            (<div className="grid sm:grid-cols-3 grid-cols-1 gap-5 mt-10">
                                {
                                    currSelectedList.map((res) => {
                                        console.log(res);
                                        return (
                                            <div className="bg-White rounded-lg p-5 md:p-5 common-shadow " onClick={(e) => handleModalOpen(e,'Ready to View', res)}>
                                                <div className="md:flex gap-5">
                                                    <div className="sm:w-full w-3/4 sm:h-full h-3/4">
                                                      {res?.documentType ==="png" ||   res?.documentType ==="jpg" || res?.documentType ==="jpeg"  ?<img
                                                            src={process.env.REACT_APP_IMG_BASEURL + res.docName}
                                                            alt="images"
                                                            className="w-full h-full object-contain"
                                                        /> : res?.documentType ==="pdf" ? 
                                                        <embed
                                                        // height="95px"
                                                        // width="68px"
                                                        className="object-contain w-full"
                                                        src={`${process.env.REACT_APP_IMG_BASEURL}${res.docName}`}
                                                      />: "" }
                                                    </div>
                                                    <div className="space-y-3 sm:mt-0 mt-4 ">
                                                        <div className="flex items-center gap-2">
                                                            <CalendarOutlined />
                                                            <h4 className="text-primaryColor sm:text-lg text-sm font-medium">
                                                                {moment(res.createdDate).format("DD/MM/YYYY")}
                                                            </h4>
                                                        </div>
                                                        <div className="sm:text-base text-sm break-words  ">{res?.docName}</div>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter prescription name"
                                                            
                                                            className="border rounded-md h-10 sm:w-[100%] w-[97%] p-1"
                                                            value={prescriptionName.id === res.id ? prescriptionName.name : res.prescriptionName} 
                                                            onChange={(e) => handlePrescriptionNameChange(e, res.id)} 
                                                            onClick={handleInputClick}
                                                        />
                                                    </div>
                                                </div>
                                                {prescriptionName.id == res.id ? <ArrowCircleRightIcon
                                            className="mr-1 text-brand-lightgreen absolute right-0 bottom-1 "
                                            onClick={handlePrescriptionNameSubmit}
                                            sx={{ fontSize: 20 }}
                                        /> : null} 
                                                <div className="md:flex justify-end mb-6  md:mt-3 mt-3 text-secondaryColor text-sm cursor-pointer">
                                                    {res.visitId !== 0 ?
                                                        (
                                                            <p className="sm:text-base text-sm border border-sm px-2 py-2 sm:py-0 rounded w-max" onClick={(e) => { e.stopPropagation(); handleClick(res)}}>
                                                                Click to View
                                                            </p>
                                                        ) : res.visitId === 0 && !res.conversionNeeded ? (
                                                            <p className="sm:text-base text-sm border border-sm px-2 py-2 sm:py-0 rounded w-max" onClick={(e) => { e.stopPropagation();handleClick(res)} }>
                                                                Click to View
                                                            </p>
                                                        ) : <p className="text-base text-danger">
                                                            Not Ready
                                                        </p>}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>) :
                            (
                                <div className="w-full h-full flex items-center justify-center my-6">
                                    <div className="flex flex-col">
                                        <img
                                            className="h-24 lg:h-28 mt-5 lg:mt-0 "
                                            src={noDataFound}
                                            alt="No Diagnostics Appointments Available"
                                        />
                                        <h4 className="font-medium  text-gray-400 text-md">
                                            No E-Prescription Found
                                        </h4>
                                    </div>
                                </div>
                            )
                        }
                        {/* <div className="grid grid-cols-3 gap-5 mt-10">
                            <div className="bg-White rounded-lg p-3 md:p-5 common-shadow ">
                                <div className="flex gap-5">
                                    <div className="h-[90px]">
                                        <img
                                            src={DoctorPrescription}
                                            alt="images"
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <div className="">
                                        <div className="flex items-center gap-2">
                                            <CalendarOutlined />
                                            <h4 className="text-primaryColor text-lg font-medium">
                                                21/02/2024
                                            </h4>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter prescription name"
                                            className="border text-lightGray rounded-md h-10"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end  mt-3 text-secondaryColor text-sm cursor-pointer">
                                    <p className="text-base" onClick={() => handleModalOpen('Ready to View')}>Ready to View</p>
                                </div>
                            </div>
                            <div className="bg-White rounded-lg p-3 md:p-5 common-shadow ">
                                <div className="flex gap-5">
                                    <div className="h-[90px]">
                                        <img
                                            src={DoctorPrescription}
                                            alt="images"
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <div className="">
                                        <div className="flex items-center gap-2">
                                            <CalendarOutlined />
                                            <h4 className="text-primaryColor text-lg font-medium">
                                                21/02/2024
                                            </h4>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter prescription name"
                                            className="border text-lightGray rounded-md h-10"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end  mt-3 text-secondaryColor text-sm cursor-pointer">
                                    <p className="text-base" onClick={() => handleModalOpen('Ready to View')}>Ready to View</p>
                                </div>
                            </div>
                            <div className="bg-White rounded-lg p-3 md:p-5 common-shadow ">
                                <div className="flex gap-5">
                                    <div className="h-[90px]">
                                        <img
                                            src={DoctorPrescription}
                                            alt="images"
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <div className="">
                                        <div className="flex items-center gap-2">
                                            <CalendarOutlined />
                                            <h4 className="text-primaryColor text-lg font-medium">
                                                21/02/2024
                                            </h4>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter prescription name"
                                            className="border text-lightGray rounded-md h-10"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end  mt-3 text-danger text-sm cursor-pointer">
                                    <p className="text-base" onClick={() => handleModalOpen('Not Ready')}>Not Ready</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="mt-20 w-full">
                            <h2 className="text-xl font-medium">Disclaimer</h2>
                            <ul className="mt-2 border-lightGray border-[4px] rounded-3xl p-4">
                                <li className="list-disc ml-3">
                                    Our pharmacist has reviewed the prescription and has
                                    identified the medicine/ lab test based on our understanding
                                    of the uploaded prescription. You need to confirm medical
                                    accuracy with your doctor and CureBay does not take
                                    responsibility for the translation.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </section>
            <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        className="w-full sm:w-1/2"
      >
        <h2>Saved Prescription</h2>
        <div className="h-4/5" >
          

          <div class="flex flex-col justify-center mt-11 h-full relative">
          <PrescriptionTempate prescriptionType = "saved" originalPrescription = {originalPrescription} selectedPrescription = {selectedPrescription} prescribedList = {prescribedList} />
          </div>
          {
            originalPrescription ? <>
            <div className="flex items-center font-semibold " >When did you consulted with doctor last time?
              <button class="h-14 w-56 sm:h-10 btn-52 text-white  flex jus-content-center font-semibold align-center bg-blue-700 ml-2"
              onClick={() => history.push('/doctors')}
              >
            Book Appoinment
          </button>
               </div>
            </> : null}
         {!originalPrescription ? <>
          <div class="flex justify-center mt-2"></div>
          <p class="mt-10 font-bold text-black-600 text-lg leading-6 mb-9 text-center md:text-left">
            Your order from Prescription
          </p>
          {prescribedList?.medicines?.length &&
            prescribedList.medicines.map((res, index) => {
              return (
                <div class="grid grid-flow-col gap-4 gap-y-4 md:gap-20 mb-4">
                  <p class="text-sm md:text-md">{res.medicineName}</p>
                  <p class="text-lg font-bold text-black-700">
                    &#8377;{res.medicineRate}
                  </p>
                  <button
                    onClick={(e) => {
                      addtoCart(e, res, index);
                    }}
                    disabled={
                      isAdding !== -1 || drugIDsInCart.indexOf(res.id) !== -1
                    }
                    className={`${
                      drugIDsInCart.indexOf(res.id) !== -1
                        ? "text-red-500 font-medium text-xs py-1 cursor-not-allowed lg:m-auto border-2 border-gray pb-1 rounded-sm px-2 h-8"
                        : "hover:scale-150 cursor-pointer py-2  text-xs font-normal bg-brand-lightgreen text-white mb-3 rounded-sm px-2 h-8"
                    }`}
                  >
                    {drugIDsInCart.indexOf(res.id.toString()) === -1
                      ? "+ Add"
                      : "Added"}{" "}
                    {index === isAdding && (
                      <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-300 h-1 w-5"></div>
                    )}
                  </button>
                </div>
              );
            })}
            <div className="flex justify-start align-center relative" >
                          <button style={{ backgroundColor: "#66B889", borderRadius: "5px" }} className=" text-white p-2 mr-2 font-semibold text-xs mb-8" onClick={addMultipleToCart} >
                          {addingMultipleMedicineLoader ? <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-300 h-5 w-5"></div> : "ADD ALL"}
                          </button>
                          
                          <button style={{ backgroundColor: "#66B889", borderRadius: "5px" }} className=" text-white p-2 mr-2 font-semibold text-xs mb-8" onClick={() => {setVisible(false); history.push('/cart')}} >GO TO CART</button>
                        </div>
                        </> : null}
        </div>
      </Sidebar>
        </Layouts>
    );
}

export default Savedprescription;