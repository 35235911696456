import React, { useEffect, useRef, useState } from 'react';
// import close from '../../Assets/Images/closeee.svg';
// import Dob from '../../Assets/Images/calendar.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import PDF from '../../Assets/Images/PDF.png'
import moment from "moment";
import { encodeBase64File } from '../../../../Helper/filebase64';
import { patientaddsocialhistory } from '../../../../../Redux/Action/UserprofileActions';
//import FamilyDropdown from "./Familydropdown";
import { getsocialhistory } from '../../../../../Redux/Action/UserprofileActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPatientfamilymembers as getpatientfamilymemberslist, getchronicconditions } from "../../../../../Redux/Action/UserprofileActions";
//import HealthRecordService from '../../Redux/services/HealthRecordService';
import userprofileservice from "../../../../../Redux/Services/userprofileservice";
import { Form, Select, message } from 'antd';
import { Option } from 'antd/es/mentions';
import { values } from 'lodash';

function AddChronicalHistory(props) {

  const goBack = () => {
    props.closePopup();
  }
  const [memberList, setMemberList] = useState([]);
  const [chronicList, setChronicList] = useState([]);
  const [selectedChronicCondition, setselectedChronicCondition] = useState();
  const [FamilymemberName, setFamilymemberName] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [memberCode, setMemberCode] = useState("");

  const location = useLocation();


  const handleChronicChange = (value, option) => {
    console.log('Selected value:', value);
    console.log('Selected option:', option.props.children);
    const ChronicCondition = {
      code: value, description: option.props.children
    }
    setselectedChronicCondition(ChronicCondition);
  }


  const changeDate = (e) => {
    setaddsocialhistory({
      ...addsocialhistory,
      givenDate: moment(e).format("yyyy-MM-DD HH:mm:ss"),
    });
  };
  const patientCode = useSelector(state => state.authReducer.patientCode);
 
  const patientdata = localStorage.getItem("patientprofile")
  console.log("patient", patientdata);

  const saveChronicConditions = () => {
    console.log(JSON.stringify(selectedChronicCondition));
    //let patient = localStorage.getItem("patientprofile");
    const loginObj = JSON.parse(localStorage.getItem("loginObj"));
    //let array = selectedChronicConditions ? selectedChronicConditions : [];
    let payload = [{
      chronicCode: selectedChronicCondition.code,
      chronicDescription: selectedChronicCondition.description,
      givenDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      createdBy: props.patCode, //loginObj.user.code,
      createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      modifiedBy: props.patCode, //loginObj.user.code,
      modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
      patientId: props.patCode,
      status: 1,
    }];
    console.log(payload);
    userprofileservice.addpatientchronicconditionslist(payload).then((res) => {
      console.log(res);
      props.loadData();
      message.success("Added Successfully");
      formRef.current.resetFields();
      setselectedChronicCondition('');
      props.closePopup();
    }
    )
    //props.saveChronicConditions()
    //setsaveChronicConditionsLoading(true);
    /*let payload = selectedChronicConditions;
    console.log(JSON.stringify(payload));
    HealthRecordService.addpatientchronicconditionslist(payload).then((res) => {
      setsaveChronicConditionsLoading(false)
      if(res.data == 1) {
        toast("Chronic Conditions Added Successfully");
      }
    }, (err) => {
      setsaveChronicConditionsLoading(false)
      console.log(err);
    })*/
  }


  const [addsocialhistory, setaddsocialhistory] = useState({});


  return (
    <>
      <Form ref={formRef} className="CommonForm" layout="vertical" autoComplete="off">
        <Form.Item label="Chronic Condition" name="chronicCondition">
          <Select
            placeholder="Select Chronic Condition"
            style={{ width: "100%", height: "50px" }}
            onChange={handleChronicChange}
          >
            <Option value="CC01">COPD</Option>
            <Option value="CC02">Asthma</Option>
            <Option value="CC03">Diabetes Mellitus</Option>
            <Option value="CC04">Hypertension</Option>
            <Option value="CC05">Congestive Heart Failure</Option>
            <Option value="CC06">Cardiac Disease</Option>
            <Option value="CC07">Renal Disease</Option>
            <Option value="CC08">Chronic Renal Failure</Option>
            <Option value="CC09">Rheumatoid Arthritis</Option>
            <Option value="CC10">Organ Transplant</Option>
            <Option value="CC11">High Cholesterol</Option>
            <Option value="CC12">Psychiatric Disorder</Option>
            <Option value="CC13">Neurological Disorder</Option>
            <Option value="CC14">Oncological Disorders</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <button
            onClick={saveChronicConditions}
            className="p-2 w-full bg-primaryColor rounded-md text-White font-semibold text-center"
            type="primary"
            htmlType="submit"
          >
            Save details
          </button>
        </Form.Item>
      </Form>
    </>
  );
}

export default AddChronicalHistory;
