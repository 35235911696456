import React from "react";
import Layouts from "../../Layouts";
import RoundClock from "../../../Assets/Images/Icons/RoundClock.svg";
import Testreport from "../../../Assets/Images/Icons/testreport.svg";
import Dailyupdate from "../../../Assets/Images/Icons/dailyupdate.svg";
import Comperhensive from "../../../Assets/Images/Icons/TimeFrame.svg";
import PieceMind from "../../../Assets/Images/Icons/truck.svg";
import  Emergency from "../../../Assets/Images/Icons/EmergencyVan.svg";
import  Wildrange from "../../../Assets/Images/Icons/WildRange.svg";
import Surgical from "../../../Assets/Images/Icons/Surgical.svg";
import Hospital from "../../../Assets/Images/Icons/hospital.svg"
import SubHeader from "../../Shared/SubHeader";


function TotalCare() {
  return (
    <Layouts>
      <SubHeader />
      <section className="home-banner bg-White md:min-h-[65vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[65vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[65vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-secondaryColor text-sm md:text-3xl font-medium  mb-1 md:mb-3">
                CureBay Totalcare
              </h1>
              <h2 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Take care from{" "}
                <span className="text-secondaryColor">anywhere</span>
              </h2>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                CureBay Totalcare is a meticulously crafted healthcare solution
                for your loved ones, providing holistic care for their
                well-being. With instant emergency intervention, experienced
                doctors visit your home, and an emergency ambulance service just
                a call away, we ensure swift responses in critical situations.
                Our services include timely diagnosis, periodic health check-ups
                with instant online reports, and top-notch medical assistance
                through a stellar network of hospital partners like Apollo,
                Utkal, Sparsh Hospitals and many more eminent hospitals across
                Odisha.
              </p>
              <button
                onClick={() => window.open("https://curebaytotalcare.com/")}
                className="mt-5 bg-White text-primaryColor border-primaryColor border md:border-0 text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full md:rounded"
              >
                Know More
              </button>
            </div>
            <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img
                  src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/TotalCare.png"
                  alt="images"
                  className="w-full h-full object-cover image-boxshadow rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[50px]">
            Why CureBay <span className="text-secondaryColor">Totalcare</span>?
          </h2>
          <div className="md:flex gap-5 justify-center flex-wrap">
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={RoundClock} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Timely diagnosis and treatment
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Testreport} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Periodic health check-ups with instant online Reports
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Comperhensive} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Instant emergency intervention
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Wildrange} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                5 Healthcare packages to choose.
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Dailyupdate} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Daily health updates
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Hospital} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Best Hospital Partners such as, Apollo Hospital, Utkal Hospital,
                Sparsh Hospital
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Surgical} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Experienced Doctors visiting home
              </p>
            </div>
            <div className="bg-White hover:bg-primaryColor rounded-xl p-5 common-shadow mb-5 md:mb-0 w-full md:w-[32%] hoverWhite">
              <div className="mb-5">
                <img src={Emergency} alt="images" />
              </div>
              <p className="text-primaryColor md:leading-loose text-base">
                Emergency Ambulance Service
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default TotalCare;
