import React, { useState, useEffect, useRef } from "react";
import { DatePicker, Modal, message,Select } from "antd";
import moment from "moment";
import { addRegistration } from "../../Redux/Action/userActions";
// import mome
import { connect, useDispatch, useSelector } from "react-redux";
import http from "../../Redux/Services/http-common";
import { Logout } from "../../Redux/Action/userActions";
import TermsAndConditions from "../../Assets/privacyPolicy.pdf";
import PrivacyPolicy from "../../Assets/Terms&conditions.pdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import hospitalservice from "../../Redux/Services/hospitalservice";
import { Document, Page } from 'react-pdf';
const Regiterform = (props) => {
  const [err, setErr] = useState({});
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
 
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(true);
  const [activeTab, setActiveTab] = useState("terms");
  const [showConsent, setShowConsent] = useState(false);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const {patientSession} = userData;
  const [numPagestcPDf, setNumPagestcPDf] = useState();
  const [numPagesprivacy, setNumPagesPrivacy] = useState();
  const [tcCheck,settcCheck] = useState(false)
  const [privacyCheck, setPrivacyCheck] = useState(false)
  const [consentInfo, setConsentInfo] = useState({
    privacyPdf: "",
        privacyVersion: "",
        tcPdf: "",
        tcVersion: ""
  });
  const [stateList, setstateList] = useState([""]);

  const [formData, setformData] = useState({ mobile: props.mobile});
  const [width, setWidth] = useState(window.innerWidth);
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    const updateVh = () => {
      setVh(window.innerHeight * 0.01);
    };

    // Initial update
    updateVh();

    // Update on resize
    window.addEventListener('resize', updateVh);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updateVh);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let modalWidth;
  if (width <= 767) {
    modalWidth = '100%'; // Mobile devices
  } else if (width >= 768 && width <= 1023) {
    modalWidth = '500px'; // Tablets and small laptops
  } else {
    modalWidth = '600px'; // Desktops and large laptops
  }
  function allValuesAreEmpty(obj) {
    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        obj[key].trim() !== ""
      ) {
        return false; // If any non-empty value found, return false
      }
    }
    return true; // If loop completes without finding any non-empty value, return true
  }

  const getVersion = async () => {
    //setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT_B2C`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      //setloginVersion(data?.data);
      console.log(data?.data,"consent")
      let info = {
        privacyPdf: data?.data[0].consentFile,
        privacyVersion: data?.data[0].versionNumber,
        tcPdf: data?.data[1].consentFile,
        tcVersion: data?.data[1].versionNumber
  
      }
      setConsentInfo(info)
     // setLoading(false);
    } catch (e) {
      console.log("Error fetching data:", e);
      //setLoading(false);
    }
  };
  useEffect(() => {
    getVersion();
  }, []);


  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await hospitalservice.getAllStates();
        setstateList(response.data); 
        
      } catch (err) {
        
      }
    };

    fetchStates();
  }, []); 
  console.log(stateList,"Statte")
  const signUp = () => {
    // let payload = formData
    // payload.aaddress1 = ''
    // payload.city = ''
    // payload.code = ''
    // payload.country = ''
    // payload.gender = ''
    // payload.isApproved = 1
    // payload.pinCode = ''
    // payload.referredBy = 'DHPADMIN'
    // payload.modifiedBy = 'DHPADMIN'
    // payload.createdBy = 'DHPADMIN'
    // setLoader(true);
    let error = {};
    error.firstName = formData.firstName ? "" : "Please Enter FirstName";
    error.lastName = formData.lastName ? "" : "Please Enter lastName";
    error.mobile = formData.mobile ? "" : "Please Enter Mobile No";
    error.uniqueId = formData.uniqueId ? "" : "Please Enter uniqueId";
    error.dob = formData.dob ? "" : "Please Select DOB";
    error.email = formData.email ? "" : "Please Enter email";
    error.gender = formData.gender ? "" : "Please Enter gender";
    error.bloodGroup = formData.bloodGroup ? "" : "Please Enter Blood group";
    error.maritalStatus = formData.maritalStatus ? "" : "Please Enter Maritial Status";
     error.salutation = formData.salutation ? "" : "Please Enter salutation";
    if(!tcCheck || !privacyCheck){
      message.error("Please accept both terms and condition and privacy policy!")
      return;
    }
    if (!allValuesAreEmpty(error)) {
      setErr(error);
    }

    let payload = {
      address1: "",
      city: "",
      code: "",
      country: "",
      createdBy: "DHPADMIN",
      email: formData.email,
      firstName: formData.firstName,
      gender: formData.gender,
      isApproved: 1,
      lastName: formData.lastName,
      mobile: formData.mobile,
      pinCode: "",
      referredBy: "DHPADMIN",
      modifiedBy: "DHPADMIN",
      roleCode: "PATIENT",
      salutation: formData.salutation,
      source: "",
      uniqueId: formData.uniqueId,
      status: 1,
      dob: formatDate(formData.dob),
      bloodGroup: formData.bloodGroup,
      maritalStatus:formData.maritialStatus,
      secMobileNumber:formData.secMobileNumber,
      state:formData.state
    };
   payload.dob= moment( payload.dob).format("MM/DD/YYYY") 
    setMsg("");
    dispatch(addRegistration(payload))
      .then(async (result) => {
        console.log("result", result, result.statusCode);
        if (result && result?.details?.length > 0) {
          message.error(result.details[0])
          setMsg(result.details[0]);
        } else {
          const payload = {
            createdBy: "CUREBAYADMIN",
            privacyPolicyConsent: 1,
            sourceChannel: "B2C",
            status: 1,
            termsAndConditionConsent: 1,
            type: "P",
            patientId: result,
            privacyVersionNumber: consentInfo.privacyVersion,
            tcVersionNumber: consentInfo.tcVersion,
          };
      
          console.log("Payload:", payload);
          const postResponse = await http.post(
            `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
            payload
          );
          message.success("You are successfully registered!")
          setMsg("You are successfully registered!");
          setTimeout(() => {
            props.modalClose();
          }, 2000);
        }
      })
      .catch((error) => {
        setMsg(error.response.data.details[0]);
      });
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  }
  const handleChange = (e) => {
    console.log("Event:", e.value); 
    if (e?.target?.value?.length === 0) {
      setErr(""); // Assuming setErr is used for error handling
    }
  
    if (e?.target?.name) {
      console.log(e.target.value, "dsvosdvoisdhivpds");
      setformData({ ...formData, [e.target.name]: e.target.value });
    }  else {
      setformData({ ...formData, ["dob"]: moment(e).format("MM/DD/YYYY") });
    }
  };
  
  // const handleChange = (value, name) => {
  //   if (!value) {
  //     setErr(""); // Clear error message if value is empty
  //   }
  //   setformData({
  //     ...formData,
  //     [name]: value // Update formData with the selected value
  //   });
  // };

  console.log(formData,"formdata")

    const contactUs = () => {
      props.modalClose();
  };

  const handleClick = () => {
    setShowConsent(!showConsent);
    // patientConsentList();
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const agree = async () => {
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 1,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 1,
      type: "P",
      patientId: userData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );
    setIsModalOpen2(false);

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      if(showConsent){
        handleClick()
      }
      setIsVisible(false);
    }
  };

  const disAgree = async () => {
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 0,
      sourceChannel: "B2C",
      status: 1,
      termsAndConditionConsent: 0,
      type: "P",
      patientId: userData.code,
      privacyVersionNumber: consentInfo.privacyVersion,
      tcVersionNumber: consentInfo.tcVersion,
    };

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      if(showConsent){
        handleClick();
      }
      setIsVisible(false);
    // logout();
    }
  };

  const logout = (e) => {
    localStorage.clear();
    let dataObj = {
      sessionId: patientSession?.id,
      userCode: patientSession?.patientCode,
    };
    dispatch(Logout(dataObj));
    history.push('/');
  };

  function onDocumentLoadSuccesstcPdf({ numPages }) {
    setNumPagestcPDf(numPages);
  }

  function onDocumentLoadSuccessprivacy({numPages}) {
    setNumPagesPrivacy(numPages);
  }

  return (
    <>
      <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">


      <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="salutation"
          >
           Salutation <span className="text-danger">*</span>
          </label>
          <Select
                          placeholder="Select Salutation"
                          value={formData.salutation}
                          style={{ height: 48 }}
                          className="text-[#616469]"
                          optionFilterProp="children"
                          options={[
                            { value: "", label: "Select Salutation" },
                            { value: "Mr.", label: "Mr." },
                            { value: "Ms.", label: "Ms." },
                            { value: "Mrs.", label: "Mrs." },
                            { value: "Dr.", label: "Dr." },
                          ]}
                          onChange={(e) =>
                            setformData({ ...formData, ["salutation"]: e })
                          }
                        
                         
                        />
          {err.salutation && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.salutation}
            </span>
          )}
        </div>

        {/* First input field */}
        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="firstName"
          >
            First Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            maxLength="40"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
            placeholder="Enter Your Firstname"
            onChange={handleChange}
          />
          {err.firstName && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.firstName}
            </span>
          )}
        </div>

      
        {/* Second input field */}
        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="lastName"
          >
            Last Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            maxLength="40"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
            placeholder="Enter Your Lastname"
            onChange={handleChange}
          />
          {err.lastName && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.lastName}
            </span>
          )}
        </div>
        <div className="flex flex-col justify-center">
      <label className="text-primaryColor mb-3 inline-block" htmlFor="gender">
        Gender <span className="text-danger">*</span>
      </label>
      <Select
                          placeholder="Select"
                          optionFilterProp="children"
                          style={{ height: 48 }}
                          value={formData.gender} 
                          
                          className="text-[#616469]"
                          
                          options={[
                            { value: "M", label: "Male" },
                            { value: "F", label: "Female" },
                            { value: "O", label: "Others" }
                          ]}
                          onChange={(e) =>
                            setformData({ ...formData, ["gender"]: e })
                          }
                          
                        />
     
      {err.gender && (
        <span className="text-red-600 font-normal text-xs tracking-widest">
          {err.gender}
        </span>
      )}
      </div>
        {/* Third input field */}

        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="maritialStatus"
          >
          Maritial Status <span className="text-danger">*</span>
          </label>
          <Select
                name="maritialStatus"
                placeholder="Select"
                optionFilterProp="children"
                style={{ height: 48 }}
                options={[
                  {
                    value: "Y",
                    label: "Yes",
                  },
                  {
                    value: "N",
                    label: "No",
                  },
                 
                ]}
                onChange={(e) =>
                  setformData({ ...formData, ["maritialStatus"]: e })
                }
                value={formData.maritialStatus} 
              />
          {err.maritialStatus && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.maritialStatus}
            </span>
          )}
        </div>

        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="bloodGroup"
          >
         Blood Group <span className="text-danger">*</span>
          </label>
          <Select
                placeholder="Select"
               
                optionFilterProp="children"
                style={{ height: 48 }}
                options={[
                  {
                    value: "O-",
                    label: "O-",
                  },
                  {
                    value: "O+",
                    label: "O+",
                  },
                  {
                    value: "A-",
                    label: "A-",
                  },
                  {
                    value: "A+",
                    label: "A+",
                  },
                  {
                    value: "B-",
                    label: "B-",
                  },
                  {
                    value: "B+",
                    label: "B+",
                  },
                  {
                    value: "AB-",
                    label: "AB-",
                  },
                  {
                    value: "AB+",
                    label: "AB+",
                  },
                ]}
                onChange={(e) =>
                  setformData({ ...formData, ["bloodGroup"]: e })
                }
                value={formData.bloodGroup} 
                />
          {err.bloodGroup && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.bloodGroup}
            </span>
          )}
        </div>
        <div className="flex flex-col justify-center">
      <label
        className="text-primaryColor mb-3 inline-block"
        htmlFor="maritialStatus"
      >
        Select State <span className="text-danger">*</span>
      </label>
      <Select
        name="state"
        placeholder="Select"
        optionFilterProp="children"
        value={formData.state}
        style={{ height: 48 }}
        options={stateList.map((res) => ({
          value: res.code,
          label: res.description
        }))}
        onChange={(e) =>
          setformData({ ...formData, ["state"]: e })
        }
      />
      {err.state && (
        <span className="text-red-600 font-normal text-xs tracking-widest">
          {err.state}
        </span>
      )}
    </div>

        {/* Fourth input field */}
        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="uniqueId"
          >
            Last Four Digits of Aadhaar ID{" "}
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="uniqueId"
            name="uniqueId"
            maxLength="4"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
            placeholder="Enter Your last four digits of Aadhaar Id"
            onChange={handleChange}
          />
          {err.uniqueId && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.uniqueId}
            </span>
          )}
        </div>
        {/* Fifth input field */}
        <div className="flex flex-col justify-center">
          <label className="text-primaryColor mb-3 inline-block" htmlFor="dob">
            Date of Birth <span className="text-danger">*</span>
          </label>

          <DatePicker
            id="dob"
            onChange={handleChange}
            // onSelect={(date) => setFieldValue('dob', moment(date).format("MM/DD/YYYY"))}

            format="MM/DD/YYYY" // Customize date format if needed
            placeholder="Select Date of Birth"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
          />
          {err.dob && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.dob}
            </span>
          )}
        </div>
        {/* Sixth input field */}
        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="mobileNo"
          >
            Phone Number <span className="text-danger">*</span>
          </label>

          <input
            type="text"
            id="mobile"
            name="mobile"
            maxLength="40"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
            placeholder="Enter Your Phone Number"
            value={formData.mobile}
            disabled
            onChange={handleChange}
          />
          {err.mobile && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.mobile}
            </span>
          )}
        </div>

        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="secMobileNumber"
          >
          Secondary Phone Number 
          </label>

          <input
            type="text"
            id="secMobileNumber"
            name="secMobileNumber"
            maxLength="40"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
            placeholder="Enter Your Phone Number"
           
            
            onChange={handleChange}
            value={formData.secMobileNumber}
          />
          {/* {err.secMobileNumber && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.secMobileNumber}
            </span>
          )} */}
        </div>
        {/* Seventh input field */}
        <div className="flex flex-col justify-center">
          <label
            className="text-primaryColor mb-3 inline-block"
            htmlFor="email"
          >
            Email <span className="text-danger">*</span>
          </label>

          <input
            type="text"
            id="email"
            name="email"
            maxLength="40"
            className="w-full h-[50px] rounded-lg bg-White px-5 border border-gray"
            placeholder="Enter Your Email"
            onChange={handleChange}
          />
          {err.email && (
            <span className="text-red-600 font-normal text-xs tracking-widest">
              {err.email}
            </span>
          )}
        </div>
        {/* Eighth input field */}
        {/* Button */}
        {/* <div className="flex justify-center">
            <button
              type="button" // Assuming this is just triggering some client-side action
              className="py-2 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Sign Up
            </button>
          </div> */}
      </div>
      <div className="mt-5">
        {/* Terms of Use consent */}
        <div className="flex items-start">
          <input
            className="mt-1"
            onChange={(e) => settcCheck(e.target.checked)}
            type="checkbox"
          />
          <p className="ml-2 text-sm text-textGray">
            I hereby accept and consent to be governed by these{" "}
            <span
              className="cursor-pointer text-primaryColor hover:underline font-medium"
            //   onClick={() => setOpenConsent(true)}
            onClick={showModal2}
            >
              Terms of Use
            </span>
          </p>
        </div>
        {/* Privacy Policy consent */}
        <div className="flex items-start">
          <input
            className="mt-1"
            onChange={(e) => setPrivacyCheck(e.target.checked)}
            type="checkbox"
          />
          <p className="ml-2 text-sm text-textGray pt-2">
            I hereby accept and consent to be governed by these{" "}
            <span
              className="cursor-pointer text-primaryColor hover:underline font-medium"
            //   onClick={() => setOpenConsentPrivacy(true)}
            onClick={showModal2}
            >
              Privacy Policy
            </span>
          </p>
        </div>
        {/* Submit Button */}
        <div className="flex w-full my-5">
          <button
            // disabled={!consent || !consentPrivacy}
            type="submit"
            className="p-3 bg-primaryColor  text-White w-full  rounded-lg text-center text-base font-normal"
            onClick={() => signUp("")}
          >
            Sign Up
            {/* {showLoader && (
          <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
        )} */}
          </button>
        </div>
        <div className=" lg:mx-0 my-3  text-primaryColor">
          <span className="text-textGray text-base font-medium in-line flex ">
            Already have an account?&nbsp;{" "}
            <p
              className="font-medium text-base text-primaryColor cursor-pointer underline"
              onClick={(e) => {
                props.loginfalse();
              }}
            >
              Login
            </p>
          </span>
        </div>
        <br></br>
        <br></br>
        <div className="text-sm text-textGray flex flex-wrap gap-1">
          By continuing, you agree to our{" "}
          <a class="text-textGray underline"
          onClick={showModal2}
          //  href="/terms-and-condition"
           >
            Terms of service{" "}
          </a>
          <div>
            &&nbsp;{" "}
            <a class="text-textGray underline" 
            onClick={showModal2}
            // href="/terms-and-condition"
            >
              Privacy & Legal Policy.{" "}
            </a>
          </div>
        </div>
        <div className="text-sm text-primaryColor mt-3 font-medium underline">
          <a
            className=" text-primaryColor mt-5 font-medium"
            // href="/contact-with-us"
            href="#Contactus"
            onClick={contactUs}
          >
            Need Help? Get In Touch
          </a>
        </div>
      </div>

      <Modal
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        centered
        footer={null}
      styles={{
        content: {
            height: `${vh * 98}px`,
            width: modalWidth,
            // overflow:"scroll"
        },
        footer:{
          alignContent: "center",
          paddingRight: 10,
          height: "10%"
        },
        body:{
          height: "90%" 
        }
      }}
        // footer={[
        //   <div className="w-full flex justify-end items-center gap-2">
        //     <button
        //       onClick={agree}
        //       className="bg-secondaryColor p-2 w-40 rounded-md text-White font-bold"
        //     >
        //       Agree
        //     </button>
        //     <button className="bg-[red] p-2 w-40 rounded-md text-White font-bold" onClick={disAgree}>
        //       Disagree
        //     </button>
        //   </div>,
        // ]}
      >
        <div className="flex text-xl font-semibold gap-6 p-2 text-primaryColor w-full">
          <div
            onClick={() => handleTabClick("terms")}
            className={`text-xl p-1  cursor-pointer transition-shadow duration-1000 ${activeTab === "terms"
                ? "border-b-4 border-primaryColor rounded-t-lg"
                : "text-textGray"
              }`}
          >
            Terms and Conditions
          </div>
          <div
            onClick={() => handleTabClick("privacy")}
            className={`cursor-pointer p-1 transition-shadow duration-1000 ${activeTab === "privacy"
                ? "border-b-4 border-primaryColor rounded-t-lg"
                : "text-textGray"
              }`}
          >
            Privacy Policy
          </div>
        </div>
        <div className="consentBodyContainer">
          {activeTab === "terms" && (
            <div className="consentcont overflow-scroll">
               {/* <iframe
                          // height="95px"
                          // width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`}
                        /> */}
                        <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.tcPdf}`} onLoadSuccess={onDocumentLoadSuccesstcPdf}>
               {numPagestcPDf &&
          Array.from({ length: numPagestcPDf }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false}  />
          )}      
      </Document>
              {/* <embed
              src={PrivacyPolicy}
                // src={TermsAndConditions}
                type="application/pdf"
                className="w-full h-full"
              /> */}
            </div>
          )}
          {activeTab === "privacy" && (
            <div className="consentcont overflow-scroll">
               {/* <iframe
                          // height="95px"
                          // width="68px"
                          className="border rounded cursor-pointer h-96 w-full"
                          src={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`}
                        /> */}
                          <Document file={`${process.env.REACT_APP_IMG_BASEURL}${consentInfo.privacyPdf}`} onLoadSuccess={onDocumentLoadSuccessprivacy} >
               {numPagesprivacy &&
          Array.from({ length: numPagesprivacy }, (_, index) => index + 1).map(
            (pageNumber) => <Page  width={600} pageNumber={pageNumber} renderTextLayer = {true} renderAnnotationLayer = {false} />
          )}      
      </Document>
              {/* <embed
              src={TermsAndConditions}
                // src={PrivacyPolicy}
                type="application/pdf"
                className="w-full h-full"
              /> */}
            </div>
          )}
        </div>
      </Modal>

    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addRegistration: (data) => dispatch(addRegistration(data)),
  // setLoginModal: () => dispatch(setLoginModal()),
});
// export default(Regiterform);
export default connect(null, mapDispatchToProps)(Regiterform);