const APP_ROUTES = {
  HOME: "/",
  OURTEAM: "/our-team",
  PRIVACYPOLICY: "/privacy-policy",
  TERMSCONDITIONS: "/terms-conditions",
  BLOGS: "/blogs",
  BLOGSDETAILS: "/blogs-details",
  BLOGSDETAILS2: "/blogs-details2",
  BLOGSDETAILS3: "/blogs-details3",
  BLOGSDETAILS4: "/blogs-details4",
  BLOGSDETAILS5: "/blogs-details5",
  BLOGSDETAILS6: "/blogs-details6",
  MEDIANEWS: "/media-news",
  AWARDS: "/awards",
  WHYCUREBAY: "/why-curebay",
  VIDEO_CALL: "/videocall",
  SEARCHALL: "/all/:search",
  REFUNDPOLICY: "/refund-policy",
  MEDICINEDELIVERYPOLICY: "/Medicine-Delivery-Policy",
  SWASHTYAMITRA: "/partners/swashtya-mitra",
  FRANCHISE: "/partners/franchise",
  HOSPITALSERVICE: "/partners/hospital",
  DIAGNOSTICSERVICE: "/partners/diagnostics",
  DOCTORSSERVICE: "/partners/doctors",
  ALLIEDSERVICE: "/partners/allied-service",
  CONCIERGE: "/services/concierge",
  BOOKANAPPOINTMENT: "/services/book-an-appointment",
  APPOINMENT_CONFIRM: "/confirm_appointment",
  MEDICINE: "/services/order-medicines",
  LAB: "/services/lab",
  MEMBERSHIP: "/services/membership",
  SURGERY: "/services/surgery",
  TOTALCARE: "/services/totalcare",
  CAREER: "/curebay-career",
  FINDDOCTORS: "/find-doctors",
  ABOUTUS: "/about-us",
  EPRESCRIPTION: "/e-prescription",
  SURGERYDETAILS: "/surgery-details/:search",
  SPECIALITY: "/services/book-an-appointment/:speciality",
  DOCTOR: "/doctors/:doctor",
  PAYMENT: "/payment",
  DIAGNOSTICDETAILS: "/services/lab/:laboratory",
  MEDICININFO: "/medicine/:medicine",
  MYAPPOINTMENTS: "/myappointments",
  MYREPORTS: "/myreports",
  MYLABREPORTS: "/mylabreports",
  MYPRESCRIPTION: "/myprescription",
  MYWALLET: "/mywallet",
  MYPAYMENT: "/mypayment",
  MYMEMBERSHIP: "/mymembership",
  MYREQEUSTS: "/myrequests",
  MYPROFILE: "/myprofile",
  MANAGEPROFILE: "/manageprofile",
  ADDFAMILYMEMBER: "/addfamilymember",
  MEDICALHISTORY: "/medicalhistory",
  MYVITALS: "/myvitals",
  ADDADDRESSBOOK: "/addAddressbook",
  LABORDER: "/laborder",
  FEEDBACK: "/feedback",
  SAVEDPRESCRIPTION: "/saved-prescription",
  SAVEDPRESCRIPTIONS: "/saved-prescriptions",
  MEDICINEORDER: "/medicineorder",
  HOSPITAL: "/hospital/:enquire",
  HOSPITALENQUIRE: "/hospitalenquire",
  HOSPITALLIST: "/hospitallist",
  MEDICALSUMMARY: "/medical-summary",
  MEDICINE_CART: "/cart",
  CHECKOUT: "/cart/checkout",
  CARTREVIEW: "/cart/review",
  MEDICINE_ORDERPLACE: "/medicine_orderplace",
  CHANGEPASSWORD: "/change-password",
  FORGETPASSWORDRESET: "/forgetpasswordreset",
  CART_ORDER_SUCESS: "/order-sucess",
  LABVIEW: "/labview",
  CARESATHI: "/ask-care-sathi",
  DENTALCARE: "/services/dentalcare",
};

export { APP_ROUTES };
