import React, { useState } from "react";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";

const PreferedDateAndTime = (props) => {
  const {
    preferDate,
    preferTime,
    changeDate,
    handleChange,
    preferredLabTime,
    limitTimeForLab,
  } = props;
  const handleDateChange = (date, dateString) => {
    changeDate(dateString)
  };
  const [type, setType] = useState("time");
  return (
    <div>
      <div className="flex gap-3">
        <DatePicker
          placeholder="Select Date"
          format="DD-MM-YYYY"
          disabledDate={(current) => current && current < moment().startOf('day')}
          // value={moment(preferDate).format("DD-MM-YYYY")}
          onChange={handleDateChange}
          className="w-full" />
        <div className="w-full">
          <select
            class="border border-gray rounded-md px-2 text-xs h-10 bg-white border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 w-full"
            id="procedureStatus"
            name="procedureStatus"
            value={preferTime}
            onChange={handleChange}
          >
            <option value="">Select time </option>
            {preferredLabTime.map((res, i) => (
              <option
                key={i}
                className="py-1 text-sm text-green-600 font-semibold"
                value={res.code}
                disabled={limitTimeForLab(res)}
              >
                {res.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default PreferedDateAndTime;
