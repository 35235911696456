import React, { useEffect, useRef, useState } from "react";
import Layouts from "../../Layouts";
import HealthcareSolutions from "../../../Assets/Images/Icons/HealthcareSolutions.svg";
import LowInversment from "../../../Assets/Images/Icons/LowInversment.svg";
import WildRange from "../../../Assets/Images/Icons/WildRange.svg";
import HighReturn from "../../../Assets/Images/Icons/HighReturn.svg";
import { Form, Input, InputNumber, Modal, Select, message } from "antd";
import http from "../../../Redux/Services/http-common";
import { useDispatch } from "react-redux";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import { ToastContainer, toast } from "react-toastify";
import SubHeader from "../../Shared/SubHeader";
import { useSelector } from "react-redux";
function Franchise() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  ///////////////////////////////////
  const [oldProfile, setOldProfile] = useState();
  const patientinfo = useSelector((state) => state.patientinfo);
  const userData = useSelector((state) => state.authReducer.patientData);
  const [profile, setProfile] = useState(userData);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;

  useEffect(() => {
    async function fetchStates() {
      const res = await dispatch(getAllStates());
      setStates(res);
    }
    fetchStates();
  }, []);

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const handleChange = (value) => {
    setSelectedState(value);
  };

  const handleSubmit = async () => {
    const formValues = formRef.current.getFieldsValue();

    console.log("Form values:", formValues);
    const { name, localities, pincode, email, phone } = formValues;
    if (profile.city === undefined || profile.city === "") {
      if (localities === undefined || localities === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.firstName === undefined || profile.firstName === "" || profile.firstName === null) {
      if (name === undefined || name === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.state === undefined || profile.state === "" || profile.state === null) {
      if (selectedState === undefined || selectedState === "") {
        message.error("Please fill in all fields");
        return;
      }
    }

    if (profile.pinCode === undefined || profile.pinCode === "" || profile.pinCode === null) {
      if (pincode === undefined || pincode === "") {
        message.error("Please fill in all fields");
        return;
      }
    }
    let payload;
    if (Object.keys(userData).length === 0) {
      if (email === undefined || email === "") {
        message.error("Please fill in all fields");
        return;
      }
      if (phone === undefined || phone === "") {
        message.error("Please fill in all fields");
        return;
      }
      payload = {
        phoneNumber: phone,
        email,
        contactPerson: name ? name : profile.firstName,
        Localities: localities,
        city: localities?localities:profile.city,
        state: selectedState ? selectedState : profile.state === "AP"?"Andhra Pradesh":
        profile.state === "AS"?"Assam":
        profile.state === "BR"?"Bihar":
        profile.state === "CT"?"Chhattisgarh":
        profile.state === "DL"?"Delhi":
        profile.state === "GA"?"Goa":
        profile.state === "GJ"?"Gujarat":
        profile.state === "HR"?"Haryana":
        profile.state === "HP"?"Himachal Pradesh":
        profile.state === "JK"?"Jammu and Kashmir":
        profile.state === "JH"?"Jharkhand":
        profile.state === "KA"?"Karnataka":
        profile.state === "KL"?"Kerala":
        profile.state === "MP"?"Madhya Pradesh":
        profile.state === "MH"?"Maharashtra":
        profile.state === "MN"?"Manipur":
        profile.state === "ML"?"Meghalaya":
        profile.state === "MZ"?"Mizoram":
        profile.state === "NL"?"Nagaland":
        profile.state === "OD"?"Odisha":
        profile.state === "PY"?"Puducherry":
        profile.state === "PB"?"Punjab":
        profile.state === "PJ"?"Rajasthan":
        profile.state === "TN"?"Tamil Nadu":
        profile.state === "TS"?"Telangana":
        profile.state === "TR"?"Tripura":
        profile.state === "UP"?"Uttar Pradesh":
        profile.state === "UK"?"Uttarakhand":
        profile.state === "WB"?"West Bengal":
        profile.state,
        pincode: pincode ? pincode.toString() : profile.pinCode,
        type: "Franchise",
      };
    }
    else {
      payload = {
        phoneNumber: profile.mobile,
        email: profile.email,
        contactPerson: name ? name : profile.firstName,
        Localities: localities,
        city:localities? localities:profile.city,
        state: selectedState ? selectedState : profile.state === "AP"?"Andhra Pradesh":
        profile.state === "AS"?"Assam":
        profile.state === "BR"?"Bihar":
        profile.state === "CT"?"Chhattisgarh":
        profile.state === "DL"?"Delhi":
        profile.state === "GA"?"Goa":
        profile.state === "GJ"?"Gujarat":
        profile.state === "HR"?"Haryana":
        profile.state === "HP"?"Himachal Pradesh":
        profile.state === "JK"?"Jammu and Kashmir":
        profile.state === "JH"?"Jharkhand":
        profile.state === "KA"?"Karnataka":
        profile.state === "KL"?"Kerala":
        profile.state === "MP"?"Madhya Pradesh":
        profile.state === "MH"?"Maharashtra":
        profile.state === "MN"?"Manipur":
        profile.state === "ML"?"Meghalaya":
        profile.state === "MZ"?"Mizoram":
        profile.state === "NL"?"Nagaland":
        profile.state === "OD"?"Odisha":
        profile.state === "PY"?"Puducherry":
        profile.state === "PB"?"Punjab":
        profile.state === "PJ"?"Rajasthan":
        profile.state === "TN"?"Tamil Nadu":
        profile.state === "TS"?"Telangana":
        profile.state === "TR"?"Tripura":
        profile.state === "UP"?"Uttar Pradesh":
        profile.state === "UK"?"Uttarakhand":
        profile.state === "WB"?"West Bengal":
        profile.state,
        pincode: pincode ? pincode.toString() : profile.pinCode,
        type: "Franchise",
      };
    }



    try {
      const res = await http.post("partnerEnquiryForm/partner", payload);
      console.log(res);
      setSelectedState('');
      formRef.current.resetFields();
      message.success("Thank You! Our support team will contact you shortly.");
    } catch (error) {
      console.log(error);
      message.error("Error Occurred");
    }
  };

  const filterOption = (input, option) =>
    option?.label?.toLowerCase().includes(input.toLowerCase());


  return (
    <>
      <ToastContainer />
      <Layouts>
        <SubHeader />
        <section className="home-banner md:min-h-[60vh] py-8 ">
          <div className="container mx-auto">
            <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
            <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
              <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                  Join the movement & become a{" "}
                  <span className="text-secondaryColor">
                    CureBay Franchisee
                  </span>
                  !
                </h1>
                <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                  Be a Hero in India’s Health Revolution as a CureBay Franchisee
                </p>
              </div>
              <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                <div className="w-full md:w-[90%] md:h-[400px] rounded ">
                  <img
                    src="https://storage.googleapis.com/curebay-nonprod-application-data/B2C%20Images/Banner/Franchisee.webp"
                    alt="images"
                    className="w-full h-full image-boxshadow rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightGray">
          <div className="container mx-auto py-[50px] md:py-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-[50px]">
              Benefits of partnering with <br />
              <span className="text-secondaryColor"> CureBay</span>
            </h2>
            <div className="md:w-[70%] mx-auto grid md:grid-cols-2 gap-5">
              <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow  hoverWhite">
                <div className="mb-5">
                  <img src={HealthcareSolutions} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Healthcare Solutions
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Access digital platforms and medical-grade devices.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                <div className="mb-5">
                  <img src={LowInversment} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Low Investment
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Benefit from our cost-effective model.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                <div className="mb-5">
                  <img src={WildRange} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  Wide range of services
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Get medications, lab tests, and hospital services.
                </p>
              </div>
              <div className="bg-White hover:bg-primaryColor rounded-xl px-8 py-5 common-shadow hoverWhite">
                <div className="mb-5">
                  <img src={HighReturn} alt="images" />
                </div>
                <h5 className="text-primaryColor text-xl font-semibold mb-3 ">
                  High Return of Investment
                </h5>
                <p className="text-textGray md:leading-loose text-base">
                  Enjoy greater returns on investment.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-White md:bg-lightGray">
          <div className="container mx-auto pt-[50px] md:pt-0  pb-[90px]">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-5 md:mb-[50px]">
              Join us as a<span className="text-secondaryColor"> Partner</span>
            </h2>
            <div className="bg-White rounded-xl md:w-[90%] mx-auto md:p-10 md:common-shadow">
              <Form
                ref={formRef}
                layout="vertical"
                autoComplete="off"
                className="CommonForm grid md:grid-cols-2 md:gap-5"
              >
                <Form.Item name="name" label={<span>Name<span className="text-danger">*</span></span>}>
                  <Input placeholder="Enter your name" defaultValue={profile.firstName} />
                </Form.Item>
                <Form.Item name="phone" label={<span>Phone Number<span className="text-danger">*</span></span>}>
                  <InputNumber
                    disabled={Object.keys(userData).length === 0 ? false : true}
                    placeholder="Enter number"
                    defaultValue={profile.mobile}
                    onKeyPress={handleKeyPress}
                    maxLength={10}
                  />
                </Form.Item>
                <Form.Item name="email" label={<span>Email Address<span className="text-danger">*</span></span>}>
                  <Input disabled={Object.keys(userData).length === 0 ? false : true} placeholder="Enter email" defaultValue={profile.email} />
                </Form.Item>
                <Form.Item name="localities" label={<span>Localities<span className="text-danger">*</span></span>}>
                  <Input placeholder="Enter locality" defaultValue={profile.city} />
                </Form.Item>
                <Form.Item name="state" label={<span>State<span className="text-danger">*</span></span>}>
                  <Select
                    showSearch
                    onChange={handleChange}
                    filterOption={filterOption}
                    placeholder="Select your state"
                    defaultValue={profile.state === "AP" ? "Andhra Pradesh" :
                      profile.state === "AS" ? "Assam" :
                        profile.state === "BR" ? "Bihar" :
                          profile.state === "CT" ? "Chhattisgarh" :
                            profile.state === "DL" ? "Delhi" :
                              profile.state === "GA" ? "Goa" :
                                profile.state === "GJ" ? "Gujarat" :
                                  profile.state === "HR" ? "Haryana" :
                                    profile.state === "HP" ? "Himachal Pradesh" :
                                      profile.state === "JK" ? "Jammu and Kashmir" :
                                        profile.state === "JH" ? "Jharkhand" :
                                          profile.state === "KA" ? "Karnataka" :
                                            profile.state === "KL" ? "Kerala" :
                                              profile.state === "MP" ? "Madhya Pradesh" :
                                                profile.state === "MH" ? "Maharashtra" :
                                                  profile.state === "MN" ? "Manipur" :
                                                    profile.state === "ML" ? "Meghalaya" :
                                                      profile.state === "MZ" ? "Mizoram" :
                                                        profile.state === "NL" ? "Nagaland" :
                                                          profile.state === "OD" ? "Odisha" :
                                                            profile.state === "PY" ? "Puducherry" :
                                                              profile.state === "PB" ? "Punjab" :
                                                                profile.state === "PJ" ? "Rajasthan" :
                                                                  profile.state === "TN" ? "Tamil Nadu" :
                                                                    profile.state === "TS" ? "Telangana" :
                                                                      profile.state === "TR" ? "Tripura" :
                                                                        profile.state === "UP" ? "Uttar Pradesh" :
                                                                          profile.state === "UK" ? "Uttarakhand" :
                                                                            profile.state === "WB" ? "West Bengal" :
                                                                              profile.state
                    }
                    optionFilterProp="children"
                    options={states.map((state) => {
                      return {
                        label: state.description,
                        value: state.description,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item name="pincode" label={<span>PIN Code<span className="text-danger">*</span></span>}>
                  <Input
                    placeholder="Enter pincode"
                    defaultValue={profile.pinCode}
                    onKeyPress={handleKeyPress}
                    maxLength={6}
                  />
                </Form.Item>
              </Form>
              <button
                className="primary_btn !w-full mt-8 !h-[50px] !text-lg"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
}

export default Franchise;